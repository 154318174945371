<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-3 my-2">
      <span class="form-title" :class="color">{{ label }}</span>
      <span v-if="compulsory && !disabled" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-9 my-2 input-group" :class="unit">
      <input
        v-model="mValue"
        :min="min"
        :max="max ? max : null"
        type="number"
        :step="step"
        class="form-control"
        :class="{ 'field-error': errorMessage }"
        :placeholder="placeholder"
        :disabled="disabled"
        @keydown="preventKeyCode"
        @focusout="handleFocusOut"
        @submit.prevent="$emit('submitHandle')" />
      <div v-if="unit" class="input-group-append">
        <span class="input-group-text">{{ unit }}</span>
      </div>
      <p v-if="errorMessage" class="error">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: { type: String, default: "bg-blue-light" },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    modelValue: { type: [Number, String], default: null },
    compulsory: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
    unit: { type: String, default: "" },
    min: { type: Number, default: 0 },
    max: { type: Number, default: null },
    step: { type: Number, default: 1 },
    charCodeList: { type: Array, default: () => [",", "+", "-", "e"] },
  },
  emits: ["update:modelValue", "focus-out"],
  computed: {
    mValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        value = value >= 0 ? value : 0;
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleFocusOut() {
      this.$emit("focus-out", this.mValue);
    },
    preventKeyCode($event) {
      if (["", undefined, null].includes(this.modelValue)) {
        if (["."].includes($event.key)) {
          $event.preventDefault();
        }
      }
      if (this.charCodeList.includes($event.key)) {
        $event.preventDefault();
      }
    },
  },
};
</script>
