<template>
  <BaseLayout>
    <CustomerTitle />
    <CustomerBody :customer-data="customer" />
    <CustomerActionTab />
  </BaseLayout>
</template>

<script>
import { getCustomerDetail } from "/src/service/customers.service.js";

import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import CustomerTitle from "/src/components/customers/commons/CustomerTitle.vue";
import CustomerBody from "/src/components/customers/commons/CustomerBody.vue";
import CustomerActionTab from "/src/components/customers/action/CustomerActionTab.vue";

export default {
  components: {
    BaseLayout,
    CustomerTitle,
    CustomerBody,
    CustomerActionTab,
  },
  data() {
    return {
      customer: null,
    };
  },
  created() {
    window.scrollTo({ top: 0 });
    this.fetchCustomerDetail();
  },
  methods: {
    fetchCustomerDetail() {
      getCustomerDetail(this.$route.params.customer_id).then((response) => {
        this.customer = response.data;
      });
    },
  },
};
</script>
