<template>
  <div class="pagi-info">
    <p class="m-0">{{ start }}-{{ end }}/{{ total }}</p>
  </div>
</template>

<script>
export default {
  props: {
    page: { type: Number, default: 0 },
    size: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
  },
  computed: {
    start() {
      // count from 1
      return (this.page - 1) * this.size + 1;
    },
    end() {
      return Math.min(this.page * this.size, this.total);
    },
  },
};
</script>
