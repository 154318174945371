<template>
  <div class="cus-filter">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-12">
          <label>条件絞り込み</label>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="input-group">
                  <input
                    v-model.trim="formData.full_name_kanji"
                    type="text"
                    class="form-control"
                    placeholder="東急太郎" />
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="input-group">
                  <input
                    v-model.trim="formData.full_name_furigana"
                    type="text"
                    class="form-control"
                    placeholder="トウキュウタロウ" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <label class="text-nowrap my-0 mr-3">住所</label>
                  <FieldSelect2
                    v-model="formData.address_prefecture"
                    class="custom-select2 w-100"
                    sub-class="form-control floor1-select2"
                    :options="prefectures" />
                  <span class="mx-2"></span>
                  <input
                    v-model.trim="formData.address_district"
                    type="text"
                    class="form-control"
                    placeholder="市区町村" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="input-group">
                  <input
                    v-model.trim="formData.email"
                    type="email"
                    class="form-control"
                    placeholder="メールアドレス" />
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="input-group">
                  <input
                    v-model.trim="formData.telephone_number"
                    type="text"
                    class="form-control"
                    placeholder="電話番号" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 my-3">
          <div class="text-center">
            <Button
              class="btn-blue-light px-3 m-2 w-105"
              text="絞り込み"
              @click="navigateToSearch()" />
            <Button
              class="btn-grey px-3 m-2"
              text="検索条件クリア"
              :disabled="!isClearable"
              @click="clearFormData()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getParamsUrl } from "/src/service/utility.service.js";
import { prefecturesOfJapan } from "/src/helpers/select-options.js";

import FieldSelect2 from "/src/components/utilitys/inputs/FieldSelect2.vue";
import Button from "/src/components/utilitys/buttons/Button.vue";

export default {
  components: {
    FieldSelect2,
    Button,
  },
  emits: ["reload-data"],
  data() {
    return {
      formData: { ...this.initialFormData() },
      prefectures: prefecturesOfJapan,
    };
  },
  computed: {
    isClearable() {
      return Object.values(this.formData).some((value) => value);
    },
    activeParams() {
      // Drop empty values to make search parameters
      const result = { page: 1 };
      for (const key in this.formData) {
        const value = this.formData[key];
        if (value !== null && value !== undefined && value !== "") {
          result[key] = value;
        }
      }
      return result;
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.formData = { ...this.initialFormData() };
      },
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener("keydown", this.enterToSearch);
  },
  unmounted() {
    document.removeEventListener("keydown", this.enterToSearch);
  },
  methods: {
    clearFormData() {
      // Clear all formData
      for (const key in this.formData) this.formData[key] = "";
    },
    navigateToSearch() {
      const queryString = new URLSearchParams(this.activeParams).toString();
      if ("?" + queryString === location.search) this.$emit("reload-data");
      this.$router.push(`${location.pathname}?${queryString}`);
    },
    initialFormData() {
      const params = getParamsUrl();
      return {
        full_name_kanji: params.full_name_kanji || "",
        full_name_furigana: params.full_name_furigana || "",
        address_prefecture: params.address_prefecture || "",
        address_district: params.address_district || "",
        email: params.email || "",
        telephone_number: params.telephone_number || "",
      };
    },
    enterToSearch(e) {
      if (e.key == "Enter") this.navigateToSearch();
    },
  },
};
</script>
