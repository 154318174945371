export const backToCustomerAction = function (
  currentComponent,
  customerId,
  actionType,
  tab = "TabPanelContract"
) {
  const routeName = currentComponent.$route.name;
  // Navigate back to TLI07003 screen
  currentComponent.$router.replace({
    name: "customer-action",
    params: { customer_id: customerId },
    query: {
      tab: tab,
      outcome: `${routeName}-${actionType}`,
    },
  });
};
