<template>
  <ModalLayout>
    <div class="fs-16 my-4">{{ stopFlagInfo.message }}</div>
    <div class="text-center">
      <Button
        class="btn-blue-light mx-2 w-125 fs-14"
        text="はい"
        data-dismiss="modal"
        @click="submitChangeFlag()" />
      <Button
        class="btn-grey mx-2 w-125 fs-14"
        text="いいえ"
        data-dismiss="modal"
        @click="showAlert(stopFlagInfo.noResponse)" />
    </div>
  </ModalLayout>
</template>

<script>
import { updateRecommendStopFlag } from "/src/service/customers.service.js";
import { mapState, mapActions } from "vuex";

import ModalLayout from "/src/components/layouts/ModalLayout.vue";
import Button from "/src/components/utilitys/buttons/Button.vue";

export default {
  components: {
    ModalLayout,
    Button,
  },
  props: {
    stopFlagInfo: { type: Object, default: null },
  },
  data() {
    return {
      isUpdated: false,
    };
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
  },
  watch: {
    isLoading(isLoading) {
      // Show alert and reload data after success updated
      if (!isLoading && this.isUpdated) {
        // reset isUpdated
        this.isUpdated = false;
        // Show alert message
        this.showAlert(this.stopFlagInfo.yesResponse);
        // Emit reload data event
        this.$emit("reload-data");
      }
    },
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    async submitChangeFlag() {
      // Call API
      const response = await updateRecommendStopFlag(
        this.$route.params.customer_id,
        this.stopFlagInfo.newFlag
      );

      this.isUpdated = response.status === 200;
    },
  },
};
</script>
