<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-4 my-2">
      {{ label }}
      <span v-if="mandatory" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-7 my-2">
      <div
        v-for="(item, index) in checkOptions"
        :key="index"
        class="d-inline-block">
        <div class="form-check custom-checkbox ml-3">
          <input
            :id="item.value"
            class="form-check-input"
            type="radio"
            :value="item.value"
            :checked="modelValue == item.value"
            @change="handleCheck(item.value, $event.target.checked)" />
          <label :for="item.value">
            {{ item.label }}
          </label>
        </div>
      </div>
      <p v-show="error !== ''" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { type: Number, default: null },
    checkOptions: { type: Array, default: () => [] },
    label: { type: String, default: "" },
    error: { type: String, default: "" },
    mandatory: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  methods: {
    handleCheck: function (value, isChecked) {
      let mValue;
      if (isChecked) mValue = value;
      else mValue = null;
      this.$emit("update:modelValue", mValue);
    },
  },
};
</script>
