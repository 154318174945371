<template>
  <div class="custom-pagi my-4">
    <paginate
      :page-count="pages"
      :page-range="checkRange()"
      :margin-pages="1"
      :initial-page="initialPage"
      :click-handler="changePageInterface"
      :hide-prev-next="true"
      :prev-class="'page-item prev-next-item'"
      :next-class="'page-item prev-next-item'"
      :prev-text="pprev"
      :next-text="pnext"
      :container-class="'pagination justify-content-center'"
      :page-class="'page-item'">
    </paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
export default {
  name: "PaginateInterface",
  components: {
    paginate: Paginate,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 1,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    pageRange: {
      type: Number,
      default: 5,
    },
    size: {
      type: Number,
      default: 50,
    },
    clickHandler: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      pprev: '<i class="fas fa-chevron-left"></i>',
      pnext: '<i class="fas fa-chevron-right"></i>',
    };
  },
  methods: {
    changePageInterface(numpage) {
      if (this.clickHandler != null) return this.clickHandler(numpage);
    },
    checkRange() {
      if (this.initialPage < 6) return 6;
      if (this.initialPage == this.pages - 4) return 7;
      return 5;
    },
  },
};
</script>
