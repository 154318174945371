<template>
  <div class="row mb-4">
    <div class="col-md-12">
      <h3 class="card-title-cus">販売受託・ルジェンテ新築物件</h3>
    </div>
  </div>
  <div class="cus-filter">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <label class="text-nowrap my-2 mr-4 form-check-label">物件名 </label>
        </div>
        <div class="col-sm-10">
          <div class="cus-colWidth">
            <div class="input-group">
              <input
                v-model="property.name"
                type="text"
                maxlength="99"
                class="form-control"
                placeholder="物件名"
                disabled />
            </div>
            <div class="row mt-4">
              <div class="col-sm-6 col-xl-5 mt-3">
                <UploadImageFile
                  id="image1"
                  v-model="property.appearance_image_link"
                  text-in-image="外観アップロード"
                  @focusout="
                    dataParams.appearance_image = $event;
                    removeEmptyErrorMessage(
                      dataParams.appearance_image,
                      'appearance_image'
                    );
                  " />
                <div class="text-center fs-16 my-2">
                  外観画像 <span class="badge badge-red">必 須</span>
                  <p
                    v-if="errorObject.appearance_image"
                    class="error text-nowrap">
                    {{ errorObject.appearance_image }}
                  </p>
                </div>
              </div>
              <div class="col-sm-6 col-xl-5 mt-3">
                <UploadImageFile
                  id="image2"
                  v-model="property.guide_map_image_link"
                  text-in-image="画像アップロード"
                  @focusout="
                    dataParams.guide_map_image = $event;
                    removeEmptyErrorMessage(
                      dataParams.guide_map_image,
                      'guide_map_image'
                    );
                  " />
                <div class="text-center fs-16 my-2">
                  現地案内図 <span class="badge badge-red">必 須</span>
                  <p
                    v-if="errorObject.guide_map_image"
                    class="error text-nowrap">
                    {{ errorObject.guide_map_image }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-relative text-right mb-2">
    <div class="page-note text-left">
      ※お客様への提示項目は色付きで表示しています
    </div>
  </div>
  <div class="wrap-ct">
    <div class="my-4 mx-3 mx-md-5">
      <div class="custom-form col-xl-11">
        <form>
          <FieldInputText
            v-model="property.building_number"
            :disabled="true"
            label="棟番号"
            color=""
            placeholder="棟番号"
            max-length="99" />
          <div class="row">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">所在地</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <textarea
                type="text"
                name=""
                class="form-control minh-120"
                placeholder="所在地"
                :value="
                  func.showValue(
                    func.showValue(property.location_prefecture) +
                      func.showValue(property.location_district) +
                      func.showValue(property.location_detail)
                  )
                "
                disabled></textarea>
            </div>
          </div>
          <FieldInputText
            :model-value="
              interpolationStation(
                property.nearest_station_railway_1,
                property.nearest_station_1,
                property.walking_to_nearest_station_1
              )
            "
            label="最寄駅①"
            :disabled="true"
            placeholder="最寄駅①" />
          <FieldInputText
            :model-value="
              func.showValue(property.bus_to_nearest_station_1, {
                suffix: '分',
                includeZero: true,
              })
            "
            :disabled="true"
            color=""
            label="最寄駅①までバス"
            placeholder="最寄駅①までバス" />
          <FieldInputText
            label="最寄駅②"
            :disabled="true"
            placeholder="最寄駅②"
            :model-value="
              interpolationStation(
                property.nearest_station_railway_2,
                property.nearest_station_2,
                property.walking_to_nearest_station_2
              )
            " />
          <FieldInputText
            :model-value="
              func.showValue(property.bus_to_nearest_station_2, {
                suffix: '分',
                includeZero: true,
              })
            "
            :disabled="true"
            color=""
            label="最寄駅②までバス"
            placeholder="最寄駅②までバス" />
          <FieldInputText
            label="最寄駅③"
            :disabled="true"
            placeholder="最寄駅③"
            :model-value="
              interpolationStation(
                property.nearest_station_railway_3,
                property.nearest_station_3,
                property.walking_to_nearest_station_3
              )
            " />
          <FieldInputText
            :model-value="
              func.showValue(property.bus_to_nearest_station_3, {
                suffix: '分',
                includeZero: true,
              })
            "
            :disabled="true"
            color=""
            label="最寄駅③までバス"
            placeholder="最寄駅③までバス" />
          <FieldShortInput
            label1="区画最低価格"
            :value1="
              func.showValue(
                func.formatNumber(
                  func.convertAndRoundToMY(property.block_price_min)
                ),
                {
                  suffix: '万円',
                  includeZero: true,
                }
              )
            "
            color1=""
            color2=""
            :disable1="true"
            :disable2="true"
            label2="区画最高価格"
            :value2="
              func.showValue(
                func.formatNumber(
                  func.convertAndRoundToMY(property.block_price_max)
                ),
                {
                  suffix: '万円',
                  includeZero: true,
                }
              )
            " />
          <FieldShortInput
            label1="区画最低間取り"
            :value1="
              func.convertEnum(
                property.block_layout_type_min,
                BLOCK_LAYOUT_TYPE
              )
            "
            color1=""
            color2=""
            :disable1="true"
            :disable2="true"
            label2="区画最高間取り"
            :value2="
              func.convertEnum(
                property.block_layout_type_max,
                BLOCK_LAYOUT_TYPE
              )
            " />
          <FieldShortInput
            label1="区画最低面積"
            :value1="
              func.showValue(
                property.block_area_min
                  ? func.formatNumber(property.block_area_min, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : func.formatNumber(property.block_area_min),
                {
                  suffix: '㎡',
                  includeZero: true,
                }
              )
            "
            color1=""
            color2=""
            :disable1="true"
            :disable2="true"
            label2="区画最高面積"
            :value2="
              func.showValue(
                property.block_area_max
                  ? func.formatNumber(property.block_area_max, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : func.formatNumber(property.block_area_max),
                {
                  suffix: '㎡',
                  includeZero: true,
                }
              )
            " />
          <FieldInputText
            :model-value="property.structure_and_scale"
            :disabled="true"
            label="構造・規模"
            placeholder="構造・規模" />
          <FieldInputText
            :model-value="
              func.showValue(func.formatNumber(property.number_of_houses), {
                suffix: '戸',
                includeZero: true,
              })
            "
            label="総戸数"
            :disabled="true"
            placeholder="総戸数" />
          <FieldInputText
            :model-value="
              func.showValue(func.formatNumber(property.number_of_floors), {
                suffix: '階',
                includeZero: true,
              })
            "
            :disabled="true"
            placeholder="階建"
            color=""
            label="階建" />
          <FieldInputText
            :model-value="
              func.showValue(
                property.site_area
                  ? func.formatNumber(property.site_area, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : func.formatNumber(property.site_area),
                {
                  suffix: '㎡',
                  includeZero: true,
                }
              )
            "
            :disabled="true"
            label="敷地面積"
            placeholder="敷地面積" />
          <FieldInputText
            v-model="property.use_district"
            label="用途地域"
            :class="{ 'field-error': errorObject.use_district }"
            placeholder="用途地域"
            max-length="99"
            :compulsory="true"
            :error-message="errorObject.use_district"
            @focusout="
              removeEmptyErrorMessage(property.use_district, 'use_district')
            " />
          <FieldInputText
            :model-value="property.building_confirmation_number"
            :disabled="true"
            label="建築確認番号"
            placeholder="建築確認番号"
            max-length="99" />
          <FieldInputText
            :model-value="
              func.formatDate(
                property.acquisition_date_of_building_confirmation,
                { dateStyle: 'long' }
              )
            "
            label="確認番号取得日"
            :disabled="true"
            placeholder="確認番号取得日" />
          <FieldInputText
            :model-value="
              func.formatDate(property.completion_date, { dateStyle: 'long' })
            "
            :disabled="true"
            placeholder="竣工日"
            label="竣工日" />
          <FieldInputText
            v-model="property.right_type"
            label="権利形態"
            :class="{ 'field-error': errorObject.right_type }"
            placeholder="権利形態"
            max-length="99"
            :compulsory="true"
            :error-message="errorObject.right_type"
            @focus-out="
              removeEmptyErrorMessage(property.right_type, 'right_type')
            " />
          <FieldInputText
            :key="dataSalesCompanies"
            :model-value="property.seller_owner_1_?.sales_company_name"
            label="売主1"
            placeholder="売主1"
            :disabled="true" />
          <FieldInputText
            :key="dataSalesCompanies"
            :model-value="property.seller_owner_2_?.sales_company_name"
            label="売主2"
            placeholder="売主2"
            :disabled="true" />
          <FieldInputText
            :key="dataSalesCompanies"
            :model-value="property.seller_owner_3_?.sales_company_name"
            label="売主3"
            placeholder="売主3"
            :disabled="true" />
          <FieldInputText
            :key="dataSalesCompanies"
            :model-value="property.seller_owner_4_?.sales_company_name"
            label="売主4"
            placeholder="売主4"
            :disabled="true" />
          <FieldInputText
            :key="dataSalesCompanies"
            :model-value="property.seller_owner_5_?.sales_company_name"
            label="売主5"
            placeholder="売主5"
            :disabled="true" />
          <FieldInputText
            :model-value="property.construction_company"
            :disabled="true"
            label="施工会社"
            placeholder="施工会社" />
          <FieldInputText
            v-model="property.management_company"
            :disabled="true"
            placeholder="管理会社"
            label="管理会社" />
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">管理形態</span>
              <span class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.management_type"
                  label="管理形態"
                  :class="{ 'field-error': errorObject.management_type }"
                  :options="MANAGEMENT_TYPE"
                  :keyid="'value'"
                  :keytext="'label'"
                  placeholder="管理形態"
                  :change="
                    removeEmptyErrorMessage(
                      selectFields.management_type,
                      'management_type'
                    )
                  " />
              </div>
              <p v-if="errorObject.management_type" class="error">
                {{ errorObject.management_type }}
              </p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">管理員の勤務形態</span>
              <span class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.manager_working_style"
                  label="管理員の勤務形態"
                  :class="{
                    'field-error': errorObject.manager_working_style,
                  }"
                  :options="MANAGER_WORKING_STYLE"
                  :keyid="'value'"
                  :keytext="'label'"
                  placeholder="管理員の勤務形態"
                  :change="
                    removeEmptyErrorMessage(
                      selectFields.manager_working_style,
                      'manager_working_style'
                    )
                  " />
              </div>
              <p v-if="errorObject.manager_working_style" class="error">
                {{ errorObject.manager_working_style }}
              </p>
            </div>
          </div>
          <FieldInputText
            :model-value="
              func.showValue(func.formatNumber(property.parking_lot_count), {
                suffix: '台',
                includeZero: true,
              })
            "
            :disabled="true"
            placeholder="駐車場台数"
            label="駐車場台数" />
          <FieldInputText
            v-model="property.parking_lot_note"
            label="駐車場備考"
            max-length="9999"
            placeholder="駐車場備考"
            :error-message="errorObject.parking_lot_note" />
          <FieldCheckBox
            :id="1"
            v-model="property.concierge_service"
            label="コンシェルジュサービス" />
          <FieldCheckBox
            :id="2"
            v-model="property.all_day_manned_management"
            label="24時間有人管理" />
          <FieldInputText
            v-model="property.trade_type"
            :disabled="true"
            :error-message="errorObject.trade_type"
            placeholder="取引形態"
            max-length="99"
            label="取引形態"
            @focus-out="
              removeEmptyErrorMessage(property.trade_type, 'trade_type')
            " />
          <div class="row">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">備考</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <textarea
                v-model="property.property_note"
                type="text"
                name=""
                max-length="9999"
                class="form-control minh-120"
                placeholder="備考"></textarea>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title">レコメンド対象除外<br />フラグ</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="form-check custom-checkbox mx-3">
                <input
                  id="option1"
                  v-model="property.recommend_stop_flag"
                  class="form-check-input"
                  type="checkbox" />
                <label for="option1"></label>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title">見学予約ボタン設置<br />フラグ</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="form-check custom-checkbox mx-3">
                <input
                  id="option2"
                  v-model="property.tour_available_flag"
                  class="form-check-input"
                  type="checkbox" />
                <label for="option2"></label>
              </div>
            </div>
          </div>
          <FieldInputText
            :model-value="convertDateTime(property?.created_at)"
            :disabled="true"
            color=""
            placeholder="登録日時"
            :label="`登録日時`" />
          <FieldInputText
            :model-value="convertDateTime(property?.updated_at)"
            :disabled="true"
            color=""
            placeholder="更新日時"
            :label="`更新日時`" />
          <FieldInputText
            :model-value="property?.updated_by_user?.name"
            :disabled="true"
            color=""
            placeholder="更新者"
            :label="`更新者`" />
          <div class="row justify-content-end">
            <div class="col-12 col-md-9">
              <div class="my-4 text-center">
                <button
                  type="button"
                  class="btn btn-cus btn-blue-light m-2 ml-md-0 px-4 minw-100"
                  :disabled="!isFormChange"
                  @click="submitHandle">
                  変 更
                </button>
                <button
                  type="button"
                  class="btn btn-cus btn-grey m-2 w-150"
                  data-toggle="modal"
                  data-target="#existModal">
                  キャンセル
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  roundDecimal,
  convertValueToString,
  interpolationStation,
  scrollToTopElement,
} from "/src/components/utilitys/utility.js";
import {
  BLOCK_LAYOUT_TYPE,
  MANAGEMENT_TYPE,
  MANAGER_WORKING_STYLE,
  ORIGINAL_LOCATION,
} from "/src/constants";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2.vue";
import { testImageInvalid } from "/src/service/properties.service.js";
import {
  FieldInputText,
  FieldCheckBox,
  FieldShortInput,
  UploadImageFile,
} from "/src/components/properties/utility";
export default {
  components: {
    FieldSelect2,
    FieldInputText,
    FieldShortInput,
    FieldCheckBox,
    UploadImageFile,
  },
  props: {
    originalProperty: {
      type: Object,
      default: () => null,
    },
    selectList: {
      type: Array,
      default: () => ["management_type", "manager_working_style"],
    },
    notCheckList: {
      type: Array,
      default: () => [
        "appearance_image_link",
        "guide_map_image_link",
        "location_prefecture",
        "location_district",
        "nearest_station_railway_1",
        "nearest_station_1 ",
        "completion_date",
      ],
    },
  },
  data() {
    let property = { ...this.originalProperty };
    let selectFields = {
      management_type: null,
      manager_working_style: null,
    };
    for (let key in selectFields) {
      if (this.originalProperty && this.originalProperty[key]) {
        selectFields[key] = convertValueToString(this.originalProperty[key]);
      }
    }
    return {
      property,
      selectFields,
      compulsoryList: [
        "use_district",
        "right_type",
        "management_type",
        "manager_working_style",
      ],
      dataParams: {
        appearance_image: "",
        guide_map_image: "",
      },
      errorObject: {},
      dataSalesCompanies: [],
      postField: [
        "appearance_image_link",
        "guide_map_image_link",
        "use_district",
        "right_type",
        "management_type",
        "manager_working_style",
        "parking_lot_note",
        "concierge_service",
        "all_day_manned_management",
        "property_note",
        "tour_available_flag",
        "recommend_stop_flag",
      ],
      BLOCK_LAYOUT_TYPE,
      MANAGEMENT_TYPE,
      MANAGER_WORKING_STYLE,
      ERROR_MESSAGE: {
        1: "1桁の半角数字のみ入力可能です。",
        2: "2桁の半角数字のみ入力可能です。",
        4: "4桁の半角数字のみ入力可能です。",
        3: "3桁の半角数字のみ入力可能です。",
        6: "6桁の半角数字と小数点のみ入力可能です。",
        REQUIRED: "入力項目に不足、または不備があります。",
      },
    };
  },
  computed: {
    isFormChange() {
      // Check if form is changed based on property and selectFields
      for (let key in this.property) {
        if (this.property[key] != this.originalProperty[key]) {
          return true;
        }
      }
      for (let key in this.selectFields) {
        if (
          this.selectFields[key] !=
          convertValueToString(this.originalProperty[key])
        ) {
          return true;
        }
      }
      if (this.dataParams.appearance_image || this.dataParams.guide_map_image) {
        return true;
      }
      return false;
    },
    tli() {
      return (
        this.property.location_of_original_data === ORIGINAL_LOCATION.DYNAMIC
      );
    },
  },
  created() {
    if (this.property["site_area"]) {
      this.property["site_area"] = roundDecimal(this.property["site_area"]);
    }
  },
  methods: {
    interpolationStation(
      nearest_railway,
      nearest_station,
      walk_to_near_station
    ) {
      return interpolationStation(
        nearest_railway,
        nearest_station,
        walk_to_near_station
      );
    },
    convertDateTime(date) {
      // increase space between date and time
      if (!date) {
        return "";
      }
      let dateTimeArray = this.func
        .formatDateToParts(date, {
          dateStyle: "long",
          timeStyle: "short",
        })
        .map((item) => item.value);
      // convert month and date to 2 digits
      if (parseInt(dateTimeArray[2]) < 10) {
        dateTimeArray[2] = "0" + dateTimeArray[2];
      }
      if (parseInt(dateTimeArray[4]) < 10) {
        dateTimeArray[4] = "0" + dateTimeArray[4];
      }
      if (parseInt(dateTimeArray[6]) < 10) {
        dateTimeArray[6] = "0" + dateTimeArray[6];
      }
      return (
        dateTimeArray.slice(0, 6).join("") +
        "   " +
        dateTimeArray.slice(6).join("")
      );
    },
    // VALIDATE
    validateRequired(value) {
      if ([undefined, null, ""].includes(value)) {
        return false;
      }
      return true;
    },
    removeEmptyErrorMessage(value, field, errorObject = this.errorObject) {
      if (this.validateRequired(value)) {
        if (errorObject[field] == this.ERROR_MESSAGE.REQUIRED) {
          errorObject[field] = "";
        }
      }
    },
    async validateForm() {
      // Validate empty of fields
      this.compulsoryList.forEach((field) => {
        if (this.notCheckList.includes(field)) {
          // pass
        } else if (this.selectList.includes(field)) {
          if (!this.validateRequired(this.selectFields[field])) {
            this.errorObject[field] = this.ERROR_MESSAGE.REQUIRED;
          }
        } else if (!this.validateRequired(this.property[field])) {
          this.errorObject[field] = this.ERROR_MESSAGE.REQUIRED;
        }
      });
      // Validate images
      if (
        !(
          this.dataParams.appearance_image ||
          this.property.appearance_image_link
        )
      ) {
        this.errorObject["appearance_image"] = this.ERROR_MESSAGE.REQUIRED;
      }
      if (
        !(this.dataParams.guide_map_image || this.property.guide_map_image_link)
      ) {
        this.errorObject["guide_map_image"] = this.ERROR_MESSAGE.REQUIRED;
      }
      // Validate Image with BE
      if (this.dataParams.appearance_image) {
        let errorMessage = await testImageInvalid(
          this.dataParams.appearance_image
        );
        if (errorMessage) {
          this.errorObject.appearance_image = errorMessage;
        } else {
          this.errorObject.appearance_image = "";
        }
      }
      if (this.dataParams.guide_map_image) {
        let errorMessage = await testImageInvalid(
          this.dataParams.guide_map_image
        );
        if (errorMessage) {
          this.errorObject.guide_map_image = errorMessage;
        } else {
          this.errorObject.guide_map_image = "";
        }
      }
      // End Validate Image with BE
      for (let key in this.errorObject) {
        if (this.errorObject[key] != "") {
          return false;
        }
      }
      return true;
    },
    // END VALIDATE
    // START Submit functions
    handleData() {
      for (let key in this.selectFields) {
        this.property[key] = this.selectFields[key];
      }
      // Get only key postField from property
      let property = {};
      for (let key in this.property) {
        if (this.postField.includes(key)) {
          property[key] = this.property[key];
        }
      }
      // Clean data
      for (let key in property) {
        if (property[key] === "") {
          property[key] = null;
        }
      }
      return property;
    },
    async submitHandle() {
      if (await this.validateForm()) {
        this.$emit("submitHandle", {
          property: this.handleData(),
          appearance_image: this.dataParams.appearance_image,
          guide_map_image: this.dataParams.guide_map_image,
        });
      } else {
        scrollToTopElement([".error"]);
      }
    },
    // END Submit functions
  },
};
</script>
