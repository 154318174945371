<template>
  <div class="card">
    <div class="card-body minh-520">
      <div class="row mb-4">
        <div class="col-md-12">
          <h3 class="card-title-cus">ユーザー情報参照</h3>
        </div>
      </div>
      <div class="wrap-ct">
        <div class="custom-table custom-table-2 py-4">
          <table class="table table-striped-2">
            <tbody>
              <tr>
                <td class="border-right">名前</td>
                <td>{{ user.name }}</td>
              </tr>
              <tr>
                <td class="border-right">メールアドレス</td>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <td class="border-right">ユーザー属性</td>
                <td>
                  {{ roleToText[user.role] }}
                </td>
              </tr>
              <tr>
                <td class="border-right">フォロー担当フラグ</td>
                <td>
                  <div
                    class="form-check custom-checkbox"
                    style="pointer-events: none">
                    <input
                      id="option4"
                      v-model="user.follow_available_flag"
                      class="form-check-input"
                      type="checkbox"
                      checked
                      disabled />
                    <label for="option4"></label>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="border-right">登録日</td>
                <td>
                  {{
                    func.formatDate(
                      user.created_at,
                      { dateStyle: "long" },
                      true
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="border-right">登録者</td>
                <td>
                  {{
                    func.showValue(user.created_by_user, {
                      getProperty: "name",
                    })
                  }}
                </td>
              </tr>
              <tr>
                <td class="border-right">更新日</td>
                <td>
                  {{
                    func.formatDate(
                      user.updated_at,
                      { dateStyle: "long" },
                      true
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="border-right">更新者</td>
                <td>
                  {{
                    func.showValue(user.updated_by_user, {
                      getProperty: "name",
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mb-4 text-center">
          <button
            type="button"
            class="btn btn-cus btn-blue-light btn-ok m-2 w-150"
            @click="$router.push({ name: `users` })">
            戻 る
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserDetail } from "/src/service/users.service.js";
export default {
  name: "UserDetail",
  data() {
    return {
      user: {},
      roleToText: {
        1: "閲覧のみユーザー",
        3: "編集ユーザー",
        7: "管理者",
      },
    };
  },
  created() {
    getUserDetail(this.$route.params.user_id).then((res) => {
      this.user = res.data;
    });
  },
};
</script>
