import axios from "axios";
import { SALESFORCE_PROPERTIES_API } from "./const.service.js";

const getSalesForceProPerties = async (param) => {
  try {
    return axios.get(SALESFORCE_PROPERTIES_API, { params: param });
  } catch (error) {
    console.log(error);
  }
};

const getSalesForceDetail = async (uuid) => {
  try {
    const response = await axios.get(`${SALESFORCE_PROPERTIES_API}/${uuid}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export { getSalesForceProPerties, getSalesForceDetail };
