<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-3 my-2">
      <span class="form-title" :class="color">{{ label }}</span>
      <span v-if="compulsory && !disabled" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-9 my-2">
      <input
        v-model="mValue"
        :maxlength="maxLength"
        class="form-control"
        :class="{ 'field-error': errorMessage }"
        :disabled="disabled"
        :placeholder="placeholder"
        @focusout="handleFocusOut" />
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: { type: String, default: "bg-blue-light" },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    modelValue: { type: [String, Number], default: "" },
    compulsory: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
    maxLength: { type: String, default: "999" },
  },
  computed: {
    mValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleFocusOut() {
      this.$emit("focus-out", this.mValue);
    },
  },
};
</script>
