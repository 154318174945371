const apiRailroad = [
  {
    name: "JR在来線",
    list: [
      {
        name: "函館本線",
        railroad_cd_1: 1,
        railroad_cd_2: 51,
        reins_railroad_cd: 1051,
      },
      {
        name: "札沼線",
        railroad_cd_1: 1,
        railroad_cd_2: 55,
        reins_railroad_cd: 1055,
      },
      {
        name: "千歳線",
        railroad_cd_1: 1,
        railroad_cd_2: 56,
        reins_railroad_cd: 1056,
      },
      {
        name: "石勝線",
        railroad_cd_1: 1,
        railroad_cd_2: 57,
        reins_railroad_cd: 1057,
      },
      {
        name: "室蘭本線",
        railroad_cd_1: 1,
        railroad_cd_2: 61,
        reins_railroad_cd: 1061,
      },
      {
        name: "日高本線",
        railroad_cd_1: 1,
        railroad_cd_2: 63,
        reins_railroad_cd: 1063,
      },
      {
        name: "留萌本線",
        railroad_cd_1: 1,
        railroad_cd_2: 65,
        reins_railroad_cd: 1065,
      },
      {
        name: "根室本線",
        railroad_cd_1: 1,
        railroad_cd_2: 67,
        reins_railroad_cd: 1067,
      },
      {
        name: "富良野線",
        railroad_cd_1: 1,
        railroad_cd_2: 68,
        reins_railroad_cd: 1068,
      },
      {
        name: "宗谷本線",
        railroad_cd_1: 1,
        railroad_cd_2: 72,
        reins_railroad_cd: 1072,
      },
      {
        name: "石北本線",
        railroad_cd_1: 1,
        railroad_cd_2: 76,
        reins_railroad_cd: 1076,
      },
      {
        name: "釧網本線",
        railroad_cd_1: 1,
        railroad_cd_2: 77,
        reins_railroad_cd: 1077,
      },
      {
        name: "東北本線",
        railroad_cd_1: 2,
        railroad_cd_2: 101,
        reins_railroad_cd: 2101,
      },
      {
        name: "常磐線",
        railroad_cd_1: 2,
        railroad_cd_2: 102,
        reins_railroad_cd: 2102,
      },
      {
        name: "水郡線",
        railroad_cd_1: 2,
        railroad_cd_2: 103,
        reins_railroad_cd: 2103,
      },
      {
        name: "川越線",
        railroad_cd_1: 2,
        railroad_cd_2: 104,
        reins_railroad_cd: 2104,
      },
      {
        name: "高崎線",
        railroad_cd_1: 2,
        railroad_cd_2: 105,
        reins_railroad_cd: 2105,
      },
      {
        name: "上越線",
        railroad_cd_1: 2,
        railroad_cd_2: 106,
        reins_railroad_cd: 2106,
      },
      {
        name: "吾妻線",
        railroad_cd_1: 2,
        railroad_cd_2: 107,
        reins_railroad_cd: 2107,
      },
      {
        name: "両毛線",
        railroad_cd_1: 2,
        railroad_cd_2: 108,
        reins_railroad_cd: 2108,
      },
      {
        name: "水戸線",
        railroad_cd_1: 2,
        railroad_cd_2: 110,
        reins_railroad_cd: 2110,
      },
      {
        name: "日光線",
        railroad_cd_1: 2,
        railroad_cd_2: 112,
        reins_railroad_cd: 2112,
      },
      {
        name: "烏山線",
        railroad_cd_1: 2,
        railroad_cd_2: 113,
        reins_railroad_cd: 2113,
      },
      {
        name: "仙山線",
        railroad_cd_1: 2,
        railroad_cd_2: 115,
        reins_railroad_cd: 2115,
      },
      {
        name: "仙石線",
        railroad_cd_1: 2,
        railroad_cd_2: 116,
        reins_railroad_cd: 2116,
      },
      {
        name: "石巻線",
        railroad_cd_1: 2,
        railroad_cd_2: 117,
        reins_railroad_cd: 2117,
      },
      {
        name: "気仙沼線",
        railroad_cd_1: 2,
        railroad_cd_2: 118,
        reins_railroad_cd: 2118,
      },
      {
        name: "大船渡線",
        railroad_cd_1: 2,
        railroad_cd_2: 119,
        reins_railroad_cd: 2119,
      },
      {
        name: "北上線",
        railroad_cd_1: 2,
        railroad_cd_2: 120,
        reins_railroad_cd: 2120,
      },
      {
        name: "釜石線",
        railroad_cd_1: 2,
        railroad_cd_2: 121,
        reins_railroad_cd: 2121,
      },
      {
        name: "田沢湖線",
        railroad_cd_1: 2,
        railroad_cd_2: 122,
        reins_railroad_cd: 2122,
      },
      {
        name: "山田線",
        railroad_cd_1: 2,
        railroad_cd_2: 124,
        reins_railroad_cd: 2124,
      },
      {
        name: "花輪線",
        railroad_cd_1: 2,
        railroad_cd_2: 126,
        reins_railroad_cd: 2126,
      },
      {
        name: "八戸線",
        railroad_cd_1: 2,
        railroad_cd_2: 127,
        reins_railroad_cd: 2127,
      },
      {
        name: "大湊線",
        railroad_cd_1: 2,
        railroad_cd_2: 128,
        reins_railroad_cd: 2128,
      },
      {
        name: "磐越東線",
        railroad_cd_1: 2,
        railroad_cd_2: 129,
        reins_railroad_cd: 2129,
      },
      {
        name: "磐越西線",
        railroad_cd_1: 2,
        railroad_cd_2: 130,
        reins_railroad_cd: 2130,
      },
      {
        name: "只見線",
        railroad_cd_1: 2,
        railroad_cd_2: 131,
        reins_railroad_cd: 2131,
      },
      {
        name: "陸羽東線",
        railroad_cd_1: 2,
        railroad_cd_2: 133,
        reins_railroad_cd: 2133,
      },
      {
        name: "陸羽西線",
        railroad_cd_1: 2,
        railroad_cd_2: 134,
        reins_railroad_cd: 2134,
      },
      {
        name: "奥羽本線",
        railroad_cd_1: 2,
        railroad_cd_2: 141,
        reins_railroad_cd: 2141,
      },
      {
        name: "米坂線",
        railroad_cd_1: 2,
        railroad_cd_2: 142,
        reins_railroad_cd: 2142,
      },
      {
        name: "左沢線",
        railroad_cd_1: 2,
        railroad_cd_2: 144,
        reins_railroad_cd: 2144,
      },
      {
        name: "男鹿線",
        railroad_cd_1: 2,
        railroad_cd_2: 145,
        reins_railroad_cd: 2145,
      },
      {
        name: "五能線",
        railroad_cd_1: 2,
        railroad_cd_2: 146,
        reins_railroad_cd: 2146,
      },
      {
        name: "津軽線",
        railroad_cd_1: 2,
        railroad_cd_2: 148,
        reins_railroad_cd: 2148,
      },
      {
        name: "羽越本線",
        railroad_cd_1: 2,
        railroad_cd_2: 149,
        reins_railroad_cd: 2149,
      },
      {
        name: "白新線",
        railroad_cd_1: 2,
        railroad_cd_2: 150,
        reins_railroad_cd: 2150,
      },
      {
        name: "信越本線",
        railroad_cd_1: 2,
        railroad_cd_2: 151,
        reins_railroad_cd: 2151,
      },
      {
        name: "飯山線",
        railroad_cd_1: 2,
        railroad_cd_2: 152,
        reins_railroad_cd: 2152,
      },
      {
        name: "越後線",
        railroad_cd_1: 2,
        railroad_cd_2: 153,
        reins_railroad_cd: 2153,
      },
      {
        name: "弥彦線",
        railroad_cd_1: 2,
        railroad_cd_2: 154,
        reins_railroad_cd: 2154,
      },
      {
        name: "湘南新宿ライン宇須",
        railroad_cd_1: 2,
        railroad_cd_2: 159,
        reins_railroad_cd: 2159,
      },
      {
        name: "湘南新宿ライン高海",
        railroad_cd_1: 2,
        railroad_cd_2: 160,
        reins_railroad_cd: 2160,
      },
      {
        name: "総武本線",
        railroad_cd_1: 2,
        railroad_cd_2: 161,
        reins_railroad_cd: 2161,
      },
      {
        name: "外房線",
        railroad_cd_1: 2,
        railroad_cd_2: 162,
        reins_railroad_cd: 2162,
      },
      {
        name: "内房線",
        railroad_cd_1: 2,
        railroad_cd_2: 163,
        reins_railroad_cd: 2163,
      },
      {
        name: "京葉線",
        railroad_cd_1: 2,
        railroad_cd_2: 164,
        reins_railroad_cd: 2164,
      },
      {
        name: "成田線",
        railroad_cd_1: 2,
        railroad_cd_2: 166,
        reins_railroad_cd: 2166,
      },
      {
        name: "鹿島線",
        railroad_cd_1: 2,
        railroad_cd_2: 167,
        reins_railroad_cd: 2167,
      },
      {
        name: "久留里線",
        railroad_cd_1: 2,
        railroad_cd_2: 168,
        reins_railroad_cd: 2168,
      },
      {
        name: "東金線",
        railroad_cd_1: 2,
        railroad_cd_2: 169,
        reins_railroad_cd: 2169,
      },
      {
        name: "東海道本線",
        railroad_cd_1: 2,
        railroad_cd_2: 171,
        reins_railroad_cd: 2171,
      },
      {
        name: "山手線",
        railroad_cd_1: 2,
        railroad_cd_2: 172,
        reins_railroad_cd: 2172,
      },
      {
        name: "南武線",
        railroad_cd_1: 2,
        railroad_cd_2: 174,
        reins_railroad_cd: 2174,
      },
      {
        name: "鶴見線",
        railroad_cd_1: 2,
        railroad_cd_2: 175,
        reins_railroad_cd: 2175,
      },
      {
        name: "武蔵野線",
        railroad_cd_1: 2,
        railroad_cd_2: 176,
        reins_railroad_cd: 2176,
      },
      {
        name: "横浜線",
        railroad_cd_1: 2,
        railroad_cd_2: 177,
        reins_railroad_cd: 2177,
      },
      {
        name: "根岸線",
        railroad_cd_1: 2,
        railroad_cd_2: 178,
        reins_railroad_cd: 2178,
      },
      {
        name: "横須賀線",
        railroad_cd_1: 2,
        railroad_cd_2: 179,
        reins_railroad_cd: 2179,
      },
      {
        name: "相模線",
        railroad_cd_1: 2,
        railroad_cd_2: 180,
        reins_railroad_cd: 2180,
      },
      {
        name: "中央本線",
        railroad_cd_1: 2,
        railroad_cd_2: 184,
        reins_railroad_cd: 2184,
      },
      {
        name: "青梅線",
        railroad_cd_1: 2,
        railroad_cd_2: 185,
        reins_railroad_cd: 2185,
      },
      {
        name: "五日市線",
        railroad_cd_1: 2,
        railroad_cd_2: 186,
        reins_railroad_cd: 2186,
      },
      {
        name: "八高線",
        railroad_cd_1: 2,
        railroad_cd_2: 187,
        reins_railroad_cd: 2187,
      },
      {
        name: "小海線",
        railroad_cd_1: 2,
        railroad_cd_2: 188,
        reins_railroad_cd: 2188,
      },
      {
        name: "篠ノ井線",
        railroad_cd_1: 2,
        railroad_cd_2: 189,
        reins_railroad_cd: 2189,
      },
      {
        name: "大糸線",
        railroad_cd_1: 2,
        railroad_cd_2: 190,
        reins_railroad_cd: 2190,
      },
      {
        name: "京浜東北・根岸線",
        railroad_cd_1: 2,
        railroad_cd_2: 196,
        reins_railroad_cd: 2196,
      },
      {
        name: "千代田・常磐緩行線",
        railroad_cd_1: 2,
        railroad_cd_2: 197,
        reins_railroad_cd: 2197,
      },
      {
        name: "埼京線",
        railroad_cd_1: 2,
        railroad_cd_2: 198,
        reins_railroad_cd: 2198,
      },
      {
        name: "総武・中央緩行線",
        railroad_cd_1: 2,
        railroad_cd_2: 199,
        reins_railroad_cd: 2199,
      },
      {
        name: "伊東線",
        railroad_cd_1: 2,
        railroad_cd_2: 401,
        reins_railroad_cd: 2401,
      },
      {
        name: "東海道本線",
        railroad_cd_1: 4,
        railroad_cd_2: 171,
        reins_railroad_cd: 4171,
      },
      {
        name: "御殿場線",
        railroad_cd_1: 4,
        railroad_cd_2: 181,
        reins_railroad_cd: 4181,
      },
      {
        name: "身延線",
        railroad_cd_1: 4,
        railroad_cd_2: 182,
        reins_railroad_cd: 4182,
      },
      {
        name: "飯田線",
        railroad_cd_1: 4,
        railroad_cd_2: 183,
        reins_railroad_cd: 4183,
      },
      {
        name: "中央本線",
        railroad_cd_1: 4,
        railroad_cd_2: 184,
        reins_railroad_cd: 4184,
      },
      {
        name: "武豊線",
        railroad_cd_1: 4,
        railroad_cd_2: 408,
        reins_railroad_cd: 4408,
      },
      {
        name: "高山本線",
        railroad_cd_1: 4,
        railroad_cd_2: 418,
        reins_railroad_cd: 4418,
      },
      {
        name: "太多線",
        railroad_cd_1: 4,
        railroad_cd_2: 422,
        reins_railroad_cd: 4422,
      },
      {
        name: "関西本線",
        railroad_cd_1: 4,
        railroad_cd_2: 426,
        reins_railroad_cd: 4426,
      },
      {
        name: "紀勢本線",
        railroad_cd_1: 4,
        railroad_cd_2: 431,
        reins_railroad_cd: 4431,
      },
      {
        name: "名松線",
        railroad_cd_1: 4,
        railroad_cd_2: 432,
        reins_railroad_cd: 4432,
      },
      {
        name: "参宮線",
        railroad_cd_1: 4,
        railroad_cd_2: 433,
        reins_railroad_cd: 4433,
      },
      {
        name: "博多南線",
        railroad_cd_1: 6,
        railroad_cd_2: 50,
        reins_railroad_cd: 6050,
      },
      {
        name: "東海道本線",
        railroad_cd_1: 6,
        railroad_cd_2: 171,
        reins_railroad_cd: 6171,
      },
      {
        name: "大糸線",
        railroad_cd_1: 6,
        railroad_cd_2: 190,
        reins_railroad_cd: 6190,
      },
      {
        name: "北陸本線",
        railroad_cd_1: 6,
        railroad_cd_2: 191,
        reins_railroad_cd: 6191,
      },
      {
        name: "小浜線",
        railroad_cd_1: 6,
        railroad_cd_2: 411,
        reins_railroad_cd: 6411,
      },
      {
        name: "越美北線",
        railroad_cd_1: 6,
        railroad_cd_2: 412,
        reins_railroad_cd: 6412,
      },
      {
        name: "七尾線",
        railroad_cd_1: 6,
        railroad_cd_2: 413,
        reins_railroad_cd: 6413,
      },
      {
        name: "城端線",
        railroad_cd_1: 6,
        railroad_cd_2: 415,
        reins_railroad_cd: 6415,
      },
      {
        name: "氷見線",
        railroad_cd_1: 6,
        railroad_cd_2: 416,
        reins_railroad_cd: 6416,
      },
      {
        name: "高山本線",
        railroad_cd_1: 6,
        railroad_cd_2: 418,
        reins_railroad_cd: 6418,
      },
      {
        name: "関西本線",
        railroad_cd_1: 6,
        railroad_cd_2: 426,
        reins_railroad_cd: 6426,
      },
      {
        name: "紀勢本線",
        railroad_cd_1: 6,
        railroad_cd_2: 431,
        reins_railroad_cd: 6431,
      },
      {
        name: "湖西線",
        railroad_cd_1: 6,
        railroad_cd_2: 602,
        reins_railroad_cd: 6602,
      },
      {
        name: "大阪環状線",
        railroad_cd_1: 6,
        railroad_cd_2: 603,
        reins_railroad_cd: 6603,
      },
      {
        name: "桜島線",
        railroad_cd_1: 6,
        railroad_cd_2: 604,
        reins_railroad_cd: 6604,
      },
      {
        name: "福知山線",
        railroad_cd_1: 6,
        railroad_cd_2: 605,
        reins_railroad_cd: 6605,
      },
      {
        name: "山陽本線",
        railroad_cd_1: 6,
        railroad_cd_2: 611,
        reins_railroad_cd: 6611,
      },
      {
        name: "加古川線",
        railroad_cd_1: 6,
        railroad_cd_2: 612,
        reins_railroad_cd: 6612,
      },
      {
        name: "播但線",
        railroad_cd_1: 6,
        railroad_cd_2: 613,
        reins_railroad_cd: 6613,
      },
      {
        name: "姫新線",
        railroad_cd_1: 6,
        railroad_cd_2: 614,
        reins_railroad_cd: 6614,
      },
      {
        name: "赤穂線",
        railroad_cd_1: 6,
        railroad_cd_2: 615,
        reins_railroad_cd: 6615,
      },
      {
        name: "津山線",
        railroad_cd_1: 6,
        railroad_cd_2: 616,
        reins_railroad_cd: 6616,
      },
      {
        name: "吉備線",
        railroad_cd_1: 6,
        railroad_cd_2: 617,
        reins_railroad_cd: 6617,
      },
      {
        name: "宇野線",
        railroad_cd_1: 6,
        railroad_cd_2: 618,
        reins_railroad_cd: 6618,
      },
      {
        name: "伯備線",
        railroad_cd_1: 6,
        railroad_cd_2: 619,
        reins_railroad_cd: 6619,
      },
      {
        name: "芸備線",
        railroad_cd_1: 6,
        railroad_cd_2: 620,
        reins_railroad_cd: 6620,
      },
      {
        name: "福塩線",
        railroad_cd_1: 6,
        railroad_cd_2: 621,
        reins_railroad_cd: 6621,
      },
      {
        name: "呉線",
        railroad_cd_1: 6,
        railroad_cd_2: 622,
        reins_railroad_cd: 6622,
      },
      {
        name: "可部線",
        railroad_cd_1: 6,
        railroad_cd_2: 623,
        reins_railroad_cd: 6623,
      },
      {
        name: "岩徳線",
        railroad_cd_1: 6,
        railroad_cd_2: 624,
        reins_railroad_cd: 6624,
      },
      {
        name: "山口線",
        railroad_cd_1: 6,
        railroad_cd_2: 626,
        reins_railroad_cd: 6626,
      },
      {
        name: "宇部線",
        railroad_cd_1: 6,
        railroad_cd_2: 627,
        reins_railroad_cd: 6627,
      },
      {
        name: "小野田線",
        railroad_cd_1: 6,
        railroad_cd_2: 628,
        reins_railroad_cd: 6628,
      },
      {
        name: "美祢線",
        railroad_cd_1: 6,
        railroad_cd_2: 629,
        reins_railroad_cd: 6629,
      },
      {
        name: "山陰本線",
        railroad_cd_1: 6,
        railroad_cd_2: 631,
        reins_railroad_cd: 6631,
      },
      {
        name: "舞鶴線",
        railroad_cd_1: 6,
        railroad_cd_2: 632,
        reins_railroad_cd: 6632,
      },
      {
        name: "因美線",
        railroad_cd_1: 6,
        railroad_cd_2: 634,
        reins_railroad_cd: 6634,
      },
      {
        name: "境線",
        railroad_cd_1: 6,
        railroad_cd_2: 636,
        reins_railroad_cd: 6636,
      },
      {
        name: "木次線",
        railroad_cd_1: 6,
        railroad_cd_2: 637,
        reins_railroad_cd: 6637,
      },
      {
        name: "草津線",
        railroad_cd_1: 6,
        railroad_cd_2: 641,
        reins_railroad_cd: 6641,
      },
      {
        name: "奈良線",
        railroad_cd_1: 6,
        railroad_cd_2: 643,
        reins_railroad_cd: 6643,
      },
      {
        name: "桜井線",
        railroad_cd_1: 6,
        railroad_cd_2: 644,
        reins_railroad_cd: 6644,
      },
      {
        name: "片町線",
        railroad_cd_1: 6,
        railroad_cd_2: 645,
        reins_railroad_cd: 6645,
      },
      {
        name: "和歌山線",
        railroad_cd_1: 6,
        railroad_cd_2: 646,
        reins_railroad_cd: 6646,
      },
      {
        name: "阪和線",
        railroad_cd_1: 6,
        railroad_cd_2: 647,
        reins_railroad_cd: 6647,
      },
      {
        name: "関西空港線",
        railroad_cd_1: 6,
        railroad_cd_2: 648,
        reins_railroad_cd: 6648,
      },
      {
        name: "ＪＲ東西線",
        railroad_cd_1: 6,
        railroad_cd_2: 649,
        reins_railroad_cd: 6649,
      },
      {
        name: "本四備讃線",
        railroad_cd_1: 6,
        railroad_cd_2: 810,
        reins_railroad_cd: 6810,
      },
      {
        name: "予讃線",
        railroad_cd_1: 8,
        railroad_cd_2: 801,
        reins_railroad_cd: 8801,
      },
      {
        name: "内子線",
        railroad_cd_1: 8,
        railroad_cd_2: 802,
        reins_railroad_cd: 8802,
      },
      {
        name: "予土線",
        railroad_cd_1: 8,
        railroad_cd_2: 803,
        reins_railroad_cd: 8803,
      },
      {
        name: "高徳線",
        railroad_cd_1: 8,
        railroad_cd_2: 804,
        reins_railroad_cd: 8804,
      },
      {
        name: "鳴門線",
        railroad_cd_1: 8,
        railroad_cd_2: 805,
        reins_railroad_cd: 8805,
      },
      {
        name: "徳島線",
        railroad_cd_1: 8,
        railroad_cd_2: 806,
        reins_railroad_cd: 8806,
      },
      {
        name: "牟岐線",
        railroad_cd_1: 8,
        railroad_cd_2: 807,
        reins_railroad_cd: 8807,
      },
      {
        name: "土讃線",
        railroad_cd_1: 8,
        railroad_cd_2: 808,
        reins_railroad_cd: 8808,
      },
      {
        name: "本四備讃線",
        railroad_cd_1: 8,
        railroad_cd_2: 810,
        reins_railroad_cd: 8810,
      },
      {
        name: "山陽本線",
        railroad_cd_1: 9,
        railroad_cd_2: 611,
        reins_railroad_cd: 9611,
      },
      {
        name: "鹿児島本線",
        railroad_cd_1: 9,
        railroad_cd_2: 901,
        reins_railroad_cd: 9901,
      },
      {
        name: "香椎線",
        railroad_cd_1: 9,
        railroad_cd_2: 902,
        reins_railroad_cd: 9902,
      },
      {
        name: "篠栗線",
        railroad_cd_1: 9,
        railroad_cd_2: 903,
        reins_railroad_cd: 9903,
      },
      {
        name: "三角線",
        railroad_cd_1: 9,
        railroad_cd_2: 904,
        reins_railroad_cd: 9904,
      },
      {
        name: "肥薩線",
        railroad_cd_1: 9,
        railroad_cd_2: 905,
        reins_railroad_cd: 9905,
      },
      {
        name: "指宿枕崎線",
        railroad_cd_1: 9,
        railroad_cd_2: 908,
        reins_railroad_cd: 9908,
      },
      {
        name: "長崎本線",
        railroad_cd_1: 9,
        railroad_cd_2: 911,
        reins_railroad_cd: 9911,
      },
      {
        name: "唐津線",
        railroad_cd_1: 9,
        railroad_cd_2: 913,
        reins_railroad_cd: 9913,
      },
      {
        name: "筑肥線",
        railroad_cd_1: 9,
        railroad_cd_2: 914,
        reins_railroad_cd: 9914,
      },
      {
        name: "佐世保線",
        railroad_cd_1: 9,
        railroad_cd_2: 915,
        reins_railroad_cd: 9915,
      },
      {
        name: "大村線",
        railroad_cd_1: 9,
        railroad_cd_2: 917,
        reins_railroad_cd: 9917,
      },
      {
        name: "久大本線",
        railroad_cd_1: 9,
        railroad_cd_2: 921,
        reins_railroad_cd: 9921,
      },
      {
        name: "豊肥本線",
        railroad_cd_1: 9,
        railroad_cd_2: 922,
        reins_railroad_cd: 9922,
      },
      {
        name: "日豊本線",
        railroad_cd_1: 9,
        railroad_cd_2: 926,
        reins_railroad_cd: 9926,
      },
      {
        name: "日田彦山線",
        railroad_cd_1: 9,
        railroad_cd_2: 927,
        reins_railroad_cd: 9927,
      },
      {
        name: "日南線",
        railroad_cd_1: 9,
        railroad_cd_2: 930,
        reins_railroad_cd: 9930,
      },
      {
        name: "吉都線",
        railroad_cd_1: 9,
        railroad_cd_2: 931,
        reins_railroad_cd: 9931,
      },
      {
        name: "筑豊本線",
        railroad_cd_1: 9,
        railroad_cd_2: 936,
        reins_railroad_cd: 9936,
      },
      {
        name: "後藤寺線",
        railroad_cd_1: 9,
        railroad_cd_2: 940,
        reins_railroad_cd: 9940,
      },
      {
        name: "宮崎空港線",
        railroad_cd_1: 9,
        railroad_cd_2: 942,
        reins_railroad_cd: 9942,
      },
    ],
  },
  {
    name: "JR新幹線",
    list: [
      {
        name: "東北新幹線",
        railroad_cd_1: 2,
        railroad_cd_2: 2,
        reins_railroad_cd: 2002,
      },
      {
        name: "上越新幹線",
        railroad_cd_1: 2,
        railroad_cd_2: 3,
        reins_railroad_cd: 2003,
      },
      {
        name: "山形新幹線",
        railroad_cd_1: 2,
        railroad_cd_2: 4,
        reins_railroad_cd: 2004,
      },
      {
        name: "秋田新幹線",
        railroad_cd_1: 2,
        railroad_cd_2: 5,
        reins_railroad_cd: 2005,
      },
      {
        name: "北陸新幹線",
        railroad_cd_1: 2,
        railroad_cd_2: 6,
        reins_railroad_cd: 2006,
      },
      {
        name: "東海道・山陽新幹線",
        railroad_cd_1: 4,
        railroad_cd_2: 1,
        reins_railroad_cd: 4001,
      },
      {
        name: "東海道・山陽新幹線",
        railroad_cd_1: 6,
        railroad_cd_2: 1,
        reins_railroad_cd: 6001,
      },
      {
        name: "北陸新幹線",
        railroad_cd_1: 6,
        railroad_cd_2: 6,
        reins_railroad_cd: 6006,
      },
      {
        name: "九州新幹線",
        railroad_cd_1: 9,
        railroad_cd_2: 31,
        reins_railroad_cd: 9031,
      },
      {
        name: "西九州新幹線",
        railroad_cd_1: 9,
        railroad_cd_2: 32,
        reins_railroad_cd: 9032,
      },
    ],
  },
  {
    name: "札幌市交通局",
    list: [
      {
        name: "札幌市南北線",
        railroad_cd_1: 1,
        railroad_cd_2: 82,
        reins_railroad_cd: 1082,
      },
      {
        name: "札幌市東西線",
        railroad_cd_1: 1,
        railroad_cd_2: 83,
        reins_railroad_cd: 1083,
      },
      {
        name: "札幌市軌道線",
        railroad_cd_1: 1,
        railroad_cd_2: 84,
        reins_railroad_cd: 1084,
      },
      {
        name: "札幌市東豊線",
        railroad_cd_1: 1,
        railroad_cd_2: 85,
        reins_railroad_cd: 1085,
      },
      {
        name: "函館市電本線湯川線",
        railroad_cd_1: 1,
        railroad_cd_2: 93,
        reins_railroad_cd: 1093,
      },
      {
        name: "函館市電宝来谷地頭",
        railroad_cd_1: 1,
        railroad_cd_2: 94,
        reins_railroad_cd: 1094,
      },
    ],
  },
  {
    name: "道南いさりび鉄道",
    list: [
      {
        name: "道南いさりび鉄道",
        railroad_cd_1: 1,
        railroad_cd_2: 95,
        reins_railroad_cd: 1095,
      },
    ],
  },
  {
    name: "わたらせ渓谷鉄道",
    list: [
      {
        name: "わたらせ渓谷鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 109,
        reins_railroad_cd: 2109,
      },
    ],
  },
  {
    name: "真岡鉄道",
    list: [
      {
        name: "真岡鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 111,
        reins_railroad_cd: 2111,
      },
    ],
  },
  {
    name: "阿武隈急行",
    list: [
      {
        name: "阿武隈急行",
        railroad_cd_1: 2,
        railroad_cd_2: 114,
        reins_railroad_cd: 2114,
      },
    ],
  },
  {
    name: "会津鉄道",
    list: [
      {
        name: "会津鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 132,
        reins_railroad_cd: 2132,
      },
    ],
  },
  {
    name: "山形鉄道",
    list: [
      {
        name: "山鉄フラワー長井線",
        railroad_cd_1: 2,
        railroad_cd_2: 143,
        reins_railroad_cd: 2143,
      },
    ],
  },
  {
    name: "秋田内陸縦貫鉄道",
    list: [
      {
        name: "秋田内陸縦貫鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 147,
        reins_railroad_cd: 2147,
      },
    ],
  },
  {
    name: "しなの鉄道",
    list: [
      {
        name: "しなの鉄道北しなの",
        railroad_cd_1: 2,
        railroad_cd_2: 155,
        reins_railroad_cd: 2155,
      },
      {
        name: "しなの鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 193,
        reins_railroad_cd: 2193,
      },
    ],
  },
  {
    name: "えちごトキめき鉄道",
    list: [
      {
        name: "妙高はねうまライン",
        railroad_cd_1: 2,
        railroad_cd_2: 156,
        reins_railroad_cd: 2156,
      },
      {
        name: "日本海ひすいライン",
        railroad_cd_1: 6,
        railroad_cd_2: 424,
        reins_railroad_cd: 6424,
      },
    ],
  },
  {
    name: "いすみ鉄道",
    list: [
      {
        name: "いすみ鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 165,
        reins_railroad_cd: 2165,
      },
    ],
  },
  {
    name: "北越急行",
    list: [
      {
        name: "北越急行ほくほく線",
        railroad_cd_1: 2,
        railroad_cd_2: 192,
        reins_railroad_cd: 2192,
      },
    ],
  },
  {
    name: "いわて銀河鉄道",
    list: [
      {
        name: "いわて銀河鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 194,
        reins_railroad_cd: 2194,
      },
    ],
  },
  {
    name: "青い森鉄道",
    list: [
      {
        name: "青い森鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 195,
        reins_railroad_cd: 2195,
      },
    ],
  },
  {
    name: "津軽鉄道",
    list: [
      {
        name: "津軽鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 201,
        reins_railroad_cd: 2201,
      },
    ],
  },
  {
    name: "弘南鉄道",
    list: [
      {
        name: "弘南鉄道弘南線",
        railroad_cd_1: 2,
        railroad_cd_2: 202,
        reins_railroad_cd: 2202,
      },
      {
        name: "弘南鉄道大鰐線",
        railroad_cd_1: 2,
        railroad_cd_2: 203,
        reins_railroad_cd: 2203,
      },
    ],
  },
  {
    name: "三陸鉄道",
    list: [
      {
        name: "三陸鉄道リアス線",
        railroad_cd_1: 2,
        railroad_cd_2: 210,
        reins_railroad_cd: 2210,
      },
    ],
  },
  {
    name: "由利高原鉄道",
    list: [
      {
        name: "由利高原鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 211,
        reins_railroad_cd: 2211,
      },
    ],
  },
  {
    name: "福島交通",
    list: [
      {
        name: "福島交通飯坂線",
        railroad_cd_1: 2,
        railroad_cd_2: 213,
        reins_railroad_cd: 2213,
      },
    ],
  },
  {
    name: "仙台市交通局",
    list: [
      {
        name: "仙台市地下鉄南北線",
        railroad_cd_1: 2,
        railroad_cd_2: 217,
        reins_railroad_cd: 2217,
      },
      {
        name: "仙台市地下鉄東西線",
        railroad_cd_1: 2,
        railroad_cd_2: 218,
        reins_railroad_cd: 2218,
      },
    ],
  },
  {
    name: "仙台空港鉄道",
    list: [
      {
        name: "仙台空港鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 219,
        reins_railroad_cd: 2219,
      },
    ],
  },
  {
    name: "長野電鉄",
    list: [
      {
        name: "長野電鉄長野線",
        railroad_cd_1: 2,
        railroad_cd_2: 221,
        reins_railroad_cd: 2221,
      },
    ],
  },
  {
    name: "上田電鉄",
    list: [
      {
        name: "上田電鉄別所線",
        railroad_cd_1: 2,
        railroad_cd_2: 224,
        reins_railroad_cd: 2224,
      },
    ],
  },
  {
    name: "松本電気鉄道",
    list: [
      {
        name: "松本電気鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 225,
        reins_railroad_cd: 2225,
      },
    ],
  },
  {
    name: "関東鉄道",
    list: [
      {
        name: "関東鉄道竜ヶ崎線",
        railroad_cd_1: 2,
        railroad_cd_2: 231,
        reins_railroad_cd: 2231,
      },
      {
        name: "関東鉄道常総線",
        railroad_cd_1: 2,
        railroad_cd_2: 232,
        reins_railroad_cd: 2232,
      },
    ],
  },
  {
    name: "鹿島臨海鉄道",
    list: [
      {
        name: "鹿島臨海鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 235,
        reins_railroad_cd: 2235,
      },
    ],
  },
  {
    name: "ひたちなか海浜鉄道",
    list: [
      {
        name: "ひたちなか海浜鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 236,
        reins_railroad_cd: 2236,
      },
    ],
  },
  {
    name: "銚子電気鉄道",
    list: [
      {
        name: "銚子電気鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 238,
        reins_railroad_cd: 2238,
      },
    ],
  },
  {
    name: "小湊鐵道",
    list: [
      {
        name: "小湊鐵道",
        railroad_cd_1: 2,
        railroad_cd_2: 239,
        reins_railroad_cd: 2239,
      },
    ],
  },
  {
    name: "千葉都市モノレール",
    list: [
      {
        name: "千葉都市モノレール",
        railroad_cd_1: 2,
        railroad_cd_2: 240,
        reins_railroad_cd: 2240,
      },
    ],
  },
  {
    name: "上信電鉄",
    list: [
      {
        name: "上信電鉄上信線",
        railroad_cd_1: 2,
        railroad_cd_2: 241,
        reins_railroad_cd: 2241,
      },
    ],
  },
  {
    name: "上毛電気鉄道",
    list: [
      {
        name: "上毛電気鉄道上毛線",
        railroad_cd_1: 2,
        railroad_cd_2: 242,
        reins_railroad_cd: 2242,
      },
    ],
  },
  {
    name: "秩父鉄道",
    list: [
      {
        name: "秩父鉄道本線",
        railroad_cd_1: 2,
        railroad_cd_2: 243,
        reins_railroad_cd: 2243,
      },
    ],
  },
  {
    name: "埼玉新都市交通",
    list: [
      {
        name: "埼玉新都市交通",
        railroad_cd_1: 2,
        railroad_cd_2: 244,
        reins_railroad_cd: 2244,
      },
    ],
  },
  {
    name: "流鉄",
    list: [
      {
        name: "流鉄流山線",
        railroad_cd_1: 2,
        railroad_cd_2: 245,
        reins_railroad_cd: 2245,
      },
    ],
  },
  {
    name: "首都圏新都市鉄道",
    list: [
      {
        name: "つくばエクスプレス",
        railroad_cd_1: 2,
        railroad_cd_2: 246,
        reins_railroad_cd: 2246,
      },
    ],
  },
  {
    name: "京成電鉄",
    list: [
      {
        name: "成田スカイアクセス",
        railroad_cd_1: 2,
        railroad_cd_2: 250,
        reins_railroad_cd: 2250,
      },
      {
        name: "京成電鉄本線",
        railroad_cd_1: 2,
        railroad_cd_2: 251,
        reins_railroad_cd: 2251,
      },
      {
        name: "京成電鉄押上線",
        railroad_cd_1: 2,
        railroad_cd_2: 252,
        reins_railroad_cd: 2252,
      },
      {
        name: "京成電鉄千葉線",
        railroad_cd_1: 2,
        railroad_cd_2: 253,
        reins_railroad_cd: 2253,
      },
      {
        name: "京成電鉄金町線",
        railroad_cd_1: 2,
        railroad_cd_2: 254,
        reins_railroad_cd: 2254,
      },
      {
        name: "京成電鉄千原線",
        railroad_cd_1: 2,
        railroad_cd_2: 255,
        reins_railroad_cd: 2255,
      },
      {
        name: "新京成電鉄",
        railroad_cd_1: 2,
        railroad_cd_2: 256,
        reins_railroad_cd: 2256,
      },
    ],
  },
  {
    name: "北総鉄道",
    list: [
      {
        name: "北総鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 257,
        reins_railroad_cd: 2257,
      },
    ],
  },
  {
    name: "山万",
    list: [
      {
        name: "山万ユーカリが丘線",
        railroad_cd_1: 2,
        railroad_cd_2: 259,
        reins_railroad_cd: 2259,
      },
    ],
  },
  {
    name: "芝山鉄道",
    list: [
      {
        name: "芝山鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 260,
        reins_railroad_cd: 2260,
      },
    ],
  },
  {
    name: "東武鉄道",
    list: [
      {
        name: "東武伊勢崎・大師線",
        railroad_cd_1: 2,
        railroad_cd_2: 261,
        reins_railroad_cd: 2261,
      },
      {
        name: "東武鉄道亀戸線",
        railroad_cd_1: 2,
        railroad_cd_2: 262,
        reins_railroad_cd: 2262,
      },
      {
        name: "東武鉄道桐生線",
        railroad_cd_1: 2,
        railroad_cd_2: 263,
        reins_railroad_cd: 2263,
      },
      {
        name: "東武鉄道小泉線",
        railroad_cd_1: 2,
        railroad_cd_2: 264,
        reins_railroad_cd: 2264,
      },
      {
        name: "東武鉄道佐野線",
        railroad_cd_1: 2,
        railroad_cd_2: 265,
        reins_railroad_cd: 2265,
      },
      {
        name: "東武鉄道日光線",
        railroad_cd_1: 2,
        railroad_cd_2: 266,
        reins_railroad_cd: 2266,
      },
      {
        name: "東武鉄道鬼怒川線",
        railroad_cd_1: 2,
        railroad_cd_2: 267,
        reins_railroad_cd: 2267,
      },
      {
        name: "東武鉄道宇都宮線",
        railroad_cd_1: 2,
        railroad_cd_2: 268,
        reins_railroad_cd: 2268,
      },
      {
        name: "東武鉄道野田線",
        railroad_cd_1: 2,
        railroad_cd_2: 269,
        reins_railroad_cd: 2269,
      },
      {
        name: "東武鉄道東上線",
        railroad_cd_1: 2,
        railroad_cd_2: 270,
        reins_railroad_cd: 2270,
      },
      {
        name: "東武鉄道越生線",
        railroad_cd_1: 2,
        railroad_cd_2: 271,
        reins_railroad_cd: 2271,
      },
    ],
  },
  {
    name: "野岩鉄道",
    list: [
      {
        name: "野岩鉄道会津鬼怒川",
        railroad_cd_1: 2,
        railroad_cd_2: 275,
        reins_railroad_cd: 2275,
      },
    ],
  },
  {
    name: "西武鉄道",
    list: [
      {
        name: "西武池袋・豊島線",
        railroad_cd_1: 2,
        railroad_cd_2: 281,
        reins_railroad_cd: 2281,
      },
      {
        name: "西武池袋西武秩父線",
        railroad_cd_1: 2,
        railroad_cd_2: 282,
        reins_railroad_cd: 2282,
      },
      {
        name: "西武有楽町線",
        railroad_cd_1: 2,
        railroad_cd_2: 283,
        reins_railroad_cd: 2283,
      },
      {
        name: "西武鉄道新宿線",
        railroad_cd_1: 2,
        railroad_cd_2: 284,
        reins_railroad_cd: 2284,
      },
      {
        name: "西武鉄道国分寺線",
        railroad_cd_1: 2,
        railroad_cd_2: 285,
        reins_railroad_cd: 2285,
      },
      {
        name: "西武鉄道多摩湖線",
        railroad_cd_1: 2,
        railroad_cd_2: 286,
        reins_railroad_cd: 2286,
      },
      {
        name: "西武鉄道多摩川線",
        railroad_cd_1: 2,
        railroad_cd_2: 287,
        reins_railroad_cd: 2287,
      },
      {
        name: "西武鉄道拝島線",
        railroad_cd_1: 2,
        railroad_cd_2: 288,
        reins_railroad_cd: 2288,
      },
      {
        name: "西武鉄道狭山線",
        railroad_cd_1: 2,
        railroad_cd_2: 289,
        reins_railroad_cd: 2289,
      },
      {
        name: "西武鉄道山口線",
        railroad_cd_1: 2,
        railroad_cd_2: 290,
        reins_railroad_cd: 2290,
      },
    ],
  },
  {
    name: "京王電鉄",
    list: [
      {
        name: "京王電鉄京王線",
        railroad_cd_1: 2,
        railroad_cd_2: 301,
        reins_railroad_cd: 2301,
      },
      {
        name: "京王電鉄高尾線",
        railroad_cd_1: 2,
        railroad_cd_2: 302,
        reins_railroad_cd: 2302,
      },
      {
        name: "京王電鉄相模原線",
        railroad_cd_1: 2,
        railroad_cd_2: 303,
        reins_railroad_cd: 2303,
      },
      {
        name: "京王電鉄井の頭線",
        railroad_cd_1: 2,
        railroad_cd_2: 304,
        reins_railroad_cd: 2304,
      },
    ],
  },
  {
    name: "小田急電鉄",
    list: [
      {
        name: "小田急電鉄小田原線",
        railroad_cd_1: 2,
        railroad_cd_2: 311,
        reins_railroad_cd: 2311,
      },
      {
        name: "小田急電鉄江ノ島線",
        railroad_cd_1: 2,
        railroad_cd_2: 312,
        reins_railroad_cd: 2312,
      },
      {
        name: "小田急電鉄多摩線",
        railroad_cd_1: 2,
        railroad_cd_2: 313,
        reins_railroad_cd: 2313,
      },
    ],
  },
  {
    name: "東急電鉄",
    list: [
      {
        name: "東急東横線",
        railroad_cd_1: 2,
        railroad_cd_2: 321,
        reins_railroad_cd: 2321,
      },
      {
        name: "東急多摩川線",
        railroad_cd_1: 2,
        railroad_cd_2: 322,
        reins_railroad_cd: 2322,
      },
      {
        name: "東急大井町線",
        railroad_cd_1: 2,
        railroad_cd_2: 323,
        reins_railroad_cd: 2323,
      },
      {
        name: "東急田園都市線",
        railroad_cd_1: 2,
        railroad_cd_2: 324,
        reins_railroad_cd: 2324,
      },
      {
        name: "東急池上線",
        railroad_cd_1: 2,
        railroad_cd_2: 325,
        reins_railroad_cd: 2325,
      },
      {
        name: "東急世田谷線",
        railroad_cd_1: 2,
        railroad_cd_2: 326,
        reins_railroad_cd: 2326,
      },
      {
        name: "東急目黒線",
        railroad_cd_1: 2,
        railroad_cd_2: 327,
        reins_railroad_cd: 2327,
      },
      {
        name: "東急こどもの国線",
        railroad_cd_1: 2,
        railroad_cd_2: 328,
        reins_railroad_cd: 2328,
      },
      {
        name: "東急新横浜線",
        railroad_cd_1: 2,
        railroad_cd_2: 329,
        reins_railroad_cd: 2329,
      },
    ],
  },
  {
    name: "京浜急行電鉄",
    list: [
      {
        name: "京浜急行電鉄本線",
        railroad_cd_1: 2,
        railroad_cd_2: 331,
        reins_railroad_cd: 2331,
      },
      {
        name: "京浜急行電鉄空港線",
        railroad_cd_1: 2,
        railroad_cd_2: 332,
        reins_railroad_cd: 2332,
      },
      {
        name: "京浜急行電鉄大師線",
        railroad_cd_1: 2,
        railroad_cd_2: 333,
        reins_railroad_cd: 2333,
      },
      {
        name: "京浜急行電鉄逗子線",
        railroad_cd_1: 2,
        railroad_cd_2: 334,
        reins_railroad_cd: 2334,
      },
      {
        name: "京急久里浜線",
        railroad_cd_1: 2,
        railroad_cd_2: 335,
        reins_railroad_cd: 2335,
      },
    ],
  },
  {
    name: "東京メトロ",
    list: [
      {
        name: "東京地下鉄銀座線",
        railroad_cd_1: 2,
        railroad_cd_2: 341,
        reins_railroad_cd: 2341,
      },
      {
        name: "東京地下鉄丸ノ内線",
        railroad_cd_1: 2,
        railroad_cd_2: 342,
        reins_railroad_cd: 2342,
      },
      {
        name: "東京地下鉄方南支線",
        railroad_cd_1: 2,
        railroad_cd_2: 343,
        reins_railroad_cd: 2343,
      },
      {
        name: "東京地下鉄日比谷線",
        railroad_cd_1: 2,
        railroad_cd_2: 344,
        reins_railroad_cd: 2344,
      },
      {
        name: "東京地下鉄東西線",
        railroad_cd_1: 2,
        railroad_cd_2: 345,
        reins_railroad_cd: 2345,
      },
      {
        name: "東京地下鉄千代田線",
        railroad_cd_1: 2,
        railroad_cd_2: 346,
        reins_railroad_cd: 2346,
      },
      {
        name: "東京地下鉄有楽町線",
        railroad_cd_1: 2,
        railroad_cd_2: 347,
        reins_railroad_cd: 2347,
      },
      {
        name: "東京地下鉄半蔵門線",
        railroad_cd_1: 2,
        railroad_cd_2: 348,
        reins_railroad_cd: 2348,
      },
      {
        name: "東京地下鉄南北線",
        railroad_cd_1: 2,
        railroad_cd_2: 349,
        reins_railroad_cd: 2349,
      },
      {
        name: "東京地下鉄副都心線",
        railroad_cd_1: 2,
        railroad_cd_2: 350,
        reins_railroad_cd: 2350,
      },
    ],
  },
  {
    name: "東京都交通局（都営）",
    list: [
      {
        name: "東京都浅草線",
        railroad_cd_1: 2,
        railroad_cd_2: 351,
        reins_railroad_cd: 2351,
      },
      {
        name: "東京都三田線",
        railroad_cd_1: 2,
        railroad_cd_2: 352,
        reins_railroad_cd: 2352,
      },
      {
        name: "東京都新宿線",
        railroad_cd_1: 2,
        railroad_cd_2: 353,
        reins_railroad_cd: 2353,
      },
      {
        name: "東京都荒川線",
        railroad_cd_1: 2,
        railroad_cd_2: 354,
        reins_railroad_cd: 2354,
      },
      {
        name: "東京モノレール羽田",
        railroad_cd_1: 2,
        railroad_cd_2: 355,
        reins_railroad_cd: 2355,
      },
      {
        name: "ゆりかもめ",
        railroad_cd_1: 2,
        railroad_cd_2: 357,
        reins_railroad_cd: 2357,
      },
    ],
  },
  {
    name: "東京モノレール",
    list: [
      {
        name: "東京都大江戸線",
        railroad_cd_1: 2,
        railroad_cd_2: 358,
        reins_railroad_cd: 2358,
      },
    ],
  },
  {
    name: "ゆりかもめ",
    list: [
      {
        name: "日暮里舎人ライナー",
        railroad_cd_1: 2,
        railroad_cd_2: 359,
        reins_railroad_cd: 2359,
      },
    ],
  },
  {
    name: "相模鉄道",
    list: [
      {
        name: "相模鉄道本線",
        railroad_cd_1: 2,
        railroad_cd_2: 361,
        reins_railroad_cd: 2361,
      },
      {
        name: "相模鉄道いずみ野線",
        railroad_cd_1: 2,
        railroad_cd_2: 362,
        reins_railroad_cd: 2362,
      },
      {
        name: "相模鉄道相鉄新横浜",
        railroad_cd_1: 2,
        railroad_cd_2: 363,
        reins_railroad_cd: 2363,
      },
    ],
  },
  {
    name: "横浜市交通局",
    list: [
      {
        name: "横浜市ブルーライン",
        railroad_cd_1: 2,
        railroad_cd_2: 371,
        reins_railroad_cd: 2371,
      },
      {
        name: "横浜市グリーンＬ",
        railroad_cd_1: 2,
        railroad_cd_2: 374,
        reins_railroad_cd: 2374,
      },
    ],
  },
  {
    name: "横浜シーサイドライン",
    list: [
      {
        name: "金沢シーサイドＬ",
        railroad_cd_1: 2,
        railroad_cd_2: 372,
        reins_railroad_cd: 2372,
      },
    ],
  },
  {
    name: "横浜高速鉄道",
    list: [
      {
        name: "横浜高速鉄道ＭＭ線",
        railroad_cd_1: 2,
        railroad_cd_2: 373,
        reins_railroad_cd: 2373,
      },
    ],
  },
  {
    name: "江ノ島電鉄",
    list: [
      {
        name: "江ノ島電鉄",
        railroad_cd_1: 2,
        railroad_cd_2: 381,
        reins_railroad_cd: 2381,
      },
    ],
  },
  {
    name: "湘南モノレール",
    list: [
      {
        name: "湘南モノレール",
        railroad_cd_1: 2,
        railroad_cd_2: 382,
        reins_railroad_cd: 2382,
      },
    ],
  },
  {
    name: "箱根登山鉄道",
    list: [
      {
        name: "箱根登山鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 383,
        reins_railroad_cd: 2383,
      },
      {
        name: "箱根登山ケーブル線",
        railroad_cd_1: 2,
        railroad_cd_2: 384,
        reins_railroad_cd: 2384,
      },
    ],
  },
  {
    name: "富士急行",
    list: [
      {
        name: "富士急行",
        railroad_cd_1: 2,
        railroad_cd_2: 386,
        reins_railroad_cd: 2386,
      },
    ],
  },
  {
    name: "伊豆箱根鉄道",
    list: [
      {
        name: "伊豆箱根大雄山線",
        railroad_cd_1: 2,
        railroad_cd_2: 391,
        reins_railroad_cd: 2391,
      },
      {
        name: "伊豆箱根鉄道駿豆線",
        railroad_cd_1: 4,
        railroad_cd_2: 392,
        reins_railroad_cd: 4392,
      },
    ],
  },
  {
    name: "伊豆急行",
    list: [
      {
        name: "伊豆急行",
        railroad_cd_1: 2,
        railroad_cd_2: 393,
        reins_railroad_cd: 2393,
      },
    ],
  },
  {
    name: "東京臨海高速鉄道",
    list: [
      {
        name: "東京臨海高速鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 394,
        reins_railroad_cd: 2394,
      },
    ],
  },
  {
    name: "東葉高速鉄道",
    list: [
      {
        name: "東葉高速鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 395,
        reins_railroad_cd: 2395,
      },
    ],
  },
  {
    name: "多摩モノレール",
    list: [
      {
        name: "多摩モノレール",
        railroad_cd_1: 2,
        railroad_cd_2: 398,
        reins_railroad_cd: 2398,
      },
    ],
  },
  {
    name: "埼玉高速鉄道",
    list: [
      {
        name: "埼玉高速鉄道",
        railroad_cd_1: 2,
        railroad_cd_2: 399,
        reins_railroad_cd: 2399,
      },
    ],
  },
  {
    name: "舞浜リゾートライン",
    list: [
      {
        name: "ディズニーリゾート",
        railroad_cd_1: 2,
        railroad_cd_2: 400,
        reins_railroad_cd: 2400,
      },
    ],
  },
  {
    name: "天竜浜名湖鉄道",
    list: [
      {
        name: "天竜浜名湖鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 406,
        reins_railroad_cd: 4406,
      },
    ],
  },
  {
    name: "愛知環状鉄道",
    list: [
      {
        name: "愛知環状鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 407,
        reins_railroad_cd: 4407,
      },
    ],
  },
  {
    name: "長良川鉄道",
    list: [
      {
        name: "長良川鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 419,
        reins_railroad_cd: 4419,
      },
    ],
  },
  {
    name: "伊勢鉄道",
    list: [
      {
        name: "伊勢鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 427,
        reins_railroad_cd: 4427,
      },
    ],
  },
  {
    name: "東海交通事業",
    list: [
      {
        name: "東海交通事業城北線",
        railroad_cd_1: 4,
        railroad_cd_2: 437,
        reins_railroad_cd: 4437,
      },
    ],
  },
  {
    name: "名古屋臨海高速鉄道",
    list: [
      {
        name: "名古屋臨海高速鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 438,
        reins_railroad_cd: 4438,
      },
    ],
  },
  {
    name: "岳南電車",
    list: [
      {
        name: "岳南電車",
        railroad_cd_1: 4,
        railroad_cd_2: 441,
        reins_railroad_cd: 4441,
      },
    ],
  },
  {
    name: "静岡鉄道",
    list: [
      {
        name: "静岡鉄道静岡清水線",
        railroad_cd_1: 4,
        railroad_cd_2: 442,
        reins_railroad_cd: 4442,
      },
    ],
  },
  {
    name: "大井川鐵道",
    list: [
      {
        name: "大井川鐵道本線",
        railroad_cd_1: 4,
        railroad_cd_2: 443,
        reins_railroad_cd: 4443,
      },
      {
        name: "大井川鐵道井川線",
        railroad_cd_1: 4,
        railroad_cd_2: 444,
        reins_railroad_cd: 4444,
      },
    ],
  },
  {
    name: "遠州鉄道",
    list: [
      {
        name: "遠州鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 445,
        reins_railroad_cd: 4445,
      },
    ],
  },
  {
    name: "豊橋鉄道",
    list: [
      {
        name: "豊橋鉄道東田本線",
        railroad_cd_1: 4,
        railroad_cd_2: 446,
        reins_railroad_cd: 4446,
      },
      {
        name: "豊橋鉄道渥美線",
        railroad_cd_1: 4,
        railroad_cd_2: 447,
        reins_railroad_cd: 4447,
      },
    ],
  },
  {
    name: "愛知高速交通",
    list: [
      {
        name: "愛知高速東部丘陵線",
        railroad_cd_1: 4,
        railroad_cd_2: 450,
        reins_railroad_cd: 4450,
      },
    ],
  },
  {
    name: "名古屋鉄道",
    list: [
      {
        name: "名鉄名古屋本線",
        railroad_cd_1: 4,
        railroad_cd_2: 451,
        reins_railroad_cd: 4451,
      },
      {
        name: "名鉄豊川線",
        railroad_cd_1: 4,
        railroad_cd_2: 452,
        reins_railroad_cd: 4452,
      },
      {
        name: "名鉄西尾線",
        railroad_cd_1: 4,
        railroad_cd_2: 453,
        reins_railroad_cd: 4453,
      },
      {
        name: "名鉄三河線",
        railroad_cd_1: 4,
        railroad_cd_2: 454,
        reins_railroad_cd: 4454,
      },
      {
        name: "名鉄蒲郡線",
        railroad_cd_1: 4,
        railroad_cd_2: 455,
        reins_railroad_cd: 4455,
      },
      {
        name: "名鉄豊田線",
        railroad_cd_1: 4,
        railroad_cd_2: 456,
        reins_railroad_cd: 4456,
      },
      {
        name: "名鉄常滑線",
        railroad_cd_1: 4,
        railroad_cd_2: 457,
        reins_railroad_cd: 4457,
      },
      {
        name: "名鉄築港線",
        railroad_cd_1: 4,
        railroad_cd_2: 458,
        reins_railroad_cd: 4458,
      },
      {
        name: "名鉄河和線",
        railroad_cd_1: 4,
        railroad_cd_2: 459,
        reins_railroad_cd: 4459,
      },
      {
        name: "名鉄知多新線",
        railroad_cd_1: 4,
        railroad_cd_2: 460,
        reins_railroad_cd: 4460,
      },
      {
        name: "名鉄津島線",
        railroad_cd_1: 4,
        railroad_cd_2: 461,
        reins_railroad_cd: 4461,
      },
      {
        name: "名鉄尾西線",
        railroad_cd_1: 4,
        railroad_cd_2: 462,
        reins_railroad_cd: 4462,
      },
      {
        name: "名鉄竹鼻・羽島線",
        railroad_cd_1: 4,
        railroad_cd_2: 463,
        reins_railroad_cd: 4463,
      },
      {
        name: "名鉄犬山線",
        railroad_cd_1: 4,
        railroad_cd_2: 464,
        reins_railroad_cd: 4464,
      },
      {
        name: "名鉄各務原線",
        railroad_cd_1: 4,
        railroad_cd_2: 465,
        reins_railroad_cd: 4465,
      },
      {
        name: "名鉄広見線",
        railroad_cd_1: 4,
        railroad_cd_2: 466,
        reins_railroad_cd: 4466,
      },
      {
        name: "名鉄小牧線",
        railroad_cd_1: 4,
        railroad_cd_2: 468,
        reins_railroad_cd: 4468,
      },
      {
        name: "名鉄瀬戸線",
        railroad_cd_1: 4,
        railroad_cd_2: 473,
        reins_railroad_cd: 4473,
      },
      {
        name: "名鉄空港線",
        railroad_cd_1: 4,
        railroad_cd_2: 474,
        reins_railroad_cd: 4474,
      },
    ],
  },
  {
    name: "名古屋市交通局",
    list: [
      {
        name: "名古屋市東山線",
        railroad_cd_1: 4,
        railroad_cd_2: 481,
        reins_railroad_cd: 4481,
      },
      {
        name: "名古屋市名城線",
        railroad_cd_1: 4,
        railroad_cd_2: 482,
        reins_railroad_cd: 4482,
      },
      {
        name: "名古屋市鶴舞線",
        railroad_cd_1: 4,
        railroad_cd_2: 483,
        reins_railroad_cd: 4483,
      },
      {
        name: "名古屋市桜通線",
        railroad_cd_1: 4,
        railroad_cd_2: 484,
        reins_railroad_cd: 4484,
      },
      {
        name: "名古屋市上飯田線",
        railroad_cd_1: 4,
        railroad_cd_2: 486,
        reins_railroad_cd: 4486,
      },
      {
        name: "名古屋市名港線",
        railroad_cd_1: 4,
        railroad_cd_2: 487,
        reins_railroad_cd: 4487,
      },
      {
        name: "ガイドウエイ志段味",
        railroad_cd_1: 4,
        railroad_cd_2: 490,
        reins_railroad_cd: 4490,
      },
    ],
  },
  {
    name: "樽見鉄道",
    list: [
      {
        name: "樽見鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 491,
        reins_railroad_cd: 4491,
      },
    ],
  },
  {
    name: "明知鉄道",
    list: [
      {
        name: "明知鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 494,
        reins_railroad_cd: 4494,
      },
    ],
  },
  {
    name: "養老鉄道",
    list: [
      {
        name: "養老鉄道",
        railroad_cd_1: 4,
        railroad_cd_2: 496,
        reins_railroad_cd: 4496,
      },
    ],
  },
  {
    name: "三岐鉄道",
    list: [
      {
        name: "三岐鉄道三岐線",
        railroad_cd_1: 4,
        railroad_cd_2: 536,
        reins_railroad_cd: 4536,
      },
      {
        name: "三岐鉄道北勢線",
        railroad_cd_1: 4,
        railroad_cd_2: 537,
        reins_railroad_cd: 4537,
      },
    ],
  },
  {
    name: "四日市あすなろう鉄道",
    list: [
      {
        name: "四日市あすなろう",
        railroad_cd_1: 4,
        railroad_cd_2: 538,
        reins_railroad_cd: 4538,
      },
    ],
  },
  {
    name: "近畿日本鉄道",
    list: [
      {
        name: "近鉄山田鳥羽志摩線",
        railroad_cd_1: 4,
        railroad_cd_2: 542,
        reins_railroad_cd: 4542,
      },
      {
        name: "近鉄名古屋線",
        railroad_cd_1: 4,
        railroad_cd_2: 543,
        reins_railroad_cd: 4543,
      },
      {
        name: "近鉄湯の山線",
        railroad_cd_1: 4,
        railroad_cd_2: 547,
        reins_railroad_cd: 4547,
      },
      {
        name: "近鉄鈴鹿線",
        railroad_cd_1: 4,
        railroad_cd_2: 549,
        reins_railroad_cd: 4549,
      },
      {
        name: "近鉄大阪線",
        railroad_cd_1: 6,
        railroad_cd_2: 541,
        reins_railroad_cd: 6541,
      },
      {
        name: "近鉄難波・奈良線",
        railroad_cd_1: 6,
        railroad_cd_2: 551,
        reins_railroad_cd: 6551,
      },
      {
        name: "近鉄橿原線",
        railroad_cd_1: 6,
        railroad_cd_2: 552,
        reins_railroad_cd: 6552,
      },
      {
        name: "近鉄天理線",
        railroad_cd_1: 6,
        railroad_cd_2: 553,
        reins_railroad_cd: 6553,
      },
      {
        name: "近鉄信貴線",
        railroad_cd_1: 6,
        railroad_cd_2: 554,
        reins_railroad_cd: 6554,
      },
      {
        name: "近鉄南大阪線",
        railroad_cd_1: 6,
        railroad_cd_2: 555,
        reins_railroad_cd: 6555,
      },
      {
        name: "近鉄吉野線",
        railroad_cd_1: 6,
        railroad_cd_2: 556,
        reins_railroad_cd: 6556,
      },
      {
        name: "近鉄長野線",
        railroad_cd_1: 6,
        railroad_cd_2: 557,
        reins_railroad_cd: 6557,
      },
      {
        name: "近鉄道明寺線",
        railroad_cd_1: 6,
        railroad_cd_2: 558,
        reins_railroad_cd: 6558,
      },
      {
        name: "近鉄御所線",
        railroad_cd_1: 6,
        railroad_cd_2: 559,
        reins_railroad_cd: 6559,
      },
      {
        name: "近鉄京都線",
        railroad_cd_1: 6,
        railroad_cd_2: 560,
        reins_railroad_cd: 6560,
      },
      {
        name: "近鉄生駒線",
        railroad_cd_1: 6,
        railroad_cd_2: 561,
        reins_railroad_cd: 6561,
      },
      {
        name: "近鉄田原本線",
        railroad_cd_1: 6,
        railroad_cd_2: 562,
        reins_railroad_cd: 6562,
      },
      {
        name: "近鉄けいはんな線",
        railroad_cd_1: 6,
        railroad_cd_2: 563,
        reins_railroad_cd: 6563,
      },
      {
        name: "近鉄西信貴ケーブル",
        railroad_cd_1: 6,
        railroad_cd_2: 564,
        reins_railroad_cd: 6564,
      },
      {
        name: "近鉄生駒ケーブル線",
        railroad_cd_1: 6,
        railroad_cd_2: 565,
        reins_railroad_cd: 6565,
      },
    ],
  },
  {
    name: "のと鉄道",
    list: [
      {
        name: "のと鉄道・七尾線",
        railroad_cd_1: 6,
        railroad_cd_2: 420,
        reins_railroad_cd: 6420,
      },
    ],
  },
  {
    name: "ＩＲいしかわ鉄道",
    list: [
      {
        name: "ＩＲいしかわ鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 421,
        reins_railroad_cd: 6421,
      },
    ],
  },
  {
    name: "あいの風とやま鉄道",
    list: [
      {
        name: "あいの風とやま鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 423,
        reins_railroad_cd: 6423,
      },
    ],
  },
  {
    name: "富山地方鉄道",
    list: [
      {
        name: "富山地鉄本線",
        railroad_cd_1: 6,
        railroad_cd_2: 501,
        reins_railroad_cd: 6501,
      },
      {
        name: "富山地鉄立山線",
        railroad_cd_1: 6,
        railroad_cd_2: 502,
        reins_railroad_cd: 6502,
      },
      {
        name: "富山地鉄不二越上滝",
        railroad_cd_1: 6,
        railroad_cd_2: 503,
        reins_railroad_cd: 6503,
      },
      {
        name: "富山地鉄富山市内線",
        railroad_cd_1: 6,
        railroad_cd_2: 504,
        reins_railroad_cd: 6504,
      },
      {
        name: "富山地鉄富山都心線",
        railroad_cd_1: 6,
        railroad_cd_2: 505,
        reins_railroad_cd: 6505,
      },
      {
        name: "富山地鉄富山港線",
        railroad_cd_1: 6,
        railroad_cd_2: 508,
        reins_railroad_cd: 6508,
      },
    ],
  },
  {
    name: "黒部峡谷鉄道",
    list: [
      {
        name: "黒部峡谷鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 506,
        reins_railroad_cd: 6506,
      },
    ],
  },
  {
    name: "万葉線",
    list: [
      {
        name: "万葉線",
        railroad_cd_1: 6,
        railroad_cd_2: 507,
        reins_railroad_cd: 6507,
      },
    ],
  },
  {
    name: "北陸鉄道",
    list: [
      {
        name: "北陸鉄道浅野川線",
        railroad_cd_1: 6,
        railroad_cd_2: 511,
        reins_railroad_cd: 6511,
      },
      {
        name: "北陸鉄道石川線",
        railroad_cd_1: 6,
        railroad_cd_2: 512,
        reins_railroad_cd: 6512,
      },
    ],
  },
  {
    name: "福井鉄道",
    list: [
      {
        name: "福井鉄道福武線",
        railroad_cd_1: 6,
        railroad_cd_2: 521,
        reins_railroad_cd: 6521,
      },
    ],
  },
  {
    name: "えちぜん鉄道",
    list: [
      {
        name: "えちぜん鉄道勝山線",
        railroad_cd_1: 6,
        railroad_cd_2: 522,
        reins_railroad_cd: 6522,
      },
      {
        name: "えちぜん鉄道三国線",
        railroad_cd_1: 6,
        railroad_cd_2: 523,
        reins_railroad_cd: 6523,
      },
    ],
  },
  {
    name: "伊賀鉄道",
    list: [
      {
        name: "伊賀鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 570,
        reins_railroad_cd: 6570,
      },
    ],
  },
  {
    name: "近江鉄道",
    list: [
      {
        name: "近江鉄道本線",
        railroad_cd_1: 6,
        railroad_cd_2: 571,
        reins_railroad_cd: 6571,
      },
      {
        name: "近江鉄道多賀線",
        railroad_cd_1: 6,
        railroad_cd_2: 572,
        reins_railroad_cd: 6572,
      },
      {
        name: "近江鉄道八日市線",
        railroad_cd_1: 6,
        railroad_cd_2: 573,
        reins_railroad_cd: 6573,
      },
    ],
  },
  {
    name: "京福電気鉄道",
    list: [
      {
        name: "京福嵐山本線",
        railroad_cd_1: 6,
        railroad_cd_2: 581,
        reins_railroad_cd: 6581,
      },
      {
        name: "京福北野線",
        railroad_cd_1: 6,
        railroad_cd_2: 582,
        reins_railroad_cd: 6582,
      },
    ],
  },
  {
    name: "叡山電鉄",
    list: [
      {
        name: "叡山電鉄",
        railroad_cd_1: 6,
        railroad_cd_2: 586,
        reins_railroad_cd: 6586,
      },
    ],
  },
  {
    name: "京都市交通局",
    list: [
      {
        name: "京都市烏丸線",
        railroad_cd_1: 6,
        railroad_cd_2: 591,
        reins_railroad_cd: 6591,
      },
      {
        name: "京都市東西線",
        railroad_cd_1: 6,
        railroad_cd_2: 592,
        reins_railroad_cd: 6592,
      },
    ],
  },
  {
    name: "嵯峨野観光鉄道",
    list: [
      {
        name: "嵯峨野観光鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 596,
        reins_railroad_cd: 6596,
      },
    ],
  },
  {
    name: "錦川鉄道",
    list: [
      {
        name: "錦川鉄道錦川清流線",
        railroad_cd_1: 6,
        railroad_cd_2: 625,
        reins_railroad_cd: 6625,
      },
    ],
  },
  {
    name: "若桜鉄道",
    list: [
      {
        name: "若桜鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 635,
        reins_railroad_cd: 6635,
      },
    ],
  },
  {
    name: "信楽高原鉄道",
    list: [
      {
        name: "信楽高原鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 642,
        reins_railroad_cd: 6642,
      },
    ],
  },
  {
    name: "大阪外環状鉄道",
    list: [
      {
        name: "おおさか東線",
        railroad_cd_1: 6,
        railroad_cd_2: 650,
        reins_railroad_cd: 6650,
      },
    ],
  },
  {
    name: "京阪電気鉄道",
    list: [
      {
        name: "京阪電気鉄道京阪線",
        railroad_cd_1: 6,
        railroad_cd_2: 651,
        reins_railroad_cd: 6651,
      },
      {
        name: "京阪電気鉄道交野線",
        railroad_cd_1: 6,
        railroad_cd_2: 652,
        reins_railroad_cd: 6652,
      },
      {
        name: "京阪電気鉄道宇治線",
        railroad_cd_1: 6,
        railroad_cd_2: 653,
        reins_railroad_cd: 6653,
      },
      {
        name: "京阪電気鉄道京津線",
        railroad_cd_1: 6,
        railroad_cd_2: 654,
        reins_railroad_cd: 6654,
      },
      {
        name: "京阪電気鉄道石坂線",
        railroad_cd_1: 6,
        railroad_cd_2: 655,
        reins_railroad_cd: 6655,
      },
      {
        name: "京阪電気鉄道鴨東線",
        railroad_cd_1: 6,
        railroad_cd_2: 656,
        reins_railroad_cd: 6656,
      },
      {
        name: "京阪電鉄ケーブル線",
        railroad_cd_1: 6,
        railroad_cd_2: 657,
        reins_railroad_cd: 6657,
      },
      {
        name: "京阪電鉄中之島線",
        railroad_cd_1: 6,
        railroad_cd_2: 658,
        reins_railroad_cd: 6658,
      },
    ],
  },
  {
    name: "阪急電鉄",
    list: [
      {
        name: "阪急電鉄京都線",
        railroad_cd_1: 6,
        railroad_cd_2: 661,
        reins_railroad_cd: 6661,
      },
      {
        name: "阪急電鉄千里線",
        railroad_cd_1: 6,
        railroad_cd_2: 662,
        reins_railroad_cd: 6662,
      },
      {
        name: "阪急電鉄嵐山線",
        railroad_cd_1: 6,
        railroad_cd_2: 663,
        reins_railroad_cd: 6663,
      },
      {
        name: "阪急電鉄神戸線",
        railroad_cd_1: 6,
        railroad_cd_2: 664,
        reins_railroad_cd: 6664,
      },
      {
        name: "阪急電鉄今津線",
        railroad_cd_1: 6,
        railroad_cd_2: 665,
        reins_railroad_cd: 6665,
      },
      {
        name: "阪急電鉄伊丹線",
        railroad_cd_1: 6,
        railroad_cd_2: 666,
        reins_railroad_cd: 6666,
      },
      {
        name: "阪急電鉄甲陽線",
        railroad_cd_1: 6,
        railroad_cd_2: 667,
        reins_railroad_cd: 6667,
      },
      {
        name: "阪急電鉄宝塚線",
        railroad_cd_1: 6,
        railroad_cd_2: 668,
        reins_railroad_cd: 6668,
      },
      {
        name: "阪急電鉄箕面線",
        railroad_cd_1: 6,
        railroad_cd_2: 669,
        reins_railroad_cd: 6669,
      },
    ],
  },
  {
    name: "阪神電気鉄道",
    list: [
      {
        name: "阪神電鉄本線",
        railroad_cd_1: 6,
        railroad_cd_2: 671,
        reins_railroad_cd: 6671,
      },
      {
        name: "阪神電鉄武庫川線",
        railroad_cd_1: 6,
        railroad_cd_2: 672,
        reins_railroad_cd: 6672,
      },
      {
        name: "阪神電鉄阪神なんば",
        railroad_cd_1: 6,
        railroad_cd_2: 673,
        reins_railroad_cd: 6673,
      },
    ],
  },
  {
    name: "能勢電鉄",
    list: [
      {
        name: "能勢電鉄",
        railroad_cd_1: 6,
        railroad_cd_2: 676,
        reins_railroad_cd: 6676,
      },
    ],
  },
  {
    name: "南海電鉄",
    list: [
      {
        name: "南海電鉄南海本線",
        railroad_cd_1: 6,
        railroad_cd_2: 681,
        reins_railroad_cd: 6681,
      },
      {
        name: "南海電鉄高師浜支線",
        railroad_cd_1: 6,
        railroad_cd_2: 683,
        reins_railroad_cd: 6683,
      },
      {
        name: "南海電鉄多奈川線",
        railroad_cd_1: 6,
        railroad_cd_2: 684,
        reins_railroad_cd: 6684,
      },
      {
        name: "南海電鉄加太支線",
        railroad_cd_1: 6,
        railroad_cd_2: 685,
        reins_railroad_cd: 6685,
      },
      {
        name: "南海電鉄高野線",
        railroad_cd_1: 6,
        railroad_cd_2: 686,
        reins_railroad_cd: 6686,
      },
      {
        name: "南海電鉄和歌山港線",
        railroad_cd_1: 6,
        railroad_cd_2: 687,
        reins_railroad_cd: 6687,
      },
      {
        name: "南海電鉄空港線",
        railroad_cd_1: 6,
        railroad_cd_2: 691,
        reins_railroad_cd: 6691,
      },
      {
        name: "南海電鉄汐見橋線",
        railroad_cd_1: 6,
        railroad_cd_2: 692,
        reins_railroad_cd: 6692,
      },
      {
        name: "南海高野山ケーブル",
        railroad_cd_1: 6,
        railroad_cd_2: 693,
        reins_railroad_cd: 6693,
      },
    ],
  },
  {
    name: "阪堺電気軌道",
    list: [
      {
        name: "阪堺電気軌道阪堺線",
        railroad_cd_1: 6,
        railroad_cd_2: 689,
        reins_railroad_cd: 6689,
      },
      {
        name: "阪堺電気軌道上町線",
        railroad_cd_1: 6,
        railroad_cd_2: 690,
        reins_railroad_cd: 6690,
      },
    ],
  },
  {
    name: "大阪メトロ",
    list: [
      {
        name: "大阪市長堀鶴見緑地",
        railroad_cd_1: 6,
        railroad_cd_2: 698,
        reins_railroad_cd: 6698,
      },
      {
        name: "大阪市今里筋線",
        railroad_cd_1: 6,
        railroad_cd_2: 699,
        reins_railroad_cd: 6699,
      },
      {
        name: "大阪市御堂筋線",
        railroad_cd_1: 6,
        railroad_cd_2: 701,
        reins_railroad_cd: 6701,
      },
      {
        name: "大阪市谷町線",
        railroad_cd_1: 6,
        railroad_cd_2: 702,
        reins_railroad_cd: 6702,
      },
      {
        name: "大阪市四つ橋線",
        railroad_cd_1: 6,
        railroad_cd_2: 703,
        reins_railroad_cd: 6703,
      },
      {
        name: "大阪市中央線",
        railroad_cd_1: 6,
        railroad_cd_2: 704,
        reins_railroad_cd: 6704,
      },
      {
        name: "大阪市千日前線",
        railroad_cd_1: 6,
        railroad_cd_2: 705,
        reins_railroad_cd: 6705,
      },
      {
        name: "大阪市堺筋線",
        railroad_cd_1: 6,
        railroad_cd_2: 706,
        reins_railroad_cd: 6706,
      },
      {
        name: "南港ポートタウン線",
        railroad_cd_1: 6,
        railroad_cd_2: 707,
        reins_railroad_cd: 6707,
      },
    ],
  },
  {
    name: "大阪モノレール",
    list: [
      {
        name: "大阪モノレール本線",
        railroad_cd_1: 6,
        railroad_cd_2: 709,
        reins_railroad_cd: 6709,
      },
      {
        name: "大阪モノレール彩都",
        railroad_cd_1: 6,
        railroad_cd_2: 710,
        reins_railroad_cd: 6710,
      },
    ],
  },
  {
    name: "北大阪急行電鉄",
    list: [
      {
        name: "北大阪急行南北線",
        railroad_cd_1: 6,
        railroad_cd_2: 711,
        reins_railroad_cd: 6711,
      },
    ],
  },
  {
    name: "泉北高速鉄道",
    list: [
      {
        name: "泉北高速鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 712,
        reins_railroad_cd: 6712,
      },
    ],
  },
  {
    name: "水間鉄道",
    list: [
      {
        name: "水間鉄道水間線",
        railroad_cd_1: 6,
        railroad_cd_2: 713,
        reins_railroad_cd: 6713,
      },
    ],
  },
  {
    name: "紀州鉄道",
    list: [
      {
        name: "紀州鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 716,
        reins_railroad_cd: 6716,
      },
    ],
  },
  {
    name: "和歌山電鐵",
    list: [
      {
        name: "和歌山電鐵貴志川線",
        railroad_cd_1: 6,
        railroad_cd_2: 717,
        reins_railroad_cd: 6717,
      },
    ],
  },
  {
    name: "神戸電鉄",
    list: [
      {
        name: "神戸電鉄有馬線",
        railroad_cd_1: 6,
        railroad_cd_2: 721,
        reins_railroad_cd: 6721,
      },
      {
        name: "神戸電鉄三田線",
        railroad_cd_1: 6,
        railroad_cd_2: 722,
        reins_railroad_cd: 6722,
      },
      {
        name: "神戸電鉄粟生線",
        railroad_cd_1: 6,
        railroad_cd_2: 723,
        reins_railroad_cd: 6723,
      },
      {
        name: "神戸電鉄公園都市線",
        railroad_cd_1: 6,
        railroad_cd_2: 724,
        reins_railroad_cd: 6724,
      },
    ],
  },
  {
    name: "山陽電気鉄道",
    list: [
      {
        name: "山陽電気鉄道本線",
        railroad_cd_1: 6,
        railroad_cd_2: 726,
        reins_railroad_cd: 6726,
      },
      {
        name: "山陽電気鉄道網干線",
        railroad_cd_1: 6,
        railroad_cd_2: 727,
        reins_railroad_cd: 6727,
      },
    ],
  },
  {
    name: "神戸高速鉄道",
    list: [
      {
        name: "神戸高速鉄道東西線",
        railroad_cd_1: 6,
        railroad_cd_2: 731,
        reins_railroad_cd: 6731,
      },
      {
        name: "神戸高速鉄道南北線",
        railroad_cd_1: 6,
        railroad_cd_2: 732,
        reins_railroad_cd: 6732,
      },
    ],
  },
  {
    name: "神戸市交通局",
    list: [
      {
        name: "神戸市西神山手線",
        railroad_cd_1: 6,
        railroad_cd_2: 733,
        reins_railroad_cd: 6733,
      },
      {
        name: "神戸市北神線",
        railroad_cd_1: 6,
        railroad_cd_2: 735,
        reins_railroad_cd: 6735,
      },
      {
        name: "神戸市海岸線",
        railroad_cd_1: 6,
        railroad_cd_2: 737,
        reins_railroad_cd: 6737,
      },
    ],
  },
  {
    name: "神戸新交通",
    list: [
      {
        name: "神戸新交通",
        railroad_cd_1: 6,
        railroad_cd_2: 734,
        reins_railroad_cd: 6734,
      },
      {
        name: "六甲アイランド線",
        railroad_cd_1: 6,
        railroad_cd_2: 736,
        reins_railroad_cd: 6736,
      },
    ],
  },
  {
    name: "北条鉄道",
    list: [
      {
        name: "北条鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 741,
        reins_railroad_cd: 6741,
      },
    ],
  },
  {
    name: "岡山電気軌道",
    list: [
      {
        name: "岡山電軌東山本線",
        railroad_cd_1: 6,
        railroad_cd_2: 745,
        reins_railroad_cd: 6745,
      },
      {
        name: "岡山電軌清輝橋線",
        railroad_cd_1: 6,
        railroad_cd_2: 746,
        reins_railroad_cd: 6746,
      },
    ],
  },
  {
    name: "水島臨海鉄道",
    list: [
      {
        name: "水島臨海鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 748,
        reins_railroad_cd: 6748,
      },
    ],
  },
  {
    name: "智頭急行",
    list: [
      {
        name: "智頭急行",
        railroad_cd_1: 6,
        railroad_cd_2: 749,
        reins_railroad_cd: 6749,
      },
    ],
  },
  {
    name: "井原鉄道",
    list: [
      {
        name: "井原鉄道",
        railroad_cd_1: 6,
        railroad_cd_2: 750,
        reins_railroad_cd: 6750,
      },
    ],
  },
  {
    name: "広島電鉄",
    list: [
      {
        name: "広島電鉄本線",
        railroad_cd_1: 6,
        railroad_cd_2: 751,
        reins_railroad_cd: 6751,
      },
      {
        name: "広島電鉄宇品線",
        railroad_cd_1: 6,
        railroad_cd_2: 752,
        reins_railroad_cd: 6752,
      },
      {
        name: "広島電鉄横川線",
        railroad_cd_1: 6,
        railroad_cd_2: 753,
        reins_railroad_cd: 6753,
      },
      {
        name: "広島電鉄江波線",
        railroad_cd_1: 6,
        railroad_cd_2: 754,
        reins_railroad_cd: 6754,
      },
      {
        name: "広島電鉄皆実線",
        railroad_cd_1: 6,
        railroad_cd_2: 755,
        reins_railroad_cd: 6755,
      },
      {
        name: "広島電鉄白島線",
        railroad_cd_1: 6,
        railroad_cd_2: 756,
        reins_railroad_cd: 6756,
      },
      {
        name: "広島電鉄宮島線",
        railroad_cd_1: 6,
        railroad_cd_2: 757,
        reins_railroad_cd: 6757,
      },
    ],
  },
  {
    name: "一畑電車",
    list: [
      {
        name: "一畑電車北松江線",
        railroad_cd_1: 6,
        railroad_cd_2: 761,
        reins_railroad_cd: 6761,
      },
      {
        name: "一畑電車大社線",
        railroad_cd_1: 6,
        railroad_cd_2: 762,
        reins_railroad_cd: 6762,
      },
    ],
  },

  {
    name: "広島高速交通",
    list: [
      {
        name: "アストラムライン",
        railroad_cd_1: 6,
        railroad_cd_2: 765,
        reins_railroad_cd: 6765,
      },
    ],
  },
  {
    name: "スカイレールサービス",
    list: [
      {
        name: "スカイレール",
        railroad_cd_1: 6,
        railroad_cd_2: 774,
        reins_railroad_cd: 6774,
      },
    ],
  },
  {
    name: "京都丹後鉄道",
    list: [
      {
        name: "京都丹後鉄道宮福線",
        railroad_cd_1: 6,
        railroad_cd_2: 791,
        reins_railroad_cd: 6791,
      },
      {
        name: "京都丹後鉄道宮舞線",
        railroad_cd_1: 6,
        railroad_cd_2: 792,
        reins_railroad_cd: 6792,
      },
      {
        name: "京都丹後鉄道宮豊線",
        railroad_cd_1: 6,
        railroad_cd_2: 793,
        reins_railroad_cd: 6793,
      },
    ],
  },
  {
    name: "土佐くろしお鉄道",
    list: [
      {
        name: "土佐くろしお宿毛線",
        railroad_cd_1: 8,
        railroad_cd_2: 809,
        reins_railroad_cd: 8809,
      },
      {
        name: "土佐くろしおなはり",
        railroad_cd_1: 8,
        railroad_cd_2: 834,
        reins_railroad_cd: 8834,
      },
    ],
  },
  {
    name: "高松琴平電気鉄道",
    list: [
      {
        name: "高松琴平電鉄琴平線",
        railroad_cd_1: 8,
        railroad_cd_2: 821,
        reins_railroad_cd: 8821,
      },
      {
        name: "高松琴平電鉄長尾線",
        railroad_cd_1: 8,
        railroad_cd_2: 822,
        reins_railroad_cd: 8822,
      },
      {
        name: "高松琴平電鉄志度線",
        railroad_cd_1: 8,
        railroad_cd_2: 823,
        reins_railroad_cd: 8823,
      },
    ],
  },
  {
    name: "伊予鉄道",
    list: [
      {
        name: "伊予鉄道高浜線",
        railroad_cd_1: 8,
        railroad_cd_2: 824,
        reins_railroad_cd: 8824,
      },
      {
        name: "伊予鉄道郡中線",
        railroad_cd_1: 8,
        railroad_cd_2: 825,
        reins_railroad_cd: 8825,
      },
      {
        name: "伊予鉄道横河原線",
        railroad_cd_1: 8,
        railroad_cd_2: 826,
        reins_railroad_cd: 8826,
      },
      {
        name: "伊予鉄道環状線",
        railroad_cd_1: 8,
        railroad_cd_2: 827,
        reins_railroad_cd: 8827,
      },
      {
        name: "伊予鉄道城南線",
        railroad_cd_1: 8,
        railroad_cd_2: 828,
        reins_railroad_cd: 8828,
      },
      {
        name: "伊予鉄道本町線",
        railroad_cd_1: 8,
        railroad_cd_2: 829,
        reins_railroad_cd: 8829,
      },
    ],
  },
  {
    name: "とさでん交通",
    list: [
      {
        name: "とさでん交通桟橋線",
        railroad_cd_1: 8,
        railroad_cd_2: 830,
        reins_railroad_cd: 8830,
      },
      {
        name: "とさでん交通伊野線",
        railroad_cd_1: 8,
        railroad_cd_2: 831,
        reins_railroad_cd: 8831,
      },
      {
        name: "とさでん交通後免線",
        railroad_cd_1: 8,
        railroad_cd_2: 832,
        reins_railroad_cd: 8832,
      },
    ],
  },

  {
    name: "阿佐海岸鉄道",
    list: [
      {
        name: "阿佐海岸阿佐東線",
        railroad_cd_1: 8,
        railroad_cd_2: 833,
        reins_railroad_cd: 8833,
      },
    ],
  },
  {
    name: "くま川鉄道",
    list: [
      {
        name: "くま川鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 909,
        reins_railroad_cd: 9909,
      },
    ],
  },
  {
    name: "松浦鉄道",
    list: [
      {
        name: "松浦鉄道西九州線",
        railroad_cd_1: 9,
        railroad_cd_2: 916,
        reins_railroad_cd: 9916,
      },
    ],
  },
  {
    name: "平成筑豊鉄道",
    list: [
      {
        name: "平成筑豊鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 941,
        reins_railroad_cd: 9941,
      },
      {
        name: "門司港レトロ観光線",
        railroad_cd_1: 9,
        railroad_cd_2: 963,
        reins_railroad_cd: 9963,
      },
    ],
  },
  {
    name: "西日本鉄道",
    list: [
      {
        name: "西鉄天神大牟田線",
        railroad_cd_1: 9,
        railroad_cd_2: 951,
        reins_railroad_cd: 9951,
      },
      {
        name: "西日本鉄道太宰府線",
        railroad_cd_1: 9,
        railroad_cd_2: 952,
        reins_railroad_cd: 9952,
      },
      {
        name: "西日本鉄道甘木線",
        railroad_cd_1: 9,
        railroad_cd_2: 953,
        reins_railroad_cd: 9953,
      },
      {
        name: "西日本鉄道貝塚線",
        railroad_cd_1: 9,
        railroad_cd_2: 954,
        reins_railroad_cd: 9954,
      },
    ],
  },
  {
    name: "筑豊電気鉄道",
    list: [
      {
        name: "筑豊電気鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 961,
        reins_railroad_cd: 9961,
      },
    ],
  },
  {
    name: "北九州高速鉄道",
    list: [
      {
        name: "北九州高速鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 962,
        reins_railroad_cd: 9962,
      },
    ],
  },
  {
    name: "福岡市交通局",
    list: [
      {
        name: "福岡市空港線",
        railroad_cd_1: 9,
        railroad_cd_2: 966,
        reins_railroad_cd: 9966,
      },
      {
        name: "福岡市箱崎線",
        railroad_cd_1: 9,
        railroad_cd_2: 967,
        reins_railroad_cd: 9967,
      },
      {
        name: "福岡市七隈線",
        railroad_cd_1: 9,
        railroad_cd_2: 968,
        reins_railroad_cd: 9968,
      },
    ],
  },
  {
    name: "島原鉄道",
    list: [
      {
        name: "島原鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 971,
        reins_railroad_cd: 9971,
      },
    ],
  },
  {
    name: "長崎電気軌道",
    list: [
      {
        name: "長崎電軌本線",
        railroad_cd_1: 9,
        railroad_cd_2: 976,
        reins_railroad_cd: 9976,
      },
      {
        name: "長崎電軌桜町支線",
        railroad_cd_1: 9,
        railroad_cd_2: 977,
        reins_railroad_cd: 9977,
      },
      {
        name: "長崎電軌大浦支線",
        railroad_cd_1: 9,
        railroad_cd_2: 978,
        reins_railroad_cd: 9978,
      },
      {
        name: "長崎電軌蛍茶屋支線",
        railroad_cd_1: 9,
        railroad_cd_2: 979,
        reins_railroad_cd: 9979,
      },
    ],
  },
  {
    name: "熊本市交通局",
    list: [
      {
        name: "熊本市健軍線",
        railroad_cd_1: 9,
        railroad_cd_2: 981,
        reins_railroad_cd: 9981,
      },
      {
        name: "熊本市上熊本線",
        railroad_cd_1: 9,
        railroad_cd_2: 982,
        reins_railroad_cd: 9982,
      },
    ],
  },
  {
    name: "熊本電気鉄道",
    list: [
      {
        name: "熊本電気鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 983,
        reins_railroad_cd: 9983,
      },
    ],
  },
  {
    name: "鹿児島市交通局",
    list: [
      {
        name: "鹿児島市唐湊線",
        railroad_cd_1: 9,
        railroad_cd_2: 986,
        reins_railroad_cd: 9986,
      },
      {
        name: "鹿児島市谷山線",
        railroad_cd_1: 9,
        railroad_cd_2: 987,
        reins_railroad_cd: 9987,
      },
    ],
  },
  {
    name: "甘木鉄道",
    list: [
      {
        name: "甘木鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 991,
        reins_railroad_cd: 9991,
      },
    ],
  },
  {
    name: "南阿蘇鉄道",
    list: [
      {
        name: "南阿蘇鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 992,
        reins_railroad_cd: 9992,
      },
    ],
  },
  {
    name: "肥薩おれんじ鉄道",
    list: [
      {
        name: "肥薩おれんじ鉄道",
        railroad_cd_1: 9,
        railroad_cd_2: 993,
        reins_railroad_cd: 9993,
      },
    ],
  },
  {
    name: "沖縄都市モノレール",
    list: [
      {
        name: "沖縄都市モノレール",
        railroad_cd_1: 9,
        railroad_cd_2: 997,
        reins_railroad_cd: 9997,
      },
    ],
  },
];

export { apiRailroad };
