<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="wrapper">
    <SideBarTop />
    <SideBarLeft />
    <div class="content-wrapper">
      <section class="content py-3">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body minh-520">
              <div class="row mb-4">
                <div class="col-md-12">
                  <h3 class="card-title-cus">フォロー顧客担当確認</h3>
                </div>
              </div>
              <div class="cus-filter">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-sm-4 col-lg-2 my-2">
                      <label>フォロー対象日</label>
                    </div>
                    <div class="col-sm-8 col-lg-10 my-2">
                      <div class="form-group w-215 mb-0">
                        <InputTime
                          :id="'start-date'"
                          v-model:modelvalue="filter.follow_target_date"
                          :placeholder="'YYYY-MM-DD'"
                          :clearable="false"
                          @update:modelvalue="handlerDate" />
                      </div>
                    </div>
                    <div class="col-sm-4 col-lg-2 my-2">
                      <label>担当者名</label>
                    </div>
                    <div class="col-sm-8 col-lg-10 my-2">
                      <input
                        v-model="user_object.name"
                        type="text"
                        class="form-control w-215"
                        placeholder=""
                        disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isShow" class="wrap-ct">
                <!-- <div class="text-center bg-blue3 fs-16 text-white py-2">
                  顧客情報
                </div> -->
                <TableFollowPriorites
                  :items="items"
                  :page="page"
                  :size="size" />
                <div class="custom-pagi my-4">
                  <PaginateInterface
                    :key="`paginate_${page}_${size}_${total}`"
                    :initial-page="page"
                    :pages="pages"
                    :size="size" />
                </div>
                <PaginateInfo :page="page" :total="total" :size="size" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <ModalConfirmFollow :id="'modalFollow'" :message="messAlert" />
</template>

<script>
import $ from "jquery";
import { mapState } from "vuex";
import { getParamsUrl } from "/src/service/utility.service.js";
import PaginateInfo from "/src/components/commons/PaginateInfo.vue";
import PaginateInterface from "/src/components/commons/PaginateInterface.vue";
import TableFollowPriorites from "/src/components/follow-priorites-customer/TableFollowPriorites.vue";
import SideBarLeft from "/src/components/layouts/SidebarLeft.vue";
import SideBarTop from "/src/components/layouts/SidebarTop.vue";
import { getPropertiesCustomers } from "/src/service/priorities-customers.js";
import InputTime from "/src/components/utilitys/inputs/InputTime.vue";
import ModalConfirmFollow from "/src/components/modals/ModalConfirmFollow.vue";
import {
  formatDateTimeNow,
  formatDate,
} from "/src/components/follow-priorites-customer/common-follow.js";

export default {
  components: {
    PaginateInfo,
    TableFollowPriorites,
    SideBarLeft,
    SideBarTop,
    PaginateInterface,
    InputTime,
    ModalConfirmFollow,
  },
  data() {
    const filter = {
      follow_target_date: "",
    };
    return {
      filter,
      page: 1,
      size: 50,
      items: [],
      pages: 1,
      total: 0,
      isShow: false,
      messAlert: "フォロー対象日ではありません。",
      formatDateTimeNow,
      formatDate,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
  },
  watch: {
    "$route.query": {
      handler: function () {
        if (location.search != "") {
          let params = this.createdParam();
          this.filter.follow_target_date = params["follow_target_date"];
          this.getData(params);
        }
      },
    },
  },
  updated() {
    this.fetchInitialData();
  },
  created() {
    this.fetchInitialData();
    const params = this.createdParam();
    if (location.search != "") {
      this.getData(params);
    }
    if (location.search == "") {
      this.filter.follow_target_date = this.formatDateTimeNow();
    } else {
      this.filter.follow_target_date = params.follow_target_date;
    }
  },
  methods: {
    createdParam() {
      let params = getParamsUrl();
      this.page = params["page"] ? parseInt(params["page"]) : this.page;
      this.size = params["size"] ? parseInt(params["size"]) : this.size;
      params = {
        ...params,
        page: this.page,
        size: this.size,
        order_by: "-priority_score,customer_id,follow_id",
      };
      return params;
    },
    fetchInitialData() {
      if (location.search == "") {
        this.filter.follow_target_date = this.formatDateTimeNow();
        this.$router.push(
          `${location.pathname}?follow_target_date=${this.formatDateTimeNow()}`
        );
      }
    },
    createQueryStringFromData() {
      return Object.keys(this.$data.filter)
        .filter((key) => this.$data.filter[key])
        .map((key) => `${key}=${this.$data.filter[key]}`)
        .join("&");
    },
    getData(params) {
      getPropertiesCustomers(params).then((data) => {
        this.total = data.total;
        this.pages = data.pages;
        this.items = data.items;
        if (this.items.length != 0) {
          this.isShow = true;
        } else {
          this.isShow = false;
          $("#modalFollow").modal();
        }
      });
    },
    handlerDate(value) {
      this.filter.follow_target_date = this.formatDate(value);
      let query = this.createQueryStringFromData() + "&page=1";
      let oldQuery = decodeURI(location.search);
      if (oldQuery.slice(1) == query) {
        this.$router.push(this.$router.currentRoute);
      } else {
        this.$router.push(`${location.pathname}?${query}`);
      }
    },
  },
};
</script>
