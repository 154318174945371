import axios from "axios";
import store from "../store";
import { USER_API, USER_TOKEN, CSRF_API } from "./const.service.js";

const textNormalize = (characters) => {
  return characters.normalize("NFKC");
};
const latinToFullWidth = function (str) {
  // Convert latin characters (1 byte) into Japanese full width characters (2 bytes)
  return str.replace(/[!-~]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
  });
};
const check_duplicate = async (param) => {
  // Send request with query email and name
  let url = USER_API + "/duplicate";
  return axios.get(url, {
    params: param,
  });
};
const getUserLogin = () => {
  return axios.get(USER_TOKEN).then((response) => {
    return response;
  });
};
const getUserList = (params) => {
  return axios.get(USER_API, { params }).then((response) => {
    return response;
  });
};
const getUserDetail = (id) => {
  return axios.get(USER_API + "/" + id).then((response) => {
    return response;
  });
};
const updateUser = (id, body) => {
  if (id != body.user_id) {
    throw "user_id not match";
  }
  return axios.put(USER_API + "/" + id, body).then((response) => {
    return response;
  });
};
const addUser = (body) => {
  return axios.post(USER_API, body).then((_) => {
    return true;
  });
};
const deleteUserList = (id_list) => {
  let axios_list = [];
  for (let i = 0; i < id_list.length; i++) {
    axios_list.push(axios.delete(USER_API + "/" + id_list[i]));
  }
  Promise.all(axios_list).then(() => {
    store.dispatch("alertMessage/showAlert", "ユーザー情報を削除しました。");
  });
};
const getCsrfToken = () => {
  return axios.get(CSRF_API);
};
export {
  check_duplicate,
  textNormalize,
  latinToFullWidth,
  getUserLogin,
  getUserList,
  getCsrfToken,
  deleteUserList,
  getUserDetail,
  addUser,
  updateUser,
};
