<template>
  <div
    :id="id"
    class="modal custom-modal fade"
    tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="my-4 text-center">
            <div class="fs-16 mb-4">
              {{ message }}
            </div>
            <div class="text-center">
              <button
                class="btn btn-cus btn-blue-light mx-2 w-125 fs-14"
                data-dismiss="modal">
                閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalAlert",
  props: {
    id: { type: String, default: "" },
    message: { type: String, default: "" },
  },
};
</script>
