function sortByMultipleFields(array, order_by, parent) {
  const fields = [];
  for (const column of order_by.split(",")) {
    const sign = column.charAt(0); // '+' or '-'
    const field = column.slice(1); // field name, example 'budget_limit'
    fields.push({ sign, field });
  }

  return array.sort((a, b) => {
    for (const el of fields) {
      const sign = el.sign === "-" ? -1 : 1;
      const field = el.field;
      const valueA = parent ? a[parent][field] : a[field];
      const valueB = parent ? b[parent][field] : b[field];
      // Handle potential null values
      // Asc: Place nulls values first
      // Desc: Place nulls values at the end
      if (valueA === null && valueB !== null) {
        return -1 * sign;
      } else if (valueA !== null && valueB === null) {
        return 1 * sign;
      } else if (valueA !== valueB) {
        let compareValues;
        // Case-insensitive sorting for strings
        if (typeof valueA === "string" && typeof valueB === "string") {
          compareValues = valueA
            .toLowerCase()
            .localeCompare(valueB.toLowerCase());
        } else {
          compareValues = valueA - valueB; // Numerical or other types
        }
        return sign * compareValues;
      }
    }

    return 0; // Equal if all fields are the same
  });
}

export { sortByMultipleFields };
