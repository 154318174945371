import axios from "axios";
import { CUSTOMERS_API } from "./const.service.js";

const getCustomerList = async (params) => {
  params = { ...params, recommend_disabled_flag: false };
  return axios
    .get(CUSTOMERS_API, { params: params })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const getCustomerDetail = async (customer_id) => {
  return axios
    .get(CUSTOMERS_API + "/" + customer_id)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const updateRecommendStopFlag = async (customer_id, newFlag) => {
  const body = { recommend_stop_flag: newFlag };
  return axios
    .patch(CUSTOMERS_API + "/" + customer_id, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export { getCustomerList, getCustomerDetail, updateRecommendStopFlag };
