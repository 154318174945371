<template>
  <div class="custom-tab">
    <nav>
      <div id="nav-tab" class="nav nav-tabs" role="tablist">
        <span
          v-for="(item, index) in navTabs"
          :key="index"
          class="nav-item nav-link"
          :class="{ active: currentTab === item.comp }"
          @click="handleChangeTab(item)">
          {{ item.label }}
        </span>
      </div>
    </nav>
    <div id="nav-tabContent" class="tab-content">
      <transition name="fade" mode="out-in">
        <keep-alive>
          <component
            :is="currentTab"
            :key="`${currentTab}_${currentKey[currentTab]}`"
            @emit-delete="handleDeleteRequest">
          </component>
        </keep-alive>
      </transition>
    </div>
    <ModalConfirm
      id="confirm-modal"
      :message="deleteState.confirmMsg"
      @no="showClosedMessage"
      @yes="submitDelete" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { deleteSaleFollowHistory } from "/src/service/sales-follow-histories.service.js";
import { deleteClosedProperty } from "/src/service/closed-properties.service.js";
import { deleteCustomerActionHistory } from "/src/service/customer-action-histories.service.js";
const { apiMessage } = require("/src/assets/validation.json");

import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import TabPanelContract from "./tabs/TabPanelContract.vue";
import TabPanelFollow from "./tabs/TabPanelFollow.vue";
import TabPanelReservation from "./tabs/TabPanelReservation.vue";
import TabPanelMail from "./tabs/TabPanelMail.vue";

import $ from "jquery";

export default {
  components: {
    ModalConfirm,
    TabPanelContract,
    TabPanelFollow,
    TabPanelReservation,
    TabPanelMail,
  },
  data() {
    return {
      currentTab: "TabPanelContract",
      currentKey: {
        TabPanelContract: 0,
        TabPanelFollow: 0,
        TabPanelReservation: 0,
        TabPanelMail: 0,
      },
      navTabs: [
        { label: "来場・成約履歴", comp: "TabPanelContract" },
        { label: "フォロー履歴", comp: "TabPanelFollow" },
        { label: "問合せ・来場予約履歴", comp: "TabPanelReservation" },
        { label: "レコメンドメール配信履歴", comp: "TabPanelMail" },
      ],
      deleteState: { confirmMsg: "", itemId: "", apiName: "" },
      /* eslint-disable prettier/prettier */
      initialMessage: {
        "history-registry-cancel": "来場・送客情報の登録をキャンセルしました。",
        "history-registry-submit": "来場・送客情報を登録しました。",
        "history-update-cancel": "来場・送客情報の変更をキャンセルしました。",
        "history-update-submit": "来場・送客情報を変更しました。",
        "contract-registry-cancel": "成約情報の登録をキャンセルしました。",
        "contract-registry-submit": "成約情報を登録しました。",
        "contract-update-cancel": "成約情報の変更をキャンセルしました。",
        "contract-update-submit": "成約情報を変更しました。",
        "add-sales-follow-cancel": "フォロー履歴情報の登録をキャンセルしました。",
        "add-sales-follow-submit": "フォロー履歴情報を登録しました。",
        "update-sales-follow-cancel": "フォロー履歴情報の変更をキャンセルしました。",
        "update-sales-follow-submit": "フォロー履歴情報を変更しました。",
      },
      /* eslint-enable prettier/prettier */
    };
  },
  created() {
    const { tab, outcome } = this.$route.query;
    if (
      this.navTabs.map((el) => el.comp).includes(tab) &&
      tab !== "TabPanelContract"
    ) {
      this.currentTab = tab;
      this.$router.replace({ query: { tab: this.currentTab } });
    } else this.$router.replace({});

    const msg = this.initialMessage[outcome];
    if (msg) this.showAlert(msg);
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    showClosedMessage() {
      this.showAlert(apiMessage.ABORT_PROCESS);
    },
    handleChangeTab(item) {
      this.currentTab = item.comp;
      this.$router.replace({ query: { tab: this.currentTab } });
    },
    handleDeleteRequest(dataObj) {
      this.deleteState.confirmMsg = dataObj.message;
      this.deleteState.itemId = dataObj.id;
      this.deleteState.apiName = dataObj.apiDelete;

      $("#confirm-modal").modal("show");
    },
    submitDelete() {
      if (this.deleteState.apiName === "deleteSaleFollowHistory") {
        deleteSaleFollowHistory(this.deleteState.itemId).then(() => {
          this.showAlert("フォロー履歴を削除しました。");
          this.reloadTab();
        });
      }
      if (this.deleteState.apiName === "deleteClosedProperty") {
        deleteClosedProperty(this.deleteState.itemId).then(() => {
          this.showAlert("成約履歴を削除しました。");
          this.reloadTab();
        });
      }
      if (this.deleteState.apiName === "deleteCustomerActionHistory") {
        deleteCustomerActionHistory(this.deleteState.itemId).then(() => {
          this.showAlert("来場・送客履歴を削除しました。");
          this.reloadTab();
        });
      }
    },
    reloadTab() {
      this.currentKey[this.currentTab] += 1;
    },
  },
};
</script>

<style scoped>
#nav-tab > .nav-item {
  cursor: pointer;
  user-select: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style>
.pre-wrap {
  white-space: pre-wrap;
}
</style>
