<template>
  <ScrollTableLayout v-if="items.length > 0">
    <table class="table table-striped">
      <thead>
        <tr class="text-nowrap text-center">
          <th class="minw-160">物件名</th>
          <th>所在地</th>
          <th>沿線</th>
          <th class="text-left">最寄駅</th>
          <th class="text-right">徒歩</th>
          <th>
            <div class="sorted">
              価格
              <div class="sorting">
                <span
                  class="sorting_asc"
                  :class="{ active: priceDesc == false }"
                  @click="sorting('block_price_min', false)"
                  ><i class="fas fa-caret-up"></i></span
                ><span
                  class="sorting_desc"
                  :class="{ active: priceDesc == true }"
                  @click="sorting('block_price_min', true)"
                  ><i class="fas fa-caret-down"></i
                ></span>
              </div>
            </div>
          </th>
          <th>面積</th>
          <th class="text-right">間取り</th>
          <th class="text-right">階建</th>
          <th class="text-left">
            <div class="sorted">
              竣工日
              <div class="sorting">
                <span
                  class="sorting_asc"
                  :class="{ active: completionDateDesc == false }"
                  @click="sorting('completion_date', false)"
                  ><i class="fas fa-caret-up"></i></span
                ><span
                  class="sorting_desc"
                  :class="{ active: completionDateDesc == true }"
                  @click="sorting('completion_date', true)"
                  ><i class="fas fa-caret-down"></i
                ></span>
              </div>
            </div>
          </th>
          <th class="text-right">総戸数</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.property_id">
          <td>
            <router-link
              :to="{
                name: 'property-detail',
                params: { id: item.property_id },
              }"
              class="txt-underline"
              >{{ item.name }}</router-link
            >
          </td>
          <td>{{ item.location_district }}</td>
          <td>{{ item.nearest_station_railway_1 }}</td>
          <td>{{ item.nearest_station_1 }}</td>
          <td class="text-right">
            {{
              func.showValue(item.walking_to_nearest_station_1, {
                suffix: "分",
                includeZero: true,
              })
            }}
          </td>
          <td class="text-right">
            {{
              func.showValue(
                func.formatNumber(
                  func.convertAndRoundToMY(item.block_price_min)
                ),
                { suffix: "万円", includeZero: true }
              )
            }}{{ item.block_price_min || item.block_price_max ? "～" : ""
            }}<br />{{
              func.showValue(
                func.formatNumber(
                  func.convertAndRoundToMY(item.block_price_max)
                ),
                { suffix: "万円", includeZero: true }
              )
            }}
          </td>
          <td class="text-right">
            {{
              func.showValue(
                item.block_area_min
                  ? func.formatNumber(item.block_area_min, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : func.formatNumber(item.block_area_min),
                {
                  suffix: "㎡",
                  includeZero: true,
                }
              )
            }}{{ item.block_area_min || item.block_area_max ? "～" : ""
            }}<br />{{
              func.showValue(
                item.block_area_max
                  ? func.formatNumber(item.block_area_max, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : func.formatNumber(item.block_area_max),
                {
                  suffix: "㎡",
                  includeZero: true,
                }
              )
            }}
          </td>
          <td class="text-right">
            {{ func.convertEnum(item.block_layout_type_min, BLOCK_LAYOUT_TYPE)
            }}{{
              item.block_layout_type_min || item.block_layout_type_max
                ? "～"
                : ""
            }}<br />{{
              func.convertEnum(item.block_layout_type_max, BLOCK_LAYOUT_TYPE)
            }}
          </td>
          <td class="text-right">
            {{
              func.showValue(item.number_of_floors, {
                suffix: "階",
                includeZero: true,
              })
            }}
          </td>
          <td class="text-nowrap">
            {{
              func
                .formatDateToParts(item.completion_date, {
                  dateStyle: "long",
                })
                .map((item) => item.value)
                .slice(0, 4)
                .join("")
            }}
          </td>
          <td class="text-right">
            {{
              func.showValue(func.formatNumber(item.number_of_houses), {
                suffix: "戸",
                includeZero: true,
              })
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </ScrollTableLayout>
</template>
<script>
import { BLOCK_LAYOUT_TYPE } from "/src/constants";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
export default {
  name: "PropertyList",
  components: {
    ScrollTableLayout,
  },
  props: ["items", "order_by_src", "desc_src"],
  data() {
    return {
      order_by: this.order_by_src || "",
      desc: this.desc_src || null,
      BLOCK_LAYOUT_TYPE,
    };
  },
  computed: {
    priceDesc() {
      if (this.order_by.includes("block_price_min")) {
        return this.desc ? true : false;
      }
      return null;
    },
    completionDateDesc() {
      if (this.order_by.includes("completion_date")) {
        return this.desc ? true : false;
      }
      return null;
    },
  },
  methods: {
    sorting(field, desc) {
      this.order_by = field;
      this.desc = desc;
      if (desc) {
        this.order_by = "-" + field;
      }
      if (this.order_by.endsWith("min")) {
        let max_field = this.order_by.replace("min", "max");
        this.order_by += "," + max_field;
      }
      this.$emit("sorting", {
        order_by: this.order_by,
        desc: this.desc,
      });
    },
  },
};
</script>
