<template>
  <ContractFormBase
    title="成約登録"
    :init-customer-id="customerId"
    :modal-msg="modalMsg"
    @confirm-submit="handleSubmit"
    @confirm-cancel="handleCancel" />
</template>

<script>
import { createClosedProperty } from "/src/service/closed-properties.service.js";
import { backToCustomerAction } from "/src/helpers/route-navigate.js";

import ContractFormBase from "/src/components/closed-properties/ContractFormBase.vue";

export default {
  components: {
    ContractFormBase,
  },
  data() {
    return {
      modalMsg: {
        submit: "成約情報を登録します。",
        cancel: "成約情報の登録をキャンセルします。",
      },
    };
  },
  computed: {
    customerId() {
      const idStr = this.$route.params.customer_id;
      if (idStr) return parseInt(idStr);
      return null;
    },
  },
  methods: {
    async handleSubmit(body) {
      await createClosedProperty(body);
      backToCustomerAction(this, body.customer_id, "submit");
    },
    handleCancel(body) {
      backToCustomerAction(this, body.customer_id, "cancel");
    },
  },
};
</script>
