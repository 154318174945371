<template>
  <div class="form-group">
    <div class="input-group date custom-inp-date" data-target-input="nearest">
      <VueDatePicker
        :id="id"
        v-model="date"
        :model-value="modelvalue"
        :enable-time-picker="false"
        :format="formatDate"
        :placeholder="placeholder"
        :clearable="clearable"
        :disabled="disabled"
        locale="ja"
        cancel-text="キャンセル"
        select-text="選択する"
        auto-apply
        @update:model-value="setDate"
        @cleared="setDate()">
        <template #input-icon>
          <i class="far fa-calendar"></i>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputTimes",
  props: {
    id: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    modelvalue: { type: String, default: "" },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      date: null,
    };
  },
  methods: {
    formatDate(date) {
      if (date == null) return "";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("/");
    },
    setDate(value) {
      this.date = value;
      this.$emit("update:modelvalue", this.formatDate(this.date));
    },
  },
};
</script>
