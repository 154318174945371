<template>
  <div class="wrapper single-page">
    <div class="content-wrapper">
      <section class="content py-5">
        <div class="container-fluid">
          <section class="content">
            <div class="error-page">
              <div class="text-center">
                <h3>
                  <i class="fas fa-exclamation-triangle text-danger"></i>
                  Oops! Page not found.
                </h3>
                <p>
                  We could not find the page you were looking for.<br />
                  Meanwhile, you may
                  <router-link to="/">return to Home</router-link>
                </p>
              </div>
              <h2 class="headline float-none text-primary text-center">404</h2>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PageNotFound",
  computed: {
    ...mapState("user", ["user_object"]),
  },
  created() {
    if (this.user_object.role == null) {
      this.$router.push({ name: "redirect-login" });
    }
  },
};
</script>
