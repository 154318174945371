<template>
  <div class="wrapper">
    <SideBarTop />
    <SideBarLeft />
    <div class="content-wrapper">
      <section class="content py-3">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <slot></slot>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SideBarTop from "./SidebarTop.vue";
import SideBarLeft from "./SidebarLeft.vue";

export default {
  components: {
    SideBarTop,
    SideBarLeft,
  },
};
</script>
