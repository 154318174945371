<template>
  <div class="wrap-ct">
    <div class="row my-4 mx-3 align-items-end">
      <div class="col-12">
        <div class="text-md-right">
          <button
            class="btn btn-cus btn-blue-light btn-add-tbl mr-2"
            @click="$router.push({ name: `user-add` })">
            <img src="@/assets/img/icons/ic-plus.png" />
            ユーザー新規登録
          </button>
        </div>
      </div>
    </div>
    <ScrollTableLayout v-if="total > 0">
      <table class="table table-striped">
        <thead>
          <tr class="text-center">
            <th class="minw-160">氏名</th>
            <th>メールアドレス</th>
            <th class="text-left">
              <div class="sorted">
                ユーザー属性
                <div class="sorting">
                  <span
                    class="sorting_asc"
                    :class="{ active: roleDesc == false }"
                    @click="sorting('role', false)"
                    ><i class="fas fa-caret-up"></i></span
                  ><span
                    class="sorting_desc"
                    :class="{ active: roleDesc == true }"
                    @click="sorting('role', true)"
                    ><i class="fas fa-caret-down"></i
                  ></span>
                </div>
              </div>
            </th>
            <th class="text-center">
              <div class="sorted">
                フォロー担当
                <div class="sorting">
                  <span
                    class="sorting_asc"
                    :class="{ active: followDesc == false }"
                    @click="sorting('follow_available_flag', false)"
                    ><i class="fas fa-caret-up"></i></span
                  ><span
                    class="sorting_desc"
                    :class="{ active: followDesc == true }"
                    @click="sorting('follow_available_flag', true)"
                    ><i class="fas fa-caret-down"></i
                  ></span>
                </div>
              </div>
            </th>
            <th>登録日</th>
            <th>編集</th>
            <th>削除</th>
          </tr>
        </thead>
        <tbody>
          <TrUser
            v-for="user in users"
            :id="user.user_id"
            :key="user.user_id"
            :name="user.name"
            :role="user.role"
            :email="user.email"
            :follow-available-flag="user.follow_available_flag"
            :created-at="user.created_at"
            :is-selected="user.isSelected"
            @delete-user="deleteUser" />
        </tbody></table
    ></ScrollTableLayout>
    <PaginateInterface
      v-if="total > 0"
      :key="users"
      :pages="pages"
      :page="page"
      :size="size"
      :initial-page="initialPage" />
    <PaginateInfo
      v-if="total > 0"
      :key="users"
      :page="page"
      :size="size"
      :total="total" />
    <ModalConfirm
      id="confirmDeleteUser"
      message="指定したユーザーを削除します。
              (担当者の活動履歴は保持されます。)"
      @yes="handleDeleteUser"
      @no="abortDeleteUser" />
  </div>
</template>
<script>
import $ from "jquery";
import { mapActions } from "vuex";
import PaginateInterface from "/src/components/commons/PaginateInterface.vue";
import PaginateInfo from "/src/components/commons/PaginateInfo.vue";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import TrUser from "./TrUser.vue";
import { mapState } from "vuex";
import { deleteUserList } from "/src/service/users.service.js";
import { apiMessage } from "/src/assets/validation.json";

export default {
  name: "UserList",
  components: {
    PaginateInterface,
    PaginateInfo,
    TrUser,
    ModalConfirm,
    ScrollTableLayout,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 50,
    },
    total: {
      type: Number,
      default: 0,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      order_by: "",
    };
  },
  computed: {
    ...mapState("user", ["users", "user_object"]),
    numberDeleteUser() {
      return this.$store.getters["user/getUserIdForDelete"].length;
    },
    roleDesc() {
      return this.fieldDesc("role");
    },
    followDesc() {
      return this.fieldDesc("follow_available_flag");
    },
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    fieldDesc(fieldName) {
      if (this.order_by.includes(fieldName)) {
        return this.desc ? true : false;
      }
      return null;
    },
    sorting(field, desc) {
      this.order_by = field;
      this.desc = desc;
      if (desc) {
        this.order_by = "-" + field;
      }
      this.$emit("sorting", {
        order_by: this.order_by,
      });
    },
    handleDeleteUser() {
      let user_id_list = this.$store.getters["user/getUserIdForDelete"];
      $("#confirmDeleteUser").modal("hide");
      deleteUserList(user_id_list);
      this.$store.commit("user/deleteUserFromList", user_id_list);
    },
    abortDeleteUser() {
      this.showAlert(apiMessage.ABORT_PROCESS);
      this.$store.commit("user/toggleUser", this.user_delete_id);
    },
    deleteUser(id) {
      if (id == this.user_object.user_id) {
        this.$store.commit("confirmapi/setConfirm", {
          status: true,
          mes: "ログインユーザーのため削除できません。",
        });
      } else {
        this.user_delete_id = id;
        // add id to store.state.users
        this.$store.commit("user/toggleUser", this.user_delete_id);
        // show confirmDeleteUser modal
        $("#confirmDeleteUser").modal("show");
      }
    },
  },
};
</script>
