<template>
  <tr :class="{ active: isSelected }">
    <td>
      <router-link
        :to="{ name: 'user-detail', params: { user_id: id } }"
        class="txt-underline"
        >{{ name }}</router-link
      >
    </td>
    <td class="text-nowrap">{{ email }}</td>
    <td class="text-nowrap">{{ roleName }}</td>
    <td v-if="followAvailableFlag">
      <div class="form-check custom-checkbox pl-0" style="pointer-events: none">
        <input
          :id="'option1' + id"
          class="form-check-input mx-auto"
          type="checkbox"
          name="distinguish"
          value=""
          checked
          disabled />
        <label :for="'option1' + id"></label>
      </div>
    </td>
    <td v-else>
      <div class="form-check custom-checkbox pl-0" style="pointer-events: none">
        <input
          :id="'option2' + id"
          class="form-check-input mx-auto"
          type="checkbox"
          name="distinguish"
          value=""
          disabled />
        <label :for="'option2' + id"></label>
      </div>
    </td>
    <td class="text-center text-nowrap">
      {{ func.formatDate(createdAt, { dateStyle: "long" }) }}
    </td>
    <td class="text-center">
      <button
        class="btn btn-cus btn-blue-light"
        @click="$router.push({ name: 'user-edit', params: { user_id: id } })">
        <img src="@/assets/img/icons/ic-pen.png" alt="edit" />
      </button>
    </td>
    <td class="text-center">
      <button class="btn btn-cus btn-pink" @click="$emit('delete-user', id)">
        <img src="@/assets/img/icons/ic-trasts-black.png" alt="edit" />
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "TrUser",
  props: {
    id: { type: Number, default: 0 },
    name: { type: String, default: "" },
    role: { type: Number, default: 0 },
    email: { type: String, default: "" },
    followAvailableFlag: { type: Boolean, default: false },
    createdAt: { type: String, default: "" },
    isSelected: { type: Boolean, default: false },
  },
  computed: {
    roleName: function () {
      const roleDict = {
        1: "閲覧のみユーザー",
        3: "編集ユーザー",
        7: "管理者",
      };
      return roleDict[this.role];
    },
  },
};
</script>
