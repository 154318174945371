<template>
  <BaseLayout>
    <FormLayout :title="title">
      <CustomerName
        :customer-name="customerName"
        :customer-id="customerId"
        :fetch-delay="API_DELAY" />
      <SelectTime
        v-model="dataParam.dateVisit"
        label="送客日"
        :error="errorMsg.dateVisit"
        @update:modelValue="checkAndHideError('dateVisit', 'dateVisit')" />
      <SelectUser
        v-model="dataParam.userId"
        :error="errorMsg.userId"
        :fetch-delay="2 * API_DELAY"
        @update:modelValue="checkAndHideError('userId', 'userId')" />
      <RadioAssetType
        v-model="dataParam.typeAsset"
        @update:modelValue="errorMsg.property = ''" />
      <PropertyAndBlockLayout :error="errorMsg.property">
        <template #property>
          <div v-show="isSalesForce">
            <input
              v-model.trim="dataParam.propertyName"
              type="text"
              maxlength="99"
              class="form-control"
              :class="{ 'field-error': errorMsg.property }"
              placeholder="物件名を入力"
              @focusout="jTextNormalize('propertyName')"
              @input="checkAndHideError('propertyName', 'property')" />
          </div>
          <SelectProperty
            v-show="!isSalesForce"
            v-model="dataParam.propertyId"
            :class="{ 'field-error': errorMsg.property }"
            :fetch-delay="3 * API_DELAY"
            @update:modelValue="checkAndHideError('propertyId', 'property')" />
        </template>
        <template #block>
          <div v-show="isSalesForce">
            <input
              v-model.trim="dataParam.blockName"
              type="text"
              maxlength="99"
              class="form-control"
              placeholder="区画名を入力"
              @input="if (!dataParam.blockName) dataParam.blockName = null;"
              @focusout="jTextNormalize('blockName')" />
          </div>
          <SelectBlock
            v-show="!isSalesForce"
            v-model="dataParam.blockId"
            :property-id="dataParam.propertyId" />
        </template>
      </PropertyAndBlockLayout>
      <div class="row">
        <div class="col-12">
          <div class="my-4 text-center">
            <FunctionalButton
              v-if="$route.name === 'history-registry'"
              class="btn-blue-light m-2 w-150"
              type="button"
              text="登録"
              @click="handleBtnSubmit('action-add-modal')" />
            <FunctionalButton
              v-if="$route.name === 'history-update'"
              class="btn-blue-light m-2 w-150"
              type="button"
              text="変更"
              :disabled="isLoading || !isChanged"
              @click="isChanged && handleBtnSubmit('action-add-modal')" />
            <FunctionalButton
              class="btn-grey m-2 w-150"
              type="button"
              data-toggle="modal"
              data-target="#action-add-modal"
              text="キャンセル"
              @click="handleBtnCancel()" />
          </div>
        </div>
      </div>
    </FormLayout>
    <ModalConfirm
      id="action-add-modal"
      :message="modalMsg[clickedButton]"
      @no="showClosedMessage"
      @yes="handleConfirm" />
  </BaseLayout>
</template>

<script>
import { API_DELAY } from "/src/frontend/src/constants.js";
import { getActionHistoryDetail } from "/src/service/customer-action-histories.service.js";
import customerActionHistoryMixin from "/src/components/customers/action/history-mixin.js";

import PropertyAndBlockLayout from "/src/components/customers/action/form-fields/PropertyAndBlockLayout.vue";

export default {
  components: {
    PropertyAndBlockLayout,
  },
  mixins: [customerActionHistoryMixin],
  props: {
    initHistoryId: { type: Number, default: null },
  },
  data() {
    const now = new Date();
    const nowStr = now.toLocaleDateString("ja-JP", { dateStyle: "medium" });
    const mixinDataParam = this.$options.mixins[0].data().dataParam;
    return {
      dataParam: {
        userId: mixinDataParam.userId,
        typeAsset: mixinDataParam.typeAsset,
        propertyId: mixinDataParam.propertyId,
        blockId: mixinDataParam.blockId,
        propertyName: mixinDataParam.propertyName,
        blockName: mixinDataParam.blockName,
        dateVisit: nowStr,
      },
      errorMsg: {
        dateVisit: "",
        userId: "",
        property: "",
      },
      API_DELAY,
    };
  },
  computed: {
    requestBody() {
      const propertyInfo = this.isSalesForce
        ? {
            property_name: this.dataParam.propertyName,
            block_name: this.dataParam.blockName || null,
          }
        : {
            property_id: this.dataParam.propertyId,
            block_id: this.dataParam.blockId || null,
          };
      const body = {
        customer_id: this.customerId,
        user_id: this.dataParam.userId,
        action_date: this.dataParam.dateVisit.replaceAll("/", "-"),
        property_table_type: this.dataParam.typeAsset,
        ...propertyInfo,
      };
      return body;
    },
  },
  created() {
    if (this.initHistoryId) this.fetchActionDetail();
  },
  methods: {
    async fetchActionDetail() {
      const response = await getActionHistoryDetail(this.initHistoryId);
      const data = response.data;
      this.dataParam.dateVisit = data.action_date.replaceAll("-", "/");
      this.assignCommon(data);
      if (!this.canEditItem(data)) this.$router.replace({ name: "error404" });
    },
    isValidForm() {
      const isValidDate = this.validateField("dateVisit", "dateVisit");
      const isValidUser = this.validateField("userId", "userId");
      const isValidProperty = this.isSalesForce
        ? this.validateField("propertyName", "property")
        : this.validateField("propertyId", "property");

      return isValidDate && isValidUser && isValidProperty;
    },
  },
};
</script>
