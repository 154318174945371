<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-xl-3 my-2">顧客氏名</div>
    <div class="col-12 col-md-5 col-xl-5 my-2">
      {{ fullNameKanji }}
    </div>
  </div>
</template>

<script>
import { getCustomerDetail } from "/src/service/customers.service.js";

export default {
  props: {
    customerId: { type: Number, default: null },
    customerName: { type: String, default: "" },
  },
  data() {
    return {
      fetchedName: this.customerName,
    };
  },
  computed: {
    fullNameKanji() {
      return this.customerName || this.fetchedName;
    },
  },
  created() {
    this.fetchCustomer();
  },
  methods: {
    async fetchCustomer() {
      if (this.customerName) return;
      if (!this.customerId) return;
      const response = await getCustomerDetail(this.customerId);
      const customerName = response.data.full_name_kanji;
      if (customerName) this.fetchedName = customerName;
    },
  },
};
</script>
