<template>
  <button :type="buttonType" :class="classListClass" :disabled="disabled">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonForm",
  // props: ["classlist", "text"],
  props: {
    buttonType: {
      type: String,
      default: "button",
    },
    classlist: {
      type: String,
      default: "btn btn-cus",
    },
    classListExtend: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classListClass() {
      return `${this.classlist} ${this.classListExtend}`;
    },
  },
};
</script>
