<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-xl-3 my-2">
      担当者
      <span v-show="!disabled" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-5 col-xl-3 my-2">
      <FieldSelect2
        :key="`select_user_${userId}_${users.length}`"
        v-model.number="userId"
        class="custom-select2 w-100"
        :class="{ 'field-error': error }"
        :long-options="users"
        placeholder="担当者名を選択"
        :disabled="disabled" />
    </div>
  </div>
  <div v-show="error !== ''" class="row align-items-center mb-2">
    <div class="col-12 col-md-4 col-xl-3"></div>
    <div class="col">
      <p class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { getUserList } from "/src/service/users.service.js";
import { USER_ROLE } from "/src/frontend/src/constants.js";

import FieldSelect2 from "/src/components/utilitys/inputs/FieldSelect2.vue";

export default {
  components: {
    FieldSelect2,
  },
  props: {
    modelValue: { type: Number, default: null },
    error: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    fetchDelay: { type: Number, default: 0 },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      users: [],
    };
  },
  computed: {
    userId: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  created() {
    setTimeout(() => {
      this.fetchUsers();
    }, this.fetchDelay);
  },
  methods: {
    async fetchUsers() {
      const params = { all: true, role: USER_ROLE.EDITOR };
      const response = await getUserList(params);
      let isFoundUser = false;
      response.data.items.forEach((el) => {
        this.users.push({ value: el.user_id, label: el.name });
        if (el.user_id === this.userId) isFoundUser = true;
      });
      if (!isFoundUser) this.$emit("update:modelValue", null);
    },
  },
};
</script>
