<template>
  <BaseLayout>
    <div class="wrap-ct border-0">
      <div class="custom-tab">
        <nav>
          <div id="nav-tab" class="nav nav-tabs" role="tablist">
            <a
              id="nav-1-tab"
              class="nav-item nav-link active"
              data-toggle="tab"
              href="#nav-1"
              role="tab"
              aria-controls="nav-1"
              aria-selected="true"
              >送信履歴一覧</a
            >
            <a
              v-if="user_object.role === USER_ROLE.ADMIN"
              id="nav-2-tab"
              class="nav-item nav-link"
              data-toggle="tab"
              href="#nav-2"
              role="tab"
              aria-controls="nav-2"
              aria-selected="false"
              @click="loadTabFollow"
              >フォロー担当振分</a
            >
            <a
              v-if="
                user_object.role === USER_ROLE.EDITOR &&
                user_object.follow_available_flag === true
              "
              id="nav-2-tab"
              class="nav-item nav-link"
              data-toggle="tab"
              href="#nav-3"
              role="tab"
              aria-controls="nav-2"
              aria-selected="false"
              @click="loadTabFollowCusomter"
              >フォロー担当振分</a
            >
          </div>
        </nav>
        <div id="nav-tabContent" class="tab-content">
          <TabListMail :id="'nav-1'" />
          <TabFollow
            v-if="user_object.role === USER_ROLE.ADMIN"
            :id="'nav-2'"
            ref="tabFollow" />
          <TabFollowCustomer
            v-if="
              user_object.role === USER_ROLE.EDITOR &&
              user_object.follow_available_flag === true
            "
            :id="'nav-3'"
            ref="tabFollowCustomer" />
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { mapState } from "vuex";
import { USER_ROLE } from "@/constants.js";

import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import TabListMail from "/src/components/new-mails/main/TabListMail.vue";
import TabFollow from "/src/components/new-mails/main/TabFollow.vue";
import TabFollowCustomer from "/src/components/new-mails/main/TabFollowCustomer.vue";

export default {
  name: "NewMails",
  components: {
    BaseLayout,
    TabListMail,
    TabFollow,
    TabFollowCustomer,
  },
  data() {
    return {
      USER_ROLE,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
  },
  methods: {
    loadTabFollow() {
      this.$refs["tabFollow"].getDataFirst();
    },
    loadTabFollowCusomter() {
      this.$refs["tabFollowCustomer"].getDataFirst();
    },
  },
};
</script>
