<template>
  <div class="card-body">
    <div class="row mb-4">
      <div class="col-md-12">
        <h3 class="card-title-cus">ユーザー一覧</h3>
      </div>
    </div>
    <UserList
      :page="page"
      :size="size"
      :total="total"
      :pages="pages"
      :initial-page="initialPage"
      @sorting="handleSorting" />
  </div>
</template>
<script>
import UserList from "../../components/users/UserList.vue";
import { getUserList } from "/src/service/users.service.js";
import { getParamsUrl } from "/src/service/utility.service.js";
export default {
  name: "UserDashboard",
  components: {
    UserList,
  },
  data() {
    return {
      page: 1,
      pages: 1,
      size: 50,
      total: 0,
      initialPage: 1,
      order: { order_by: "" },
      order_by_query: "",
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (location.search != "") {
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchInitialData();
  },
  updated() {
    this.fetchInitialData();
  },
  methods: {
    fetchData(addParams = {}) {
      let params = getParamsUrl();
      this.page = params["page"] ? parseInt(params["page"]) : this.page;
      this.initialPage = this.page;
      // flat addParams and params
      let all_params = { ...params, ...addParams, size: this.size };
      getUserList(all_params).then((res) => {
        this.total = res.data["total"];
        this.pages = res.data["pages"];
        this.size = res.data["size"];
        this.$store.commit("user/setUserList", res.data["items"]);
      });
    },
    fetchInitialData() {
      if (location.search == "") {
        this.$router.push(`${location.pathname}?page=1`);
      }
    },
    handleSorting(event) {
      this.order.order_by = event.order_by;
      let order_query_string = "order_by=" + this.order.order_by;
      if (order_query_string != this.order_by_query) {
        this.order_by_query = order_query_string;
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.$router.replace({
            query: { ...this.$route.query, ...this.order, ...{ page: 1 } },
          });
        }, 500);
      } else {
        this.page = 1;
        this.timeOut = setTimeout(() => {
          this.$router.replace({
            query: { ...this.$route.query, ...this.order, ...{ page: 1 } },
          });
        }, 500);
      }
    },
  },
};
</script>
