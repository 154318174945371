import axios from "axios";
import { CLOSED_PROPERTIES_API } from "./const.service.js";

const getClosedProperties = async (params) => {
  return axios
    .get(CLOSED_PROPERTIES_API, { params: params })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const getClosedPropertyDetail = async (closed_property_id) => {
  return axios
    .get(CLOSED_PROPERTIES_API + "/" + closed_property_id)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const deleteClosedProperty = async (closed_property_id) => {
  return axios
    .delete(CLOSED_PROPERTIES_API + "/" + closed_property_id)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const createClosedProperty = async (body) => {
  return axios
    .post(CLOSED_PROPERTIES_API, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const updateClosedProperty = async (closed_property_id, body) => {
  return axios
    .put(CLOSED_PROPERTIES_API + "/" + closed_property_id, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export {
  getClosedProperties,
  getClosedPropertyDetail,
  deleteClosedProperty,
  createClosedProperty,
  updateClosedProperty,
};
