<template>
  <div class="cus-filter">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="filter.name"
                type="text"
                class="form-control"
                placeholder="物件名" />
              <div class="input-group-append bg-white">
                <img
                  src="@/assets/img/icons/ic-homew.png"
                  alt="icon"
                  width="24"
                  height="24" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="filter.location"
                type="text"
                class="form-control"
                placeholder="所在地" />
              <div class="input-group-append bg-white">
                <img
                  src="@/assets/img/icons/ic-map.png"
                  alt="icon"
                  width="14"
                  height="20" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="filter.nearest_station_railway"
                type="text"
                class="form-control"
                placeholder="沿線" />
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="filter.nearest_station"
                type="text"
                class="form-control"
                placeholder="最寄駅" />
              <div class="input-group-append bg-white">
                <img
                  src="@/assets/img/icons/ic-bus.png"
                  alt="icon"
                  width="16"
                  height="19" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">価格</label>
              <input
                v-model="filter.price_min"
                type="number"
                min="0"
                name=""
                class="form-control"
                placeholder="最低"
                @input="
                  filter.price_min = func.onlyNumberInput(
                    $event,
                    filter.price_min
                  )
                " />
              <span class="ml-1 text-nowrap">万円</span>
              <span class="mx-2">～</span>
              <input
                v-model="filter.price_max"
                type="number"
                min="0"
                name=""
                class="form-control"
                placeholder="最高"
                @input="
                  filter.price_max = func.onlyNumberInput(
                    $event,
                    filter.price_max
                  )
                " />
              <span class="ml-1 text-nowrap">万円</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">面積</label>
              <input
                v-model="filter.block_area_min"
                type="number"
                min="0"
                name=""
                class="form-control"
                placeholder="最低"
                @input="
                  filter.block_area_min = func.onlyNumberInput(
                    $event,
                    filter.block_area_min
                  )
                " />
              <span class="ml-1 text-nowrap">㎡</span>
              <span class="mx-2">～</span>
              <input
                v-model="filter.block_area_max"
                type="number"
                min="0"
                name=""
                class="form-control"
                placeholder="最高"
                @input="
                  filter.block_area_max = func.onlyNumberInput(
                    $event,
                    filter.block_area_max
                  )
                " />
              <span class="ml-1 text-nowrap">㎡</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">間取り</label>
              <FieldSelect2
                v-model="filter.layout_type_min"
                sub-class="form-control construcyea-select4"
                class="custom-select2 w-100"
                :long-options="blockLayoutTypeVar"
                placeholder="最低" />
              <span class="mx-2">～</span>
              <FieldSelect2
                v-model="filter.layout_type_max"
                sub-class="form-control construcyea-select4"
                class="custom-select2 w-100"
                :long-options="blockLayoutTypeVar"
                placeholder="最高" />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">徒歩</label>
              <input
                v-model="filter.walking_to_nearest_station"
                type="number"
                min="0"
                name=""
                class="form-control"
                placeholder="00"
                @input="
                  filter.walking_to_nearest_station = func.onlyNumberInput(
                    $event,
                    filter.walking_to_nearest_station
                  )
                " />
              <span class="ml-1 text-nowrap">分以内</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">築年月</label>
              <FieldSelect2
                v-model="filter.start_year"
                class="custom-select2 w-100"
                :options="years"
                placeholder="最低" />
              <span class="mx-2">年</span>
              <FieldSelect2
                v-model="filter.start_month"
                class="custom-select2 w-100"
                :long-options="months"
                placeholder="最低" />
              <span class="mx-2">月</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">～</label>
              <FieldSelect2
                v-model="filter.end_year"
                class="custom-select2 w-100"
                :options="years"
                placeholder="最高" />
              <span class="mx-2">年</span>
              <FieldSelect2
                v-model="filter.end_month"
                class="custom-select2 w-100"
                :long-options="months"
                placeholder="最高" />
              <span class="mx-2">月</span>
            </div>
          </div>
        </div>
        <div v-if="formDateIsValid" class="col-12">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3"></label>
              <div class="text-red2 mb-2 fs-12 form-error">
                日付の大小関係が不正です
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 my-3">
          <div class="text-center">
            <button
              class="btn btn-cus btn-blue-light px-3 m-2 w-105"
              @click="navigateToSearch">
              絞り込み
            </button>
            <button
              :disabled="isClear"
              class="btn btn-cus btn-grey px-3 m-2"
              @click="clearFilter">
              検索条件クリア
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  months,
  years,
  blockLayoutTypeVar,
} from "/src/components/constants/const.js";
import FieldSelect2 from "/src/components/utilitys/inputs/FieldSelect2.vue";
import { getParamsUrl } from "/src/service/utility.service.js";
export default {
  components: {
    FieldSelect2,
  },
  data() {
    let params = getParamsUrl();
    return {
      filter: {
        name: params.name || "",
        location: params.location || "",
        nearest_station_railway: params.nearest_station_railway || "",
        nearest_station: params.nearest_station || "",
        price_min: params.price_min || "",
        price_max: params.price_max || "",
        block_area_min: params.block_area_min || "",
        block_area_max: params.block_area_max || "",
        layout_type_min: params.layout_type_min || "",
        layout_type_max: params.layout_type_max || "",
        walking_to_nearest_station: params.walking_to_nearest_station || "",
        start_month: params.start_month || "",
        start_year: params.start_year || "",
        end_month: params.end_month || "",
        end_year: params.end_year || "",
      },
      blockLayoutTypeVar,
      months,
      years,
    };
  },
  computed: {
    isClear() {
      return !(
        this.filter.name ||
        this.filter.location ||
        this.filter.nearest_station_railway ||
        this.filter.nearest_station ||
        this.filter.price_min ||
        this.filter.price_max ||
        this.filter.block_area_min ||
        this.filter.block_area_max ||
        this.filter.layout_type_min ||
        this.filter.layout_type_max ||
        this.filter.walking_to_nearest_station ||
        this.filter.start_month ||
        this.filter.end_month ||
        this.filter.start_year ||
        this.filter.end_year
      );
    },
    formDateIsValid() {
      if (this.filter.start_year == "" || this.filter.end_year == "") {
        if (this.filter.start_year == "" && this.filter.end_year == "") {
          if (this.filter.start_month || this.filter.end_month) {
            return true;
          }
        }
        if (this.filter.start_month && this.filter.end_month) {
          return true;
        } else if (
          (this.filter.start_year && this.filter.end_month) ||
          (this.filter.end_year && this.filter.start_month)
        ) {
          return true;
        }
      }
      if (
        this.convertStringToInt(this.filter.start_year) >
          this.convertStringToInt(this.filter.end_year) ||
        (this.convertStringToInt(this.filter.start_year) ==
          this.convertStringToInt(this.filter.end_year) &&
          this.convertStringToInt(this.filter.start_month) >
            this.convertStringToInt(this.filter.end_month))
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.enterToSearch);
  },
  unmounted() {
    document.removeEventListener("keydown", this.enterToSearch);
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    clearFilter() {
      this.filter.name = "";
      this.filter.location = "";
      this.filter.nearest_station_railway = "";
      this.filter.nearest_station = "";
      this.filter.price_min = "";
      this.filter.price_max = "";
      this.filter.block_area_min = "";
      this.filter.block_area_max = "";
      this.filter.layout_type_min = "";
      this.filter.layout_type_max = "";
      this.filter.walking_to_nearest_station = "";
      this.filter.start_month = "";
      this.filter.start_year = "";
      this.filter.end_month = "";
      this.filter.end_year = "";
      this.show = true;
    },
    createQueryStringFromData() {
      return Object.keys(this.$data.filter)
        .filter((key) => this.$data.filter[key])
        .map((key) => `${key}=${this.$data.filter[key]}`)
        .join("&");
    },
    navigateToSearch() {
      let query = this.createQueryStringFromData() + "&page=1";
      let oldQuery = decodeURI(location.search);
      if (
        oldQuery.slice(1) == query ||
        (query == "&page=1" && oldQuery.slice(1) == "page=1")
      ) {
        this.$emit("reload");
      } else {
        if (query == "&page=1") {
          query = "page=1";
        }
        this.$router.push(`${location.pathname}?${query}`);
      }
    },
    convertStringToInt(input) {
      if (typeof input === "number") {
        return input;
      }
      return parseInt(input, 10);
    },
    enterToSearch(e) {
      if (e.key == "Enter") {
        this.navigateToSearch();
      }
    },
  },
};
</script>
