<template>
  <ScrollTableLayout>
    <table class="table table-striped">
      <thead>
        <tr class="text-nowrap text-center">
          <th class="w-215 minw-160">氏名</th>
          <th class="w-215">フリガナ</th>
          <th class="w-105 text-center">性別</th>
          <th class="w-105 text-center">年齢</th>
          <th class="w-145">住所</th>
          <th>メールアドレス</th>
          <th>電話番号</th>
          <th>担当</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in customers" :key="index">
          <td>
            <router-link
              :to="{
                name: 'customer-detail',
                params: { customer_id: row.customer_id },
              }"
              class="txt-underline white-space-normal">
              {{ func.displayValue(row.full_name_kanji) }}
            </router-link>
          </td>
          <td>{{ func.displayValue(row.full_name_furigana) }}</td>
          <td class="text-center">{{ func.displayValue(row.gender) }}</td>
          <td class="text-center">{{ getAge(row) }}</td>
          <td>{{ getAddress(row) }}</td>
          <td class="text-nowrap">
            <span class="email-link">
              {{ func.displayValue(row.email) }}
            </span>
          </td>
          <td class="text-nowrap">
            {{ func.displayValue(row.telephone_number) }}
          </td>
          <td>{{ getFollower(row) }}</td>
        </tr>
      </tbody>
    </table>
  </ScrollTableLayout>
</template>

<script>
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";

export default {
  components: {
    ScrollTableLayout,
  },
  props: {
    customers: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  methods: {
    getAge(item) {
      const dateStr = item.birthday;
      const date = Date.parse(dateStr);
      if (isNaN(date)) return this.func.displayValue(dateStr);
      const age = this.func.birthdayToAge(item.birthday);
      return age > 0 ? age : "";
    },
    getAddress(item) {
      const district = item.address_district || "";
      return district;
    },
    getFollower(item) {
      return this.func.displayValue(item.latest_user_followed?.name);
    },
  },
};
</script>

<style scoped>
.email-link {
  text-decoration: underline;
  color: #007bff;
}
</style>
