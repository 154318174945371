<template>
  <BaseLayout>
    <div class="row">
      <FreemailForm ref="FreemailForm" :mail-type="mailType" />
      <FreemailFilter
        :radio-options="radioOptions"
        @change-mail-type="changeMailType"
        @reload-data="fetchData"
        @click-search="resetData" />
    </div>
    <div v-show="showTable" class="wapper-table">
      <div class="row align-items-center">
        <div class="col-6">
          <label class="fs-16 py-4 mb-0">送信先顧客一覧</label>
        </div>
        <div class="col-6">
          <div class="mx-2 mx-md-4 py-4 text-right">
            <Button
              class="btn-pink minw-100"
              text="送信"
              :disabled="total !== fetchedIds.size"
              @click="total === fetchedIds.size && showPopUP()" />
          </div>
        </div>
      </div>
      <div class="wrap-ct">
        <FreemailTable
          ref="FreemailTable"
          :mails="mails"
          :mail-type="mailType" />
        <BasicPaginate
          :key="`paginate_${page}_${size}_${total}`"
          :show-always="true"
          :page="page"
          :size="size"
          :pages="pages"
          :total="total" />
      </div>
    </div>
  </BaseLayout>
  <ModalConfirm
    id="model-confirm-sendmail"
    message="メールを送信します。"
    @no="showClosedMessage"
    @yes="sendEmails" />
  <ModalConfirmFollow
    id="model-error-popup"
    message="入力項目に不足、または不備があります。" />
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";

import {
  getNewMails,
  sendFreeNewMails,
} from "/src/service/new-mail.service.js";
import { getRecommendMailList } from "/src/service/recommend.service.js";
import { sendFreeMails } from "/src/service/recommend.service.js";
import { ORIGINAL_LOCATION } from "/src/frontend/src/constants.js";
const { apiMessage } = require("/src/assets/validation.json");

import Button from "/src/components/utilitys/buttons/Button.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import ModalConfirmFollow from "/src/components/modals/ModalConfirmFollow.vue";
import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import FreemailForm from "/src/components/freemail-setting/FreemailForm.vue";
import FreemailTable from "/src/components/freemail-setting/FreemailTable.vue";
import FreemailFilter from "/src/components/freemail-setting/FreemailFilter.vue";
import BasicPaginate from "/src/components/commons/BasicPaginate.vue";

function keyRename(Obj, oldName, newName) {
  Obj[newName] = Obj[oldName];
  delete Obj[oldName];
}

export default {
  components: {
    BaseLayout,
    FreemailForm,
    FreemailTable,
    FreemailFilter,
    BasicPaginate,
    Button,
    ModalConfirm,
    ModalConfirmFollow,
  },
  data() {
    return {
      mailType: "regular", // "regular" or "new"
      showTable: false,
      mails: [],
      fetchedIds: new Set(),
      size: 50,
      total: 0,
      page: 1,
      pages: 1,
      radioOptions: [
        {
          location_of_original_data: ORIGINAL_LOCATION.DYNAMIC,
          property_category: "新築",
        },
        {
          location_of_original_data: ORIGINAL_LOCATION.OTHER,
          property_category: "他社媒介/新築",
        },
        {
          location_of_original_data: ORIGINAL_LOCATION.SALESFORCE,
          property_category: "中古",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.initialURL();
        this.fetchData();
      },
      immediate: true,
    },
  },
  created() {
    this.initialURL();
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    validateCheckBoxes() {
      const error =
        this.$refs.FreemailTable.checkedIds.length === this.fetchedIds.size;
      this.$refs.FreemailTable.error = error;
      return !error;
    },
    showPopUP() {
      const isValidForm = this.$refs.FreemailForm.formValidate();
      const isValidCheckBoxes = this.validateCheckBoxes();
      if (isValidForm && isValidCheckBoxes) {
        $("#model-confirm-sendmail").modal();
      } else {
        $("#model-error-popup").modal();
      }
    },
    initialURL() {
      if (!["regular", "new"].includes(this.$route.query.mailType))
        this.$router.replace({ query: { mailType: "regular" } });
    },
    fetchData() {
      if (!location.search) return;
      this.page = parseInt(this.$route.query.page) || this.page;
      this.mailType = this.$route.query.mailType || this.mailType;
      if (this.mailType === "regular") this.fetchRegularMails();
      if (this.mailType === "new") this.fetchNewMails();
    },
    fetchRegularMails() {
      const searchParams = {
        is_sent: true,
        page: this.page,
        size: this.size,
        order_by: "-plan_recommend_at",
      };
      this.parseParamsFromURL(searchParams, [
        "property_id",
        "property_name",
        "block_id",
        "block_name",
        "plan_recommend_from_date",
        "plan_recommend_to_date",
        "plan_recommend_time",
        "full_name_kanji",
        "full_name_furigana",
        "mail_recipient",
      ]);

      const assetType = this.$route.query.assetType;
      if (assetType) {
        const selectedRadio = this.radioOptions.find(
          (el) => el.location_of_original_data == assetType
        );
        if (!selectedRadio) {
          this.showTable = false;
          return;
        }
        searchParams.property_category = selectedRadio.property_category;
      }

      getRecommendMailList(searchParams).then((response) => {
        this.assignData(response);
      });
    },
    fetchNewMails() {
      const searchParams = {
        is_sent: true,
        page: this.page,
        size: this.size,
        order_by: "-plan_send_date",
      };
      this.parseParamsFromURL(searchParams, [
        "property_id",
        "plan_recommend_from_date",
        "plan_recommend_to_date",
        "full_name_kanji",
        "full_name_furigana",
        "mail_recipient",
      ]);
      keyRename(searchParams, "plan_recommend_from_date", "plan_send_from");
      keyRename(searchParams, "plan_recommend_to_date", "plan_send_to");
      keyRename(searchParams, "mail_recipient", "email");

      getNewMails(searchParams).then((response) => {
        this.assignData(response);
      });
    },
    assignData(response) {
      this.total = response.data["total"];
      this.pages = response.data["pages"];
      this.mails = response.data["items"];
      this.showTable = response.data["items"].length !== 0;
      if (!this.showTable) this.notifyEmpty();
      else
        this.mails.forEach((el) => {
          const idx =
            this.mailType === "regular" ? el.recommend_mail_id : el.new_mail_id;
          this.fetchedIds.add(idx);

          const isInvalid = !this.$refs.FreemailTable.isValidMail(el);
          const isExisted = this.$refs.FreemailTable.checkedIds.includes(idx);
          if (isInvalid && !isExisted)
            this.$refs.FreemailTable.checkedIds.push(idx);
        });
    },
    parseParamsFromURL(searchParams, keyList) {
      for (const key of keyList) {
        const value = this.$route.query[key];
        if (value) searchParams[key] = value;
      }
    },
    changeMailType(newValue) {
      this.mailType = newValue;
      setTimeout(() => {
        this.resetData();
      }, 100); // Delay for render FreemailTable
      this.mails.splice(0);
      this.showTable = false;
    },
    resetData() {
      this.$refs.FreemailTable.checkedIds.splice(0);
      this.fetchedIds = new Set();
      this.total = 0;
      this.page = 1;
      this.pages = 1;
    },
    async sendEmails() {
      const excludeIds = this.$refs.FreemailTable.checkedIds;
      const sendIds = [...this.fetchedIds].filter(
        (el) => !excludeIds.includes(el)
      );
      const body = {
        subject: this.$refs.FreemailForm.form.subject,
        body: this.$refs.FreemailForm.form.body,
        signature: this.$refs.FreemailForm.form.signature,
        is_include_url: this.$refs.FreemailForm.form.is_include_url,
        mail_ids: sendIds,
      };
      if (this.mailType === "regular") await sendFreeMails(body);
      if (this.mailType === "new") await sendFreeNewMails(body);
      this.showAlert("メールを送信しました。");
    },
    showClosedMessage() {
      this.showAlert(apiMessage.ABORT_PROCESS);
    },
    notifyEmpty() {
      this.$store.commit("confirmapi/setConfirm", {
        status: true,
        mes: apiMessage.NO_SEARCH_RESULT,
      });
    },
  },
};
</script>
