<template>
  <PropertyForm
    :compulsory-list="compulsoryList"
    @submitHandle="submitHandle" />
  <ModalConfirm
    id="submitModal"
    message="物件情報を登録します。"
    @yes="registerProperty"
    @no="abortProcess" />
  <ModalConfirm
    id="existModal"
    message="物件情報の登録をキャンセルします。"
    @yes="navigateBackTLI4001"
    @no="abortProcess" />
</template>
<script>
import $ from "jquery";
import {
  createProperty,
  updateProperty,
  uploadImage,
} from "/src/service/properties.service.js";
import PropertyForm from "/src/components/properties/PropertyForm.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import { ORIGINAL_LOCATION } from "/src/constants";
const { apiMessage } = require("/src/assets/validation.json");
export default {
  components: {
    PropertyForm,
    ModalConfirm,
  },
  data() {
    return {
      compulsoryList: [
        "name",
        "appearance_image_link",
        "guide_map_image_link",
        "location_prefecture",
        "location_district", //所在地-市町村
        "location_detail",
        "nearest_station_railway_1", //最寄駅沿線①
        "nearest_station_1 ", //最寄駅①
        "walking_to_nearest_station_1", //最寄駅①まで徒歩
        "structure_and_scale",
        "number_of_houses",
        "number_of_floors",
        "site_area",
        "use_district",
        "building_confirmation_number",
        "acquisition_date_of_building_confirmation",
        "completion_date", //竣工日
        "right_type",
        "seller_owner_1",
        "construction_company",
        "management_company",
        "management_type",
        "manager_working_style",
        "parking_lot_count",
        "parking_lot_status",
        "trade_type",
      ],
    };
  },
  created() {
    this.$store.dispatch("properties/setRouteName", ORIGINAL_LOCATION.OTHER);
  },
  methods: {
    registerProperty() {
      createProperty(this.property).then((res) => {
        this.property = { ...this.property, ...res.data };
        this.submitForm();
      });
    },
    submitForm() {
      if (this.appearance_image || this.guide_map_image) {
        let formData = new FormData();
        if (this.appearance_image) {
          formData.append(
            "names",
            `${
              this.property.property_id
            }_other_apperance.${this.appearance_image.name.split(".").pop()}`
          );
          formData.append("files", this.appearance_image);
        }
        if (this.guide_map_image) {
          formData.append(
            "names",
            `${
              this.property.property_id
            }_other_guide_map.${this.guide_map_image.name.split(".").pop()}`
          );
          formData.append("files", this.guide_map_image);
        }
        uploadImage(formData).then((res) => {
          for (let item of res.data) {
            if (item.name.includes("apperance")) {
              this.property.appearance_image_link = item.name;
            }
            if (item.name.includes("guide_map")) {
              this.property.guide_map_image_link = item.name;
            }
          }
          this.uploadPropertyForm(this.property.property_id, this.property);
        });
      } else {
        this.uploadPropertyForm(this.property.property_id, this.property);
      }
    },
    uploadPropertyForm(property_id, data) {
      updateProperty(property_id, data).then((_) => {
        this.$store.dispatch(
          "alertMessage/showAlert",
          "物件情報を登録しました。"
        );
        this.$router.push({
          name: "property-detail",
          params: { id: property_id },
          hash: "#nav-2",
        });
      });
    },
    submitHandle(event) {
      this.property = event.property;
      this.property["location_of_original_data"] = ORIGINAL_LOCATION.OTHER;
      this.property["company_classification"] = "他社";
      this.appearance_image = event.appearance_image;
      this.guide_map_image = event.guide_map_image;
      $("#submitModal").modal("show");
    },
    abortProcess() {
      this.$store.dispatch("alertMessage/showAlert", apiMessage.ABORT_PROCESS);
    },
    navigateBackTLI4001() {
      this.$router.push({
        name: "properties-other",
      });
      this.$store.dispatch(
        "alertMessage/showAlert",
        "物件情報の登録をキャンセルしました。"
      );
    },
  },
};
</script>
