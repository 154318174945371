<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="my-4">
    <div class="my-2">
      <img
        src="@/assets/img/property-feat/feat-title.png"
        alt="img"
        class="img-fluid" />
    </div>
    <div class="my-2 fs-16">
      所在地　東京都中央区銀座1-8-19　キラリトギンザ8階
    </div>
    <div class="my-2 fs-16">
      <span class="pr-3">営業時間</span>
      <span>10:00~18:00</span>
    </div>
    <div class="my-2 fs-16">
      <span class="pr-3">定休日</span>
      <span>毎週火曜日・水曜日・木曜日</span>
    </div>
    <div class="my-2">
      <a href="tel:0120006109" class="btn feat-btn-phone my-3">
        <img
          src="@/assets/img/property-feat/feat-phone.png"
          alt="img"
          class="img-fluid mr-3" />
        0120-006-109
        <span>（無料）</span>
      </a>
      <span
        v-if="enable"
        style="pointer-events: none"
        class="btn feat-btn-pink-2 my-3">
        <img
          src="@/assets/img/property-feat/feat-date.png"
          alt="img"
          class="img-fluid mr-3" />
        見学予約
      </span>
      <span style="pointer-events: none" class="btn feat-btn-pink my-3">
        <img
          src="@/assets/img/property-feat/feat-mess.png"
          alt="img"
          class="img-fluid mr-3" />
        その他お問合せ
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null },
    enable: { type: Boolean, default: true },
  },
};
</script>
