<template>
  <ContractFormBase
    title="成約情報変更"
    :init-closed-property-id="closedPropertyId"
    :modal-msg="modalMsg"
    @confirm-submit="handleSubmit"
    @confirm-cancel="handleCancel" />
</template>

<script>
import { updateClosedProperty } from "/src/service/closed-properties.service.js";
import { backToCustomerAction } from "/src/helpers/route-navigate.js";

import ContractFormBase from "/src/components/closed-properties/ContractFormBase.vue";

export default {
  components: {
    ContractFormBase,
  },
  data() {
    return {
      modalMsg: {
        submit: "成約情報を変更します。",
        cancel: "成約情報の変更をキャンセルします。",
      },
    };
  },
  computed: {
    closedPropertyId() {
      const idStr = this.$route.params.closed_property_id;
      if (idStr) return parseInt(idStr);
      return null;
    },
  },
  methods: {
    async handleSubmit(body) {
      await updateClosedProperty(this.closedPropertyId, body);
      backToCustomerAction(this, body.customer_id, "submit");
    },
    handleCancel(body) {
      backToCustomerAction(this, body.customer_id, "cancel");
    },
  },
};
</script>
