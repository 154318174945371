<template>
  <div>
    <TabTableLayout
      v-model="inquiryHistory"
      table-label="問合せ・来場予約履歴"
      :fetch-func="fetchData()">
      <thead>
        <tr class="text-nowrap text-center">
          <th>問合せ日</th>
          <th>問合せ区分</th>
          <th>問合せ先物件名</th>
          <th>来場<br />第一希望日</th>
          <th>来場<br />第二希望日</th>
          <th>来場<br />第三希望日</th>
          <th>ステータス</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in inquiryHistory.items"
          :key="`tr_reservation_${index}`">
          <td class="text-nowrap">{{ formatDate(item.created_at) }}</td>
          <td class="text-nowrap">
            <router-link
              :to="{
                name: 'inquiry-reservation-id',
                params: { inquiry_id: item.inquiry_id },
              }"
              class="txt-underline">
              {{ func.displayValue(item.inquiry_classification_txt) }}
            </router-link>
          </td>
          <td>{{ func.displayValue(item.property_name) }}</td>
          <td class="text-nowrap">{{ formatDate(item.preferred_date_1) }}</td>
          <td class="text-nowrap">{{ formatDate(item.preferred_date_2) }}</td>
          <td class="text-nowrap">{{ formatDate(item.preferred_date_3) }}</td>
          <td class="text-nowrap">
            {{ item.follow_status ? "対応済み" : "未対応" }}
          </td>
        </tr>
      </tbody>
    </TabTableLayout>
  </div>
</template>

<script>
import { getInquiryHistories } from "/src/service/inquiry.service.js";

import TabTableLayout from "./TabTableLayout.vue";

export default {
  components: {
    TabTableLayout,
  },
  data() {
    return {
      inquiryHistory: {},
    };
  },
  methods: {
    formatDate(dateStr) {
      return this.func.formatDate(dateStr, { dateStyle: "long" });
    },
    fetchData() {
      return getInquiryHistories;
    },
  },
};
</script>
