<template>
  <div class="cus-filter">
    <div class="card-body">
      <div class="row align-items-center">
        <div v-if="other_company" class="col-12">
          <div class="form-group">
            <label class="position-relative text-nowrap mr-3">物件区分</label>
            <div
              v-for="(item, index) in BLOCK_CLASSIFICATION"
              :key="index"
              class="d-inline-block my-2">
              <div class="custom-checkbox mr-3">
                <input
                  :id="index"
                  class="form-check-input mx-auto"
                  type="checkbox"
                  :value="item"
                  :checked="filter.block_classification.includes(item)"
                  @change="handleCheck(item, $event.target.checked)" />
                <label :for="index">{{
                  item == "完成後未入居" ? "建物完成後未入居(築１年超)" : item
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="filter.name"
                type="text"
                class="form-control"
                placeholder="物件名"
                maxlength="99" />
              <div class="input-group-append bg-white">
                <img
                  src="@/assets/img/icons/ic-homew.png"
                  alt="icon"
                  width="24"
                  height="24" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="filter.location"
                type="text"
                class="form-control"
                placeholder="所在地"
                maxlength="99" />
              <div class="input-group-append bg-white">
                <img
                  src="@/assets/img/icons/ic-map.png"
                  alt="icon"
                  width="14"
                  height="20" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="filter.nearest_station_railway"
                type="text"
                class="form-control"
                placeholder="沿線"
                maxlength="99" />
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="filter.nearest_station"
                type="text"
                class="form-control"
                placeholder="最寄駅"
                maxlength="99" />
              <div class="input-group-append bg-white">
                <img
                  src="@/assets/img/icons/ic-bus.png"
                  alt="icon"
                  width="16"
                  height="19" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">価格</label>
              <input
                v-model="filter.block_price_min"
                type="number"
                name=""
                min="0"
                class="form-control"
                placeholder="最低"
                @input="
                  filter.block_price_min = func.onlyNumberInput(
                    $event,
                    filter.block_layout_type_min
                  )
                " />
              <span class="ml-1 text-nowrap">万円</span>
              <span class="mx-2">～</span>
              <input
                v-model="filter.block_price_max"
                type="number"
                name=""
                min="0"
                class="form-control"
                placeholder="最高"
                @input="
                  filter.block_price_max = func.onlyNumberInput(
                    $event,
                    filter.block_price_max
                  )
                " />
              <span class="ml-1 text-nowrap">万円</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">面積</label>
              <input
                v-model="filter.block_area_min"
                type="number"
                name=""
                min="0"
                class="form-control"
                placeholder="最低"
                @input="
                  filter.block_area_min = func.onlyNumberInput(
                    $event,
                    filter.block_area_min,
                    true
                  )
                " />
              <span class="ml-1 text-nowrap">㎡</span>
              <span class="mx-2">～</span>
              <input
                v-model="filter.block_area_max"
                type="number"
                min="0"
                name=""
                class="form-control"
                placeholder="最高"
                @input="
                  filter.block_area_max = func.onlyNumberInput(
                    $event,
                    filter.block_area_max,
                    true
                  )
                " />
              <span class="ml-1 text-nowrap">㎡</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">間取り</label>
              <div class="custom-select2 w-100">
                <FieldSelect2
                  v-model="filter.block_layout_type_min"
                  :options="BLOCK_LAYOUT_TYPE"
                  :placeholder="'最低'"
                  :keyid="'value'"
                  :keytext="'label'" />
              </div>
              <span class="mx-2">～</span>
              <div class="custom-select2 w-100">
                <FieldSelect2
                  v-model="filter.block_layout_type_max"
                  :options="BLOCK_LAYOUT_TYPE"
                  :placeholder="'最高'"
                  :keyid="'value'"
                  :keytext="'label'" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-nowrap my-0 mr-3">徒歩</label>
              <input
                v-model="filter.walking_to_nearest_station"
                type="number"
                name=""
                min="0"
                class="form-control"
                placeholder="00"
                @input="
                  filter.walking_to_nearest_station = func.onlyNumberInput(
                    $event,
                    filter.walking_to_nearest_station
                  )
                " />
              <span class="ml-1 text-nowrap">分以内</span>
            </div>
          </div>
        </div>
        <div class="col-12 my-3">
          <div class="text-center">
            <button
              class="btn btn-cus btn-blue-light px-3 m-2 w-105"
              @click="navigateToSearch">
              絞り込み
            </button>
            <button
              class="btn btn-cus btn-grey px-3 m-2"
              :disabled="isFormValid"
              @click="clearFilter">
              検索条件クリア
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getParamsUrl } from "/src/service/utility.service.js";
import { BLOCK_LAYOUT_TYPE, BLOCK_CLASSIFICATION } from "/src/constants";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2";
export default {
  components: {
    FieldSelect2,
  },
  data() {
    let params = getParamsUrl();
    return {
      filter: {
        name: params.name || "",
        location: params.location || "",
        nearest_station_railway: params.nearest_station_railway || "",
        nearest_station: params.nearest_station || "",
        block_price_min: params.block_price_min || "",
        block_price_max: params.block_price_max || "",
        block_area_min: params.block_area_min || "",
        block_area_max: params.block_area_max || "",
        block_layout_type_min: params.block_layout_type_min || "",
        block_layout_type_max: params.block_layout_type_max || "",
        walking_to_nearest_station: params.walking_to_nearest_station || "",
        block_classification: params.block_classification
          ? this.validateBlockClassification(params.block_classification)
          : [],
      },
      other_company:
        this.$router.currentRoute._value.name == "properties-other"
          ? true
          : false,
      BLOCK_CLASSIFICATION: BLOCK_CLASSIFICATION.slice(1).map(
        (item) => item.value
      ),
      BLOCK_LAYOUT_TYPE,
    };
  },
  computed: {
    isFormValid() {
      return !(
        this.filter.name !== "" ||
        this.filter.location !== "" ||
        this.filter.nearest_station_railway !== "" ||
        this.filter.nearest_station !== "" ||
        this.filter.block_price_min !== "" ||
        this.filter.block_price_max !== "" ||
        this.filter.block_area_min !== "" ||
        this.filter.block_area_max !== "" ||
        this.filter.block_layout_type_min !== "" ||
        this.filter.block_layout_type_max !== "" ||
        this.filter.walking_to_nearest_station !== "" ||
        this.filter.block_classification.length !== 0
      );
    },
  },
  mounted() {
    document.addEventListener("keydown", this.enterToSearch);
  },
  unmounted() {
    document.removeEventListener("keydown", this.enterToSearch);
  },
  methods: {
    enterToSearch(e) {
      if (e.key == "Enter") {
        this.navigateToSearch();
      }
    },
    validateBlockClassification(value) {
      let valueArray = value.split(",");
      let blockClassificationArray = BLOCK_CLASSIFICATION.slice(1).map(
        (item) => item.value
      );
      let result = valueArray.filter((value) =>
        blockClassificationArray.includes(value)
      );
      return result;
    },
    clearFilter() {
      this.filter.name = "";
      this.filter.location = "";
      this.filter.nearest_station_railway = "";
      this.filter.nearest_station = "";
      this.filter.block_price_min = "";
      this.filter.block_price_max = "";
      this.filter.block_area_min = "";
      this.filter.block_area_max = "";
      this.filter.block_layout_type_min = "";
      this.filter.block_layout_type_max = "";
      this.filter.walking_to_nearest_station = "";
      this.filter.block_classification = [];
    },
    navigateToSearch() {
      if (
        parseInt(this.filter.block_layout_type_min) >
        parseInt(this.filter.block_layout_type_max)
      ) {
        this.filter.block_layout_type_max = this.filter.block_layout_type_min;
      }
      let query = this.createQueryStringFromData() + "&page=1";
      let oldQuery = decodeURI(location.search);
      if (
        oldQuery.slice(1) == query ||
        (query == "&page=1" && oldQuery.slice(1) == "page=1")
      ) {
        this.$emit("reload");
      } else {
        if (query == "&page=1") {
          query = "page=1";
        }
        this.$emit("order-reset");
        this.$router.push(`${location.pathname}?${query}`);
      }
    },
    createQueryStringFromData() {
      // Create queryString

      this.filter.block_classification =
        this.filter.block_classification.join();

      let queryString = Object.keys(this.$data.filter)
        .filter((key) => this.$data.filter[key])
        .map((key) => `${key}=${this.$data.filter[key]}`)
        .join("&");

      this.filter.block_classification =
        this.filter.block_classification.split(",");
      if (
        this.filter.block_classification.length == 1 &&
        this.filter.block_classification[0] == ""
      ) {
        this.filter.block_classification = [];
      }
      return queryString;
    },
    handleCheck(value, isChecked) {
      if (isChecked) this.filter.block_classification.push(value);
      else
        this.filter.block_classification =
          this.filter.block_classification.filter((item) => item !== value);
    },
  },
};
</script>
