<template>
  <BaseLayout>
    <h3 class="card-title-cus">顧客検索</h3>
    <CustomerFilter @reload-data="fetchData" />
    <div v-show="showTable" class="wapper-table">
      <label class="fs-16">顧客一覧</label>
      <div class="wrap-ct">
        <CustomerTable :customers="customers" />
        <BasicPaginate
          :key="`paginate_${page}_${size}_${total}`"
          :show-always="true"
          :page="page"
          :size="size"
          :pages="pages"
          :total="total" />
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { getParamsUrl } from "/src/service/utility.service.js";
import { getCustomerList } from "/src/service/customers.service.js";
const { apiMessage } = require("/src/assets/validation.json");

import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import CustomerFilter from "/src/components/customers/search/CustomerFilter.vue";
import CustomerTable from "/src/components/customers/search/CustomerTable.vue";
import BasicPaginate from "/src/components/commons/BasicPaginate.vue";

export default {
  components: {
    BaseLayout,
    CustomerFilter,
    CustomerTable,
    BasicPaginate,
  },
  data() {
    return {
      showTable: false,
      customers: [],
      size: 50,
      total: 0,
      page: 1,
      pages: 1,
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.initialURL();
        this.fetchData();
      },
      immediate: true,
    },
  },
  created() {
    this.initialURL();
  },
  methods: {
    initialURL() {
      if (location.search === "")
        this.$router.replace(`${location.pathname}?page=1`);
    },
    fetchData() {
      if (!location.search) return;

      const params = getParamsUrl();
      this.page = parseInt(params["page"]) || this.page;

      const searchParams = { ...params, page: this.page, size: this.size };
      getCustomerList(searchParams).then((response) => {
        this.total = response.data["total"];
        this.pages = response.data["pages"];
        this.customers = response.data["items"];
        this.showTable = response.data["items"].length !== 0;
        if (!this.showTable) this.notifyEmpty();
      });
    },
    notifyEmpty() {
      this.$store.commit("confirmapi/setConfirm", {
        status: true,
        mes: apiMessage.NO_SEARCH_RESULT,
      });
    },
  },
};
</script>
