<template>
  <div class="wrapper single-page">
    <div class="content-wrapper">
      <section>
        <h3 class="card-page-title mt-4 mb-0 mx-0 mx-md-2">
          <img
            src="@/assets/img/property-feat/feat-foot-title.png"
            alt="title"
            class="img-fluid" />
        </h3>
        <div class="container-fluid">
          <div class="card mb-0">
            <div class="card-body minh-520 p-0">
              <div class="wrap-ct border-0">
                <HouseHeader :data="data" :text-button="textButton" />
                <HouseImage :data="data" />
                <HouseIcon :data="data" />
                <HouseTable :data="data" :schedule="schedule" />
                <HouseMap :data="data" />
                <HouseBottom :data="data" :enable="data.isShowButton" />
              </div>
              <HouseFooter />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import HouseHeader from "/src/components/recommend-properties/commons/HouseHeader.vue";
import HouseImage from "/src/components/recommend-properties/commons/HouseImage.vue";
import HouseIcon from "/src/components/recommend-properties/commons/HouseIcon.vue";
import HouseTable from "/src/components/recommend-properties/commons/HouseTable.vue";
import HouseMap from "/src/frontend/src/components/recommend-properties/commons/HouseMap.vue";
import HouseBottom from "/src/components/recommend-properties/commons/HouseBottom.vue";
import HouseFooter from "/src/components/recommend-properties/commons/HouseFooter.vue";

export default {
  components: {
    HouseHeader,
    HouseImage,
    HouseIcon,
    HouseTable,
    HouseMap,
    HouseBottom,
    HouseFooter,
  },
  props: {
    data: { type: Object, default: null },
    textButton: { type: String, default: null },
    schedule: { type: Object, default: null },
  },
};
</script>
