<template>
  <div class="contruc-img">
    <img
      v-if="imgSrc"
      :id="imageId"
      :src="imgSrc"
      alt="img"
      class="img-fluid" />
    <img
      v-else
      :id="imageId"
      src="@/assets/img/img-none.png"
      alt="img"
      class="img-fluid" />
    <input
      :id="uploadId"
      type="file"
      accept=".jpg,.jpeg,.png,.gif"
      class="d-none"
      :data-id="id"
      @change="changeFile" />
    <label
      class="contruc-img-text"
      :for="uploadId"
      :data-id="id"
      @drop="dropFile"
      @dragover="allowDrop">
      <img
        src="@/assets/img/icons/ic-plus-circle.png"
        alt="icon"
        class="img-fluid" />
      {{ textInImage }}
    </label>
  </div>
</template>
<script>
import { IMAGE_PROPERTIES } from "/src/constants";
export default {
  name: "ImageFile",
  props: {
    // random id
    id: {
      type: String,
      default: Math.random().toString(36).substring(2, 15),
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    textInImage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgSrc: this.modelValue ? IMAGE_PROPERTIES + this.modelValue : "",
    };
  },
  computed: {
    imageId() {
      return `img-upload-${this.id}`;
    },
    uploadId() {
      return `upload-file-${this.id}`;
    },
  },
  methods: {
    changeFile(ev) {
      let file = ev.target.files[0];
      if (this.checkImg(file.type, file.size)) {
        this.loadFile(file, ev.target.getAttribute("data-id"));
        this.$emit("focusout", file);
      }
    },
    dropFile(ev) {
      ev.preventDefault();
      let file = ev.dataTransfer.files[0];
      if (this.checkImg(file.type, file.size)) {
        this.loadFile(file, ev.target.getAttribute("data-id"));
        this.$emit("focusout", file);
      }
    },
    allowDrop(event) {
      event.preventDefault();
    },
    loadFile(file, id) {
      var output = document.getElementById("img-upload-" + id);
      output.src = URL.createObjectURL(file);
      output.onload = () => {
        URL.revokeObjectURL(output.src);
      };
    },
    checkImg(type, size) {
      if (type.split("/")[0] === "image" && size < 5000000) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
