<template>
  <div class="row align-items-center" :class="{ 'mb-2': error }">
    <div class="col-md-12 col-xl-6">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 col-xl-6 my-2">
          物件名
          <span class="badge badge-red">必 須</span>
        </div>
        <div class="col-12 col-md-5 col-xl-6 my-2">
          <slot name="property"></slot>
        </div>
      </div>
      <div v-show="error" class="row error-container">
        <div class="col-12 col-md-8 pad-error-50">
          <p class="error">{{ error }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-xl-6">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 col-xl-4 my-2 text-xl-right">区画名</div>
        <div class="col-12 col-md-5 col-xl-6 my-2">
          <slot name="block"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: { type: String, default: "" },
  },
};
</script>

<style scoped>
@import "@/assets/css/action-form-extra.css";
</style>
