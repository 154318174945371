<template>
  <SalesFollowHistoryBase
    v-if="customerId"
    title="フォロー履歴変更"
    :fetch-delay="nextFetchTime"
    :customer-id="customerId"
    :customer-name="customerName"
    :init-form-data="initFormData"
    :dynamic-properties="availableProperties['dynamic']"
    :other-properties="availableProperties['other']"
    :modal-msg="modalMsg"
    @confirm-submit="handleSubmit"
    @confirm-cancel="handleCancel" />
</template>

<script>
import { API_DELAY } from "/src/frontend/src/constants.js";
import {
  navigateBack,
  createRequestBody,
  fetchAvailableProperties,
  fetchSalesFollowHistory,
} from "./utils.js";
import { updateSaleFollowHistory } from "/src/service/sales-follow-histories.service.js";

import SalesFollowHistoryBase from "/src/components/sales-follow-histories/SalesFollowHistoryBase.vue";

export default {
  components: {
    SalesFollowHistoryBase,
  },
  data() {
    return {
      initFormData: {
        user_id: null,
        follow_date: "",
        contact_method: "",
        contact_result: "",
        property_1: {},
        property_2: {},
        property_3: {},
        property_4: {},
        property_5: {},
        comment: "",
      },
      availableProperties: {
        dynamic: [],
        other: [],
      },
      modalMsg: {
        submit: "フォロー履歴情報を変更します。",
        cancel: "フォロー履歴情報の変更をキャンセルします。",
      },
      customerId: null,
      customerName: null,
    };
  },
  computed: {
    historyId() {
      const idStr = this.$route.params.history_id;
      if (idStr) return parseInt(idStr);
      return null;
    },
    nextFetchTime() {
      // the time to call next API
      return 4 * API_DELAY;
    },
  },
  created() {
    setTimeout(() => {
      fetchSalesFollowHistory(this);
    }, API_DELAY);

    fetchAvailableProperties(this, 2 * API_DELAY);
  },
  methods: {
    async handleSubmit(body) {
      // Call API update sales follow history
      const requestBody = createRequestBody(this.customerId, body);
      await updateSaleFollowHistory(this.historyId, requestBody);

      // Back to TLI07003
      navigateBack(this, "submit");
    },
    handleCancel() {
      // Back to TLI07003
      navigateBack(this, "cancel");
    },
  },
};
</script>
