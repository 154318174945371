<template>
  <BaseLayout>
    <FormLayout :title="title">
      <CustomerName
        :customer-name="customerName"
        :customer-id="customerId"
        :fetch-delay="API_DELAY" />
      <SelectTime
        v-model="dataParam.closeDate"
        :error="errorMsg.closeDate"
        label="成約日"
        @update:modelValue="checkAndHideError('closeDate', 'closeDate')" />
      <SelectTime
        v-model="dataParam.deliverDate"
        :error="errorMsg.deliverDate"
        label="引渡予定日"
        @update:modelValue="checkAndHideError('deliverDate', 'deliverDate')" />
      <SelectUser
        v-model="dataParam.userId"
        :error="errorMsg.userId"
        :fetch-delay="2 * API_DELAY"
        @update:modelValue="checkAndHideError('userId', 'userId')" />
      <RadioAssetType
        v-model="dataParam.typeAsset"
        @update:modelValue="
          errorMsg.property = '';
          errorMsg.block = '';
        " />
      <PropertyAndBlockLayout
        :property-error="errorMsg.property"
        :block-error="errorMsg.block">
        <template #property>
          <div v-show="isSalesForce">
            <input
              v-model.trim="dataParam.propertyName"
              type="text"
              maxlength="99"
              class="form-control"
              :class="{ 'field-error': errorMsg.property }"
              placeholder="物件名を入力"
              @focusout="jTextNormalize('propertyName')"
              @input="checkAndHideError('propertyName', 'property')" />
          </div>
          <SelectProperty
            v-show="!isSalesForce"
            v-model="dataParam.propertyId"
            :class="{ 'field-error': errorMsg.property }"
            :fetch-delay="3 * API_DELAY"
            @update:modelValue="checkAndHideError('propertyId', 'property')" />
        </template>
        <template #block>
          <div v-show="isSalesForce">
            <input
              v-model.trim="dataParam.blockName"
              type="text"
              maxlength="99"
              class="form-control"
              :class="{ 'field-error': errorMsg.block }"
              placeholder="区画名を入力"
              @focusout="jTextNormalize('blockName')"
              @input="checkAndHideError('blockName', 'block')" />
          </div>
          <SelectBlock
            v-show="!isSalesForce"
            v-model="dataParam.blockId"
            :class="{ 'field-error': errorMsg.block }"
            :property-id="dataParam.propertyId"
            @update:modelValue="checkAndHideError('blockId', 'block')" />
        </template>
      </PropertyAndBlockLayout>
      <div class="row">
        <div class="col-12">
          <div class="my-4 text-center">
            <FunctionalButton
              v-if="$route.name === 'contract-registry'"
              class="btn-blue-light m-2 w-150"
              type="button"
              text="登録"
              @click="handleBtnSubmit('contract-modal')" />
            <FunctionalButton
              v-if="$route.name === 'contract-update'"
              class="btn-blue-light m-2 w-150"
              type="button"
              text="変更"
              :disabled="isLoading || !isChanged"
              @click="isChanged && handleBtnSubmit('contract-modal')" />
            <FunctionalButton
              class="btn-grey m-2 w-150"
              type="button"
              data-toggle="modal"
              data-target="#contract-modal"
              text="キャンセル"
              @click="handleBtnCancel()" />
          </div>
        </div>
      </div>
    </FormLayout>
    <ModalConfirm
      id="contract-modal"
      :message="modalMsg[clickedButton]"
      @no="showClosedMessage"
      @yes="handleConfirm" />
  </BaseLayout>
</template>

<script>
import { API_DELAY } from "/src/frontend/src/constants.js";
import { getClosedPropertyDetail } from "/src/service/closed-properties.service.js";
import customerActionHistoryMixin from "/src/components/customers/action/history-mixin.js";

import PropertyAndBlockLayout from "/src/components/closed-properties/PropertyAndBlockLayout.vue";

export default {
  components: {
    PropertyAndBlockLayout,
  },
  mixins: [customerActionHistoryMixin],
  props: {
    initClosedPropertyId: { type: Number, default: null },
  },
  data() {
    const mixinDataParam = this.$options.mixins[0].data().dataParam;
    return {
      dataParam: {
        userId: mixinDataParam.userId,
        typeAsset: mixinDataParam.typeAsset,
        closeDate: "",
        deliverDate: "",
        propertyId: mixinDataParam.propertyId,
        blockId: mixinDataParam.blockId,
        propertyName: mixinDataParam.propertyName,
        blockName: mixinDataParam.blockName,
      },
      errorMsg: {
        closeDate: "",
        deliverDate: "",
        userId: "",
        property: "",
        block: "",
      },
      customerId: this.initCustomerId,
      API_DELAY,
    };
  },
  computed: {
    requestBody() {
      const propertyInfo = this.isSalesForce
        ? {
            property_name: this.dataParam.propertyName,
            block_name: this.dataParam.blockName,
          }
        : {
            property_id: this.dataParam.propertyId,
            block_id: this.dataParam.blockId,
          };
      const body = {
        customer_id: this.customerId,
        user_id: this.dataParam.userId,
        property_table_type: this.dataParam.typeAsset,
        closing_date: this.dataParam.closeDate.replaceAll("/", "-"),
        delivery_date: this.dataParam.deliverDate.replaceAll("/", "-"),
        ...propertyInfo,
      };
      return body;
    },
  },
  created() {
    if (this.initClosedPropertyId) this.fetchContractDetail();
  },
  methods: {
    async fetchContractDetail() {
      const response = await getClosedPropertyDetail(this.initClosedPropertyId);
      const data = response.data;
      const { closing_date, delivery_date } = data;
      this.dataParam.closeDate = closing_date.replaceAll("-", "/");
      this.dataParam.deliverDate = delivery_date.replaceAll("-", "/");
      this.assignCommon(data);
      if (!this.canEditItem(data)) this.$router.replace({ name: "error404" });
    },
    isValidForm() {
      let isValid = true;
      for (let key of ["closeDate", "deliverDate", "userId"]) {
        if (!this.validateField(key, key)) isValid = false;
      }
      const isValidProperty = this.isSalesForce
        ? this.validateField("propertyName", "property")
        : this.validateField("propertyId", "property");
      const isValidBlock = this.isSalesForce
        ? this.validateField("blockName", "block")
        : this.validateField("blockId", "block");
      return isValid && isValidProperty && isValidBlock;
    },
  },
};
</script>
