<template>
  <div class="row mb-4">
    <div class="col-12">
      <h3 class="card-title-cus">{{ title }}</h3>
    </div>
  </div>
  <div class="cus-filter border-0">
    <div class="wrap-ct">
      <div class="my-4 mx-3 mx-md-5">
        <div class="custom-form col-xl-10">
          <form>
            <slot></slot>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
  },
};
</script>
