<template>
  <div class="wrapper">
    <SideBarTop />
    <SideBarLeft />
    <div class="content-wrapper">
      <section class="content py-3">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-md-12">
                  <h3 class="card-title-cus">問合せ・来場予約一覧</h3>
                </div>
              </div>
              <FilterInquiry :key="$route.query" @reload="fetchData" />
              <div v-if="total !== 0" class="wrap-ct">
                <TableInquiry :items="items" @sort="handlerClick" />
                <PaginateInterface
                  :key="`paginate_${page}_${size}_${total}`"
                  :initial-page="page"
                  :pages="pages"
                  :size="size" />
                <PaginateInfo :page="page" :size="size" :total="total" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <ModalLayout :id="'modalFollow'">
    <div class="fs-16 my-4">条件に該当する検索結果がありません。</div>
    <div class="text-center">
      <Button
        class="btn btn-cus btn-blue-light mx-2 w-125 fs-14"
        text="閉じる "
        data-dismiss="modal" />
    </div>
  </ModalLayout>
</template>
<script>
import $ from "jquery";
import Button from "/src/components/utilitys/buttons/Button.vue";
import ModalLayout from "/src/components/layouts/ModalLayout.vue";
import PaginateInterface from "/src/components/commons/PaginateInterface.vue";
import PaginateInfo from "/src/components/commons/PaginateInfo.vue";
import { getInquiryHistories } from "/src/service/inquiry.service.js";
import { getParamsUrl } from "/src/service/utility.service";
import SideBarLeft from "/src/components/layouts/SidebarLeft.vue";
import SideBarTop from "/src/components/layouts/SidebarTop.vue";
import FilterInquiry from "/src/components/inquiry/FilterInquiry.vue";
import TableInquiry from "/src/components/inquiry/TableInquiry.vue";
export default {
  name: "InquiryReservation",
  components: {
    Button,
    ModalLayout,
    SideBarLeft,
    SideBarTop,
    FilterInquiry,
    TableInquiry,
    PaginateInfo,
    PaginateInterface,
  },
  data() {
    return {
      items: [],
      page: 1,
      size: 50,
      pages: 0,
      total: 0,
      desc: false,
      order_by: "follow_status,-created_at",
    };
  },
  watch: {
    "$route.query": {
      handler: function () {
        if (location.search != "") {
          let params = getParamsUrl();
          this.page = params["page"] ? parseInt(params["page"]) : this.page;
          params = {
            ...params,
            page: this.page,
            desc: this.desc,
            order_by: this.order_by,
          };
          this.fetchData(params);
        }
      },
      immediate: true,
    },
  },
  updated() {
    this.fetchInitialData();
  },
  created() {
    this.fetchInitialData();
  },
  methods: {
    showPopUP() {
      $("#modalFollow").modal();
    },
    fetchInitialData() {
      if (location.search == "") {
        this.$router.push(`${location.pathname}?page=1`);
      }
    },
    fetchData(params = undefined) {
      if (params == undefined) {
        params = getParamsUrl();
        this.page = params["page"] ? parseInt(params["page"]) : this.page;
        params = {
          ...params,
          page: this.page,
          desc: this.desc,
          order_by: this.order_by,
        };
      }
      getInquiryHistories(params).then((res) => {
        this.pages = res.data["pages"];
        this.items = res.data["items"];
        this.total = res.data["total"];
        if (res.data["items"].length == 0) this.showPopUP();
      });
    },
    handlerClick(isSort) {
      this.desc = isSort;
      let params = getParamsUrl();
      params = {
        ...params,
        page: this.page,
        desc: this.desc,
        order_by: this.order_by,
      };
      this.fetchData(params);
    },
  },
};
</script>
