<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-3 my-2">
      <span class="form-title bg-blue-light">{{ label }}</span>
      <span v-if="compulsory" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-9 my-2">
      <div class="d-flex align-items-center">
        <input
          v-model="dateObject.year"
          type="number"
          name=""
          min="1"
          class="form-control w-84"
          :class="{
            inputError: dateErrorObject.year || dateErrorObject.required,
          }"
          placeholder="YYYY"
          @keydown="preventKeyCode"
          @focusout="
            removeEmptyDateErrorMessage();
            checkDayExist();
            validateMax(
              dateObject.year,
              1,
              9999,
              '4桁の半角数字のみ入力可能です。',
              'year',
              dateErrorObject
            );
            emitDateString();
          " />
        <span class="mx-2 text-nowrap">年</span>
        <input
          v-model="dateObject.month"
          type="number"
          min="1"
          class="form-control w-84 ml-3"
          :class="{
            inputError: dateErrorObject.month || dateErrorObject.required,
          }"
          placeholder="MM"
          @keydown="preventKeyCode"
          @focusout="
            removeEmptyDateErrorMessage();
            checkDayExist();
            validateMax(
              dateObject.month,
              1,
              12,
              '半角数字（1-12）のみ入力可能です。',
              'month',
              dateErrorObject
            );
            emitDateString();
          " />
        <span class="mx-2 text-nowrap">月</span>
        <input
          v-model="dateObject.day"
          type="number"
          min="1"
          class="form-control w-84 ml-3"
          :class="{
            inputError: dateErrorObject.day || dateErrorObject.required,
          }"
          placeholder="DD"
          @keydown="preventKeyCode"
          @focusout="
            removeEmptyDateErrorMessage();
            checkDayExist();
            validateMax(
              dateObject.day,
              1,
              31,
              '半角数字（1-31）のみ入力可能です。',
              'day',
              dateErrorObject
            );
            emitDateString();
          " />
        <span class="mx-2 text-nowrap">日</span>
      </div>
      <p class="error">
        {{ showDateInvalidError }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    compulsory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateObject: {
        year: "",
        month: "",
        day: "",
      },
      dateErrorObject: {
        required: "",
        year: "",
        month: "",
        day: "",
      },
    };
  },
  computed: {
    showDateInvalidError() {
      if (this.dateErrorObject.required != "") {
        return this.dateErrorObject.required;
      } else if (this.dateErrorObject.year != "") {
        return this.dateErrorObject.year;
      } else if (this.dateErrorObject.month != "") {
        return this.dateErrorObject.month;
      } else if (this.dateErrorObject.day != "") {
        return this.dateErrorObject.day;
      }
      return "";
    },
  },
  created() {
    this.convertTimeStringToObject();
  },
  methods: {
    emitError() {
      if (this.dateErrorObject.required != "") {
        return this.dateErrorObject.required;
      } else if (this.dateErrorObject.year != "") {
        return this.dateErrorObject.year;
      } else if (this.dateErrorObject.month != "") {
        return this.dateErrorObject.month;
      } else if (this.dateErrorObject.day != "") {
        return this.dateErrorObject.day;
      }
      return "";
    },
    addZero(number, digit) {
      return number.toString().padStart(digit, "0");
    },
    emitDateString() {
      if (this.showDateInvalidError == "") {
        if (
          !this.validateRequired(this.dateObject.year) ||
          !this.validateRequired(this.dateObject.month) ||
          !this.validateRequired(this.dateObject.day)
        ) {
          this.$emit("update:modelValue", "");
          return;
        }
        let year = this.dateObject.year.toString().padStart(4, "0");
        let month = this.dateObject.month.toString().padStart(2, "0");
        let day = this.dateObject.day.toString().padStart(2, "0");
        this.$emit("update:modelValue", `${year}-${month}-${day}`);
        this.$emit("errorMessage", "");
      } else {
        this.$emit("errorMessage", this.emitError());
      }
    },
    convertTimeStringToObject() {
      if (!this.validateRequired(this.modelValue)) {
        return;
      }
      try {
        let date = new Date(this.modelValue);
        // get year, month, day
        this.dateObject.year = date.getFullYear();
        this.dateObject.month = date.getMonth() + 1;
        this.dateObject.day = date.getDate();
      } catch {
        return "";
      }
    },
    validateDateEmpty() {
      if (
        !this.validateRequired(this.dateObject.year) ||
        !this.validateRequired(this.dateObject.month) ||
        !this.validateRequired(this.dateObject.day)
      ) {
        this.dateErrorObject.required = "無効な日付。";
        this.emitDateString();
      }
    },
    validateRequired(value) {
      if ([undefined, null, ""].includes(value)) {
        return false;
      }
      return true;
    },
    removeEmptyDateErrorMessage() {
      if (
        this.validateRequired(this.dateObject.year) &&
        this.validateRequired(this.dateObject.month) &&
        this.validateRequired(this.dateObject.day)
      ) {
        this.dateErrorObject.required = "";
      }
    },
    checkDayExist() {
      if (
        this.validateRequired(this.dateObject.year) &&
        this.validateRequired(this.dateObject.month) &&
        this.validateRequired(this.dateObject.day)
      ) {
        let year = this.dateObject.year.toString().padStart(4, "0");
        let month = this.dateObject.month.toString().padStart(2, "0");
        let day = this.dateObject.day.toString().padStart(2, "0");
        let dateObject = new Date(`${year}-${month}-${day}`);
        let day_of_date = dateObject.getDate();
        if (day_of_date != day) {
          this.dateErrorObject.day = "無効な日付。";
          this.$emit("errorMessage", this.emitError());
        } else {
          if (this.dateErrorObject.day == "無効な日付。") {
            this.dateErrorObject.day = "";
          }
        }
      }
    },
    validateMax(
      value,
      min,
      max,
      errorMessage,
      field,
      errorObject = this.errorObject
    ) {
      if (!this.validateRequired(value)) {
        return;
      }
      if (value < min || value > max) {
        errorObject[field] = errorMessage;
      } else if (errorObject[field] == errorMessage) {
        errorObject[field] = "";
      }
    },
    preventKeyCode($event) {
      return [",", "+", "-", "e", "."].includes($event.key)
        ? $event.preventDefault()
        : null;
    },
  },
};
</script>
