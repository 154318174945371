<template>
  <div class="tab-pane fade" role="tabpanel" :aria-labelledby="ariaLabel">
    <div class="wapper-table">
      <div class="custom-table">
        <table class="table table-striped table-border-in">
          <tbody class="text-nowrap">
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-35">{{ row.label }}</td>
              <td>{{ func.displayValue(row.value) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: { type: Array, default: () => [] },
  },
  computed: {
    ariaLabel() {
      const id = this.$attrs.id;
      if (!id) console.error("You must set separate `id` for each TabPanel");
      return `${id}-tab`;
    },
  },
};
</script>
