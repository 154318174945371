<template>
  <input
    :value="value"
    :type="type"
    class="form-control"
    :placeholder="placeholder"
    @input="$emit('update:value', $event.target.value)" />
</template>

<script>
export default {
  name: "InputForm",
  props: {
    type: { type: String, default: "" },
    value: { type: String, default: "" },
    placeholder: { type: String, default: "" },
  },
  emits: ["update:value"],
};
</script>
