<template>
  <PropertyForm
    v-if="tli == false"
    :key="property"
    :disabled-list="disabledList"
    :compulsory-list="compulsoryList"
    :original-property="property"
    @submitHandle="submitHandle" />
  <TLIPropertyForm
    v-if="tli == true"
    :key="property"
    :original-property="property"
    @submitHandle="submitHandle" />
  <ModalConfirm
    id="submitModal"
    message="物件情報を変更します。"
    @yes="submitForm"
    @no="abortProcess" />
  <ModalConfirm
    id="existModal"
    message="物件情報の変更をキャンセルします。"
    @yes="navigateBackTLI4002"
    @no="abortProcess" />
</template>
<script>
import $ from "jquery";
import {
  getPropertyDetail,
  updateProperty,
  uploadImage,
} from "/src/service/properties.service.js";
import PropertyForm from "/src/components/properties/PropertyForm.vue";
import TLIPropertyForm from "/src/components/properties/TLIPropertyForm.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import { ORIGINAL_LOCATION } from "/src/constants";
const { apiMessage } = require("/src/assets/validation.json");
export default {
  components: {
    PropertyForm,
    TLIPropertyForm,
    ModalConfirm,
  },
  data() {
    return {
      property: null,
      formData: null,
      disabledList: ["name"],
      compulsoryList: [
        "appearance_image_link",
        "guide_map_image_link",
        "location_prefecture",
        "location_district", //所在地-市町村
        "location_detail",
        "nearest_station_railway_1", //最寄駅沿線①
        "nearest_station_1 ", //最寄駅①
        "walking_to_nearest_station_1", //最寄駅①まで徒歩
        "structure_and_scale",
        "number_of_houses",
        "number_of_floors",
        "site_area",
        "use_district",
        "building_confirmation_number",
        "acquisition_date_of_building_confirmation",
        "completion_date", //竣工日
        "right_type",
        "seller_owner_1",
        "construction_company",
        "management_company",
        "management_type",
        "manager_working_style",
        "parking_lot_count",
        "parking_lot_status",
        "trade_type",
      ],
    };
  },
  computed: {
    tli() {
      if (this.property) {
        return (
          this.property?.location_of_original_data == ORIGINAL_LOCATION.DYNAMIC
        );
      }
      return null;
    },
  },
  async created() {
    await getPropertyDetail(this.$route.params.id).then((res) => {
      this.property = res.data;
      // active for left menubar
      this.$store.dispatch(
        "properties/setRouteName",
        this.property.location_of_original_data
      );
      this.property_id = JSON.parse(JSON.stringify(this.property.property_id));
    });
  },
  methods: {
    submitForm() {
      if (this.appearance_image || this.guide_map_image) {
        let name = this.tli ? "tli" : "other";
        let formData = new FormData();
        if (this.appearance_image) {
          formData.append(
            "names",
            `${this.property_id}_${name}_appearance.${this.appearance_image.name
              .split(".")
              .pop()}`
          );
          formData.append("files", this.appearance_image);
        }
        if (this.guide_map_image) {
          formData.append(
            "names",
            `${this.property_id}_${name}_guide_map.${this.guide_map_image.name
              .split(".")
              .pop()}`
          );
          formData.append("files", this.guide_map_image);
        }
        uploadImage(formData).then((res) => {
          for (let item of res.data) {
            if (item.name.includes("appearance")) {
              this.formData.appearance_image_link = item.name;
            }
            if (item.name.includes("guide_map")) {
              this.formData.guide_map_image_link = item.name;
            }
          }
          this.uploadPropertyForm(this.property_id, this.formData);
        });
      } else {
        this.uploadPropertyForm(this.property_id, this.formData);
      }
    },
    uploadPropertyForm(property_id, data) {
      updateProperty(property_id, data).then((_) => {
        this.$store.dispatch(
          "alertMessage/showAlert",
          "物件情報を変更しました。"
        );
        this.$router.push({
          name: "property-detail",
          params: {
            id: property_id,
          },
          hash: "#nav-2",
        });
      });
    },
    submitHandle(event) {
      this.formData = event.property;
      this.appearance_image = event.appearance_image;
      this.guide_map_image = event.guide_map_image;
      $("#submitModal").modal("show");
    },
    abortProcess() {
      this.$store.dispatch("alertMessage/showAlert", apiMessage.ABORT_PROCESS);
    },
    navigateBackTLI4002() {
      this.$router.push({
        name: "property-detail",
        params: { id: this.property_id },
        hash: "#nav-2",
      });
      this.$store.dispatch(
        "alertMessage/showAlert",
        "物件情報の変更をキャンセルしました。"
      );
    },
  },
};
</script>
