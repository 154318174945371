<template>
  <HistoryFormBase
    title="送客履歴変更"
    :init-history-id="historyId"
    :modal-msg="modalMsg"
    @confirm-submit="handleSubmit"
    @confirm-cancel="handleCancel" />
</template>

<script>
import { updateCustomerActionHistory } from "/src/service/customer-action-histories.service.js";
import { backToCustomerAction } from "/src/helpers/route-navigate.js";

import HistoryFormBase from "/src/components/customers/action/HistoryFormBase.vue";

export default {
  components: {
    HistoryFormBase,
  },
  data() {
    return {
      modalMsg: {
        submit: "来場・送客情報を変更します。",
        cancel: "来場・送客情報の変更をキャンセルします。",
      },
    };
  },
  computed: {
    historyId() {
      const idStr = this.$route.params.history_id;
      if (idStr) return parseInt(idStr);
      return null;
    },
  },
  methods: {
    async handleSubmit(body) {
      await updateCustomerActionHistory(this.historyId, body);
      backToCustomerAction(this, body.customer_id, "submit");
    },
    handleCancel(body) {
      backToCustomerAction(this, body.customer_id, "cancel");
    },
  },
};
</script>
