<template>
  <button class="btn btn-cus" :disabled="isLoading || !isValid">
    <slot>{{ text }}</slot>
  </button>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    text: { type: String, default: "" },
    isValid: { type: Boolean, default: true },
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
  },
};
</script>
