<template>
  <div>
    <TabTableLayout
      v-model="recommendMail"
      table-label="レコメンドメール配信履歴"
      table-class="table-border-in mb-0"
      :fetch-func="fetchData()">
      <thead>
        <tr class="text-nowrap text-center">
          <th>配信日時</th>
          <th>開封日時</th>
          <th>開封状況</th>
          <th>紹介物件名</th>
          <th>紹介物件<br />区画名</th>
          <th>物件紹介URL<br />PV数</th>
          <th>物件紹介URL<br />アクセス日時</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(item, index) in recommendMail.items"
          :key="`tr_rcm_mail_${index}`">
          <tr>
            <td colspan="1" rowspan="3" class="border-right-0">
              <TimeBlock :date-time-str="item.sent_at" />
            </td>
            <td colspan="1" rowspan="3" class="border-right-0">
              <TimeBlock :date-time-str="item.opened_at" />
            </td>
            <td colspan="1" rowspan="3">
              <span class="minh-42">
                {{ item.open_times > 0 ? "開封済み" : "未開封" }}
              </span>
            </td>
            <!-- Recommend Property 1 -->
            <td>
              <LinkToNewTab
                :route-to="pageRecommend(item.recommend_property_1)"
                :text="getPropertyName(item.recommend_property_1)" />
            </td>
            <td>{{ getBlockName(item.recommend_property_1) }}</td>
            <td>
              {{
                func.displayValue(
                  item.recommend_property_1?.number_of_page_views
                )
              }}
            </td>
            <td>
              <TimeBlock
                :date-time-str="item.recommend_property_1?.accessed_at" />
            </td>
          </tr>
          <!-- Recommend Property 2 -->
          <tr>
            <td>
              <LinkToNewTab
                :route-to="pageRecommend(item.recommend_property_2)"
                :text="getPropertyName(item.recommend_property_2)" />
            </td>
            <td>{{ getBlockName(item.recommend_property_2) }}</td>
            <td>
              {{
                func.displayValue(
                  item.recommend_property_2?.number_of_page_views
                )
              }}
            </td>
            <td>
              <TimeBlock
                :date-time-str="item.recommend_property_2?.accessed_at" />
            </td>
          </tr>
          <!-- Recommend Property 3 -->
          <tr>
            <td>
              <LinkToNewTab
                :route-to="pageRecommend(item.recommend_property_3)"
                :text="getPropertyName(item.recommend_property_3)" />
            </td>
            <td>{{ getBlockName(item.recommend_property_3) }}</td>
            <td>
              {{
                func.displayValue(
                  item.recommend_property_3?.number_of_page_views
                )
              }}
            </td>
            <td>
              <TimeBlock
                :date-time-str="item.recommend_property_3?.accessed_at" />
            </td>
          </tr>
        </template>
      </tbody>
    </TabTableLayout>
  </div>
</template>

<script>
import { PROPERTY_TYPE } from "/src/frontend/src/constants.js";
import { getRecommendMailList } from "/src/service/recommend.service.js";

import TabTableLayout from "./TabTableLayout.vue";
import LinkToNewTab from "./LinkToNewTab.vue";
import TimeBlock from "/src/components/utilitys/TimeBlock.vue";

export default {
  components: {
    TabTableLayout,
    LinkToNewTab,
    TimeBlock,
  },
  data() {
    return {
      recommendMail: {},
    };
  },
  methods: {
    formatTime(dateTimeStr) {
      return this.func
        .formatDate(dateTimeStr, {
          dateStyle: "long",
          timeStyle: "short",
        })
        .replace(" ", "\n");
    },
    getBlockName(rcmProperty) {
      if (!rcmProperty) return "";
      // If recommend_properties.property_table_type=1
      // => Show property_blocks.block_name
      // If recommend_properties.property_table_type=2
      // => Show salesforce_properties.block_name
      const tableType = rcmProperty.property_table_type;
      let blockName;
      if (tableType === PROPERTY_TYPE.PROPERTY) {
        blockName = rcmProperty.property_block?.block_name;
      }
      if (tableType === PROPERTY_TYPE.SALESFORCE_PROPERTY) {
        blockName = rcmProperty.salesforce_property?.block_name;
      }
      return this.func.displayValue(blockName);
    },
    getPropertyName(rcmProperty) {
      if (!rcmProperty) return "";
      // If recommend_properties.property_table_type=1
      // => Show properties.name
      // If recommend_properties.property_table_type=2
      // => Show salesforce_properties.name
      const tableType = rcmProperty.property_table_type;
      let propertyName;
      if (tableType === PROPERTY_TYPE.PROPERTY) {
        propertyName = rcmProperty.property?.name;
      }
      if (tableType === PROPERTY_TYPE.SALESFORCE_PROPERTY) {
        propertyName = rcmProperty.salesforce_property?.name;
      }
      return this.func.displayValue(propertyName);
    },
    fetchData() {
      return this.fetchMails;
    },
    fetchMails(params) {
      return getRecommendMailList({ ...params, is_sent: true });
    },
    pageRecommend(rcmProperty) {
      const sfUrl = rcmProperty?.salesforce_property?.reference_page_url;
      const blockUrl = rcmProperty?.property_block?.reference_page_url;

      if (sfUrl)
        return {
          name: "property-reference-sf",
          params: { uuid: sfUrl },
        };

      if (blockUrl)
        return {
          name: "property-reference",
          params: { uuid: blockUrl },
        };

      return "";
    },
  },
};
</script>
