<template>
  <SalesFollowHistoryBase
    title="フォロー履歴登録"
    :fetch-delay="nextFetchTime"
    :customer-id="customerId"
    :init-form-data="initFormData"
    :dynamic-properties="availableProperties['dynamic']"
    :other-properties="availableProperties['other']"
    :modal-msg="modalMsg"
    @confirm-submit="handleSubmit"
    @confirm-cancel="handleCancel" />
</template>

<script>
import { mapState } from "vuex";
import { API_DELAY, ORIGINAL_LOCATION } from "/src/frontend/src/constants.js";
import {
  fetchAvailableProperties,
  navigateBack,
  createRequestBody,
} from "./utils.js";
import { createSaleFollowHistory } from "/src/service/sales-follow-histories.service.js";

import SalesFollowHistoryBase from "/src/components/sales-follow-histories/SalesFollowHistoryBase.vue";

export default {
  components: {
    SalesFollowHistoryBase,
  },
  data() {
    return {
      initFormData: {
        user_id: null,
        follow_date: null,
        contact_method: "TELフォロー",
        contact_result: null,
        property_1: {
          checked: false,
          property_id: null,
          name: null,
          location_of_original_data: ORIGINAL_LOCATION.DYNAMIC,
        },
        property_2: {
          checked: false,
          property_id: null,
          name: null,
          location_of_original_data: ORIGINAL_LOCATION.DYNAMIC,
        },
        property_3: {
          checked: false,
          property_id: null,
          name: null,
          location_of_original_data: ORIGINAL_LOCATION.DYNAMIC,
        },
        property_4: {
          checked: false,
          property_id: null,
          name: null,
          location_of_original_data: ORIGINAL_LOCATION.DYNAMIC,
        },
        property_5: {
          checked: false,
          property_id: null,
          name: null,
          location_of_original_data: ORIGINAL_LOCATION.DYNAMIC,
        },
        comment: null,
      },
      availableProperties: {
        dynamic: [],
        other: [],
      },
      modalMsg: {
        submit: "フォロー履歴情報を登録します。",
        cancel: "フォロー履歴情報の登録をキャンセルします。",
      },
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    customerId() {
      const idStr = this.$route.params.customer_id;
      if (idStr) return parseInt(idStr);
      return null;
    },
    nextFetchTime() {
      // the time to call next API
      return 3 * API_DELAY;
    },
  },
  created() {
    const now = new Date();
    const nowStr = now.toLocaleDateString("ja-JP", { dateStyle: "medium" });
    this.initFormData.follow_date = nowStr;
    this.initFormData.user_id = this.user_object?.user_id;

    fetchAvailableProperties(this, API_DELAY);
  },
  methods: {
    async handleSubmit(body) {
      // Call API add sales follow history
      const requestBody = createRequestBody(this.customerId, body);
      await createSaleFollowHistory(requestBody);

      // Back to TLI07003
      navigateBack(this, "submit");
    },
    handleCancel() {
      // Back to TLI07003
      navigateBack(this, "cancel");
    },
  },
};
</script>
