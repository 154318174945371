import axios from "axios";
import store from "../store/index.js";
import {
  PROPERTIES_API,
  PROPERTY_BLOCKS_API,
  RECOMMEND_CUSTOMER_API,
} from "./const.service.js";
import { downloadFile } from "./utility.service.js";

const downloadPropertyBlocks = (params, fileName) => {
  let url = PROPERTY_BLOCKS_API + "/download";
  return downloadFile(url, params, fileName);
};

const getProperties = async (param) => {
  try {
    return axios.get(PROPERTIES_API, { params: param });
  } catch (error) {
    console.log(error);
  }
};

const getPropertyBlocks = async (param) => {
  try {
    return axios.get(PROPERTY_BLOCKS_API, { params: param });
  } catch (error) {
    console.log(error);
  }
};

const getPropertyDetail = async (id) => {
  let url = PROPERTIES_API + "/" + id;
  try {
    return axios.get(url);
  } catch (error) {
    console.log(error);
  }
};

const getPropertiesBlockDetail = async (blockId) => {
  try {
    const response = await axios.get(`${PROPERTY_BLOCKS_API}/${blockId}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getRecommendCustomer = async (param) => {
  try {
    return axios.get(RECOMMEND_CUSTOMER_API, { params: param });
  } catch (error) {
    console.log(error);
  }
};

const uploadImage = (formData, testInvalid = false) => {
  let url = PROPERTIES_API + "/images";
  if (testInvalid) {
    formData.append("pre_submit", true);
  }
  return axios({
    method: "POST",
    url,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const testImageInvalid = (image) => {
  let form = new FormData();
  form.append("files", image);
  form.append("names", `test.${image.name.split(".").pop()}`);
  return uploadImage(form, true).then((res) => {
    let data = res.data[0];
    let errorMessage = "";
    if (data["error"]) {
      if (data["error"].includes("Invalid file format")) {
        errorMessage = "無効な画像形式。";
      }
    }
    return errorMessage;
  });
};
const createProperty = (data) => {
  try {
    return axios.post(PROPERTIES_API, data);
  } catch (error) {
    console.log(error);
  }
};
const updateProperty = async (property_id, data) => {
  let url = PROPERTIES_API + "/" + property_id;
  try {
    return axios.put(url, data);
  } catch (error) {
    console.log(error);
  }
};
const createPropertyBlock = (data) => {
  return axios.post(PROPERTY_BLOCKS_API, data);
};
const uploadPropertyBlock = (blockId, data) => {
  let url = PROPERTY_BLOCKS_API + "/" + blockId;
  return axios.put(url, data);
};

const uploadPropertyBlocksExcel = (params, form) => {
  let url = PROPERTY_BLOCKS_API;
  return axios({
    method: "PUT",
    url,
    params,
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
export {
  createProperty,
  createPropertyBlock,
  downloadPropertyBlocks,
  getProperties,
  getPropertyBlocks,
  getPropertyDetail,
  getPropertiesBlockDetail,
  getRecommendCustomer,
  uploadImage,
  testImageInvalid,
  updateProperty,
  uploadPropertyBlock,
  uploadPropertyBlocksExcel,
};
