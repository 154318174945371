<template>
  <div class="position_relative">
    <div class="custom-table scrollBody_table" :class="externalClass">
      <span
        v-show="showArrow && !isEndLeft"
        class="arrowScroll left-scroll"
        :class="{ 'fix-arrow': showArrow }"
        @click="scrollLeft">
        <i class="fas fa-chevron-left"></i>
      </span>
      <span
        v-show="showArrow && !isEndRight"
        class="arrowScroll right-scroll"
        :class="{ 'fix-arrow': showArrow }"
        @click="scrollRight">
        <i class="fas fa-chevron-right"></i>
      </span>
      <slot></slot>
    </div>
    <div class="anchor"></div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    externalClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      scrollSpeed: 40,
      showArrow: false,
      isEndLeft: true,
      isEndRight: false,
    };
  },
  mounted() {
    $(window).scroll(() => {
      const fixmeTop = $(this.$el.querySelector(".scrollBody_table")).offset()
        ?.top;
      const fixmeBottom = $(this.$el.querySelector(".anchor")).offset()?.top;
      if (!fixmeTop || !fixmeBottom) return;

      const currentScroll = $(window).scrollTop();
      this.showArrow =
        this.isOverFlow() &&
        currentScroll > fixmeTop &&
        currentScroll + innerHeight - 30 < fixmeBottom;
    });
  },
  methods: {
    scrollLeft() {
      this.scrollAndCheckArrow(this.scrollSpeed);
    },
    scrollRight() {
      this.scrollAndCheckArrow(-this.scrollSpeed);
    },
    scrollAndCheckArrow(deltaLeft) {
      const target = this.$el.querySelector(".scrollBody_table");
      target.scrollLeft -= deltaLeft;
      this.isEndLeft = target.scrollLeft === 0;
      this.isEndRight =
        target.scrollLeft === target.scrollWidth - target.clientWidth;
    },
    isOverFlow() {
      const target = this.$el.querySelector(".scrollBody_table");
      return target ? target.scrollWidth > target.clientWidth : false;
    },
  },
};
</script>

<style scoped>
.arrowScroll.fix-arrow {
  top: calc(100vh / 2.2) !important;
  bottom: auto !important;
  position: fixed !important;
}
</style>
