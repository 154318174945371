<template>
  <div
    class="modal fade custom-modal"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="ariaLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="my-4 text-center">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    ariaLabel() {
      // Modal must be set attribute `id`
      const id = this.$attrs.id;
      if (!id) console.error("You must set separate `id` for each modal");
      return `${id}Label`;
    },
  },
};
</script>
