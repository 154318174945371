<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="col-xl-6">
    <h3 class="card-title-cus">送信先絞り込み</h3>
    <div class="cus-filter height_calc13">
      <div class="card-body">
        <!-- free-mail type select -->
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check custom-checkbox mb-3 pl-1 pl-lg-0">
            <input
              id="regular-mail"
              v-model="mailType"
              class="form-check-input"
              value="regular"
              type="radio"
              @click="changeMailType('regular')" />
            <label for="regular-mail">レコメンドメール</label>
          </div>
          <div
            class="form-check custom-checkbox mb-3 ml-4 ml-md-5 pl-1 pl-lg-0">
            <input
              id="new-mail"
              v-model="mailType"
              class="form-check-input"
              value="new"
              type="radio"
              @click="changeMailType('new')" />
            <label for="new-mail">新着情報メール</label>
          </div>
        </div>

        <!-- property regular mail -->
        <div
          v-if="mailType === 'regular'"
          class="d-flex align-items-center flex-wrap">
          <!-- check box for property filter -->
          <div class="form-check custom-checkbox mb-3">
            <input
              id="check-property"
              v-model="filter.property.checked"
              class="form-check-input"
              type="checkbox"
              @change="uncheckBlock(filter.property.checked)" />
            <label for="check-property" class="min-width-auto">物件名</label>
          </div>
          <!-- location of original data -->
          <div
            v-for="(item, idx) in radioOptions"
            :key="`radio-loc-${idx}`"
            class="form-check custom-checkbox mb-3 ml-4 ml-md-5 pl-1 pl-lg-0">
            <input
              :id="`type_${idx}`"
              v-model="filter.property.assetType"
              class="form-check-input"
              type="radio"
              name="construction"
              :disabled="!filter.property.checked"
              :value="item.location_of_original_data"
              @click="resetProperty" />
            <label :for="`type_${idx}`" class="min-width-auto">
              {{ item.property_category }}
            </label>
          </div>
          <!-- Select property -->
          <div
            :key="`properties_${availableProperties.dynamic.length}_${availableProperties.dynamic.other}`"
            class="mb-3 ml-4 pl-1 pl-lg-0 w-215">
            <div
              v-if="filter.property.assetType == 1"
              class="custom-select2 w-100 selectBox">
              <FieldSelect2
                :key="`dynamic-${availableProperties.dynamic.length}`"
                v-model="filter.property.id"
                :options="availableProperties.dynamic"
                :disabled="!filter.property.checked"
                placeholder="物件名を選択"
                keyid="value"
                keytext="label" />
            </div>
            <div
              v-else-if="filter.property.assetType == 3"
              class="custom-select2 w-100 selectBox">
              <FieldSelect2
                :key="`other-${availableProperties.other.length}`"
                v-model="filter.property.id"
                :options="availableProperties.other"
                placeholder="物件名を選択"
                :disabled="!filter.property.checked"
                keyid="value"
                keytext="label" />
            </div>
            <input
              v-else
              v-model.trim="filter.property.name"
              :disabled="!filter.property.checked"
              type="text"
              class="form-control textBox"
              placeholder="物件名を入力" />
          </div>
        </div>

        <!-- property new mail -->
        <div v-else class="d-flex align-items-center flex-wrap">
          <!-- check box for property filter -->
          <div class="form-check custom-checkbox mb-3">
            <input
              id="check-property"
              v-model="filter.property.checked"
              class="form-check-input"
              type="checkbox"
              @change="uncheckBlock(filter.property.checked)" />
            <label for="check-property" class="min-width-auto">物件名</label>
          </div>
          <!-- Select property -->
          <div
            :key="`properties_${availableProperties.dynamic.length}`"
            class="mb-3 ml-4 pl-1 pl-lg-0 w-215">
            <div class="custom-select2 w-100 selectBox">
              <FieldSelect2
                :key="`dynamic-${availableProperties.dynamic.length}`"
                v-model="filter.property.id"
                :options="availableProperties.dynamic"
                :disabled="!filter.property.checked"
                placeholder="物件名を選択"
                keyid="value"
                keytext="label" />
            </div>
          </div>
        </div>

        <!-- property block -->
        <div
          v-if="mailType === 'regular'"
          class="d-flex align-items-center flex-wrap">
          <div class="form-check custom-checkbox mb-3">
            <input
              id="check-block"
              v-model="filter.block.checked"
              class="form-check-input"
              type="checkbox" />
            <label for="check-block" class="min-width-auto">区画名</label>
          </div>
          <div class="mb-3 ml-4 ml-sm-5 pl-1 pl-lg-0 w-215">
            <SelectBlock
              v-if="filter.property.assetType != 2"
              v-model="filter.block.id"
              :disabled="!filter.block.checked"
              :property-id="filter.property.id" />
            <input
              v-else
              v-model.trim="filter.block.name"
              type="text"
              :disabled="!filter.block.checked"
              class="form-control textBox"
              placeholder="区画名を入力" />
          </div>
        </div>

        <!-- mail date -->
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check custom-checkbox mb-3">
            <input
              id="sendingDate"
              v-model="filter.date.checked"
              class="form-check-input"
              type="checkbox" />
            <label v-if="mailType === 'regular'" for="sendingDate">
              メール送信日
            </label>
            <label v-else for="sendingDate">送信日</label>
          </div>
          <div class="d-flex flex-wrap ml-4 ml-md-5 pl-1 pl-lg-0">
            <div class="form-group maxw-147">
              <InputDatePicker
                v-model:modelvalue="filter.date.from"
                class="mb-0"
                :disabled="!filter.date.checked"
                typeformat="be"
                @update:modelvalue="filter.date.to = filter.date.from" />
              <p v-show="isErrorDate" class="error text-nowrap">
                日付の大小関係が不正です。
              </p>
            </div>
            <span class="form-group mx-2 mt-2">～</span>
            <div class="form-group maxw-147">
              <InputDatePicker
                v-model:modelvalue="filter.date.to"
                class="mb-0"
                :disabled="!filter.date.checked"
                typeformat="be" />
            </div>
          </div>
        </div>

        <!-- mail time -->
        <div
          v-if="mailType === 'regular'"
          class="d-flex align-items-center flex-wrap">
          <div class="form-check custom-checkbox mb-3 mr-sm-2">
            <input
              id="sendingTime"
              v-model="filter.time.checked"
              class="form-check-input"
              type="checkbox" />
            <label for="sendingTime">メール送信時間</label>
          </div>
          <div class="mb-3 minw-160 ml-4 pl-1 pl-lg-0">
            <div class="custom-select2 w-100 selectBox">
              <FieldSelect2
                v-model="filter.time.value"
                :options="selectTimes"
                :disabled="!filter.time.checked"
                keyid="value"
                keytext="label"
                placeholder="時間を選択" />
            </div>
          </div>
        </div>

        <!-- customer info -->
        <div class="d-flex align-items-center flex-wrap">
          <div class="form-check custom-checkbox mb-3">
            <input
              id="option10"
              v-model="filter.customer.checked"
              class="form-check-input"
              type="checkbox" />
            <label for="option10" style="width: 0"></label>
          </div>
          <div class="mb-3 ml-2 w-145">
            <input
              v-model.trim="filter.customer.kanji"
              :disabled="!filter.customer.checked"
              type="text"
              class="form-control"
              placeholder="氏名" />
          </div>
          <div class="mb-3 ml-4 pl-1 w-145">
            <input
              v-model.trim="filter.customer.furigana"
              :disabled="!filter.customer.checked"
              type="text"
              class="form-control"
              placeholder="シメイ" />
          </div>
          <div class="mb-3 ml-4 pl-1 w-300">
            <input
              v-model.trim="filter.customer.email"
              :disabled="!filter.customer.checked"
              type="email"
              class="form-control"
              placeholder="メールアドレス" />
          </div>
        </div>

        <div class="my-3 text-center">
          <button
            type="button"
            class="btn btn-cus btn-blue-light m-2 w-150"
            @click="navigateToSearch">
            絞り込み
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDatePicker from "/src/components/utilitys/inputs/InputDatePicker.vue";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2.vue";
import SelectBlock from "/src/frontend/src/components/customers/action/form-fields/SelectBlock.vue";

import { fetchAvailableProperties } from "/src/views/sales-follow-histories/utils.js";
import { ORIGINAL_LOCATION } from "/src/frontend/src/constants.js";

export default {
  components: {
    InputDatePicker,
    FieldSelect2,
    SelectBlock,
  },
  props: {
    radioOptions: { type: Array, default: () => [] },
  },
  emits: ["click-search", "reload-data", "change-mail-type"],
  data() {
    return {
      mailType: "regular",
      filter: {
        property: {
          checked: false,
          assetType: ORIGINAL_LOCATION.DYNAMIC,
          name: null,
          id: null,
        },
        block: {
          checked: false,
          name: null,
          id: null,
        },
        date: {
          checked: false,
          from: null,
          to: null,
        },
        time: {
          checked: false,
          value: null,
        },
        customer: {
          checked: false,
          kanji: null,
          furigana: null,
          email: null,
        },
      },
      availableProperties: {
        dynamic: [],
        other: [],
      },
      times: ["10:00", "12:00", "18:00"],
    };
  },
  computed: {
    isErrorDate() {
      const fromDate = new Date(this.filter.date.from);
      const toDate = new Date(this.filter.date.to);
      return fromDate > toDate;
    },
    selectTimes() {
      return this.times.map((el) => ({ value: el, label: el }));
    },
    activeParams() {
      const formData = {
        mailType: this.mailType,
        assetType: this.getValue("property", "assetType"),
        property_id: this.getValue("property", "id"),
        property_name: this.getValue("property", "name"),
        block_id: this.getValue("block", "id"),
        block_name: this.getValue("block", "name"),
        plan_recommend_from_date: this.getValue("date", "from"),
        plan_recommend_to_date: this.getValue("date", "to"),
        plan_recommend_time: this.getValue("time", "value"),
        full_name_kanji: this.getValue("customer", "kanji"),
        full_name_furigana: this.getValue("customer", "furigana"),
        mail_recipient: this.getValue("customer", "email"),
      };

      const result = {};
      for (const key in formData) {
        const value = formData[key];
        if (value !== null && value !== undefined && value !== "") {
          result[key] = value;
        }
      }
      return result;
    },
  },
  watch: {
    availableProperties: {
      handler: function (newObj) {
        if (newObj.dynamic.length && newObj.other.length) this.initialData();
      },
      deep: true,
    },
    $route: {
      handler: "initialData",
      immediate: true,
    },
  },
  created() {
    fetchAvailableProperties(this, 0);
  },
  methods: {
    changeMailType(newValue) {
      this.$emit("change-mail-type", newValue);
      if (this.filter.property.assetType != 1) {
        this.filter.property.assetType = 1;
        this.resetProperty();
      }
    },
    uncheckBlock(propertyChecked) {
      if (!propertyChecked) this.filter.block.checked = false;
    },
    getValue(key, subKey) {
      const obj = this.filter[key];
      if (obj.checked) return obj[subKey];
      return null;
    },
    navigateToSearch() {
      const next = new URLSearchParams(this.activeParams).toString();
      const curr = new URLSearchParams(this.$route.query).toString();
      if (next === curr) {
        this.$emit("reload-data");
        this.initialData();
      } else {
        this.$router.push({ query: this.activeParams });
      }
      this.$emit("click-search");
    },
    initialData() {
      const params = this.$route.query;
      // free mail type
      this.mailType = params.mailType || "regular";
      // property and block
      switch (parseInt(params.assetType)) {
        case ORIGINAL_LOCATION.DYNAMIC:
        case ORIGINAL_LOCATION.OTHER:
          this.filter.property.assetType = params.assetType;
          this.filter.property.id = params.property_id;
          this.filter.property.checked = true; // Boolean(this.filter.property.id);
          this.filter.block.id = params.block_id;
          this.filter.block.checked = Boolean(this.filter.block.id);
          break;

        case ORIGINAL_LOCATION.SALESFORCE:
          this.filter.property.assetType = params.assetType;
          this.filter.property.name = params.property_name;
          this.filter.property.checked = true; // Boolean(this.filter.property.name);
          this.filter.block.name = params.block_name;
          this.filter.block.checked = Boolean(this.filter.block.name);
          break;

        default:
          this.filter.property.assetType = ORIGINAL_LOCATION.DYNAMIC;
          this.filter.property.checked = false;
          this.filter.block.checked = false;
          this.resetProperty();
          break;
      }
      // date from - to
      if (params.plan_recommend_from_date || params.plan_recommend_to_date) {
        this.filter.date.checked = true;
        this.filter.date.from = params.plan_recommend_from_date;
        this.filter.date.to = params.plan_recommend_to_date;
      } else {
        this.filter.date.checked = false;
        this.filter.date.from = null;
        this.filter.date.to = null;
      }
      // time recommend
      this.filter.time.value = params.plan_recommend_time;
      this.filter.time.checked = Boolean(this.filter.time.value);
      // customer
      this.filter.customer.kanji = params.full_name_kanji;
      this.filter.customer.furigana = params.full_name_furigana;
      this.filter.customer.email = params.mail_recipient;
      this.filter.customer.checked =
        Boolean(this.filter.customer.kanji) ||
        Boolean(this.filter.customer.furigana) ||
        Boolean(this.filter.customer.email);
    },
    resetProperty() {
      this.filter.property.name = null;
      this.filter.property.id = null;
      this.filter.block.name = null;
      this.filter.block.id = null;
    },
  },
};
</script>
