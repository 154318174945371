<template>
  <div class="row mb-4">
    <div class="col-md-12">
      <h3 class="card-title-cus">{{ header }}</h3>
    </div>
  </div>
  <FilterProperties
    :key="$route.query"
    @reload="fetchData()"
    @order-reset="orderReset()" />
  <div class="wrap-ct">
    <div
      v-if="
        user_object.role != USER_ROLE.VIEWER &&
        $router.currentRoute._value.name == 'properties-other'
      "
      class="text-right my-4 mx-2 mx-sm-4">
      <button
        class="btn btn-cus btn-blue-light btn-add-tbl"
        @click="$router.push({ name: 'properties-other-register' })">
        <img
          src="@/assets/img/icons/ic-plus.png"
          alt="icon"
          class="img-fluid mx-2" />
        他社物件新規登録
      </button>
    </div>
    <PropertyList
      :key="items"
      :items="items"
      :order_by_src="order.order_by"
      :desc_src="order.desc"
      @sorting="handleSorting" />
    <div v-if="items.length > 0" class="custom-pagi my-4">
      <PaginateInterface
        :key="items"
        :pages="pages"
        :size="size"
        :initial-page="initialPage" />
    </div>
    <PaginateInfo
      v-if="items.length > 0"
      :key="items"
      :page="page"
      :size="size"
      :total="total" />
  </div>
</template>

<script>
import FilterProperties from "/src/components/properties/FilterProperties.vue";
import PropertyList from "/src/components/properties/PropertyList.vue";
import { getProperties } from "/src/service/properties.service.js";
import PaginateInfo from "/src/components/commons/PaginateInfo.vue";
import PaginateInterface from "/src/components/commons/PaginateInterface.vue";
import { getParamsUrl } from "/src/service/utility.service";
import {
  USER_ROLE,
  ORIGINAL_LOCATION,
  BLOCK_CLASSIFICATION,
} from "/src/constants.js";
import { mapState } from "vuex";
export default {
  components: {
    PaginateInfo,
    PaginateInterface,
    FilterProperties,
    PropertyList,
  },
  data() {
    return {
      items: [],
      size: 50,
      total: 0,
      page: 1,
      pages: 1,
      initialPage: 1,
      USER_ROLE,
      order: { order_by: "", desc: null },
      order_by_query: "",
      header:
        this.$router.currentRoute._value.name == "properties-in-house"
          ? "販売受託・ルジェンテ新築物件"
          : "他社媒介・紹介新築等物件",
      BLOCK_CLASSIFICATION_ARRAY: BLOCK_CLASSIFICATION.slice(1).map(
        (item) => item.value
      ),
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
  },
  watch: {
    "$route.query": {
      handler() {
        this.fetchData();
      },
      immediate: true,
    },
    "$route.path": {
      handler() {
        this.order = { order_by: "", desc: null };
      },
    },
  },
  created() {
    this.parseUrl();
    this.fetchInitialData();
  },
  updated() {
    this.fetchInitialData();
    this.header =
      this.$router.currentRoute._value.name == "properties-in-house"
        ? "販売受託・ルジェンテ新築物件"
        : "他社媒介・紹介新築等物件";
  },
  methods: {
    parseUrl() {
      const { order_by } = this.$route.query;
      this.order.order_by = order_by || this.order.order_by;
      this.order.desc = this.order.order_by[0] === "-";
    },
    fetchData() {
      // Only search if there is a query string
      if (location.search != "") {
        let location_of_original_data =
          this.$router.currentRoute._value.name == "properties-in-house"
            ? ORIGINAL_LOCATION.DYNAMIC
            : ORIGINAL_LOCATION.OTHER;
        // active for left menubar
        this.$store.dispatch(
          "properties/setRouteName",
          location_of_original_data
        );
        let params = getParamsUrl();
        // multiple price for 10000
        params = this.handlaParams(params);
        this.page = params["page"] ? parseInt(params["page"]) : this.page;
        this.initialPage = this.page;
        params = { ...params, location_of_original_data, page: this.page };
        getProperties(params).then((res) => {
          if (res.data["items"].length == 0) {
            this.$store.commit("confirmapi/setConfirm", {
              status: true,
              mes: "条件に該当する検索結果がありません。",
            });
          }
          this.total = res.data["total"];
          this.pages = res.data["pages"];
          this.items = res.data["items"];
          this.size = res.data["size"];
        });
      }
    },
    orderReset() {
      this.order = { order_by: "", desc: null };
    },
    fetchInitialData() {
      if (location.search == "") {
        // router name == properties-in-house
        if (this.$router.currentRoute._value.name == "properties-in-house") {
          this.$router.push({
            path: location.pathname,
            replace: true,
            query: {
              page: 1,
            },
          });
        } else {
          this.$router.push({
            path: location.pathname,
            replace: true,
            query: {
              page: 1,
            },
          });
        }
      }
    },
    handlaParams(params) {
      // multiple price for 10000
      if (params["block_price_min"]) {
        params["block_price_min"] = parseInt(params["block_price_min"]) * 10000;
      }
      if (params["block_price_max"]) {
        params["block_price_max"] = parseInt(params["block_price_max"]) * 10000;
      }
      if (params["block_classification"]) {
        let arrayBlock = params["block_classification"].split(",");
        for (let item of arrayBlock) {
          if (!this.BLOCK_CLASSIFICATION_ARRAY.includes(item)) {
            arrayBlock = arrayBlock.filter((value) => value != item);
          }
        }
        params["block_classification"] = arrayBlock.join(",");
      }
      return params;
    },
    handleSorting(event) {
      this.order.order_by = event.order_by;
      this.order.desc = event.desc;
      let order_query_string = "order_by=" + this.order.order_by;
      if (order_query_string != this.order_by_query) {
        this.order_by_query = order_query_string;
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.$router.replace({
            query: {
              ...this.$route.query,
              order_by: this.order.order_by,
              ...{ page: 1 },
            },
          });
        }, 500);
      } else {
        this.page = 1;
        this.timeOut = setTimeout(() => {
          this.$router.replace({
            query: {
              ...this.$route.query,
              order_by: this.order.order_by,
              ...{ page: 1 },
            },
          });
        }, 500);
      }
    },
  },
};
</script>
