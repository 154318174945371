<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div
    :id="id"
    class="tab-pane fade"
    role="tabpanel"
    aria-labelledby="nav-2-tab">
    <div class="mx-4">
      <div class="cus-filter my-0 border-0">
        <div class="card-body px-0">
          <div class="row align-items-center">
            <div class="col-12 col-md-3 col-xl-2 my-2">
              <div class="form-group">
                <label class="my-0 text-nowrap">フォロー対象日</label>
              </div>
            </div>
            <div class="col-12 col-md-5 col-xl-3 my-2">
              <InputDatePicker
                v-model:modelvalue="followDate"
                :placeholder="'YYYY/MM/YY'"
                :typeformat="'be'" />
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 col-xl-2 my-2">
              <div class="form-group">
                <label class="my-0 text-nowrap">振分先頭</label>
              </div>
            </div>
            <div class="col-12 col-md-5 col-xl-3 my-2">
              <div class="custom-select2 w-100">
                <FieldSelect2
                  v-model="selectUserId"
                  :options="dataUsersSelect"
                  :keyid="'user_id'"
                  :keytext="'name'"
                  :placeholder="'振分先頭'"
                  :disabled="!isPresent || !isPriorities" />
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-end">
            <div class="col-12 col-md-5 col-xl-3 my-2">
              <button
                type="button"
                class="btn btn-cus btn-blue-light my-2 mr-0 mr-md-2 w-100"
                :disabled="
                  !isPresent || !isPriorities || dataUsersTable.length == 0
                "
                @click="autoCheck">
                均等振分
              </button>
            </div>
            <div class="col-12 col-md-4 col-xl-7 my-2">
              <button
                type="button"
                class="btn btn-cus btn-blue-light m-0 m-md-2 w-105"
                :disabled="
                  !isPresent || !isPriorities || dataUsersTable.length == 0
                "
                @click="checkSubmit">
                確 定
              </button>
            </div>
            <div v-if="isError" class="col-12 text-right text-red2">
              {{ messError }}
            </div>
          </div>
        </div>
      </div>
      <div class="wapper-table py-4">
        <div v-if="isPriorities">
          <div class="border">
            <ScrollTableLayout>
              <table class="table table-striped table-border-vertical mb-0">
                <thead>
                  <!-- <tr class="bg-blue3 text-white">
                    <th colspan="3" class="text-center">顧客情報</th>
                    <th colspan="100%" class="pl-5">
                      フォロー担当者名 / 振分可否
                    </th>
                  </tr> -->
                  <tr>
                    <th rowspan="2" class="w-105 text-center">
                      <div class="sorted">優先順位</div>
                    </th>
                    <th rowspan="2" class="w-215 text-center">氏名</th>
                    <th rowspan="2" class="w-215 text-center">フリガナ</th>
                    <th v-if="dataUsersTable.length == 0"></th>
                    <th
                      v-for="item in dataUsersTable"
                      :key="item.user_id"
                      class="text-center">
                      <div class="limit-text">
                        {{ item.name }}
                      </div>
                    </th>
                  </tr>
                  <tr class="bg-grey">
                    <th
                      v-for="(item, index) in dataUsersTable"
                      :key="index"
                      class="text-center">
                      <div class="form-check custom-checkbox pl-0">
                        <input
                          :id="'usercheck' + item.user_id"
                          v-model="item.hasFollow"
                          class="form-check-input mx-auto"
                          type="checkbox"
                          :disabled="!isPresent"
                          @change="checkUsers(item.user_id, item.hasFollow)" />
                        <label :for="'usercheck' + item.user_id"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(itemcustomer, indexcus) in prioritiesCustomer"
                    :id="'tr-' + itemcustomer.new_mail_follow_id"
                    :key="itemcustomer.new_mail_follow_id"
                    :class="{
                      active:
                        itemcustomer.assigned_person.user_id == null &&
                        startCheck == true,
                    }">
                    <td class="text-center">
                      {{ indexcus + 1 }}
                    </td>
                    <td>{{ itemcustomer.customer?.full_name_kanji }}</td>
                    <td>{{ itemcustomer.customer?.full_name_furigana }}</td>
                    <td v-if="dataUsersTable.length == 0"></td>
                    <td
                      v-for="(itemuser, indexuse) in dataUsersTable"
                      :key="itemcustomer.new_mail_follow_id + indexuse"
                      class="text-center"
                      :class="{
                        'bg-green-light':
                          itemcustomer.assigned_person.hasFollow && isPresent,
                      }">
                      <div class="form-check custom-checkbox pl-0">
                        <input
                          :id="
                            'cuscheck-' +
                            itemcustomer.new_mail_follow_id +
                            itemuser.user_id
                          "
                          v-model="itemcustomer.assigned_person.user_id"
                          class="form-check-input mx-auto"
                          type="radio"
                          :name="
                            'cuscheck-' +
                            itemcustomer.new_mail_follow_id +
                            indexcus
                          "
                          :value="itemuser.user_id"
                          :checked="
                            itemuser.user_id ==
                            itemcustomer.assigned_person.user_id
                          "
                          :disabled="itemuser.hasFollow == false || !isPresent"
                          @click="
                            checkToogle(
                              itemcustomer.new_mail_follow_id,
                              itemuser.user_id ==
                                itemcustomer.assigned_person.user_id
                            )
                          " />
                        <label
                          :for="
                            'cuscheck-' +
                            itemcustomer.new_mail_follow_id +
                            itemuser.user_id
                          "></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ScrollTableLayout>
          </div>
          <div class="row align-items-center">
            <div class="col-sm-10 text-center text-sm-right my-3">
              <button
                type="button"
                class="btn btn-cus btn-blue-light m-2 w-105"
                :disabled="
                  !isPresent || !isPriorities || dataUsersTable.length == 0
                "
                @click="checkSubmit">
                確 定
              </button>
            </div>
          </div>
          <div class="pagi-info">
            <p class="m-0">{{ totalPage }}件/ページ</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalConfirmAlert :id="'modalFollow'" :message="messAlert" />
  <ModalConfirmSubmit
    :id="'modalConfirmCheck'"
    :message="messAlert"
    @yes="handleConfirmCheck"
    @no="handleCancelCheck" />
  <ModalConfirmSubmit
    :id="'modalConfirmSubmit'"
    :message="messAlert"
    :isquest="false"
    @yes="handleConfirmSubmit"
    @no="handleCancelSubmit" />
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";

import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import InputDatePicker from "/src/components/utilitys/inputs/InputDatePicker.vue";
import ModalConfirmAlert from "/src/components/modals/ModalConfirmAlert.vue";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2.vue";
import ModalConfirmSubmit from "/src/components/modals/ModalConfirmSubmit.vue";
import { mixinModal } from "/src/mixins";

import {
  getLitsNewMailPropertiesCustomers,
  updateNewMailCustomersList,
} from "/src/service/new-mail.service.js";
import { getUserList } from "/src/service/users.service.js";

export default {
  name: "TabFollow",
  components: {
    InputDatePicker,
    ModalConfirmAlert,
    ModalConfirmSubmit,
    FieldSelect2,
    ScrollTableLayout,
  },
  mixins: [mixinModal],
  props: {
    id: { type: String, default: "nav-2" },
  },
  data() {
    const now = new Date();
    const nowStr = this.formatDate(
      now.toLocaleDateString("ja-JP", { dateStyle: "medium" })
    );
    return {
      dataParams: {
        size: 100,
        new_mail_follow_target_date: nowStr,
        // assigned_person_id: null,
        order_by: "-priority_score,customer_id,new_mail_follow_id",
      },
      numPage: 1,
      totalPage: 0,
      dataUsers: [],
      prioritiesCustomer: [],
      isPriorities: false,
      dataUsersSelect: [],
      dataUsersTable: [],
      followDate: nowStr,
      todayDate: nowStr,
      isPresent: true,
      selectUserId: 0,
      confirmCheck: false,
      confirmSubmit: false,
      msgDefault: "フォロー対象日ではありません。",
      msgOne: "フォロー可能な担当者を選択してください。",
      msgTwo: "チェックが付いている担当者に顧客を均等振り分けします。",
      msgThree: "フォロー担当者を確定します。よろしいですか？",
      messAlert: "",
      isError: false,
      messError: "フォロー担当者が割り当てられていない顧客が存在します。",
      startCheck: false,
      isLoadDate: false,
    };
  },
  watch: {
    followDate() {
      this.changeFollowDate();
    },
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    async getDataFirst() {
      if (!this.isLoadDate) {
        this.getData();
        this.isLoadDate = true;
      }
    },
    async getData() {
      await this.fetchCustomers(this.dataParams);
      await this.fetchUsers();
      if (!this.checkHasFollow() && this.isPresent) {
        this.confirmCheck = true;
        this.autoCheckAction();
      }
    },
    async fetchCustomers(params) {
      await getLitsNewMailPropertiesCustomers(params).then((data) => {
        this.totalPage = data.total;
        this.numPage = data.pages;
        this.prioritiesCustomer = this.convertPriority(data.items);
        this.isPriorities = data.items.length > 0;
        if (data.items.length == 0) {
          this.showModalAlert();
        }
      });
    },
    async fetchUsers() {
      const params = { all: true, follow_available_flag: true };
      const response = await getUserList(params);
      this.dataUsers = await this.convetUsers2(response.data.items);
      await this.loadDataUsersTable();
    },
    convertPriority(data) {
      return data.map((items) => {
        if (items.assigned_person == null) {
          items.assigned_person = {
            follow_available_flag: false,
            user_id: null,
            name: "",
            hasFollow: false,
          };
        } else {
          items.assigned_person.hasFollow = true;
        }
        return items;
      });
    },
    convetUsers(data) {
      return data.map((item) => {
        return {
          user_id: item.user_id,
          name: item.name,
          hasFollow: this.checkUsersFollow(item.user_id),
        };
      });
    },
    convetUsers2(data) {
      return data.map((item) => {
        return {
          user_id: item.user_id,
          name: item.name,
          hasFollow: true,
        };
      });
    },
    convetUsers3(data) {
      let cvuser = data.filter((item) => item.assigned_person.user_id != null);
      return cvuser.map((item) => {
        return {
          user_id: item.assigned_person.user_id,
          name: item.assigned_person.name,
          hasFollow: true,
        };
      });
    },
    checkUsersFollow(userid) {
      return this.prioritiesCustomer.some(
        (itemcus) => itemcus.assigned_person.user_id == userid
      );
    },
    async loadSelectUsers() {
      let startUser = await this.prioritiesCustomer.find(
        (item) => item.assigned_person.user_id != null
      );
      this.dataUsersSelect = await this.dataUsersTable.filter(
        (item) => item.hasFollow == true
      );
      if (
        startUser &&
        this.isPresent &&
        this.findUserTbale(startUser.assigned_person.user_id)
      ) {
        this.selectUserId = startUser.assigned_person.user_id;
      } else if (startUser && this.isPresent) {
        this.selectUserId = this.dataUsersSelect[0].user_id;
      } else if (this.dataUsersSelect.length > 0) {
        this.selectUserId = this.dataUsersSelect[0].user_id;
      } else {
        this.selectUserId = 0;
      }
    },
    loadSelectUsersReCheck() {
      this.dataUsersSelect = this.dataUsersTable.filter(
        (item) => item.hasFollow == true
      );
      if (this.selectUserId == 0 && this.dataUsersSelect.length > 0) {
        this.selectUserId = this.dataUsersSelect[0].user_id;
      } else {
        this.selectUserId;
      }
    },
    async loadDataUsersTable() {
      var cvtuser = await this.convetUsers2(this.dataUsers);
      if (!this.isPresent) {
        cvtuser = await this.convetUsers3(this.prioritiesCustomer);
        this.dataUsersTable = cvtuser.reduce((accumulator, current) => {
          if (!accumulator.find((item) => item.user_id == current.user_id)) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);
      } else {
        // if (this.checkHasFollow()) {
        //   cvtuser = await this.convetUsers(this.dataUsers);
        // }
        this.dataUsersTable = cvtuser;
      }
      this.selectUserId = 0;
      await this.loadSelectUsers();
    },
    autoCheck() {
      if (this.dataUsersSelect.length == 0) {
        this.showModalAlert(this.msgOne);
      } else if (!this.confirmCheck) {
        this.showModalConfirm(this.msgTwo);
      } else if (this.isPresent) {
        this.autoCheckAction();
        this.showAlert("均等振り分けを完了しました。");
      }
    },
    autoCheckAction() {
      let i = 0;
      if (this.selectUserId != 0)
        i = this.dataUsersTable.findIndex(
          (item) => item.user_id == this.selectUserId
        );
      this.prioritiesCustomer.forEach((item) => {
        if (i == this.dataUsersTable.length) i = 0;
        let idfollowid = item.new_mail_follow_id;
        let iduser = this.dataUsersTable[i].user_id;
        if ($("#cuscheck-" + idfollowid + iduser).attr("disabled")) {
          i++;
          if (i == this.dataUsersTable.length) i = 0;
          this.dataUsersTable.forEach(() => {
            iduser = this.dataUsersTable[i].user_id;
            if ($("#cuscheck-" + idfollowid + iduser).attr("disabled")) {
              i++;
              if (i == this.dataUsersTable.length) i = 0;
            }
          });
        }
        iduser = this.dataUsersTable[i].user_id;
        if (this.dataUsersTable[i].hasFollow) {
          item.assigned_person.user_id = iduser;
        }
        i++;
      });
      this.checkFollowId();
      this.confirmCheck = false;
    },
    checkUsers(userid, hasFollow) {
      if (hasFollow == false && this.selectUserId == userid) {
        this.selectUserId = 0;
      }
      this.loadSelectUsersReCheck();
      this.prioritiesCustomer.forEach((item, index) => {
        let elidcustomer = $(
          "#cuscheck-" + item.customer.customer_id + userid + index
        );
        if (
          item.assigned_person.user_id != null &&
          item.assigned_person.user_id == userid
        ) {
          elidcustomer.checked = false;
          item.assigned_person.user_id = null;
        }
        elidcustomer.attr("disabled", !hasFollow);
      });
    },
    checkToogle(new_mail_follow_id, hasFollow) {
      if (hasFollow == true) {
        this.prioritiesCustomer.find((item) => {
          if (item.new_mail_follow_id == new_mail_follow_id) {
            item.assigned_person.user_id = null;
          }
        });
      }
      setTimeout(() => {
        this.checkFollowId();
      }, 50);
    },
    formatDate(date) {
      if (date == null) return "";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    async changeFollowDate() {
      this.startCheck = false;
      this.isError = false;
      this.dataParams.new_mail_follow_target_date = this.followDate;
      this.dataUsersTable = [];
      await this.fetchCustomers(this.dataParams);
      await this.checkTime();
      await this.loadDataUsersTable();
      if (!this.checkHasFollow() && this.isPresent) {
        this.confirmCheck = true;
        this.autoCheckAction();
      }
    },
    checkFollowId() {
      this.prioritiesCustomer = this.prioritiesCustomer.map((items) => {
        if (
          !this.dataUsersTable.some(
            (itemuser) => itemuser.user_id == items.assigned_person.user_id
          )
        )
          items.assigned_person.user_id = null;
        return items;
      });
      if (this.startCheck) {
        this.isError = !!this.prioritiesCustomer.find(
          (item) => item.assigned_person.user_id == null
        );
      }
    },
    checkSubmit() {
      if (this.isPresent) {
        this.startCheck = true;
        this.checkFollowId();
        if (this.isError == false) {
          if (!this.confirmSubmit) {
            this.showModalConfirmSubmit(this.msgThree);
          } else {
            let body = this.prioritiesCustomer.map((item) => {
              return {
                new_mail_follow_target_date: item.new_mail_follow_target_date,
                priority_score: item.priority_score,
                new_mail_follow_id: item.new_mail_follow_id,
                customer_id: item.customer.customer_id,
                assigned_person_id: item.assigned_person.user_id,
              };
            });
            if (body)
              updateNewMailCustomersList(body)
                .then((_) => {
                  // this.loadDataUsersTable();
                  this.prioritiesCustomer.map(
                    (item) => (item.assigned_person.hasFollow = true)
                  );
                  this.loadSelectUsers();
                  this.confirmSubmit = false;
                  this.showAlert("フォロー担当者を登録しました。");
                })
                .catch((error) => {
                  console.log(error);
                  this.showModalAlert("処理に失敗しました");
                });
          }
        }
      }
    },
    checkTime() {
      let todayTime = new Date(this.todayDate).getTime();
      let selectTime = new Date(this.followDate).getTime();
      this.isPresent = todayTime <= selectTime;
    },
    checkHasFollow() {
      return this.prioritiesCustomer.some(
        (items) =>
          items.assigned_person.user_id &&
          this.findUserTbale(items.assigned_person.user_id)
      );
    },
    findUserTbale(userid) {
      return this.dataUsers.some((item) => item.user_id == userid);
    },
    showModalAlert(msg) {
      if (msg) {
        this.messAlert = msg;
      } else {
        this.messAlert = this.msgDefault;
      }
      this.showModal("modalFollow");
    },
    showModalConfirm(msg) {
      this.messAlert = msg;
      this.showModal("modalConfirmCheck");
    },
    handleConfirmCheck() {
      this.confirmCheck = true;
      this.autoCheck();
    },
    handleCancelCheck() {
      this.showAlert("処理を中止しました。");
    },
    showModalConfirmSubmit(msg) {
      this.messAlert = msg;
      this.showModal("modalConfirmSubmit");
    },
    handleConfirmSubmit() {
      this.confirmSubmit = true;
      this.checkSubmit();
    },
    handleCancelSubmit() {
      this.showAlert(" 処理を中止しました。");
    },
  },
};
</script>
