<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="mailPreview">
    <div v-html="mailPreview"></div>
  </div>
</template>

<script>
import DOMPurify from "dompurify";
import { getNewMailHTML } from "/src/service/recommend.service.js";

export default {
  data() {
    return {
      mailPreview: "",
    };
  },
  mounted() {
    this.fetchMailHTML();
  },
  methods: {
    async fetchMailHTML() {
      const dirtyContent = this.$route.query.description || "";
      const description = DOMPurify.sanitize(dirtyContent);
      const res = await getNewMailHTML(this.$route.params.uuid, description);
      const safeHtml = DOMPurify.sanitize(res.data);
      this.mailPreview = safeHtml;
      // Clean the dirtyContent in url
      this.$router.replace({ query: { description } });
    },
  },
};
</script>
