<template>
  <div v-if="showAlways || total > size">
    <PaginateInterface
      :key="`paginate_${page}_${size}_${total}`"
      :pages="pages"
      :size="size"
      :initial-page="page"
      :click-handler="clickHandler" />
    <PaginateInfo :page="page" :size="size" :total="total" />
  </div>
</template>

<script>
import PaginateInterface from "./PaginateInterface.vue";
import PaginateInfo from "./PaginateInfo.vue";

export default {
  components: {
    PaginateInterface,
    PaginateInfo,
  },
  props: {
    showAlways: { type: Boolean, default: false },
    size: { type: Number, default: 50 },
    total: { type: Number, default: 0 },
    page: { type: Number, default: 1 },
    pages: { type: Number, default: 1 },
    clickHandler: { type: Function, default: null },
  },
  data() {
    return {};
  },
};
</script>
