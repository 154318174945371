import axios from "axios";
import { TOP_FOLLOW_HISTORIES } from "./const.service.js";

const getTopFollowHistories = async (param) => {
  try {
    const response = await axios.get(TOP_FOLLOW_HISTORIES, {
      params: param,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export { getTopFollowHistories };
