<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-3 my-2">
      <span class="form-title" :class="color">{{ label }}</span>
      <span v-if="compulsory" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-9 my-2">
      <select v-model="mValue" class="form-control">
        <option
          v-for="(kvalue, key) in optionConsts"
          :key="key"
          :value="kvalue">
          {{ key }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: "FieldSelect",
  props: {
    optionConsts: { type: Object, default: () => {} },
    color: { type: String, default: "bg-blue-light" },
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    compulsory: { type: Boolean, default: false },
  },
  computed: {
    mValue: {
      get() {
        return this.value;
      },
      set(mValue) {
        this.$emit("update:value", mValue);
      },
    },
  },
};
</script>
