<template>
  <div class="wrapper single-page">
    <div class="content-wrapper">
      <section>
        <h3 class="card-page-title mt-4 mb-0 mx-0 mx-md-2">
          <img
            src="@/assets/img/property-feat/feat-foot-title.png"
            alt="title"
            class="img-fluid" />
        </h3>
        <div class="container-fluid">
          <div class="card mb-0">
            <div class="card-body minh-520 p-0">
              <div class="wrap-ct border-0">
                <HouseHeader
                  :data="data"
                  :text-button="textButton"
                  :building-number="buildingNumber" />
                <HouseImage :data="data" />
                <HouseIcon :data="data" />
                <OldHouseTable :data="data" :schedule="schedule" />
                <HouseBottom :data="data" />
              </div>
              <HouseFooter />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import HouseHeader from "/src/components/recommend-properties/commons/HouseHeader.vue";
import HouseImage from "/src/components/recommend-properties/commons/HouseImage.vue";
import HouseIcon from "/src/components/recommend-properties/commons/HouseIcon.vue";
import OldHouseTable from "/src/components/recommend-properties/commons/OldHouseTable.vue";
import HouseBottom from "/src/components/recommend-properties/commons/HouseBottom.vue";
import HouseFooter from "/src/components/recommend-properties/commons/HouseFooter.vue";

export default {
  components: {
    HouseHeader,
    HouseImage,
    HouseIcon,
    OldHouseTable,
    HouseBottom,
    HouseFooter,
  },
  props: {
    data: { type: Object, default: null },
    textButton: { type: String, default: "" },
    schedule: { type: Object, default: null },
    buildingNumber: { type: String, default: null },
  },
};
</script>
