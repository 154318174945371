<template>
  <div class="row mb-4">
    <div class="col-md-4">
      <h3 class="card-title-cus mb-3">{{ title }}</h3>
    </div>
    <div class="col-md-8">
      <div class="text-right">
        <button
          class="btn btn-cus btn-blue-light px-3 m-2 my-sm-0"
          @click="goBack">
          問合せ・来場予約一覧</button
        ><button
          class="btn btn-cus btn-blue-light px-3 m-2 my-sm-0"
          @click="nextPage(data)">
          履歴一覧</button
        ><button
          class="btn btn-cus btn-blue-light px-3 m-2 my-sm-0"
          @click="goBackHistory">
          戻る
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "data"],
  methods: {
    goBack() {
      const currentPath = this.$route.fullPath;
      var parts = currentPath.split("/");
      var newUrl = parts.slice(0, parts.length - 1).join("/");
      this.$router.replace(newUrl);
    },
    goBackHistory() {
      this.$router.go(-1);
    },
    nextPage(data) {
      this.$router.push({
        path: `/customer/${data.customer.customer_id}/actions`,
      });
    },
  },
};
</script>
