<template>
  <nav
    class="main-header navbar navbar-expand navbar-white navbar-light custom-main-header">
    <ul class="navbar-nav">
      <li class="nav-item d-lg-none">
        <a
          class="nav-link btn-menu"
          data-widget="pushmenu"
          href="#"
          role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
      <li class="nav-item d-sm-none">
        <a class="nav-link pt-1" href="#!">
          <img src="@/assets/img/logo.png" alt="logo" height="28" />
        </a>
      </li>
      <li
        v-for="(row, index) in breadcrumbs"
        :key="index"
        class="nav-item d-none d-sm-inline-block">
        <span class="nav-link" style="cursor: default">{{ row.title }}</span>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "SidebarTop",
  computed: {
    breadcrumbs() {
      const breadcrumbs = this.$route.meta.breadcrumbs || [];
      if (breadcrumbs.length === 0)
        console.error("You must set breadcrumbs in `router.js`");
      return breadcrumbs;
    },
  },
};
</script>
