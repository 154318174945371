import _ from "lodash";
import { mapState, mapActions } from "vuex";
import { textNormalize } from "/src/service/users.service.js";
import { PROPERTY_TYPE } from "/src/frontend/src/constants.js";
import { isValidItem } from "/src/components/customers/action/tabs/utils.js";
const { userMessage, apiMessage } = require("/src/assets/validation.json");
const errorBlankMessage = userMessage.REQUIRED;

import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import FormLayout from "/src/components/customers/action/form-fields/FormLayout.vue";
import PropertyAndBlockLayout from "/src/components/customers/action/form-fields/PropertyAndBlockLayout.vue";
import CustomerName from "/src/components/customers/action/form-fields/CustomerName.vue";
import SelectTime from "/src/components/customers/action/form-fields/SelectTime.vue";
import SelectUser from "/src/components/customers/action/form-fields/SelectUser.vue";
import RadioAssetType from "/src/components/customers/action/form-fields/RadioAssetType.vue";
import SelectProperty from "/src/components/customers/action/form-fields/SelectProperty.vue";
import SelectBlock from "/src/components/customers/action/form-fields/SelectBlock.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import FunctionalButton from "/src/components/utilitys/buttons/FunctionalButton.vue";

import $ from "jquery";

const customerActionHistoryMixin = {
  components: {
    BaseLayout,
    FormLayout,
    PropertyAndBlockLayout,
    CustomerName,
    SelectTime,
    SelectUser,
    RadioAssetType,
    SelectProperty,
    SelectBlock,
    ModalConfirm,
    FunctionalButton,
  },
  props: {
    modalMsg: { type: Object, required: true },
    title: { type: String, required: true },
    initCustomerId: { type: Number, default: null },
  },
  emits: ["confirm-submit", "confirm-cancel"],
  data() {
    return {
      dataParam: {
        userId: null,
        typeAsset: PROPERTY_TYPE.PROPERTY,
        propertyId: null,
        blockId: null,
        propertyName: null,
        blockName: null,
      },
      errorMsg: {},
      clickedButton: "cancel", // "submit" or "cancel"
      customerName: "",
      customerId: this.initCustomerId,
      original: null,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    ...mapState("loading", ["isLoading"]),
    isSalesForce() {
      return this.dataParam.typeAsset == PROPERTY_TYPE.SALESFORCE_PROPERTY;
    },
    requestBody() {
      // In your component,
      // overwrite this computed to modified the request body send to API
      return {};
    },
    isChanged() {
      return !_.isEqual(this.original, this.dataParam);
    },
  },
  watch: {
    isLoading(newVal) {
      // Store original data when page are loaded
      if (!this.original && !newVal) this.original = { ...this.dataParam };
    },
  },
  created() {
    // Auto assign to current user
    this.dataParam.userId = this.user_object?.user_id;
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    showClosedMessage() {
      this.showAlert(apiMessage.ABORT_PROCESS);
    },
    assignCommon(responseData) {
      this.dataParam.typeAsset = responseData.property_table_type;
      const { user, property, customer } = responseData;
      this.customerId = customer?.customer_id;
      this.customerName = customer?.full_name_kanji;
      this.dataParam.userId = user?.user_id ?? null;
      if (property) {
        this.dataParam.propertyId = property.property_id;
        this.dataParam.blockId = responseData.block_id;
      } else {
        this.dataParam.propertyName = responseData.property_name;
        this.dataParam.blockName = responseData.block_name;
      }
    },
    jTextNormalize(dataKey) {
      if (this.dataParam[dataKey])
        this.dataParam[dataKey] = textNormalize(this.dataParam[dataKey]);
    },
    isValidForm() {
      // In your component,
      // overwrite this method to perform the form check
      return false;
    },
    checkAndHideError(dataKey, errorKey) {
      if (this.dataParam[dataKey]) this.errorMsg[errorKey] = "";
    },
    validateField(dataKey, errorKey) {
      if (!this.dataParam[dataKey]) {
        this.errorMsg[errorKey] = errorBlankMessage;
        return false;
      }
      this.errorMsg[errorKey] = "";
      return true;
    },
    handleBtnSubmit(confirmModalId) {
      this.clickedButton = "submit";
      if (this.isValidForm()) $(`#${confirmModalId}`).modal("show");
    },
    handleBtnCancel() {
      this.clickedButton = "cancel";
    },
    handleConfirm() {
      if (this.clickedButton === "submit")
        this.$emit("confirm-submit", this.requestBody);
      if (this.clickedButton === "cancel")
        this.$emit("confirm-cancel", this.requestBody);
    },
    canEditItem(item) {
      const tableType = item.property_table_type;
      const location = item.property?.location_of_original_data;
      if (this.$route.name === "contract-update") {
        return isValidItem(tableType, location);
      }
      if (this.$route.name === "history-update") {
        const actionType = item.action_type;
        return actionType === 0 && isValidItem(tableType, location);
      }
      return false;
    },
  },
};

export default customerActionHistoryMixin;
