<template>
  <div>
    <TabTableLayout
      v-model="saleFollowHistory"
      table-label="フォロー履歴"
      :nav-button="{ text: 'フォロー履歴登録', routeParam: paramTLI07008 }"
      :fetch-func="fetchData()">
      <thead>
        <tr class="text-nowrap text-center">
          <th class="w-215">日付</th>
          <th class="w-300">担当者名</th>
          <th>接触方法</th>
          <th class="w-215">紹介物件</th>
          <th>コメント</th>
          <th class="w-84">編集</th>
          <th class="w-84">削除</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in saleFollowHistory.items"
          :key="`tr_sale_follow_${index}`">
          <td class="text-nowrap">
            <router-link :to="screenDetail(item)" class="txt-underline">
              {{ formatDate(item.follow_date) }}
            </router-link>
          </td>
          <td>{{ func.displayValue(item.user?.name) }}</td>
          <td class="text-nowrap">
            {{ func.displayValue(item.contact_method) }}
          </td>
          <td>{{ getPropertyName(item) }}</td>
          <td class="pre-wrap">{{ getComment(item) }}</td>
          <td>
            <FunctionalButton
              class="btn-blue-light"
              @click="goToEditSaleFollowHistory(item)">
              <i class="fas fa-pen"></i>
            </FunctionalButton>
          </td>
          <td>
            <FunctionalButton
              class="btn-pink btn-icon"
              @click="deleteSaleFollowHistory(item)">
              <i class="fas fa-trash"></i>
            </FunctionalButton>
          </td>
        </tr>
      </tbody>
    </TabTableLayout>
  </div>
</template>

<script>
import { getSaleFollowHistories } from "/src/service/sales-follow-histories.service.js";

import FunctionalButton from "/src/components/utilitys/buttons/FunctionalButton.vue";
import TabTableLayout from "./TabTableLayout.vue";

export default {
  components: {
    FunctionalButton,
    TabTableLayout,
  },
  data() {
    return {
      saleFollowHistory: {},
      paramTLI07008: { name: "add-sales-follow" },
    };
  },
  methods: {
    formatDate(dateStr) {
      return this.func.formatDate(dateStr, { dateStyle: "long" });
    },
    fetchData() {
      return getSaleFollowHistories;
    },
    getPropertyName(item) {
      for (let i = 1; i <= 5; i++) {
        const val = item[`property_name_${i}`];
        if (val) return val;
      }
      return "";
    },
    getComment(item) {
      const N = 150;
      const comment = item.comment || "";
      return comment.length > N ? `${comment.slice(0, N)}…` : comment;
    },
    deleteSaleFollowHistory(item) {
      this.$emit("emit-delete", {
        message: "指定したフォロー履歴を削除します。",
        id: item.history_id,
        apiDelete: "deleteSaleFollowHistory",
      });
    },
    screenDetail(item) {
      return {
        name: "detail-sales-follow",
        params: { history_id: item.history_id },
      };
    },
    goToEditSaleFollowHistory(item) {
      this.$router.replace({
        name: "update-sales-follow",
        params: { history_id: item.history_id },
      });
    },
  },
};
</script>
