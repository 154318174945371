<template>
  <InquiryDetail
    v-if="isShowInquiryDetail"
    :data="data"
    :title="titleInquiry" />
  <InquiryDetailResevation
    v-if="isShowInquiryDetailResevation"
    :data="data"
    :title="titleInquiry" />
</template>

<script>
import InquiryDetail from "/src/views/inquiry-reservation/InquiryDetail.vue";
import InquiryDetailResevation from "/src/views/inquiry-reservation/InquiryDetailResevation.vue";
import { getInquiryDetail } from "/src/service/inquiry.service.js";
export default {
  components: {
    InquiryDetail,
    InquiryDetailResevation,
  },
  data() {
    return {
      data: {},
      titleInquiry: "",
      isShowInquiryDetail: false,
      isShowInquiryDetailResevation: false,
    };
  },
  created() {
    const inquiryId = this.getInquiryId();
    getInquiryDetail(inquiryId).then((data) => {
      try {
        this.data = data.data;
        if (data.data.inquiry_classification == 1) {
          this.isShowInquiryDetailResevation = true;
          this.titleInquiry = "来場予約詳細";
        } else {
          this.isShowInquiryDetail = true;
          this.titleInquiry = "問合せ詳細";
        }
      } catch {
        this.isShowInquiryDetail = true;
      }
    });
  },
  methods: {
    getInquiryId() {
      const url = window.location.href;
      const parts = url.split("/");
      const uuid = parts[parts.length - 1];
      return uuid;
    },
    reloadData() {
      const inquiryId = this.getInquiryId();
      getInquiryDetail(inquiryId).then((data) => {
        this.data = data.data;
        if (data.data.inquiry_classification == 1) {
          this.isShowInquiryDetailResevation = true;
          this.titleInquiry = "来場予約詳細";
        } else {
          this.isShowInquiryDetail = true;
          this.titleInquiry = "問合せ詳細";
        }
      });
    },
  },
};
</script>
