<template>
  <div>
    <div class="text-nowrap">{{ timePart[0] }}</div>
    <div>{{ timePart[1] }}</div>
  </div>
</template>

<script>
export default {
  props: {
    dateTimeStr: { type: String, default: null },
  },
  computed: {
    timePart() {
      const result = this.func
        .formatDate(this.dateTimeStr, { dateStyle: "long", timeStyle: "short" })
        .split(" ");
      if (result.length === 2) return result;
      return ["", ""];
    },
  },
};
</script>
