<template>
  <div v-if="isEditable" class="col-12 my-3">
    <div class="text-center">
      <Button
        class="btn-blue-light px-3 m-2"
        :disabled="isLoading"
        :text="desireStopFlagInfo.buttonText"
        data-toggle="modal"
        :data-target="`#${desireStopFlagInfo.modalTargetId}`" />
    </div>
    <ModalReasonStopRecommend
      id="reason-stop-recommend-modal"
      v-model="stopRecommend.newFlag"
      next-modal-id="confirm-modal" />
    <ModalUpdateFlag
      id="confirm-modal"
      :stop-flag-info="desireStopFlagInfo"
      @reload-data="$emit('reload-data')" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { USER_ROLE } from "@/constants.js";
import { rcmStopFlag } from "/src/helpers/select-options.js";
const { apiMessage } = require("/src/assets/validation.json");

import Button from "/src/components/utilitys/buttons/Button.vue";
import ModalUpdateFlag from "../ModalUpdateFlag.vue";
import ModalReasonStopRecommend from "../ModalReasonStopRecommend.vue";

export default {
  components: {
    Button,
    ModalUpdateFlag,
    ModalReasonStopRecommend,
  },
  props: {
    customerData: { type: Object, default: null },
  },
  data() {
    return {
      // Text change follow customer.recommend_stop_flag
      resumeRecommend: {
        modalTargetId: "confirm-modal",
        buttonText: "レコメンド再開",
        message: "レコメンドを再開します。よろしいですか？",
        yesResponse: "レコメンドを再開しました。",
        noResponse: apiMessage.ABORT_PROCESS,
        newFlag: rcmStopFlag.DO_NOT_EXCLUDE.value,
      },
      stopRecommend: {
        modalTargetId: "reason-stop-recommend-modal",
        buttonText: "レコメンドメール停止",
        message: "レコメンドを停止します。よろしいですか？",
        yesResponse: "レコメンドを停止しました。",
        noResponse: apiMessage.ABORT_PROCESS,
        newFlag: rcmStopFlag.EXCLUDE_OTHER_WEB.value,
      },
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    ...mapState("loading", ["isLoading"]),
    customer() {
      return { ...this.customerData };
    },
    desireStopFlagInfo() {
      return !this.customer.recommend_stop_flag
        ? this.stopRecommend
        : this.resumeRecommend;
    },
    isEditable() {
      return [USER_ROLE.EDITOR, USER_ROLE.ADMIN].includes(
        this.user_object.role
      );
    },
  },
};
</script>
