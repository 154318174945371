const prefecturesOfJapan = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

const rcmStopFlag = {
  DO_NOT_EXCLUDE: { value: 0, text: "" },
  EXCLUDE_OTHER_WEB: { value: 1, text: "顧客要望による停止" },
  EXCLUDE_COUPLE_WEB: { value: 2, text: "配偶者などの契約による停止" },
  EXCLUDE_COUPLE_BATCH: { value: 3, text: "" },
};

const contactMethods = ["TELフォロー", "TEL不通・メールフォロー"];

const contactResults = [
  { value: 1, label: "自社受託送客" },
  { value: 2, label: "他社送客" },
  { value: 3, label: "仲介送客" },
  { value: 4, label: "継続フォロー" },
  { value: 5, label: "他決・購入中止(フォロー不要)" },
  { value: 6, label: "反響済み" },
  { value: 0, label: "その他" },
];

export { prefecturesOfJapan, rcmStopFlag, contactMethods, contactResults };
