import axios from "axios";
import {
  NEW_MAILS,
  NEW_MAIL_CONTENT,
  NEW_MAIL_FOLLOW_PRIORITY_CUSTOMER,
  NEW_MAIL_CONTENT_REPORT,
  NEW_MAILS_DELETE,
} from "./const.service.js";

const updateNewMailCustomersList = async (body) => {
  if (body.new_mail_follow_id) {
    throw "new_mail_follow_id not match";
  }
  return axios.put(NEW_MAIL_FOLLOW_PRIORITY_CUSTOMER, body).then((response) => {
    return response;
  });
};
const getNewMails = async (param) => {
  const response = await axios.get(NEW_MAILS, {
    params: param,
  });
  return response;
};
const getNewMailContent = async (param) => {
  const response = await axios.get(NEW_MAIL_CONTENT, {
    params: param,
  });
  return response;
};

const updateNewMailContent = async (body) => {
  return axios.put(NEW_MAIL_CONTENT, body);
};

const getLitsNewMailPropertiesCustomers = async (param) => {
  try {
    const response = await axios.get(NEW_MAIL_FOLLOW_PRIORITY_CUSTOMER, {
      params: param,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const getListMailContent = async (param) => {
  try {
    const response = await axios.get(NEW_MAIL_CONTENT_REPORT, {
      params: param,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const createNewMails = async (body) => {
  return axios.post(NEW_MAILS, body);
};

const deleteNewMail = async (body) => {
  return axios.post(NEW_MAILS_DELETE, body);
};

const sendFreeNewMails = async (body) => {
  return axios.post(NEW_MAILS + "/send_free_mail", body);
};

export {
  updateNewMailCustomersList,
  getNewMails,
  getNewMailContent,
  updateNewMailContent,
  getLitsNewMailPropertiesCustomers,
  getListMailContent,
  createNewMails,
  deleteNewMail,
  sendFreeNewMails,
};
