<template>
  <div class="custom-tab">
    <nav>
      <div id="nav-tab" class="nav nav-tabs" role="tablist">
        <a
          v-for="(item, index) in navTabs"
          :id="`nav-${index}-tab`"
          :key="index"
          class="nav-item nav-link"
          :class="{ active: index === 0 }"
          :href="`#nav-${index}`"
          :aria-controls="`nav-${index}`"
          :aria-selected="index === 0"
          data-toggle="tab"
          role="tab">
          {{ item }}
        </a>
      </div>
    </nav>
    <div id="nav-tabContent" class="tab-content">
      <TabPanel id="nav-0" class="show active" :rows="infoRows" />
      <TabPanel id="nav-1" :rows="desireRows" />
      <TabPanel id="nav-2" :rows="recommendInfoRows" />
      <TabFooter
        :customer-data="customerData"
        @reload-data="$emit('reload-data')" />
    </div>
  </div>
</template>

<script>
import TabPanel from "./tabs/TabPanel.vue";
import TabFooter from "./tabs/TabFooter.vue";

export default {
  components: {
    TabPanel,
    TabFooter,
  },
  props: {
    customerData: { type: Object, default: null },
  },
  data() {
    return {
      navTabs: ["顧客情報", "希望情報", "レコメンド情報"],
    };
  },
  computed: {
    customer() {
      return { ...this.customerData };
    },
    infoRows() {
      return [
        {
          label: "現住所-最寄り駅",
          value: this.customer.address_station,
        },
        {
          label: "現在の住まい-住宅区分",
          value: this.customer.selling_property_housing_type,
        },
        {
          label: "現在の住まい-持家区分",
          value: this.customer.selling_property_occupied_property_category,
        },
        {
          label: "家族数",
          value: this.customer.family_size,
        },
        {
          label: "家族構成区分",
          value: this.customer.family_composition,
        },
        {
          label: "家族構成区分（その他）",
          value: this.customer.family_composition_other,
        },
        {
          label: "勤務先名称（本人）",
          value: this.customer.working_company_name,
        },
        {
          label: "勤務先最寄り駅（本人）",
          value: this.customer.working_company_nearest_station,
        },
        {
          label: "本人年収（万円）",
          value: this.func.formatNumber(this.customer.annual_income),
        },
        {
          label: "合算者年収（万円）",
          value: this.func.formatNumber(
            this.customer.annual_income_free_summed_person
          ),
        },
      ];
    },
    desireRows() {
      return [
        {
          label: "予算（万円）",
          value: this.func.formatNumber(this.customer.budget_limit),
        },
        {
          label: "自己資金（万円）",
          value: this.func.formatNumber(this.customer.own_resources),
        },
        {
          label: "希望面積下限",
          value: this.displayArea(this.customer.demand_area_min),
        },
        {
          label: "希望面積上限",
          value: this.displayArea(this.customer.demand_area_max),
        },
        { label: "希望間取り1", value: this.customer.demand_floor_plans1_txt },
        { label: "希望間取り2", value: this.customer.demand_floor_plans2_txt },
        { label: "駐車場希望有無", value: this.customer.demand_parking_lot },
        {
          label: "購入目的1",
          value: this.customer.purchase_purpose_1,
        },
        {
          label: "購入目的2",
          value: this.customer.purchase_purpose_2,
        },
        {
          label: "購入目的3",
          value: this.customer.purchase_purpose_3,
        },
        {
          label: "購入目的-その他",
          value: this.customer.purchase_purpose_other,
        },
        {
          label: "ご購入検討の理由1",
          value: this.customer.purchase_consideration_reason_1,
        },
        {
          label: "ご購入検討の理由2",
          value: this.customer.purchase_consideration_reason_2,
        },
        {
          label: "ご購入検討の理由3",
          value: this.customer.purchase_consideration_reason_3,
        },
        {
          label: "ご購入検討の理由（その他）",
          value: this.customer.purchase_consideration_reason_other,
        },
      ];
    },
    recommendInfoRows() {
      return [
        {
          label: "現在の担当者",
          value: this.customer.latest_user_followed?.name,
        },
        {
          label: "無効フラグ",
          value: this.customer.recommend_disabled_flag ? "✓" : " ",
        },
        {
          label: "メール停止",
          value: this.customer.recommend_stop_flag ? "✓" : " ",
        },
        {
          label: "メール停止処理日時",
          value: toJST(this.customer.recommend_stopped_at),
        },
        {
          label: "メール停止処理担当者",
          value: this.customer.recommend_stopped_by_user?.name,
        },
      ];
    },
  },
  methods: {
    displayArea(number) {
      return typeof number === "number"
        ? this.func.formatNumber(number, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " m²"
        : number;
    },
  },
};

/**
 * Converts a UTC date and time string to a formatted Japan Standard Time (JST) string.
 *
 * @param {string} dateTimeStr - The UTC date and time string in ISO 8601 format (no time zone)
 * @returns {string} The formatted JST date and time string in the format: yyyy年mm月dd日 hh:mm
 */
const toJST = function (dateTimeStr) {
  if (!dateTimeStr) return "";

  const dateTime = new Date(dateTimeStr + "+00:00"); // UTC time
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Use 24-hour format
    timeZone: "Asia/Tokyo", // GMT+9 Japan timezone
  };
  const jstStr = dateTime.toLocaleString("ja-JP", options); // Output yyyy/mm/dd hh:mm:ss
  const [datePart, timePart] = jstStr.split(" ");
  const [year, month, day] = datePart.split("/");
  const [hour, min, _] = timePart.split(":");
  return `${year}年${month}月${day}日 ${hour}:${min}`;
};
</script>
