<template>
  <div
    id="nav-1"
    class="tab-pane fade active show"
    role="tabpanel"
    aria-labelledby="nav-1-tab">
    <div
      v-if="selfCompany && user_object.role != USER_ROLE.VIEWER"
      class="mx-2 mx-md-4 py-4">
      <div class="d-sm-flex justify-content-between">
        <button class="btn btn-cus btn-blue-light m-2" @click="uploadFile">
          不足項目ファイルアップロード
        </button>
        <button
          class="btn btn-cus btn-blue-light m-2"
          @click="downloadExcelFile">
          不足項目ファイルダウンロード
        </button>
      </div>
    </div>
    <div
      v-else-if="user_object.role != USER_ROLE.VIEWER"
      class="mx-2 mx-md-4 py-4 text-right">
      <button
        class="btn btn-cus btn-blue-light"
        @click="
          $router.push({
            name: 'other-block-registry-detail',
            params: { property_id: property.property_id },
          })
        ">
        区画新規登録
      </button>
    </div>
    <div class="wapper-table">
      <ScrollTableLayout>
        <table class="table table-striped">
          <thead>
            <tr class="text-nowrap text-center">
              <th>棟番号</th>
              <th class="minw-145">
                <div class="sorted">
                  区画名
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: blockNameDesc == false }"
                      @click="sorting('block_name', false)"
                      ><i class="fas fa-caret-up"></i></span
                    ><span
                      class="sorting_desc"
                      :class="{ active: blockNameDesc == true }"
                      @click="sorting('block_name', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th class="minw-145">
                <div class="sorted">
                  タイプ
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: blockTypeDesc == false }"
                      @click="sorting('block_type', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: blockTypeDesc == true }"
                      @click="sorting('block_type', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th>
                <div class="sorted">
                  間取り
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: layoutTypeDesc == false }"
                      @click="sorting('layout_type', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: layoutTypeDesc == true }"
                      @click="sorting('layout_type', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th>居住専有</th>
              <th v-if="selfCompany">
                <div class="sorted">
                  バルコニー
                  <br />面積
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: balconyAreaDesc == false }"
                      @click="sorting('balcony_area', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: balconyAreaDesc == true }"
                      @click="sorting('balcony_area', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th>階数</th>
              <th v-if="selfCompany">販売期</th>
              <th v-if="selfCompany">
                <div class="sorted">
                  税込総額 <br />（販売価格）
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: priceDesc == false }"
                      @click="sorting('price', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: priceDesc == true }"
                      @click="sorting('price', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th v-else>
                <div class="sorted">
                  価格
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: priceDesc == false }"
                      @click="sorting('price', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: priceDesc == true }"
                      @click="sorting('price', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th v-if="selfCompany">プレ価格</th>
              <th v-if="selfCompany">
                <div class="sorted">
                  管理費
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: managementFeeDesc == false }"
                      @click="sorting('management_fee', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: managementFeeDesc == true }"
                      @click="sorting('management_fee', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th v-if="selfCompany">
                <div class="sorted">
                  修繕積 <br />立金
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: repairReserveFeeDesc == false }"
                      @click="sorting('repair_reserve_fee', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: repairReserveFeeDesc == true }"
                      @click="sorting('repair_reserve_fee', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th>
                <div class="sorted">
                  間取り図 <br />登録
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: blockImageLinkDesc == false }"
                      @click="sorting('block_image_link', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: blockImageLinkDesc == true }"
                      @click="sorting('block_image_link', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th>
                <div class="sorted">
                  レコメンド <br />対象除外
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: recommendStopFlagDesc == false }"
                      @click="sorting('recommend_stop_flag', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: recommendStopFlagDesc == true }"
                      @click="sorting('recommend_stop_flag', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th>
                <div class="sorted">
                  ステータス
                  <div class="sorting">
                    <span
                      class="sorting_asc"
                      :class="{ active: sellingStatusDesc == false }"
                      @click="sorting('selling_status', false)"
                      ><i class="fas fa-caret-up"></i
                    ></span>
                    <span
                      class="sorting_desc"
                      :class="{ active: sellingStatusDesc == true }"
                      @click="sorting('selling_status', true)"
                      ><i class="fas fa-caret-down"></i
                    ></span>
                  </div>
                </div>
              </th>
              <th>紹介画面<br />プレビュー</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="block in blocks" :key="block">
              <td>{{ property.building_number }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'property-block',
                    params: { block_id: block.block_id },
                  }"
                  class="txt-underline"
                  >{{ block.block_name }}</router-link
                >
              </td>
              <td>{{ block.block_type }}</td>
              <td>
                {{ func.convertEnum(block.layout_type, BLOCK_LAYOUT_TYPE) }}
              </td>
              <td class="text-right">
                {{
                  func.showValue(
                    block.area
                      ? func.formatNumber(block.area, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : func.formatNumber(block.area),
                    {
                      suffix: "㎡",
                      includeZero: true,
                    }
                  )
                }}
              </td>
              <td v-if="selfCompany">
                {{
                  func.showValue(
                    block.balcony_area
                      ? func.formatNumber(block.balcony_area, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : func.formatNumber(block.balcony_area),
                    {
                      suffix: "㎡",
                      includeZero: true,
                    }
                  )
                }}
              </td>
              <td class="text-right">
                {{ func.showValue(block.floor_number, { suffix: "階" }) }}
              </td>
              <td v-if="selfCompany">{{ block.supply_period }}</td>
              <td class="text-right">
                {{
                  func.showValue(
                    func.formatNumber(func.convertAndRoundToMY(block.price)),
                    {
                      suffix: "万円",
                      includeZero: true,
                    }
                  )
                }}
              </td>
              <td v-if="selfCompany" class="text-right">
                {{
                  func.showValue(
                    func.formatNumber(
                      func.convertAndRoundToMY(block.pre_price)
                    ),
                    {
                      suffix: "万円",
                      includeZero: true,
                    }
                  )
                }}
              </td>
              <td v-if="selfCompany" class="text-right">
                {{
                  func.showValue(func.formatNumber(block.management_fee), {
                    suffix: "円",
                  })
                }}
              </td>
              <td v-if="selfCompany" class="text-right">
                {{
                  func.showValue(func.formatNumber(block.repair_reserve_fee), {
                    suffix: "円",
                  })
                }}
              </td>
              <td>{{ block.block_image_link ? "済み" : "未" }}</td>
              <td>{{ block.recommend_stop_flag ? "✓" : "" }}</td>
              <td>{{ block.selling_status }}</td>
              <td class="py-2">
                <a
                  :href="referencePageURL(block.reference_page_url)"
                  target="_blank">
                  <img
                    src="@/assets/img/icons/ic-eye.png"
                    alt="icon"
                    class="img-fluid" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </ScrollTableLayout>
      <PaginateInterface
        v-if="blocks.length > 0"
        :key="blocks"
        :size="size"
        :pages="pages"
        :initial-page="initialPage"
        :click-handler="updateQuery" />
      <PaginateInfo
        v-if="blocks.length > 0"
        :key="blocks"
        :total="total"
        :page="page"
        :size="size" />
    </div>
  </div>
  <ModalConfirm
    id="confirmSubmitFile"
    message="不足項目ファイルをアップロードして、データベースを更新します。"
    @no="abortSubmitForm"
    @yes="submitFile" />
</template>
<script>
import $ from "jquery";
import PaginateInterface from "../commons/PaginateInterface.vue";
import PaginateInfo from "../commons/PaginateInfo.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import {
  getPropertyBlocks,
  downloadPropertyBlocks,
} from "/src/service/properties.service.js";
import { mapState } from "vuex";
import { USER_ROLE } from "/src/constants";
import { BLOCK_LAYOUT_TYPE } from "/src/constants";
import { uploadPropertyBlocksExcel } from "/src/service/properties.service.js";
import { checkNested } from "/src/service/utility.service.js";
const { apiMessage } = require("/src/assets/validation.json");
export default {
  components: {
    PaginateInterface,
    PaginateInfo,
    ModalConfirm,
    ScrollTableLayout,
  },
  props: {
    trigger: {
      type: Number,
      required: true,
    },
    property: {
      type: Object,
      required: true,
    },
    selfCompany: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blocks: [],
      total: 0,
      page: 1,
      size: 50,
      pages: 1,
      initialPage: 1,
      USER_ROLE,
      fileExcelData: "",
      order_by: "",
      formData: null,
      inputElement: null,
      desc: null,
      BLOCK_LAYOUT_TYPE,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    blockNameDesc() {
      return this.fieldDesc("block_name");
    },
    blockTypeDesc() {
      return this.fieldDesc("block_type");
    },
    balconyAreaDesc() {
      return this.fieldDesc("balcony_area");
    },
    layoutTypeDesc() {
      return this.fieldDesc("layout_type");
    },
    priceDesc() {
      return this.fieldDesc("price");
    },
    managementFeeDesc() {
      return this.fieldDesc("management_fee");
    },
    repairReserveFeeDesc() {
      return this.fieldDesc("repair_reserve_fee");
    },
    blockImageLinkDesc() {
      return this.fieldDesc("block_image_link");
    },
    recommendStopFlagDesc() {
      return this.fieldDesc("recommend_stop_flag");
    },
    sellingStatusDesc() {
      return this.fieldDesc("selling_status");
    },
  },
  watch: {
    trigger() {
      this.parseUrl();
      this.fetchData(this.page);
    },
  },
  methods: {
    parseUrl() {
      // initial param from url
      const { block_page, block_order_by } = this.$route.query;
      this.page = parseInt(block_page) || this.page;
      this.order_by = block_order_by || this.order_by;
      this.desc = this.order_by[0] === "-";
    },
    updateQuery(page) {
      this.page = page;
      const query = {
        ...this.$route.query,
        block_page: this.page,
        block_order_by: this.order_by,
        reload: "block",
      };
      for (const k in query) if (!query[k]) delete query[k];
      if (query.block_page === 1) delete query.block_page;

      this.$router.replace({ query: query });
    },
    sorting(field, desc) {
      this.order_by = field;
      this.desc = desc;
      if (desc) {
        this.order_by = "-" + field;
      }
      if (this.order_by.endsWith("min")) {
        let max_field = this.order_by;
        max_field = max_field.slice(0, -3) + "max";
        this.order_by = this.order_by + "," + max_field;
      }
      this.updateQuery(1);
    },
    fieldDesc(fieldName) {
      if (this.order_by.includes(fieldName)) {
        return this.desc ? true : false;
      }
      return null;
    },
    referencePageURL(uuid) {
      let url = this.$router.resolve({
        name: "property-reference",
        params: { uuid: uuid },
      });
      return url.href;
    },
    fetchData(page) {
      this.page = page;
      this.initialPage = page;
      const params = {
        page: this.page,
        size: this.size,
        property_id: this.$route.params.id,
        order_by: this.order_by,
      };
      getPropertyBlocks(params).then((res) => {
        this.total = res.data["total"];
        this.blocks = res.data["items"];
        this.pages = res.data["pages"];
      });
    },
    downloadExcelFile() {
      let now = new Date();
      let [year, month, day] = [
        now.getFullYear(),
        now.getMonth() + 1,
        now.getDate(),
      ];
      let today_string = `${year}${month < 10 ? "0" + month : month}${
        day < 10 ? "0" + day : day
      }`;
      let filename =
        this.property.name +
        "_" +
        this.property.building_number +
        "_" +
        today_string +
        ".xlsx";
      downloadPropertyBlocks(
        { property_id: this.property.property_id },
        filename
      );
    },
    abortSubmitForm() {
      this.$store.dispatch("alertMessage/showAlert", apiMessage.ABORT_PROCESS);
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    submitFile() {
      uploadPropertyBlocksExcel(
        { property_id: this.property.property_id },
        this.formData
      )
        .then((res) => {
          let messageObject = {};
          messageObject["success"] = res.data["succeed"];
          messageObject["failed"] = res.data["failed"];
          if (messageObject["failed"] > 0) {
            messageObject["blockFail"] = [];
            for (let i = 0; i < res.data.errors.length; i++) {
              if (!this.isEmptyObject(res.data.errors[i])) {
                messageObject["blockFail"].push(
                  res.data.errors[i].data["block_id"]
                );
              }
            }
          }
          let message = "";
          if (messageObject["success"] > 0) {
            message += messageObject["success"] + "件を更新しました。\n";
            this.blocks = [];
            this.fetchData(1);
          }
          if (messageObject["failed"] > 0) {
            message += "以下のレコードがエラーのため更新できませんでした。\n";
            message += "物件区画ID： ";
            for (let i = 0; i < messageObject["blockFail"].length; i++) {
              message += messageObject["blockFail"][i] + " ";
            }
          }
          this.$store.dispatch("alertMessage/showAlertV2", {
            message: message,
            timeout: 10000,
          });
        })
        .catch((error) => {
          if (checkNested(error, "response", "data", "detail", "msg")) {
            let message =
              apiMessage.ABORT_PROCESS + error.response.data.detail.msg;
            this.$store.commit("confirmapi/setConfirm", {
              status: true,
              mes: message,
            });
          }
        });
    },
    uploadFile() {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("name", "file");
      input.setAttribute("accept", ".xlsx");
      input.onchange = () => {
        let formData = new FormData();
        formData.append("file", input.files[0]);
        formData.append("property_id", this.property.property_id);
        this.formData = formData;
        $("#confirmSubmitFile").modal("show");
      };
      input.click();
    },
  },
};
</script>
