<template>
  <div class="wrapper">
    <SideBarTop />
    <SideBarLeft />
    <div class="content-wrapper">
      <section class="content py-3">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body minh-520">
              <div class="row mb-4">
                <div class="col-md-7">
                  <h3 class="card-title-cus mb-3">{{ textTitle }}</h3>
                </div>
                <div class="col-md-5">
                  <div class="text-right">
                    <button
                      class="btn btn-cus btn-blue-light px-3 m-2 my-sm-0 minw-100"
                      @click="$router.go(-1)">
                      戻る
                    </button>
                  </div>
                </div>
              </div>
              <div class="cus-filter">
                <div class="card-body">
                  <div class="row">
                    <div class="minw-50"></div>
                    <div class="col-md-3">
                      <div class="contruc-img maxw-90p mx-auto">
                        <img
                          :src="IMAGE_PROPERTIES + items.block_image_link"
                          alt="img"
                          class="img-fluid" />
                      </div>
                      <div class="text-center fs-16 my-2">間取り画像</div>
                    </div>
                    <div class="col-md-8">
                      <div
                        class="row align-items-center justify-content-md-end">
                        <div class="col-4 col-md-3 my-3">ステータス</div>
                        <div class="col-8 my-3">{{ items.selling_status }}</div>
                        <div class="col-4 col-md-3 my-3">
                          <span class="form-title bg-blue-light">物件名</span>
                        </div>
                        <div class="col-8 my-3">
                          {{ items?.property?.name }}
                        </div>
                        <div class="col-4 col-md-3 my-3">
                          <span class="form-title bg-blue-light">区画名</span>
                        </div>
                        <div class="col-8 my-3">{{ items.block_name }}</div>
                        <div class="col-12 my-3">
                          <div class="text-right">
                            <router-link
                              :to="
                                '/property-block/reference/' +
                                items.reference_page_url
                              "
                              target="_blank"
                              class="btn btn-cus btn-blue-light px-3 minw-100">
                              紹介画面プレビュー
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="position-relative text-right mb-2">
                <div v-if="isShowText" class="page-note text-left">
                  ※お客様への提示項目は色付きで表示しています
                </div>
              </div>
              <div class="wapper-table">
                <SelfBlockTable v-if="isShowsSelf" :items="items" />
                <OtherBlockTable v-if="isShowOther" :items="items" />
                <div class="mb-4 text-center">
                  <button
                    v-if="isShowButton"
                    type="button"
                    class="btn btn-cus btn-blue-light m-2 ml-md-0 minw-100"
                    @click="nextPageTLI04005">
                    編 集
                  </button>
                  <button
                    type="button"
                    class="btn btn-cus btn-blue-light m-2 minw-100"
                    @click="$router.go(-1)">
                    戻 る
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { USER_ROLE } from "@/constants.js";
import {
  IMAGE_PROPERTIES,
  BLOCK_LAYOUT_TYPE,
  ORIGINAL_LOCATION,
} from "/src/constants";
import SelfBlockTable from "/src/components/dynamic-block/SelfBlockTable.vue";
import OtherBlockTable from "/src/components/dynamic-block/OtherBlockTable.vue";
import { getPropertiesBlockDetail } from "/src/service/properties.service.js";
import SideBarLeft from "/src/components/layouts/SidebarLeft.vue";
import SideBarTop from "/src/components/layouts/SidebarTop.vue";
export default {
  components: {
    OtherBlockTable,
    SelfBlockTable,
    SideBarLeft,
    SideBarTop,
  },
  data() {
    return {
      isShowsSelf: false,
      isShowOther: false,
      IMAGE_PROPERTIES,
      BLOCK_LAYOUT_TYPE,
      ORIGINAL_LOCATION,
      items: {},
      url: "/property-block/reference-sf/",
      isShowText: true,
      textTitle: "他社媒介・紹介新築等物件",
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    isShowButton() {
      return [USER_ROLE.EDITOR, USER_ROLE.ADMIN].includes(
        this.user_object.role
      );
    },
  },
  created() {
    const propertyId = this.getPropertyId();
    getPropertiesBlockDetail(propertyId).then((data) => {
      if (
        data.data.property.location_of_original_data ==
        ORIGINAL_LOCATION.DYNAMIC
      ) {
        this.isShowText = false;
        this.textTitle = "販売受託・ﾙｼﾞｪﾝﾃ新築物件";
        this.isShowsSelf = true;
      } else {
        this.isShowOther = true;
      }
      this.items = data.data;
      // active left menu bar
      this.$store.dispatch(
        "properties/setRouteName",
        this.items?.property?.location_of_original_data
      );
    });
  },
  methods: {
    getPropertyId() {
      const url = window.location.href;
      const parts = url.split("/");
      const uuid = parts[parts.length - 1];
      return uuid;
    },
    checkXO(value) {
      if (value == null) {
        return;
      }
      if (value == true) {
        return "○";
      } else {
        return "×";
      }
    },
    nextPageTLI04005() {
      this.$router.push({
        name: "property-block-update",
        params: { block_id: this.items.block_id },
      });
    },
  },
};
</script>
