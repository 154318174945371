<template>
  <SalesFollowHistoryBase
    v-if="customerId"
    title="フォロー履歴詳細"
    :fetch-delay="nextFetchTime"
    :customer-id="customerId"
    :customer-name="customerName"
    :init-form-data="initFormData"
    :modal-msg="modalMsg"
    @confirm-cancel="handleCancel" />
</template>

<script>
import { navigateBack, fetchSalesFollowHistory } from "./utils.js";
import { API_DELAY } from "/src/frontend/src/constants.js";

import SalesFollowHistoryBase from "/src/components/sales-follow-histories/SalesFollowHistoryBase.vue";

export default {
  components: {
    SalesFollowHistoryBase,
  },
  data() {
    return {
      initFormData: {
        user_id: null,
        follow_date: "",
        contact_method: "",
        contact_result: "",
        property_1: {},
        property_2: {},
        property_3: {},
        property_4: {},
        property_5: {},
        comment: "",
      },
      modalMsg: {}, // Just add it here to avoid warning, no need to assign
      customerId: null,
      customerName: null,
    };
  },
  computed: {
    historyId() {
      const idStr = this.$route.params.history_id;
      if (idStr) return parseInt(idStr);
      return null;
    },
    nextFetchTime() {
      return 2 * API_DELAY;
    },
  },
  created() {
    setTimeout(() => {
      fetchSalesFollowHistory(this);
    }, API_DELAY);
  },
  methods: {
    handleCancel() {
      // Back to TLI07003
      this.$router.go(-1);
    },
  },
};
</script>
