<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div
    id="nav-3"
    class="tab-pane fade"
    role="tabpanel"
    aria-labelledby="nav-3-tab">
    <div class="cus-filter m-3 m-md-4 border-0">
      <div class="row align-items-center">
        <div class="minw-100 ml-2">
          <div class="form-group">
            <label class="my-0 text-nowrap">配信期間</label>
          </div>
        </div>
        <div class="col-sm-8 col-xl-4">
          <div class="row align-items-center">
            <div class="col-sm-5">
              <InputDatePicker
                v-model:modelvalue="fromDate"
                typeformat="be"
                :placeholder="`下限`" />
            </div>
            <div class="form-group mx-2 mx-sm-0">～</div>
            <div class="col-sm-5">
              <InputDatePicker
                v-model:modelvalue="toDate"
                typeformat="be"
                :placeholder="`上限`" />
            </div>
          </div>
        </div>
        <div class="col-sm-2 col-md-1">
          <div class="form-group text-left">
            <button
              class="btn btn-cus btn-blue-light minw-95"
              @click="updateQuery(1)">
              表示
            </button>
          </div>
        </div>
        <div v-if="total > 0" class="col-12 col-xl">
          <div class="form-group text-xl-right">
            <label class="my-0 winw-100 mr-2 text-nowrap">メール開封率</label>
            <input
              :v-model="data.opened_rate"
              :value="data.opened_rate"
              type="text"
              class="form-control w-84 d-inline-block"
              disabled />
            <label class="my-0 ml-1 text-nowrap">%</label>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="minw-100 ml-2"></div>
        <div class="col-sm-8 col-xl-4 text-red2 mb-2 fs-12 form-error pl-2">
          {{ toDate < fromDate ? "日付の大小関係が不正です" : "" }}
        </div>
      </div>
    </div>
    <ScrollTableLayout external-class="custom-table-4">
      <table class="table">
        <thead class="bg-white">
          <tr class="text-center">
            <th>配信日</th>
            <th>
              <div class="sorted">
                開封日
                <div class="sorting">
                  <span
                    class="sorting_asc"
                    :class="{ active: openedAtDesc == false }"
                    @click="sorting('opened_at', false)"
                    ><i class="fas fa-caret-up"></i></span
                  ><span
                    class="sorting_desc"
                    :class="{ active: openedAtDesc == true }"
                    @click="sorting('opened_at', true)"
                    ><i class="fas fa-caret-down"></i
                  ></span>
                </div>
              </div>
            </th>
            <th class="w-300">氏名</th>
            <th class="w-300">フリガナ</th>
            <th>性別</th>
            <th>年齢</th>
            <th>現住所</th>
            <th>区画名</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in data.items" :key="item.id">
            <tr :class="item.opened_at ? 'active' : ''">
              <td>
                {{ formatDate(item.sent_at) }}
              </td>
              <td>
                {{ formatDate(item.opened_at) }}
              </td>
              <td>
                <a
                  :href="navigateToCustomer(item.customer?.customer_id)"
                  target="_blank"
                  class="txt-underline"
                  >{{ item.customer?.full_name_kanji }}</a
                >
              </td>
              <td>{{ item.customer?.full_name_furigana }}</td>
              <td>{{ item.customer?.gender }}</td>
              <td class="text-right">
                {{ calculateAgeFromBirthday(item.customer?.birthday) }}
              </td>
              <td>
                {{ item.customer?.address_prefecture
                }}{{ item.customer?.address_district
                }}{{ item.customer?.address_detail }}
              </td>
              <td>{{ showRecommendBlock(item) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </ScrollTableLayout>
    <PaginateInterface
      v-if="total > 0"
      :key="data.items"
      :size="size"
      :pages="pages"
      :initial-page="page"
      :click-handler="updateQuery" />
    <PaginateInfo
      v-if="total > 0"
      :key="data.items"
      :total="total"
      :page="page"
      :size="size" />
  </div>
</template>
<script>
import InputDatePicker from "/src/components/utilitys/inputs/InputDatePicker.vue";
import PaginateInterface from "../commons/PaginateInterface.vue";
import PaginateInfo from "/src/components/commons/PaginateInfo.vue";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import { getRecommendEmail } from "/src/service/recommend.service";
export default {
  components: {
    PaginateInterface,
    PaginateInfo,
    InputDatePicker,
    ScrollTableLayout,
  },
  props: {
    trigger: {
      type: Number,
      required: true,
    },
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {
        items: [
          {
            sent_at: null,
            opened_at: null,
            customer: {
              full_name_kanji: null,
              full_name_furigane: null,
              gender: null,
              birthday: null,
              address_prefeture: null,
              address_district: null,
              address_detail: null,
            },
            block_name: null,
          },
        ],
        open_date: null,
      },
      total: 0,
      page: 1,
      size: 50,
      pages: 1,
      property_id: window.location.pathname.split("/").pop(),
      fromDate: null,
      toDate: null,
      order_by: "",
      desc: null,
      firstTimefetch: true,
    };
  },
  computed: {
    openedAtDesc() {
      if (this.order_by.includes("opened_at")) {
        return this.desc ? true : false;
      }
      return null;
    },
  },
  watch: {
    trigger() {
      this.parseUrl();
      this.fetchData(this.page);
    },
  },
  mounted() {
    document.addEventListener("keydown", this.enterToSearch);
  },
  unmounted() {
    document.removeEventListener("keydown", this.enterToSearch);
  },
  methods: {
    parseUrl() {
      // initial param from url
      const { mail_page, mail_order_by, mail_from_date, mail_to_date } =
        this.$route.query;
      this.page = parseInt(mail_page) || this.page;
      this.order_by = mail_order_by || this.order_by;
      this.desc = this.order_by[0] === "-";
      this.fromDate = mail_from_date || this.fromDate;
      this.toDate = mail_to_date || this.toDate;
    },
    updateQuery(page) {
      this.page = page;
      const query = {
        ...this.$route.query,
        mail_page: this.page,
        mail_order_by: this.order_by,
        mail_from_date: this.fromDate,
        mail_to_date: this.toDate,
        reload: "mail",
      };
      for (const k in query) if (!query[k]) delete query[k];
      if (query.mail_page === 1) delete query.mail_page;

      this.$router.replace({ query: query });
    },
    enterToSearch(e) {
      if (e.key == "Enter") {
        this.updateQuery(1);
      }
    },
    formatDate(date) {
      if (!date) return;
      let dateTimeArray = this.func
        .formatDateToParts(date, { dateStyle: "long" })
        .map((item) => item.value);
      if (parseInt(dateTimeArray[2]) < 10) {
        dateTimeArray[2] = "0" + dateTimeArray[2];
      }
      if (parseInt(dateTimeArray[4]) < 10) {
        dateTimeArray[4] = "0" + dateTimeArray[4];
      }
      return dateTimeArray.slice(2, 6).join("");
    },
    sorting(order_by, desc) {
      this.order_by = order_by;
      this.desc = desc;
      if (desc) {
        this.order_by = "-" + order_by;
      }
      this.updateQuery(1);
    },
    calculateAgeFromBirthday(birthday) {
      return this.func.birthdayToAge(birthday);
    },
    fetchData(page) {
      {
        if (this.toDate < this.fromDate) {
          return;
        }
        let to_date, from_date;
        [to_date, from_date] = this.handleDate();
        let params = {
          size: this.size,
          property_id: this.property_id,
          page,
          ...{ order_by: this.order_by },
        };
        if (this.fromDate || this.toDate) {
          params = {
            ...params,
            from_date,
            to_date,
          };
        }
        getRecommendEmail(params).then((res) => {
          this.data.items = res.data.items;
          this.data.opened_rate = (res.data.opened_rate * 100).toFixed(1);
          this.page = res.data.page;
          this.size = res.data.size;
          this.pages = res.data.pages;
          this.total = res.data.total;
          if (this.firstTimefetch == true) {
            this.firstTimefetch = false;
          } else if (this.total == 0) {
            this.$store.commit("confirmapi/setConfirm", {
              status: true,
              mes: "条件に該当する検索結果がありません。",
            });
          }
        });
      }
    },
    handleDate() {
      let to_date, from_date;
      if (!this.toDate && this.fromDate) {
        to_date = "9999-01-01";
        from_date = this.fromDate;
      } else if (this.toDate && !this.fromDate) {
        to_date = this.toDate;
        from_date = "1000-01-01";
      } else if (this.toDate && this.fromDate) {
        to_date = this.toDate;
        from_date = this.fromDate;
      }
      if (from_date > to_date && to_date) {
        to_date = from_date;
      }
      return [to_date, from_date];
    },
    navigateToCustomer(id) {
      if (!id) return;
      let url = this.$router.resolve({
        name: "customer-detail",
        params: { customer_id: id },
      });
      return url.href;
    },
    showRecommendBlock(item) {
      for (let i = 1; i <= 3; i++) {
        if (item[`recommend_property_${i}`]) {
          if (
            item[`recommend_property_${i}`].property_id == this.property_id &&
            item[`recommend_property_${i}`].property_block
          ) {
            return item[`recommend_property_${i}`].property_block.block_name;
          }
        }
      }
      return "";
    },
  },
};
</script>
