<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4 custom-aside">
    <a class="brand-link">
      <img src="@/assets/img/logo.png" alt="Logo" height="37" />
    </a>

    <div class="sidebar custom-sidebar">
      <nav class="mt-3">
        <ul
          class="nav nav-pills nav-sidebar flex-column nav-legacy"
          data-widget="treeview"
          role="menu"
          data-accordion="false">
          <li
            v-if="
              [USER_ROLE.EDITOR, USER_ROLE.ADMIN].includes(user_object.role)
            "
            class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :to="{ name: 'inquiry-reservation' }"
              exact>
              <p>問合せ・来場予約一覧</p>
            </router-link>
          </li>
          <li v-if="user_object.role === USER_ROLE.ADMIN" class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :to="{ name: 'follow-up-assignment', force: true }"
              exact>
              <p>フォロー顧客担当振り分け</p>
            </router-link>
          </li>
          <li
            v-if="
              user_object.role === USER_ROLE.EDITOR &&
              user_object.follow_available_flag === true
            "
            class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :to="{ name: 'priority-customers' }"
              exact>
              <p>フォロー顧客担当確認</p>
            </router-link>
          </li>
          <li
            v-if="
              [USER_ROLE.EDITOR, USER_ROLE.ADMIN].includes(user_object.role)
            "
            class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :class="{ active: activeLink('customers') }"
              :to="{ name: 'customers' }"
              exact>
              <p>顧客管理</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :class="{
                active:
                  activeLink('properties-in-house') &&
                  routeName == 'uuid-in-house',
              }"
              :to="{ name: 'properties-in-house' }"
              exact>
              <p>販売受託・ﾙｼﾞｪﾝﾃ新築物<br />件一覧（棟）</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :class="{
                active:
                  activeLink('properties-other') &&
                  routeName == 'uuid-properties-other',
              }"
              :to="{ name: 'properties-other' }"
              exact>
              <p>他社媒介・紹介新築等物<br />件一覧（棟）</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :to="{ name: 'properties-used' }"
              exact>
              <p>中古物件一覧（区画）</p>
            </router-link>
          </li>
          <li
            v-if="
              user_object.role === USER_ROLE.ADMIN ||
              (user_object.role === USER_ROLE.EDITOR &&
                user_object.follow_available_flag === true)
            "
            class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :to="{ name: 'freemail-setting' }"
              exact>
              <p>フリーメール設定</p>
            </router-link>
          </li>
          <li
            v-if="
              user_object.role === USER_ROLE.ADMIN ||
              (user_object.role === USER_ROLE.EDITOR &&
                user_object.follow_available_flag === true)
            "
            class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :to="{ name: 'new-mails-main', force: true }"
              exact>
              <p>新着メール管理設定</p>
            </router-link>
          </li>
          <li v-if="user_object.role === USER_ROLE.ADMIN" class="nav-item">
            <router-link
              tag="li"
              class="nav-link"
              :class="{ active: activeLink('users') }"
              :to="{ name: 'users' }"
              exact>
              <p>ユーザー管理</p>
            </router-link>
          </li>
          <li v-if="user_object.role === USER_ROLE.ADMIN" class="nav-item">
            <a href="/aggregation" class="nav-link" @click="checkAggregation">
              <p>
                集計
                <i
                  v-if="statusExport"
                  class="fas fa-circle text-green fs-10 ml-1"></i>
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a href="/logout" class="nav-link" @click="submitLogout">
              <p>ログアウト</p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { USER_ROLE } from "@/constants.js";
import { LOGOUT_API } from "/src/service/const.service.js";

export default {
  name: "SidebarLeft",
  data() {
    return {
      USER_ROLE,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    ...mapState("aggregation", ["statusExport", "actionExport"]),
    ...mapState("properties", ["routeName"]),
  },
  watch: {
    $route() {
      this.toggleSideBar();
      if (this.$route.name == "properties-in-house") {
        this.setRouteName("uuid-in-house");
      }
      if (this.$route.name == "properties-other") {
        this.setRouteName("uuid-properties-other");
      }
    },
  },
  mounted() {
    window.dispatchEvent(new Event("resize"));
    this.toggleSideBar();
  },
  methods: {
    ...mapActions("properties", ["setRouteName"]),
    ...mapActions("aggregation", ["setAction"]),
    submitLogout(e) {
      e.preventDefault();
      var form = document.createElement("form");
      form.method = "POST";
      form.action = LOGOUT_API;
      document.body.appendChild(form);
      localStorage.clear();
      form.submit();
    },
    toggleSideBar() {
      document.body.classList.add("hold-transition");
      document.body.classList.remove("sidebar-open");
      if (window.innerWidth < 990)
        document.body.classList.add("sidebar-collapse");
    },
    checkAggregation(ev) {
      ev.preventDefault();
      this.setAction(!this.actionExport);
    },
    activeLink(rou) {
      switch (rou) {
        case "customers":
          return [
            "customer-detail",
            "customer-action",
            "history-registry",
            "history-update",
            "contract-registry",
            "contract-update",
            "add-sales-follow",
            "detail-sales-follow",
            "update-sales-follow",
          ].includes(this.$route.name);
        case "users":
          return ["user-add", "user-edit", "user-detail"].includes(
            this.$route.name
          );
        case "properties-in-house":
          return [
            "property-update",
            "property-detail",
            "property-block",
            "property-block-update",
          ].includes(this.$route.name);
        case "properties-other":
          return [
            "property-update",
            "property-detail",
            "property-block",
            "property-block-update",
            "other-block-registry-detail",
            "properties-other-register",
          ].includes(this.$route.name);
        default:
          return false;
      }
    },
  },
};
</script>
