import axios from "axios";
import { PRIORITIES_CUSTOMERS_API } from "./const.service.js";

const getPropertiesCustomers = async (param) => {
  try {
    const response = await axios.get(PRIORITIES_CUSTOMERS_API, {
      params: param,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const getFollowPriorityCustomers = async (uuid) => {
  try {
    const response = await axios.get(`${PRIORITIES_CUSTOMERS_API}/${uuid}`);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const updatePropertiesCustomersList = async (body) => {
  if (body.follow_id) {
    throw "follow_id not match";
  }
  return axios.put(PRIORITIES_CUSTOMERS_API, body).then((response) => {
    return response;
  });
};

export {
  getPropertiesCustomers,
  updatePropertiesCustomersList,
  getFollowPriorityCustomers,
};
