const inquiryClassification = [
  { value: 1, label: "来場予約" },
  { value: 2, label: "問合せ" },
];

const inquiryStatus = [
  { value: true, label: "対応済み" },
  { value: false, label: "未対応" },
];

export { inquiryClassification, inquiryStatus };
