<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="login-page bg-white">
    <div class="login-box custom-login">
      <div class="login-logo">
        <img src="../assets/img/logo.png" alt="logo" />
        <p class="mt-2 fs-20 text-blue m-0">新築レコメンドAIシステム</p>
      </div>
      <div class="custom-form card-body">
        <form @keydown.enter.prevent="preventEnterSubmitDefault">
          <div class="form-group mb-4">
            <label for="">ID</label>
            <InputForm
              v-model:value="cognito_id"
              :placeholder="'user cognito_id'" />
            <small class="form-text text-danger"></small>
          </div>
          <div class="pt-3 mb-4">
            <Button
              :classlist="'btn btn-cus btn-blue-light btn-block'"
              :text="'ログイン'"
              @click="submit" />
          </div>
          <div class="text-center">
            パスワードを忘れた方は
            <a href="#!" class="text-blue2">こちら</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import InputForm from "/src/components/utilitys/inputs/InputForm.vue";
import Button from "../components/utilitys/buttons/Button.vue";

export default {
  name: "LoginUser",
  components: {
    InputForm,
    Button,
  },
  data: () => ({
    cognito_id: "",
  }),
  methods: {
    async submit() {
      this.$store.commit("user/setHeaders", this.cognito_id);
      alert("You login as User with cognito_id: " + this.cognito_id);
    },
    preventEnterSubmitDefault() {
      window.event.preventDefault();
      this.submit();
    },
  },
};
</script>
