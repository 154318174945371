function getYearsFrom2009ToCurrent() {
  const startYear = 2013;
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
}

const years = getYearsFrom2009ToCurrent();

const months = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
];

const blockLayoutTypeVar = [
  { label: "1R", value: 10 },
  { label: "1K", value: 11 },
  { label: "1DK", value: 12 },
  { label: "1LK", value: 13 },
  { label: "1LDK", value: 14 },
  { label: "1SK", value: 15 },
  { label: "1SDK", value: 16 },
  { label: "1SLK", value: 17 },
  { label: "1SLDK", value: 18 },
  { label: "2R", value: 20 },
  { label: "2K", value: 21 },
  { label: "2DK", value: 22 },
  { label: "2LK", value: 23 },
  { label: "2LDK", value: 24 },
  { label: "2SK", value: 25 },
  { label: "2SDK", value: 26 },
  { label: "2SLK", value: 27 },
  { label: "2SLDK", value: 28 },
  { label: "3R", value: 30 },
  { label: "3K", value: 31 },
  { label: "3DK", value: 32 },
  { label: "3LK", value: 33 },
  { label: "3LDK", value: 34 },
  { label: "3SK", value: 35 },
  { label: "3SDK", value: 36 },
  { label: "3SLK", value: 37 },
  { label: "3SLDK", value: 38 },
  { label: "4R", value: 40 },
  { label: "4K", value: 41 },
  { label: "4DK", value: 42 },
  { label: "4LK", value: 43 },
  { label: "4LDK", value: 44 },
  { label: "4SK", value: 45 },
  { label: "4SDK", value: 46 },
  { label: "4SLK", value: 47 },
  { label: "4SLDK", value: 48 },
  { label: "5R以上", value: 127 },
];
export { months, years, blockLayoutTypeVar };
