<template>
  <Select2
    :options="dataOption"
    :settings="{
      placeholder: placeholder,
      language: language,
      allowClear: clear,
    }" />
</template>

<script>
import Select2 from "vue3-select2-component";

export default {
  name: "FieldSelect2Group",
  components: {
    Select2,
  },
  props: {
    keytextparent: { type: String, default: "" },
    keylist: { type: String, default: "" },
    keyid: { type: String, default: "" },
    keytext: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    options: { type: Array, default: () => {} },
    select: { type: Function, default: () => {} },
    clear: { type: Boolean, default: false },
  },
  data() {
    return {
      dataOption: this.options.map((item) => {
        return {
          text: item[this.keytextparent],
          children: item[this.keylist].map((item) => {
            return {
              id: item[this.keyid],
              text: item[this.keytext],
            };
          }),
        };
      }),
      language: {
        noResults: () => {
          return "該当データがありません。";
        },
      },
    };
  },
  watch: {
    options(newdata) {
      this.dataOption = newdata.map((item) => {
        return {
          text: item[this.keytextparent],
          children: item[this.keylist].map((item) => {
            return {
              id: item[this.keyid],
              text: item[this.keytext],
            };
          }),
        };
      });
    },
  },
};
</script>
