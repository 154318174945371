<template>
  <div class="custom-select2 asset-name-type asset-name-type-1 w-100">
    <select ref="select2" :disabled="true">
      <option>{{ text }}</option>
    </select>
  </div>
</template>

<script>
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.full.min";

export default {
  props: {
    text: { type: String, default: "" },
    placeholder: { type: String, default: "" },
  },
  mounted() {
    // Initialize Select2
    $(this.$refs.select2).select2({
      placeholder: this.placeholder,
    });
  },
  beforeUnmount() {
    // Destroy Select2 when the component is unmounted
    $(this.$refs.select2).select2("destroy");
  },
};
</script>
