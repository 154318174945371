<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-3 my-2">
      <span class="form-title" :class="color">{{ label }}</span>
      <span v-if="compulsory" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-9 my-2">
      <div class="form-check custom-checkbox mx-3">
        <input
          :id="'field-check-' + id"
          v-model="mValue"
          class="form-check-input"
          :class="{ 'field-error': errorMessage }"
          :type="type" />
        <label :for="'field-check-' + id"></label>
      </div>
      <p v-if="errorMessage" class="error">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "FieldCheckBox",
  props: {
    id: { type: Number, default: 0 },
    color: { type: String, default: "bg-blue-light" },
    type: { type: String, default: "checkbox" },
    label: { type: String, default: "" },
    modelValue: { type: Boolean, default: null },
    compulsory: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
  },
  computed: {
    mValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
