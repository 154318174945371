<template>
  <ScrollTableLayout>
    <table class="table table-striped">
      <thead>
        <tr class="text-nowrap text-center">
          <th class="minw-160">物件名</th>
          <th>区画名</th>
          <th>所在地</th>
          <th>沿線</th>
          <th>最寄駅</th>
          <th>徒歩</th>
          <th>
            <div class="sorted">
              価格
              <div class="sorting">
                <span
                  class="sorting_asc"
                  :class="{ active: arrowUp === desc }"
                  @click="sortPrice('up')"
                  ><i class="fas fa-caret-up"></i></span
                ><span
                  class="sorting_desc"
                  :class="{ active: arrowDown === desc }"
                  @click="sortPrice('down')"
                  ><i class="fas fa-caret-down"></i
                ></span>
              </div>
            </div>
          </th>
          <th>
            <div class="sorted">
              面積
              <div class="sorting">
                <span
                  class="sorting_asc"
                  :class="{ active: arrowUp === desc1 }"
                  @click="sortBlockArea('up')"
                  ><i class="fas fa-caret-up"></i></span
                ><span
                  class="sorting_desc"
                  :class="{ active: arrowDown === desc1 }"
                  @click="sortBlockArea('down')"
                  ><i class="fas fa-caret-down"></i
                ></span>
              </div>
            </div>
          </th>
          <th>間取り</th>
          <th>階数</th>
          <th>築年月</th>
          <th>総戸数</th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <tr v-for="item in items" :key="item.id">
          <td class="w-300 text-wrap text-left">{{ item.name }}</td>
          <td>
            <a
              :href="url + item.reference_page_url"
              target="blank"
              class="txt-underline"
              >{{ item.block_name }}</a
            >
          </td>
          <td>{{ item.location_district }}</td>
          <td>{{ item.nearest_station_railway_1 }}</td>
          <td>{{ item.nearest_station_1 }}</td>
          <td class="text-right">
            {{
              item.walking_to_nearest_station_1
                ? item.walking_to_nearest_station_1 + "分"
                : ""
            }}
          </td>
          <td class="text-right">
            {{
              item.price
                ? func.formatNumber(func.convertAndRoundToMY(item.price)) +
                  "万円"
                : ""
            }}
          </td>
          <td class="text-right">
            {{
              item.block_area
                ? func.formatNumber(item.block_area, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "㎡"
                : ""
            }}
          </td>
          <td>
            {{ item.layout_type_txt }}
          </td>
          <td class="text-right">
            {{ item.floor_number ? item.floor_number + "階" : "" }}
          </td>
          <td>
            {{
              item.completion_year_and_month
                ? convertDateToJapanese(item.completion_year_and_month)
                : ""
            }}
          </td>
          <td class="text-right">
            {{ item.number_of_houses ? item.number_of_houses + "戸" : "" }}
          </td>
        </tr>
      </tbody>
    </table>
  </ScrollTableLayout>
</template>

<script>
import { blockLayoutTypeVar } from "/src/components/constants/const.js";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
export default {
  components: {
    ScrollTableLayout,
  },
  props: ["items"],
  data() {
    return {
      blockLayoutTypeVar,
      arrowUp: false,
      arrowDown: true,
      desc: null,
      url: "/property-block/reference-sf/",
      desc1: null,
    };
  },
  methods: {
    convertDateToJapanese(dateString) {
      const [year, month] = dateString.split("-");
      return `${year}年${month}月`;
    },

    sortPrice(type) {
      if (type == "up") {
        if (this.arrowUp != this.desc) {
          this.desc = this.arrowUp;
          this.$emit("sort", { desc: false, order_by: "price" });
        }
      } else if (type == "down") {
        if (this.arrowDown != this.desc) {
          this.desc = this.arrowDown;
          this.$emit("sort", { desc: true, order_by: "price" });
        }
      }
    },
    sortBlockArea(type) {
      if (type == "up") {
        if (this.arrowUp != this.desc1) {
          this.desc1 = this.arrowUp;
          this.$emit("sort", { desc: false, order_by: "block_area" });
        }
      } else if (type == "down") {
        if (this.arrowDown != this.desc1) {
          this.desc1 = this.arrowDown;
          this.$emit("sort", { desc: true, order_by: "block_area" });
        }
      }
    },
  },
};
</script>
