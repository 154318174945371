<template>
  <div class="wrapper">
    <SideBarTop />
    <SideBarLeft />
    <div class="content-wrapper">
      <section class="content py-3">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body minh-520">
              <HeaderInquiry :title="title" :data="data" />
              <div class="wrap-ct">
                <div class="custom-table custom-table-2 py-4">
                  <StatusInquiry :data="data" />
                  <TableInquiryDetail :data="data" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import TableInquiryDetail from "/src/frontend/src/components/inquiry/TableInquiryDetail.vue";
import StatusInquiry from "/src/components/inquiry/StatusInquiry.vue";
import HeaderInquiry from "/src/components/inquiry/HeaderInquiry.vue";
import SideBarLeft from "/src/components/layouts/SidebarLeft.vue";
import SideBarTop from "/src/components/layouts/SidebarTop.vue";
export default {
  components: {
    TableInquiryDetail,
    StatusInquiry,
    SideBarLeft,
    SideBarTop,
    HeaderInquiry,
  },
  props: ["data", "title"],
};
</script>
