<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div
    :id="id"
    class="tab-pane fade"
    role="tabpanel"
    aria-labelledby="nav-2-tab">
    <div class="mx-4">
      <div class="cus-filter my-0 border-0">
        <div class="card-body px-0">
          <div class="row align-items-center">
            <div class="col-sm-4 col-lg-2 my-2">
              <label>フォロー対象日</label>
            </div>
            <div class="col-sm-8 col-lg-10 my-2">
              <div class="form-group w-215 mb-0">
                <InputTime
                  :id="'start-date'"
                  v-model:modelvalue="filter.new_mail_follow_target_date"
                  :placeholder="'YYYY-MM-DD'"
                  :clearable="false"
                  @update:modelvalue="handlerDate" />
              </div>
            </div>
            <div class="col-sm-4 col-lg-2 my-2">
              <label>担当者名</label>
            </div>
            <div class="col-sm-8 col-lg-10 my-2">
              <input
                v-model="user_object.name"
                type="text"
                class="form-control w-215"
                placeholder=""
                disabled />
            </div>
          </div>
        </div>
      </div>
      <div v-if="isShow" class="wrap-ct">
        <div class="text-center bg-blue3 fs-16 text-white py-2">顧客情報</div>
        <TableFollowPriorites :items="items" :page="page" :size="size" />
        <div class="custom-pagi my-4">
          <Paginate
            :initial-page="page"
            :page="page"
            :pages="pages"
            :total="total"
            :size="size"
            :click-handler="loadPage" />
        </div>
        <PaginateInfo :page="page" :total="total" :size="size" />
      </div>
    </div>
  </div>
  <ModalConfirmFollow :id="'modalFollow'" :message="messAlert" />
</template>

<script>
import $ from "jquery";
import { mapState } from "vuex";
import PaginateInfo from "/src/components/commons/PaginateInfo.vue";
import Paginate from "/src/components/commons/Paginate.vue";
import TableFollowPriorites from "/src/components/follow-priorites-customer/TableFollowPriorites.vue";
import { getLitsNewMailPropertiesCustomers } from "/src/service/new-mail.service.js";
import InputTime from "/src/components/utilitys/inputs/InputTime.vue";
import ModalConfirmFollow from "/src/components/modals/ModalConfirmFollow.vue";
import {
  formatDateTimeNow,
  formatDate,
} from "/src/components/follow-priorites-customer/common-follow.js";

export default {
  components: {
    PaginateInfo,
    TableFollowPriorites,
    Paginate,
    InputTime,
    ModalConfirmFollow,
  },
  props: {
    id: { type: String, default: "nav-2" },
  },
  data() {
    const filter = {
      new_mail_follow_target_date: "",
    };
    return {
      filter,
      page: 1,
      size: 50,
      items: [],
      pages: 1,
      total: 0,
      isShow: false,
      messAlert: "フォロー対象日ではありません。",
      formatDateTimeNow,
      formatDate,
      isLoad: false,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
  },
  methods: {
    loadPage(numpage) {
      this.page = numpage;
      this.getData({
        new_mail_follow_target_date: this.filter.new_mail_follow_target_date,
        page: numpage,
        size: this.size,
        order_by: "-priority_score,customer_id,follow_id",
      });
    },
    getDataFirst() {
      if (!this.isLoad) {
        this.filter.new_mail_follow_target_date = this.formatDateTimeNow();
        this.getData({
          new_mail_follow_target_date: this.filter.new_mail_follow_target_date,
          page: this.page,
          size: this.size,
          order_by: "-priority_score,customer_id,follow_id",
        });
        this.isLoad = true;
      }
    },
    getData(params) {
      if (!params.new_mail_follow_target_date) {
        return;
      }
      getLitsNewMailPropertiesCustomers(params).then((data) => {
        this.total = data.total;
        this.pages = data.pages;
        this.items = data.items;
        if (this.items.length != 0) {
          this.isShow = true;
        } else {
          this.isShow = false;
          $("#modalFollow").modal();
        }
      });
    },
    handlerDate(value) {
      this.filter.new_mail_follow_target_date = this.formatDate(value);
      this.getData({
        new_mail_follow_target_date: this.filter.new_mail_follow_target_date,
        page: this.page,
        size: this.size,
        order_by: "-priority_score,customer_id,follow_id",
      });
    },
  },
};
</script>
