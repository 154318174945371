<template>
  <NewInHouse
    v-if="renderNewInHouse"
    :data="showData"
    :text-button="textButton"
    :schedule="schedule" />
  <NewOtherCompany
    v-if="renderNewOtherCompany"
    :data="showData"
    :text-button="textButton"
    :schedule="schedule" />
</template>

<script>
import { getUuid } from "/src/components/recommend-properties/commons/utils/format.js";
import {
  getPreviousDay,
  returnTextButton,
} from "/src/components/recommend-properties/commons/utils/format.js";
import { ORIGINAL_LOCATION } from "/src/constants.js";
import { getPropertiesBlockDetail } from "/src/service/properties.service.js";
import { getRecommendScheduleCalendars } from "/src/service/recommend-schedule-calendar.js";
import NewInHouse from "/src/components/recommend-properties/NewInHouse.vue";
import NewOtherCompany from "/src/components/recommend-properties/NewOtherCompany.vue";
import { showData } from "/src/helpers/info-properties.js";
import {
  getNewInHouseHeader,
  getHouseImage,
  getNewInHouseIcon,
  getNewInHouseTable,
  getNewInHouseMapImage,
  getTourAvailable,
} from "/src/components/recommend-properties/commons/utils/newInHouse.js";

export default {
  components: { NewInHouse, NewOtherCompany },
  data() {
    return {
      getUuid,
      showData,
      textButton: "",
      ORIGINAL_LOCATION,
      renderNewInHouse: false,
      renderNewOtherCompany: false,
      data: { property_block: {} },
      schedule: {
        release_date: "",
        expiry_date: "",
        expiry_date_before_one_day: "",
      },
    };
  },
  created() {
    const uuid = this.getUuid();
    getPropertiesBlockDetail(uuid).then((data) => {
      try {
        this.data.property_block = data.data;
        this.textButton = returnTextButton(
          data.data.block_classification,
          data.data.property.completion_date
        );
        if (
          data.data.property.location_of_original_data ==
          ORIGINAL_LOCATION.DYNAMIC
        ) {
          this.renderNewInHouse = true;
        } else if (
          data.data.property.location_of_original_data ==
          ORIGINAL_LOCATION.OTHER
        ) {
          this.renderNewOtherCompany = true;
        }
        getNewInHouseHeader(this.data, this.showData);
        getHouseImage(this.data, this.showData);
        getNewInHouseIcon(this.data, this.showData);
        getNewInHouseTable(this.data, this.showData);
        getNewInHouseMapImage(this.data, this.showData);
        getTourAvailable(this.data, this.showData);
      } catch {
        this.renderNewInHouse = true;
      }
    });
    getRecommendScheduleCalendars().then((data) => {
      this.schedule.release_date = data.data.release_date;
      this.schedule.expiry_date = data.data.expiry_date;
      this.schedule.expiry_date_before_one_day = getPreviousDay(
        data.data.expiry_date
      );
    });
  },
};
</script>
