<template>
  <div
    id="logout"
    class="modal fade custom-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="my-4 text-center">
            <div class="fs-16 my-4">
              {{ message }}
            </div>
            <div class="text-center">
              <button
                class="btn btn-cus btn-pink mx-2 w-200 fs-14"
                data-dismiss="modal"
                @click="logOut">
                ログインページへ戻る
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { mapState } from "vuex";
import { USER_ROLE } from "../constants";
import { LOGOUT_API } from "/src/service/const.service.js";
import { getUserLogin } from "../service/users.service";
export default {
  name: "LoginRedirect",
  data() {
    return {
      isDisplay: false,
      message: "",
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
  },
  created() {
    localStorage.setItem("user_object", JSON.stringify({ role: null }));
    this.fetchUserLogin();
  },
  methods: {
    logOut() {
      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", LOGOUT_API);
      document.body.appendChild(form);
      localStorage.clear();
      form.submit();
    },
    redirectUser() {
      const query = this.$route.query;
      if (query && query.redirect_url) {
        this.$router.push({ path: query.redirect_url });
        return;
      }
      if (
        this.user_object.role == USER_ROLE.ADMIN ||
        this.user_object.role == USER_ROLE.EDITOR
      ) {
        this.$router.push({ name: "inquiry-reservation" });
      } else if (this.user_object.role == USER_ROLE.VIEWER) {
        this.$router.push({ name: "properties-in-house" });
      }
    },
    fetchUserLogin() {
      getUserLogin()
        .then((response) => {
          this.$store.commit("user/setUserLogin", response.data);
          localStorage.setItem("user_object", JSON.stringify(response.data));
          this.redirectUser();
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.message = error.response.data.detail.msg;
            $("#logout").modal("show");
          }
        });
    },
  },
};
</script>
