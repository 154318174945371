<template>
  <router-link
    v-if="routeTo"
    :to="routeTo"
    target="_blank"
    class="txt-underline">
    {{ text }}
  </router-link>
  <span v-else>{{ text }}</span>
</template>

<script>
export default {
  props: {
    routeTo: { type: [String, Object], required: true },
    text: { type: String, required: true },
  },
  data() {
    return {};
  },
};
</script>
