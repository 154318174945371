function formatDateTimeNow() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
function formatDate(dateString) {
  const dateParts = dateString.split("/");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  return `${year}-${month}-${day}`;
}

export { formatDateTimeNow, formatDate };
