import axios from "axios";
import { SALES_FOLLOW_HISTORIES_API } from "./const.service.js";

const getSaleFollowHistories = async (params) => {
  return axios
    .get(SALES_FOLLOW_HISTORIES_API, { params: params })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const getSaleFollowHistoryDetail = async (history_id) => {
  return axios
    .get(SALES_FOLLOW_HISTORIES_API + "/" + history_id)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const deleteSaleFollowHistory = async (history_id) => {
  return axios
    .delete(SALES_FOLLOW_HISTORIES_API + "/" + history_id)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const createSaleFollowHistory = async (body) => {
  return axios
    .post(SALES_FOLLOW_HISTORIES_API, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const updateSaleFollowHistory = async (history_id, body) => {
  return axios
    .put(SALES_FOLLOW_HISTORIES_API + "/" + history_id, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export {
  getSaleFollowHistories,
  getSaleFollowHistoryDetail,
  deleteSaleFollowHistory,
  createSaleFollowHistory,
  updateSaleFollowHistory,
};
