<template>
  <div
    class="row align-items-center"
    :class="{ 'mb-2': propertyError || blockError }">
    <div class="col-md-12 col-xl-6">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 col-xl-6 my-2">
          物件名
          <span class="badge badge-red">必 須</span>
        </div>
        <div class="col-12 col-md-5 col-xl-6 my-2">
          <slot name="property"></slot>
        </div>
      </div>
      <div v-show="propertyError" class="row error-container">
        <div class="col-12 col-md-8 error-wrapper pad-error-50">
          <p class="error">{{ propertyError }}</p>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-xl-6">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 col-xl-5 my-2 text-xl-right">
          区画名
          <span class="badge badge-red">必 須</span>
        </div>
        <div class="col-12 col-md-5 col-xl-6 my-2">
          <slot name="block"></slot>
        </div>
      </div>
      <div v-show="blockError" class="row error-container">
        <div class="col-12 col-md-8 error-wrapper pad-error-42">
          <p class="error">{{ blockError }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propertyError: { type: String, default: "" },
    blockError: { type: String, default: "" },
  },
};
</script>

<style scoped>
@import "@/assets/css/action-form-extra.css";
</style>
