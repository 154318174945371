<template>
  <div class="card-body minh-520">
    <div class="row mb-4">
      <div class="col-md-12">
        <h3 class="card-title-cus">ユーザー情報変更</h3>
      </div>
    </div>
    <div class="wrap-ct">
      <div class="my-4 mx-3 mx-md-5">
        <div class="custom-form col-xl-9">
          <form>
            <FieldText
              v-model="user.name"
              type="text"
              label="名前"
              :mandatory="true"
              placeholder="名前"
              :error="nameMsg"
              :auto-focus="true"
              @focusout="
                removeEmptyNameError();
                handleNameFocusout();
              " />
            <FieldText
              v-model="user.email"
              label="メールアドレス"
              :mandatory="true"
              placeholder="メールアドレス"
              :error="emailMsg"
              @focusout="removeEmptyEmailError()" />
            <FieldSingleSelect
              v-model="user.role"
              label="ユーザー属性"
              :check-options="checkOptions" />
            <div v-if="user.role != 1" class="row align-items-center">
              <div class="col-12 col-md-4 my-2">フォロー担当フラグ</div>
              <div class="col-12 col-md-7 my-2">
                <div class="form-check custom-checkbox ml-3">
                  <input
                    id="option4"
                    v-model="user.follow_available_flag"
                    class="form-check-input"
                    type="checkbox" />
                  <label for="option4"></label>
                </div>
              </div>
            </div>
            <FieldText
              :model-value="
                func.formatDate(user.created_at, { dateStyle: 'long' }, true)
              "
              type="text"
              label="登録日"
              :disable="true" />
            <FieldText
              :model-value="user?.created_by_user?.name"
              type="text"
              label="登録者"
              :disable="true" />
            <FieldText
              :model-value="
                func.formatDate(user.updated_at, { dateStyle: 'long' }, true)
              "
              type="text"
              label="更新日"
              :disable="true" />
            <FieldText
              :model-value="user?.updated_by_user?.name"
              type="text"
              label="更新者"
              :disable="true" />
          </form>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="my-4 text-center">
              <Button
                class-list-extend="btn-blue-light m-2 ml-md-0 w-150"
                text="変 更"
                :disabled="!isEdit"
                @click="showModalUserChange" />
              <Button
                class-list-extend="btn-blue-light btn-ok m-2 w-150"
                text="戻 る"
                @click="showModalNavigateBack" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm
      id="confirmModalUserChange"
      message="ユーザー情報を変更します。"
      @no="abortSubmitForm"
      @yes="submitForm" />
    <ModalConfirm
      id="backToUserList"
      message="ユーザー情報の変更をキャンセルします。"
      @no="abortSubmitForm"
      @yes="navigateBack" />
  </div>
</template>
<script>
import $ from "jquery";
import { mapActions } from "vuex";
import FieldSingleSelect from "/src/components/utilitys/inputs/FieldSingleSelect.vue";
import FieldText from "/src/components/utilitys/inputs/FieldText.vue";
import Button from "/src/components/utilitys/buttons/Button.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import {
  getUserDetail,
  updateUser,
  check_duplicate,
  textNormalize,
} from "/src/service/users.service.js";
import { USER_ROLE } from "/src/constants";
const {
  regex,
  apiMessage,
  userMessage,
} = require("/src/assets/validation.json");

export default {
  name: "UserEdit",
  components: {
    FieldSingleSelect,
    FieldText,
    Button,
    ModalConfirm,
  },
  data() {
    return {
      original_data: {},
      user: {
        user_id: 0,
        name: "",
        email: "",
        role: "",
        follow_available_flag: false,
        created_at: "",
        created_by: 0,
        updated_at: "",
        updated_by: 0,
      },
      nameMsg: "",
      emailMsg: "",
      checkOptions: [
        {
          label: "管理者",
          value: 7,
        },
        {
          label: "編集ユーザー",
          value: 3,
        },
        {
          label: "閲覧のみユーザー",
          value: 1,
        },
      ],
    };
  },
  computed: {
    isEdit() {
      // Check if user has changed any data (name, email, role, follow_available_flag)
      return (
        this.user.name != this.original_name ||
        this.user.email != this.original_email ||
        this.user.role != this.original_data.role ||
        this.user.follow_available_flag !=
          this.original_data.follow_available_flag
      );
    },
    updateByUser: {
      get() {
        return this.func.showValue(this.user.updated_by_user, {
          getProperty: "name",
        });
      },
    },
    createdByUser: {
      get() {
        return this.func.showValue(this.user.created_by_user, {
          getProperty: "name",
        });
      },
    },
  },
  watch: {
    "user.role": {
      handler: function (newObj) {
        console.log(newObj);
        if (newObj == 1) this.user.follow_available_flag = false;
      },
    },
  },
  created() {
    getUserDetail(this.$route.params.user_id).then((res) => {
      this.original_data = { ...res.data };
      this.user = res.data;
      this.original_email = this.user.email;
      this.original_name = this.user.name;
    });
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    nameValidate() {
      if (this.user.name == "") {
        this.nameMsg = userMessage.REQUIRED;
      } else {
        this.nameMsg = "";
      }
    },
    emailValidate() {
      const reg = new RegExp(regex.email);
      if (!reg.test(this.user.email)) {
        return false;
      }
      return true;
    },
    emptyEmailValidate() {
      if (this.user.email == "") {
        this.emailMsg = userMessage.REQUIRED;
        return false;
      }
      this.emailMsg = "";
      return true;
    },
    removeEmptyEmailError() {
      if (this.user.email != "") {
        this.emailMsg = "";
      }
    },
    removeEmptyNameError() {
      if (this.user.name != "") {
        this.nameMsg = "";
      }
    },
    handleNameFocusout() {
      this.user.name = textNormalize(this.user.name);
    },
    isValidForm() {
      return this.user.name != "" && this.user.email != "";
    },
    showModalUserChange() {
      this.nameValidate();
      this.emptyEmailValidate();
      if (this.nameMsg || this.emailMsg) {
        return;
      }
      if (this.emailValidate()) {
        check_duplicate({
          email: this.user.email,
          name: this.user.name,
          except_user_id: this.user.user_id,
        }).then((res) => {
          $("#confirmModalUserChange").modal("show");
        });
      } else {
        this.$store.commit("confirmapi/setConfirm", {
          status: true,
          mes: "処理を中止しました。メールアドレスに使用しているテキストで利用不能な文字が存在します。",
        });
      }
    },
    abortSubmitForm() {
      this.showAlert(apiMessage.ABORT_PROCESS);
    },
    submitForm() {
      let body = {
        user_id: this.user.user_id,
        name: this.user.name,
        email: this.user.email,
        role: this.user.role | USER_ROLE.VIEWER,
        follow_available_flag: this.user.follow_available_flag,
      };
      updateUser(this.$route.params.user_id, body).then((_) => {
        // Redirect to user_detail with param user_id
        this.$router.push({
          name: "users",
        });
        this.func.navigateBack(this.$router, "users");
        this.showAlert("ユーザー情報を変更しました。");
      });
    },
    showModalNavigateBack() {
      $("#backToUserList").modal("show");
    },
    navigateBack() {
      this.func.navigateBack(this.$router, "users");
      this.showAlert("ユーザー情報の変更をキャンセルしました。");
    },
  },
};
</script>
