import axios from "axios";
import { ENV_NAME } from "/src/constants";
import { apiRailroad } from "./apiRailroad.js";
const apiHosts = {
  dev: "https://intra-api-dev.livable-digital.net",
  stg: "https://intra-api-dev.livable-digital.net",
  // prod: "https://intra-api.livable-digital.net",
  prod: "https://intra-api-dev.livable-digital.net",
};
const apiHost = apiHosts[ENV_NAME];
// const apiPrefectures = {
//   apiDev:
//     "https://intra-api-dev.livable-digital.net/master/address/prefectures",
//   // apiProd: "https://intra-api.livable-digital.net/master/address/prefectures",
//   apiProd:
//     "https://intra-api-dev.livable-digital.net/master/address/prefectures",
// };
const apiPrefectures = apiHost + "/master/address/prefectures";
const apiCity = apiHost + "/master/address/location";
const apiStation = apiHost + "/master/traffic/station-reins/name";
const getPrefectures = async (host) => {
  try {
    const response = await axios.get(apiPrefectures, {
      mode: "cors",
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
const getCity = async (param) => {
  try {
    const response = await axios.get(apiCity, {
      mode: "cors",
      params: param,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
const getStation = async (param) => {
  if (param["reins_railroad_cd"] == null) {
    return { data: { data: [] } };
  }
  try {
    const response = await axios.get(apiStation, {
      mode: "cors",
      params: param,
    });
    return response;
  } catch (error) {
    console.log(error);
    return { data: { data: [] } };
  }
};
export { getPrefectures, getCity, apiRailroad, getStation };
