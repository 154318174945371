<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-3 my-2">
      <span class="form-title" :class="color1">{{ label1 }}</span>
    </div>
    <div class="col-12 col-md-3 my-2">
      <input
        :value="mValue1"
        type="text"
        name=""
        class="form-control"
        placeholder=""
        :disabled="disable1" />
    </div>
    <div class="col-12 col-md-3 my-2">
      <div class="d-md-flex mx-lg-2 justify-content-center">
        <span class="form-title">～</span>
        <span class="form-title ml-md-3" :class="color2">{{ label2 }}</span>
      </div>
    </div>
    <div class="col-12 col-md-3 my-2">
      <input
        :value="mValue2"
        type="text"
        name=""
        class="form-control"
        placeholder=""
        :disabled="disable2" />
    </div>
  </div>
</template>
<script>
export default {
  name: "FieldShortInput",
  props: {
    label1: { type: String, default: "" },
    value1: { type: [String, Number], default: "" },
    color1: { type: String, default: "bg-blue-light" },
    disable1: { type: Boolean, default: false },
    label2: { type: String, default: "" },
    value2: { type: [String, Number], default: "" },
    color2: { type: String, default: "bg-blue-light" },
    disable2: { type: Boolean, default: false },
  },
  computed: {
    mValue1: {
      get() {
        return this.value1;
      },
      set(mValue1) {
        this.$emit("update:value1", mValue1);
      },
    },
    mValue2: {
      get() {
        return this.value2;
      },
      set(mValue2) {
        this.$emit("update:value2", mValue2);
      },
    },
  },
};
</script>
