<template>
  <BaseLayout>
    <router-view></router-view>
  </BaseLayout>
</template>
<script>
import BaseLayout from "/src/components/layouts/BaseLayout.vue";
export default {
  name: "PropertyView",
  components: {
    BaseLayout,
  },
};
</script>
