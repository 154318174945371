<template>
  <div v-if="readyToRenderForm == true">
    <BlockForm
      :key="readyToRenderForm"
      :property="property"
      :disabled-list="disabledList"
      :compulsory-list="compulsoryList"
      @submit-handle="submitHandle" />
    <ModalConfirmSubmit
      id="submitModal"
      message="区画情報を登録します。"
      @yes="registerBlock"
      @no="abortProcess" />
    <ModalConfirmSubmit
      id="existModal"
      message="区画情報の登録をキャンセルします。"
      @yes="navigateBackToTLI4002"
      @no="abortProcess" />
  </div>
</template>
<script>
import $ from "jquery";
import BlockForm from "/src/components/dynamic-block/BlockForm.vue";
import ModalConfirmSubmit from "/src/components/modals/ModalConfirmSubmit.vue";
import {
  getPropertyDetail,
  createPropertyBlock,
  uploadPropertyBlock,
  uploadImage,
} from "/src/service/properties.service.js";
const { apiMessage } = require("/src/assets/validation.json");
export default {
  components: {
    BlockForm,
    ModalConfirmSubmit,
  },
  data() {
    return {
      property: null,
      image: null,
      disabledList: [],
      compulsoryList: [
        "selling_status",
        "block_image_link",
        "block_name",
        "block_classification",
        "block_type",
        "layout_type",
        "area",
        "balcony_area",
        "floor_number",
        "price",
        "management_fee",
        "repair_reserve_fee",
      ],
      formData: {},
      readyToRenderForm: false,
    };
  },
  async created() {
    await getPropertyDetail(this.$route.params.property_id).then((res) => {
      this.property = res.data;
    });
  },
  mounted() {
    this.readyToRenderForm = true;
  },
  methods: {
    registerBlock() {
      createPropertyBlock(this.formData).then((res) => {
        this.formData = res.data;
        this.submitForm();
      });
    },
    submitForm() {
      if (this.image) {
        let suffix = "other";
        let form = new FormData();
        form.append("files", this.image);
        form.append(
          "names",
          `${this.property.property_id}_${
            this.formData.block_id
          }_${suffix}_layout.${this.image.name.split(".").pop()}`
        );
        uploadImage(form)
          .then((res) => {
            this.formData["block_image_link"] = res.data[0].name;
          })
          .then((_) => {
            uploadPropertyBlock(this.formData.block_id, this.formData).then(
              (res) => {
                let id = res.data.block_id;
                this.navigateAfterSubmit(id);
              }
            );
          });
      } else {
        // Case not upload image
        uploadPropertyBlock(this.formData.block_id, this.formData).then(
          (res) => {
            let id = res.data.block_id;
            this.navigateAfterSubmit(id);
          }
        );
      }
    },
    submitHandle(event) {
      this.formData = event.formData;
      this.image = event.image;
      $("#submitModal").modal("show");
    },
    abortProcess() {
      this.$store.dispatch("alertMessage/showAlert", apiMessage.ABORT_PROCESS);
    },
    setData(propertyData) {
      this.property = propertyData;
    },
    navigateAfterSubmit(id) {
      this.$router.push({ name: "property-block", params: { block_id: id } });
      this.$store.dispatch(
        "alertMessage/showAlert",
        "区画情報を登録しました。"
      );
    },
    navigateBackToTLI4002() {
      this.func.navigateBack(this.$router, "property-detail", {
        id: this.property.property_id,
      });
      this.$store.dispatch(
        "alertMessage/showAlert",
        "区画情報の登録をキャンセルしました。"
      );
    },
  },
};
</script>
