<template>
  <div class="col-xl-6">
    <h3 class="card-title-cus">送信メール内容</h3>
    <div class="cus-filter height_calc13">
      <div class="card-body custom-form">
        <div class="row align-items-center">
          <div class="col-md-3 form-group">
            件名
            <span class="badge badge-red">必 須</span>
          </div>
          <div class="col-md-9 form-group">
            <input
              v-model="form.subject"
              type="text"
              class="form-control"
              :class="{ 'field-error': error.subject }"
              @input="error.subject = false" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 form-group">
            本文
            <span class="badge badge-red">必 須</span>
          </div>
          <div class="col-md-9 form-group">
            <textarea
              v-model="form.body"
              type="text"
              class="form-control mb-3"
              :class="{ 'field-error': error.body }"
              rows="6"
              @input="error.body = false"></textarea>
            <div class="form-check custom-checkbox ml-3">
              <input
                id="property"
                v-model="form.is_include_url"
                class="form-check-input"
                type="checkbox" />
              <label v-if="mailType === 'regular'" for="property">
                紹介物件URLを追記
              </label>
              <label v-else for="property">物件URLを追記</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 form-group">
            署名
            <span class="badge badge-red">必 須</span>
          </div>
          <div class="col-md-9">
            <textarea
              v-model="form.signature"
              type="text"
              class="form-control"
              :class="{ 'field-error': error.signature }"
              rows="6"
              @input="error.signature = false"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    mailType: { type: String, required: true },
  },
  data() {
    return {
      form: {
        subject: "【お詫び】（物件名）",
        body:
          "本メールは東急リバブルが販売する新築物件へお問合せいただいたお客様に送付しております。\n" +
          "配信停止をご希望の方へもお送りさせていただいております。ご了承いただきますようお願い申し上げます。\n" +
          "（本文）",
        signature:
          "_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/\n" +
          "東急リバブル銀座サロン\n" +
          "東京都中央区銀座１－８－１９ キラリトギンザ8階\n" +
          "0120-006-109\n" +
          "定休日：火曜日・水曜日・木曜日",
        is_include_url: false,
      },
      error: {
        subject: false,
        body: false,
        signature: false,
      },
    };
  },
  methods: {
    formValidate() {
      let isValid = true;
      for (const key in this.error) {
        if (!this.form[key]) {
          this.error[key] = true;
          isValid = false;
        }
      }
      return isValid;
    },
  },
};
</script>
