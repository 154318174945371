<template>
  <BaseLayout>
    <BlockForm
      v-if="readyToRenderForm"
      :key="readyToRenderForm"
      :property="property"
      :block-previous-data="block"
      :disabled-list="disabledList"
      :compulsory-list="compulsoryList"
      @submit-handle="submitHandle" />
    <ModalConfirmSubmit
      id="submitModal"
      message="区画情報を変更します。"
      @yes="submitForm"
      @no="abortProcess" />
    <ModalConfirmSubmit
      id="existModal"
      message="区画情報の変更をキャンセルします。"
      @yes="navigateBackTLI4004"
      @no="abortProcess" />
  </BaseLayout>
</template>
<script>
import $ from "jquery";
import BlockForm from "/src/components/dynamic-block/BlockForm.vue";
import ModalConfirmSubmit from "/src/components/modals/ModalConfirmSubmit.vue";
import { ORIGINAL_LOCATION } from "/src/frontend/src/constants.js";
import {
  getPropertyDetail,
  getPropertiesBlockDetail,
  uploadPropertyBlock,
  uploadImage,
} from "/src/service/properties.service.js";
import BaseLayout from "/src/components/layouts/BaseLayout.vue";
const { apiMessage } = require("/src/assets/validation.json");
export default {
  components: {
    BlockForm,
    ModalConfirmSubmit,
    BaseLayout,
  },
  beforeRouteEnter(to, from, next) {
    let block_id = to.params.block_id;
    getPropertiesBlockDetail(block_id).then((res) => {
      let blockData = res.data;
      getPropertyDetail(blockData.property_id).then((res) => {
        let propertyData = res.data;
        next((vm) => {
          vm.setData(blockData, propertyData);
        });
      });
    });
  },
  data() {
    return {
      block: null,
      property: null,
      image: null,
      disabledList: [],
      compulsoryList: [],
      formData: {},
      readyToRenderForm: false,
    };
  },
  computed: {
    tli() {
      if (this.property) {
        return (
          this.property.location_of_original_data === ORIGINAL_LOCATION.DYNAMIC
        );
      }
      return false;
    },
  },
  mounted() {
    this.disabledList = this.setDisabledList();
    this.compulsoryList = this.setCompulsoryList();
    // active left menu bar
    this.$store.dispatch(
      "properties/setRouteName",
      this.property.location_of_original_data
    );
    this.readyToRenderForm = true;
  },
  methods: {
    navigateBackTLI4004() {
      this.$router.push({
        name: "property-block",
        params: { id: this.block.block_id },
      });
      this.$store.dispatch(
        "alertMessage/showAlert",
        "区画情報の変更をキャンセルしました。"
      );
    },
    submitForm() {
      if (this.image) {
        // Case upload image
        let suffix = this.tli ? "tli" : "other";
        let form = new FormData();
        form.append("files", this.image);
        form.append(
          "names",
          `${this.property.property_id}_${
            this.block.block_id
          }_${suffix}_layout.${this.image.name.split(".").pop()}`
        );
        uploadImage(form)
          .then((res) => {
            this.formData["block_image_link"] = res.data[0].name;
          })
          .then((_) => {
            uploadPropertyBlock(this.block.block_id, this.formData).then(
              (res) => {
                let id = res.data.block_id;
                this.navigateAfterSubmit(id);
              }
            );
          });
      } else {
        // Case not upload image
        uploadPropertyBlock(this.block.block_id, this.formData).then((res) => {
          let id = res.data.block_id;
          this.navigateAfterSubmit(id);
        });
      }
    },
    submitHandle(event) {
      this.formData = { ...this.block, ...event.formData };
      this.image = event.image;
      $("#submitModal").modal("show");
    },
    setData(blockData, propertyData) {
      this.block = blockData;
      this.property = propertyData;
    },
    setDisabledList() {
      if (this.tli) {
        return [
          "selling_status",
          "block_name",
          "block_classification",
          "block_type",
          "area",
          "floor_number",
          "price",
        ];
      } else {
        return [];
      }
    },
    abortProcess() {
      this.$store.dispatch("alertMessage/showAlert", apiMessage.ABORT_PROCESS);
    },
    navigateAfterSubmit(id) {
      this.$router.push({ name: "property-block", params: { block_id: id } });
      this.$store.dispatch(
        "alertMessage/showAlert",
        "区画情報を変更しました。"
      );
    },
    setCompulsoryList() {
      if (this.tli) {
        return ["balcony_area", "management_fee", "repair_reserve_fee"];
      } else {
        return [
          "selling_status",
          "block_image_link",
          "block_name",
          "block_classification",
          "block_type",
          "layout_type",
          "area",
          "balcony_area",
          "floor_number",
          "price",
          "management_fee",
          "repair_reserve_fee",
        ];
      }
    },
  },
};
</script>
