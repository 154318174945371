<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-4 my-2">
      {{ label }}
      <span v-if="mandatory" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-7 my-2">
      <input
        v-model="mValue"
        class="form-control"
        maxlength="999"
        :type="type"
        :placeholder="placeholder"
        :disabled="disable"
        @focusout="handleFocusOut" />
      <p v-show="error !== ''" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { type: [String, Number], default: "" },
    label: { type: String, default: "" },
    type: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
    autoFocus: { type: Boolean, default: false },
    error: { type: String, default: "" },
    disable: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  computed: {
    mValue: {
      get() {
        return this.modelValue;
      },
      set(mValue) {
        this.$emit("update:modelValue", mValue);
      },
    },
  },
  mounted() {
    if (this.autoFocus) this.$el.querySelector("input").focus();
  },
  methods: {
    handleFocusOut() {
      this.$emit("update:modelValue", this.mValue);
    },
  },
};
</script>
