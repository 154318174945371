<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-3 my-2">
      <span class="form-title" :class="color">{{ label }}</span>
      <span v-if="compulsory" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-9 my-2 input-group" :class="unit">
      <div class="position-relative number-show-format w-100">
        <input
          :value="mValue"
          :type="disable ? 'text' : type"
          :placeholder="placeholder"
          class="form-control"
          :disabled="disabled"
          @input="mValue = $event.target.value"
          @focusout="handleFocusOut" />
        <div
          class="number-show-text form-control"
          :class="{ 'd-none': disabled }">
          <span>{{ mValue }}</span
          >{{ unit }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FieldInput",
  props: {
    color: { type: String, default: "bg-blue-light" },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    type: { type: String, default: "text" },
    modelValue: { type: [String, Number], default: "" },
    compulsory: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    unit: { type: String, default: "" },
  },
  // emits: ["update:modelValue"],
  data() {
    return {
      data: "",
      textType: "text",
      showData: false,
    };
  },
  computed: {
    mValue: {
      get() {
        if (this.data) return this.data;
        return this.modelValue;
      },
      set(value) {
        this.data = value;
      },
    },
  },
  methods: {
    handleFocusOut() {
      if (this.data) {
        this.$emit("update:modelValue", this.data);
        this.data = "";
      } else {
        this.$emit("update:modelValue", this.modelValue);
      }
    },
  },
};
</script>

<style>
.number-show-format .number-show-text {
  position: absolute;
  top: 2px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border: 0 !important;
  box-shadow: unset !important;
  border-radius: 10px;
  display: block;
  width: auto;
  height: auto;
}
.number-show-format:hover .number-show-text {
  display: none;
}
</style>
