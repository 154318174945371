<template>
  <div class="minh-520">
    <div class="row mb-4">
      <div class="col-md-12">
        <h3 class="card-title-cus">
          {{
            tli ? "販売受託・ルジェンテ新築物件" : "他社媒介・紹介新築等物件"
          }}
        </h3>
      </div>
    </div>
    <div class="cus-filter">
      <div class="card-body">
        <div class="row">
          <div class="minw-50"></div>
          <div class="col-md-3">
            <UploadImageFile
              :model-value="
                blockPreviousData ? blockPreviousData.block_image_link : ''
              "
              text-in-image="間取りアップロード"
              @focusout="
                handleImageUpload($event);
                removeEmptyErrorMessage(image, 'block_image_link');
              " />
            <div class="text-center fs-16 my-2">
              間取り画像
              <span class="badge badge-red">必 須</span>
              <p v-if="errorObject.block_image_link" class="error">
                {{ errorObject.block_image_link }}
              </p>
            </div>

            <!-- Show error below -->
          </div>
          <div class="col-md-8">
            <div class="row align-items-center justify-content-md-end">
              <div class="col-4 col-md-3 my-3">
                ステータス
                <span
                  v-if="ifCompulsory('selling_status')"
                  class="badge badge-red"
                  >必 須</span
                >
              </div>
              <div v-if="ifCompulsory('selling_status')" class="col-8 my-3">
                <div class="custom-select2 w-300">
                  <FieldSelect2
                    v-model="formDataSelect.selling_status"
                    :class="{ 'field-error': errorObject.selling_status }"
                    :options="BLOCK_SELLING_STATUS"
                    :keyid="'value'"
                    :keytext="'label'"
                    :disabled="ifDisabled('selling_status')"
                    :placeholder="'ステータス'"
                    :change="
                      removeEmptyErrorMessage(
                        formDataSelect.selling_status,
                        'selling_status'
                      )
                    " />
                </div>

                <p v-if="errorObject.selling_status" class="error">
                  {{ errorObject.selling_status }}
                </p>
              </div>
              <div v-else class="col-8 my-3">
                <input
                  :value="formDataSelect.selling_status"
                  type="text"
                  class="form-control"
                  placeholder=""
                  disabled />
              </div>
              <div class="col-4 col-md-3 my-3">
                <span class="form-title bg-blue-light">物件名</span>
              </div>
              <div class="col-8 my-3">
                <input
                  :value="property ? property.name : ''"
                  type="text"
                  class="form-control"
                  placeholder=""
                  disabled />
              </div>
              <div class="col-4 col-md-3 my-3">
                <span class="form-title bg-blue-light">区画名</span>
                <span v-if="ifCompulsory('block_name')" class="badge badge-red"
                  >必 須</span
                >
              </div>
              <div class="col-8 my-3">
                <input
                  v-model="formData.block_name"
                  :disabled="ifDisabled('block_name')"
                  maxlength="99"
                  type="text"
                  class="form-control"
                  :class="{ 'field-error': errorObject.block_name }"
                  placeholder="区画名"
                  @focusout="
                    removeEmptyErrorMessage(formData.block_name, 'block_name')
                  " />
                <p v-if="errorObject.block_name" class="error">
                  {{ errorObject.block_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative text-right mb-2">
      <div class="page-note text-left">
        ※お客様への提示項目は色付きで表示しています
      </div>
    </div>
    <div class="wrap-ct">
      <div class="my-4 mx-3 mx-md-5">
        <div class="custom-form col-xl-11">
          <form>
            <!-- 物件区分 -->
            <div v-if="!tli" class="row align-items-center">
              <div class="col-12 col-md-3 my-2">
                <span class="form-title bg-blue-light">物件区分</span>
                <span
                  v-if="ifCompulsory('block_classification')"
                  class="badge badge-red"
                  >必 須</span
                >
              </div>
              <div class="col-12 col-md-9 my-2">
                <div class="custom-select2 w-300">
                  <FieldSelect2
                    v-model="formDataSelect.block_classification"
                    :class="{
                      'field-error': errorObject.block_classification,
                    }"
                    :options="BLOCK_CLASSIFICATION"
                    :disabled="ifDisabled('block_classification')"
                    :keyid="'value'"
                    :keytext="'label'"
                    :placeholder="'物件区分'"
                    :change="
                      removeEmptyErrorMessage(
                        formDataSelect.block_classification,
                        'block_classification'
                      )
                    " />
                </div>
                <p v-if="errorObject.block_classification" class="error">
                  {{ errorObject.block_classification }}
                </p>
              </div>
            </div>
            <FieldInputText
              v-else
              :model-value="blockPreviousData.block_classification"
              :disabled="true"
              :label="`物件区分`" />
            <!-- タイプ -->
            <FieldInputText
              v-if="!tli"
              v-model="formData.block_type"
              :label="'タイプ'"
              color=""
              :error-message="errorObject.block_type"
              :compulsory="ifCompulsory('block_type')"
              :disabled="ifDisabled('block_type')"
              :placeholder="'タイプ'"
              max-length="99"
              @focus-out="
                removeEmptyErrorMessage(formData.block_type, 'block_type')
              " />
            <FieldInputText
              v-else
              :model-value="formData.block_type"
              :disabled="true"
              color=""
              :label="`タイプ`" />
            <!-- 間取り -->
            <div class="row align-items-center">
              <div class="col-12 col-md-3 my-2">
                <span class="form-title bg-blue-light">間取り</span>
                <span v-if="ifCompulsory('layout_type')" class="badge badge-red"
                  >必 須</span
                >
              </div>
              <div class="col-12 col-md-9 my-2">
                <div class="custom-select2 w-300">
                  <FieldSelect2
                    v-model="formDataSelect.layout_type"
                    :class="{ 'field-error': errorObject.layout_type }"
                    :disabled="ifDisabled('layout_type')"
                    :keyid="'value'"
                    :keytext="'label'"
                    :options="BLOCK_LAYOUT_TYPE"
                    :placeholder="'間取り'"
                    :change="
                      removeEmptyErrorMessage(
                        formDataSelect.layout_type,
                        'layout_type'
                      )
                    " />
                </div>
                <p v-if="errorObject.layout_type" class="error">
                  {{ errorObject.layout_type }}
                </p>
              </div>
            </div>
            <!-- 専有面積 -->
            <FieldInputNumber
              v-if="!tli"
              v-model="formData.area"
              :label="'専有面積'"
              :compulsory="ifCompulsory('area')"
              :disabled="ifDisabled('area')"
              :placeholder="'専有面積'"
              :error-message="errorObject.area"
              @focus-out="
                removeEmptyErrorMessage(formData.area, 'area');
                formData.area = roundDecimal($event);
                validateMax(
                  formData.area,
                  99999.99,
                  ERROR_MESSAGE['5'],
                  'area'
                );
              " />
            <FieldInputText
              v-else
              :model-value="
                func.showValue(
                  blockPreviousData.area
                    ? blockPreviousData.area.toFixed(2)
                    : func.formatNumber(blockPreviousData.area),
                  {
                    suffix: '㎡',
                  }
                )
              "
              :disabled="true"
              :label="`専有面積`" />
            <!-- バルコニー面積 -->
            <FieldInputNumber
              v-model="formData.balcony_area"
              :label="'バルコニー面積'"
              :compulsory="ifCompulsory('balcony_area')"
              :disabled="ifDisabled('balcony_area')"
              :placeholder="'バルコニー面積'"
              :error-message="errorObject.balcony_area"
              @focus-out="
                removeEmptyErrorMessage(formData.balcony_area, 'balcony_area');
                formData.balcony_area = roundDecimal($event);
                validateMax(
                  formData.balcony_area,
                  99999.99,
                  ERROR_MESSAGE['5'],
                  'balcony_area'
                );
              " />
            <!-- 階数 -->
            <FieldInputNumber
              v-if="!tli"
              v-model="formData.floor_number"
              :label="'階数'"
              :compulsory="ifCompulsory('floor_number')"
              :disabled="ifDisabled('floor_number')"
              :placeholder="'階数'"
              :error-message="errorObject.floor_number"
              @focus-out="
                removeEmptyErrorMessage(formData.floor_number, 'floor_number');
                formData.floor_number = roundInteger($event);
                validateMax(
                  formData.floor_number,
                  99,
                  ERROR_MESSAGE['2'],
                  'floor_number'
                );
              " />
            <FieldInputText
              v-else
              :model-value="
                func.showValue(blockPreviousData.floor_number, { suffix: '階' })
              "
              :disabled="true"
              :label="`階数`" />
            <!-- supply period self -->
            <FieldInputText
              v-if="tli"
              :model-value="blockPreviousData.supply_period"
              :disabled="true"
              color=""
              :label="`販売期`" />
            <!--税込総額（販売価格）  -->
            <FieldInputNumber
              v-if="!tli"
              v-model="formData.price"
              :label="'税込総額（販売価格）'"
              :compulsory="ifCompulsory('price')"
              :disabled="ifDisabled('price')"
              :placeholder="'税込総額（販売価格）'"
              :error-message="errorObject.price"
              @focus-out="
                removeEmptyErrorMessage(formData.price, 'price');
                formData.price = roundInteger($event);
                validateMax(
                  formData.price,
                  999999999999,
                  ERROR_MESSAGE['12'],
                  'price'
                );
              " />
            <FieldInputText
              v-else
              :model-value="
                func.showValue(func.formatNumber(blockPreviousData.price), {
                  suffix: '円',
                  includeZero: true,
                })
              "
              :disabled="true"
              :label="`税込総額（販売価格）`" />
            <!-- pre_price -->
            <FieldInputText
              v-if="tli"
              :model-value="
                func.showValue(func.formatNumber(blockPreviousData.pre_price), {
                  suffix: '円',
                  includeZero: true,
                })
              "
              color=""
              :disabled="true"
              :label="`プレ価格`" />

            <FieldInputNumber
              v-model="formData.management_fee"
              :label="'管理費'"
              :compulsory="ifCompulsory('management_fee')"
              :disabled="ifDisabled('management_fee')"
              :placeholder="'管理費'"
              :error-message="errorObject.management_fee"
              @focus-out="
                removeEmptyErrorMessage(
                  formData.management_fee,
                  'management_fee'
                );
                formData.management_fee = roundInteger($event);
                validateMax(
                  formData.management_fee,
                  99999999,
                  ERROR_MESSAGE['8'],
                  'management_fee'
                );
              " />
            <FieldInputNumber
              v-model="formData.repair_reserve_fee"
              :label="'修繕積立金'"
              :compulsory="ifCompulsory('repair_reserve_fee')"
              :disabled="ifDisabled('repair_reserve_fee')"
              :placeholder="'修繕積立金'"
              :error-message="errorObject.repair_reserve_fee"
              @focus-out="
                removeEmptyErrorMessage(
                  formData.repair_reserve_fee,
                  'repair_reserve_fee'
                );
                formData.repair_reserve_fee = roundInteger(
                  formData.repair_reserve_fee
                );
                validateMax(
                  formData.repair_reserve_fee,
                  99999999,
                  ERROR_MESSAGE['8'],
                  'repair_reserve_fee'
                );
              " />
            <!--Start Delivery_date -->
            <!-- Not disabled -->
            <DateTimeInput
              v-if="!tli"
              ref="delivery_date"
              v-model="formData.delivery_date"
              label="引渡予定日"
              :compulsory="true"
              @error-message="(value) => (errorObject.delivery_date = value)" />
            <!-- Disabled -->
            <div v-else>
              <div class="row align-items-center">
                <div class="col-12 col-md-3 my-2">
                  <span class="form-title bg-blue-light">引渡予定日</span>
                </div>
                <div class="col-12 col-md-9 my-2">
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    :value="
                      func.formatDate(blockPreviousData.delivery_date, {
                        dateStyle: 'long',
                      })
                    "
                    placeholder=""
                    disabled />
                </div>
              </div>
            </div>
            <!--End Delivery_date -->
            <FieldCheckBox
              :id="1"
              v-model="formData.roof_balcony_exist_flag"
              :error-message="errorObject.roof_balcony_exist_flag"
              :label="'ルーフバルコニー有無'"
              :compulsory="ifCompulsory('roof_balcony_exist_flag')"
              :disabled="ifDisabled('roof_balcony_exist_flag')"
              @click="
                removeEmptyErrorMessage(
                  formData.roof_balcony_exist_flag,
                  'roof_balcony_exist_flag'
                );
                checkBalconyCl();
              " />
            <FieldInputNumber
              v-model="formData.area_of_roof_balcony"
              :label="'ルーフバルコニー面積'"
              :error-message="errorObject.area_of_roof_balcony"
              :placeholder="'ルーフバルコニー面積'"
              @focus-out="
                removeEmptyErrorMessage(
                  formData.area_of_roof_balcony,
                  'area_of_roof_balcony'
                );
                formData.area_of_roof_balcony = roundDecimal($event);
                validateMax(
                  formData.area_of_roof_balcony,
                  99999.99,
                  ERROR_MESSAGE['5'],
                  'area_of_roof_balcony'
                );
              "
              @keyup="checkBalconyFc()" />
            <FieldInputNumber
              v-model="formData.usage_fee_of_roof_balcony"
              :label="'ルーフバルコニー使用料'"
              :error-message="errorObject.usage_fee_of_roof_balcony"
              :placeholder="'ルーフバルコニー使用料'"
              @focus-out="
                removeEmptyErrorMessage(
                  formData.usage_fee_of_roof_balcony,
                  'usage_fee_of_roof_balcony'
                );
                formData.usage_fee_of_roof_balcony = roundInteger($event);
                validateMax(
                  formData.usage_fee_of_roof_balcony,
                  99999999,
                  ERROR_MESSAGE['8'],
                  'usage_fee_of_roof_balcony'
                );
              "
              @keyup="checkBalconyFc()" />
            <FieldCheckBox
              :id="2"
              v-model="formData.private_garden_exist_flag"
              :label="'専用庭有無'"
              :error-message="errorObject.private_garden_exist_flag"
              :compulsory="ifCompulsory('private_garden_exist_flag')"
              :disabled="ifDisabled('private_garden_exist_flag')"
              @click="
                removeEmptyErrorMessage(
                  formData.private_garden_exist_flag,
                  'private_garden_exist_flag'
                );
                checkGardenCl();
              " />
            <FieldInputNumber
              v-model="formData.area_of_private_garden"
              :label="'専用庭面積'"
              :error-message="errorObject.area_of_private_garden"
              :placeholder="'専用庭面積'"
              @focus-out="
                removeEmptyErrorMessage(
                  formData.area_of_private_garden,
                  'area_of_private_garden'
                );
                formData.area_of_private_garden = roundDecimal($event);
                validateMax(
                  formData.area_of_private_garden,
                  99999.99,
                  ERROR_MESSAGE['5'],
                  'area_of_private_garden'
                );
              "
              @keyup="checkGardenFc()" />
            <FieldInputNumber
              v-model="formData.usage_fee_of_private_garden"
              :label="'専用庭使用料'"
              :error-message="errorObject.usage_fee_of_private_garden"
              :placeholder="'専用庭使用料'"
              @focus-out="
                removeEmptyErrorMessage(
                  formData.usage_fee_of_private_garden,
                  'usage_fee_of_private_garden'
                );
                formData.usage_fee_of_private_garden = roundInteger($event);
                validateMax(
                  formData.usage_fee_of_private_garden,
                  99999999,
                  ERROR_MESSAGE['8'],
                  'usage_fee_of_private_garden'
                );
              "
              @keyup="checkGardenFc()" />
            <FieldCheckBox
              :id="3"
              v-model="formData.private_terrace_exist_flag"
              :label="'専用テラス有無'"
              :error-message="errorObject.private_terrace_exist_flag"
              :compulsory="ifCompulsory('private_terrace_exist_flag')"
              :disabled="ifDisabled('private_terrace_exist_flag')"
              @click="
                removeEmptyErrorMessage(
                  formData.private_terrace_exist_flag,
                  'private_terrace_exist_flag'
                );
                checkTerraceCl();
              " />
            <FieldInputNumber
              v-model="formData.area_of_private_terrace"
              :label="'専用テラス面積'"
              :error-message="errorObject.area_of_private_terrace"
              :placeholder="'専用テラス面積'"
              @focus-out="
                removeEmptyErrorMessage(
                  formData.area_of_private_terrace,
                  'area_of_private_terrace'
                );
                formData.area_of_private_terrace = roundDecimal($event);
                validateMax(
                  formData.area_of_private_terrace,
                  99999.99,
                  ERROR_MESSAGE['5'],
                  'area_of_private_terrace'
                );
              "
              @keyup="checkTerraceFc()" />
            <FieldInputNumber
              :id="4"
              v-model="formData.usage_fee_of_private_terrace"
              :label="'専用テラス使用料'"
              :error-message="errorObject.usage_fee_of_private_terrace"
              :placeholder="'専用テラス使用料'"
              @focus-out="
                removeEmptyErrorMessage(
                  formData.usage_fee_of_private_terrace,
                  'usage_fee_of_private_terrace'
                );
                formData.usage_fee_of_private_terrace = roundInteger($event);
                validateMax(
                  formData.usage_fee_of_private_terrace,
                  99999999,
                  ERROR_MESSAGE['8'],
                  'usage_fee_of_private_terrace'
                );
              "
              @keyup="checkTerraceFc()" />
            <FieldCheckBox
              :id="4"
              v-model="formData.top_floor"
              :label="'最上階'"
              :compulsory="ifCompulsory('top_floor')"
              :disabled="ifDisabled('top_floor')" />
            <FieldCheckBox
              :id="5"
              v-model="formData.furnished"
              :label="'家具付き'"
              :compulsory="ifCompulsory('original_location')"
              :disabled="ifDisabled('original_location')" />
            <div class="row">
              <div class="col-12 col-md-3 my-2">
                <span class="form-title bg-blue-light">備考</span>
              </div>
              <div class="col-12 col-md-9 my-2">
                <textarea
                  v-model="formData.block_note"
                  type="text"
                  maxlength="9999"
                  class="form-control minh-120"
                  placeholder="備考"></textarea>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-12 col-md-3 my-2">
                <span class="form-title">レコメンド対象除外<br />フラグ</span>
              </div>
              <div class="col-12 col-md-9 my-2">
                <div class="form-check custom-checkbox mx-3">
                  <input
                    id="option9"
                    v-model="formData.recommend_stop_flag"
                    class="form-check-input"
                    type="checkbox" />
                  <label for="option9"></label>
                </div>
              </div>
            </div>
            <FieldInputText
              :model-value="convertDateTime(blockPreviousData?.created_at)"
              :disabled="true"
              color=""
              :label="`登録日時`" />
            <FieldInputText
              :model-value="convertDateTime(blockPreviousData?.updated_at)"
              :disabled="true"
              color=""
              :label="`更新日時`" />
            <FieldInputText
              :model-value="blockPreviousData?.updated_by_user?.name"
              :disabled="true"
              color=""
              :label="`更新者`" />
            <div class="my-4 text-center">
              <button
                type="button"
                class="btn btn-cus btn-blue-light m-2 ml-md-0 px-4 minw-100"
                :disabled="!isFormChange"
                @click="submitHandle">
                {{ registerURL ? "登録" : "変更" }}
              </button>
              <button
                type="button"
                class="btn btn-cus btn-grey m-2 w-150"
                data-toggle="modal"
                data-target="#existModal">
                キャンセル
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  roundDecimal,
  roundInteger,
  convertValueToString,
  scrollToTopElement,
} from "/src/components/utilitys/utility.js";
import {
  FieldInputText,
  FieldInputNumber,
  UploadImageFile,
  FieldCheckBox,
} from "/src/components/properties/utility/";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2.vue";
import DateTimeInput from "/src/components/utilitys/DateTimeInput.vue";
import {
  BLOCK_LAYOUT_TYPE,
  BLOCK_SELLING_STATUS,
  BLOCK_CLASSIFICATION,
  ORIGINAL_LOCATION,
} from "/src/constants";
import { testImageInvalid } from "/src/service/properties.service.js";
export default {
  components: {
    FieldInputText,
    FieldInputNumber,
    FieldCheckBox,
    UploadImageFile,
    FieldSelect2,
    DateTimeInput,
  },
  props: {
    property: {
      type: Object,
      default: null,
    },
    blockPreviousData: {
      type: Object,
      default: () => null,
    },
    disabledList: {
      type: Array,
      default: () => [],
    },
    compulsoryList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    // Define formData and formDataSelect
    let selectList = ["block_classification", "layout_type", "selling_status"];
    let formData = {
      area: null,
      area_of_roof_balcony: null,
      area_of_private_garden: null,
      area_of_private_terrace: null,
      balcony_area: null,
      block_classification: null,
      block_image_link: null,
      block_name: null,
      block_note: null,
      block_type: null,
      delivery_date: null,
      floor_number: null,
      furnished: false,
      layout_type: null,
      management_fee: null,
      price: null,
      private_garden_exist_flag: false,
      private_terrace_exist_flag: false,
      recommend_stop_flag: false,
      repair_reserve_fee: null,
      roof_balcony_exist_flag: false,
      selling_status: null,
      top_floor: false,
      usage_fee_of_private_garden: null,
      usage_fee_of_private_terrace: null,
      usage_fee_of_roof_balcony: null,
    };
    let formDataSelect = {
      block_classification: "",
      layout_type: "",
      selling_status: "",
    };
    // Assign formDataInput to formData and formDataSelect
    if (this.blockPreviousData != null) {
      for (let key in formData) {
        if (selectList.includes(key)) {
          formDataSelect[key] = convertValueToString(
            this.blockPreviousData[key]
          );
        } else {
          if (key.includes("area")) {
            formData[key] = roundDecimal(this.blockPreviousData[key]);
          } else {
            formData[key] = this.blockPreviousData[key];
          }
        }
      }
    }
    return {
      errorObject: {},
      formData,
      formDataSelect,
      image: null,
      selectList,
      BLOCK_LAYOUT_TYPE,
      BLOCK_SELLING_STATUS,
      BLOCK_CLASSIFICATION,
      ERROR_MESSAGE: {
        2: "2桁の半角数字のみ入力可能です。",
        4: "6桁の半角数字と小数点のみ入力可能です。",
        5: "半角数字と小数点のみ入力可能です。(整数5桁、小数点2桁)",
        6: "6桁の半角数字と小数点のみ入力可能です。",
        8: "8桁の半角数字のみ入力可能です。",
        12: "12桁の半角数字のみ入力可能です.",
        REQUIRED: "入力項目に不足、または不備があります。",
      },
    };
  },
  computed: {
    isFormChange() {
      if (this.blockPreviousData) {
        for (let key in this.formData) {
          if (this.selectList.includes(key)) {
            if (
              this.formDataSelect[key] !=
              convertValueToString(this.blockPreviousData[key])
            ) {
              return true;
            }
          } else {
            if (this.formData[key] != this.blockPreviousData[key]) {
              return true;
            }
          }
        }
        if (this.image) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    registerURL() {
      if (window.location.pathname.split("/").pop() == "register") {
        return true;
      }
      return false;
    },
    tli() {
      let lastPathURL = this.$route.path.split("/").pop();
      if (lastPathURL == "register") {
        return false;
      }
      if (this.property) {
        return (
          this.property.location_of_original_data == ORIGINAL_LOCATION.DYNAMIC
        );
      }
      return false;
    },
  },
  methods: {
    roundInteger(value) {
      return roundInteger(value);
    },
    roundDecimal(value) {
      return roundDecimal(value);
    },
    convertDateTime(date) {
      // increase space between date and time
      if (!date) {
        return "";
      }
      let dateTimeArray = this.func
        .formatDateToParts(date, {
          dateStyle: "long",
          timeStyle: "short",
        })
        .map((item) => item.value);
      return (
        dateTimeArray.slice(0, 6).join("") +
        "   " +
        dateTimeArray.slice(6).join("")
      );
    },
    // START VALIDATE METHODS
    validateMax(
      value,
      max,
      errorMessage,
      field,
      errorObject = this.errorObject,
      min = 0
    ) {
      if (value < min) {
        errorObject[field] = errorMessage;
      } else if (value > max) {
        errorObject[field] = errorMessage;
      } else if (errorObject[field] == errorMessage) {
        errorObject[field] = "";
      }
    },
    validateRequired(value) {
      if ([undefined, null, ""].includes(value)) {
        return false;
      }
      return true;
    },
    removeEmptyErrorMessage(value, field, errorObject = this.errorObject) {
      if (this.validateRequired(value)) {
        if (errorObject[field] == this.ERROR_MESSAGE.REQUIRED) {
          errorObject[field] = "";
        }
      }
    },
    async validateEmpty() {
      this.checkBalcony();
      this.checkGarden();
      this.checkTerrace();
      // Validate erroObject
      this.compulsoryList.forEach((field) => {
        if (field == "block_image_link") {
          // pass
        } else if (this.selectList.includes(field)) {
          if (!this.validateRequired(this.formDataSelect[field])) {
            this.errorObject[field] = this.ERROR_MESSAGE.REQUIRED;
          }
        } else if (!this.validateRequired(this.formData[field])) {
          this.errorObject[field] = this.ERROR_MESSAGE.REQUIRED;
        }
      });
      // Validate Image
      if (
        !(
          (this.blockPreviousData && this.blockPreviousData.block_image_link) ||
          this.image
        )
      ) {
        this.errorObject["block_image_link"] = this.ERROR_MESSAGE.REQUIRED;
      }
      // Validate delivery_date
      if (!this.tli) {
        this.$refs.delivery_date.validateDateEmpty();
      }
      // Validate Image with BE
      if (this.image) {
        let errorMessage = await testImageInvalid(this.image);
        if (errorMessage) {
          this.errorObject["block_image_link"] = errorMessage;
        } else {
          this.errorObject["block_image_link"] = "";
        }
      }
      // Validate errorObject
      for (let key in this.errorObject) {
        if (this.errorObject[key] != "") {
          return false;
        }
      }
      if (this.checkBalcony()) {
        return !this.checkBalcony();
      }
      if (this.checkGarden()) {
        return !this.checkGarden();
      }
      if (this.checkTerrace()) {
        return !this.checkTerrace();
      }
      return true;
    },
    checkBalcony() {
      if (
        this.formData.roof_balcony_exist_flag ||
        this.formData.area_of_roof_balcony ||
        this.formData.usage_fee_of_roof_balcony
      ) {
        let valss = false;
        if (this.formData.roof_balcony_exist_flag == false) {
          this.errorObject.roof_balcony_exist_flag =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.roof_balcony_exist_flag = "";
        }
        if (this.formData.area_of_roof_balcony == null) {
          this.errorObject.area_of_roof_balcony =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.area_of_roof_balcony = "";
        }
        if (this.formData.usage_fee_of_roof_balcony == null) {
          this.errorObject.usage_fee_of_roof_balcony =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.usage_fee_of_roof_balcony = "";
        }
        return valss;
      } else {
        this.errorObject.roof_balcony_exist_flag = "";
        this.errorObject.area_of_roof_balcony = "";
        this.errorObject.usage_fee_of_roof_balcony = "";
      }
      return false;
    },
    checkBalconyCl() {
      if (
        this.formData.roof_balcony_exist_flag == true &&
        this.formData.area_of_roof_balcony == null &&
        this.formData.usage_fee_of_roof_balcony == null
      ) {
        this.errorObject.roof_balcony_exist_flag = "";
        this.errorObject.area_of_roof_balcony = "";
        this.errorObject.usage_fee_of_roof_balcony = "";
      }
    },
    checkBalconyFc() {
      if (
        this.formData.roof_balcony_exist_flag == false &&
        (this.formData.area_of_roof_balcony == null ||
          this.formData.area_of_roof_balcony == "") &&
        (this.formData.usage_fee_of_roof_balcony == null ||
          this.formData.usage_fee_of_roof_balcony == "")
      ) {
        this.errorObject.roof_balcony_exist_flag = "";
        this.errorObject.area_of_roof_balcony = "";
        this.errorObject.usage_fee_of_roof_balcony = "";
      }
    },
    checkGarden() {
      if (
        this.formData.private_garden_exist_flag ||
        this.formData.area_of_private_garden ||
        this.formData.usage_fee_of_private_garden
      ) {
        let valss = false;
        if (this.formData.private_garden_exist_flag == false) {
          this.errorObject.private_garden_exist_flag =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.private_garden_exist_flag = "";
        }
        if (this.formData.area_of_private_garden == null) {
          this.errorObject.area_of_private_garden =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.area_of_private_garden = "";
        }
        if (this.formData.usage_fee_of_private_garden == null) {
          this.errorObject.usage_fee_of_private_garden =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.usage_fee_of_private_garden = "";
        }
        return valss;
      } else {
        this.errorObject.private_garden_exist_flag = "";
        this.errorObject.area_of_private_garden = "";
        this.errorObject.usage_fee_of_private_garden = "";
      }
      return false;
    },
    checkGardenCl() {
      if (
        this.formData.private_garden_exist_flag == true &&
        this.formData.area_of_private_garden == null &&
        this.formData.usage_fee_of_private_garden == null
      ) {
        this.errorObject.private_garden_exist_flag = "";
        this.errorObject.area_of_private_garden = "";
        this.errorObject.usage_fee_of_private_garden = "";
      }
    },
    checkGardenFc() {
      if (
        this.formData.private_garden_exist_flag == false &&
        (this.formData.area_of_private_garden == null ||
          this.formData.area_of_private_garden == "") &&
        (this.formData.usage_fee_of_private_garden == null ||
          this.formData.usage_fee_of_private_garden == "")
      ) {
        this.errorObject.private_garden_exist_flag = "";
        this.errorObject.area_of_private_garden = "";
        this.errorObject.usage_fee_of_private_garden = "";
      }
    },
    checkTerrace() {
      if (
        this.formData.private_terrace_exist_flag ||
        this.formData.area_of_private_terrace ||
        this.formData.usage_fee_of_private_terrace
      ) {
        let valss = false;
        if (this.formData.private_terrace_exist_flag == false) {
          this.errorObject.private_terrace_exist_flag =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.private_terrace_exist_flag = "";
        }
        if (this.formData.area_of_private_terrace == null) {
          this.errorObject.area_of_private_terrace =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.area_of_private_terrace = "";
        }
        if (this.formData.usage_fee_of_private_terrace == null) {
          this.errorObject.usage_fee_of_private_terrace =
            "入力項目に不足、または不備があります。";
          valss = true;
        } else {
          this.errorObject.usage_fee_of_private_terrace = "";
        }
        return valss;
      } else {
        this.errorObject.private_terrace_exist_flag = "";
        this.errorObject.area_of_private_terrace = "";
        this.errorObject.usage_fee_of_private_terrace = "";
      }
      return false;
    },
    checkTerraceCl() {
      if (
        this.formData.private_terrace_exist_flag == true &&
        this.formData.area_of_private_terrace == null &&
        this.formData.usage_fee_of_private_terrace == null
      ) {
        this.errorObject.private_terrace_exist_flag = "";
        this.errorObject.area_of_private_terrace = "";
        this.errorObject.usage_fee_of_private_terrace = "";
      }
    },
    checkTerraceFc() {
      if (
        this.formData.private_terrace_exist_flag == false &&
        (this.formData.area_of_private_terrace == null ||
          this.formData.area_of_private_terrace == "") &&
        (this.formData.usage_fee_of_private_terrace == null ||
          this.formData.usage_fee_of_private_terrace == "")
      ) {
        this.errorObject.private_terrace_exist_flag = "";
        this.errorObject.area_of_private_terrace = "";
        this.errorObject.usage_fee_of_private_terrace = "";
      }
    },
    // END VALIDATE METHODS
    // START SUB METHODS
    ifDisabled(name) {
      return this.disabledList.includes(name);
    },
    ifCompulsory(name) {
      return this.compulsoryList.includes(name);
    },
    mergeFormData() {
      // merge formData and formDataSelect
      let formData = { ...this.formData, ...this.formDataSelect };
      formData["property_id"] = this.property.property_id;
      return formData;
    },
    handleImageUpload(event) {
      this.image = event;
    },
    async submitHandle() {
      if (await this.validateEmpty()) {
        this.$emit("submitHandle", {
          formData: this.mergeFormData(),
          image: this.image,
        });
      } else {
        scrollToTopElement([".error"]);
      }
    },
  },
};
</script>
