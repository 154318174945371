<template>
  <div class="cus-filter">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-9">
          <div v-if="$route.name === 'customer-detail'" class="form-group">
            <label class="text-nowrap mr-3 font-weight-normal">
              ステータス:
            </label>
            <div class="d-inline-block my-2 mr-5">
              {{ getCustomerValue("contract_status") }}
            </div>
            <div class="d-inline-block my-2">
              <div class="custom-checkbox mr-3">
                <div class="pr-3">投資家</div>
                <input
                  id="option2"
                  class="form-check-input mx-auto"
                  type="checkbox"
                  :checked="getCustomerValue('investor_flag')"
                  disabled />
                <label for="option2"></label>
              </div>
            </div>
          </div>
          <div class="custom-table">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td class="col-6 col-sm-8">{{ customerName }}</td>
                  <td>{{ getCustomerValue("gender") }}</td>
                  <td class="text-nowrap">{{ customerBirthday }}</td>
                </tr>
                <tr>
                  <td>{{ customerPhoneNumber }}</td>
                  <td colspan="2" class="text-nowrap">
                    {{ getCustomerValue("email") }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3">{{ customerAddress }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { latinToFullWidth } from "/src/service/users.service.js";

export default {
  props: {
    customerData: { type: Object, default: null },
  },
  computed: {
    customer() {
      return { ...this.customerData };
    },
    customerName() {
      const kanji = this.customer.full_name_kanji || "";
      const furigana = this.customer.full_name_furigana || "";
      if (!kanji) return this.func.displayValue(furigana);
      if (!furigana) return this.func.displayValue(kanji);
      return `${kanji}（${furigana}）`;
    },
    customerBirthday() {
      const dateStr = this.customer.birthday;
      const date = Date.parse(dateStr);
      if (isNaN(date)) return this.func.displayValue(dateStr);
      const birthdayText = this.func.formatDate(dateStr, { dateStyle: "long" });
      const age = this.func.birthdayToAge(this.customer.birthday);
      const ageText = age > 0 ? `（${age}歳）` : "";
      return birthdayText + ageText;
    },
    customerAddress() {
      let postcode = this.customer.address_postcode || "";
      const splitAt = 3;
      if (!postcode.includes("-") && postcode.length > splitAt) {
        postcode = postcode.slice(0, splitAt) + "-" + postcode.slice(splitAt);
      }
      const prefecture = this.customer.address_prefecture || "";
      const district = this.customer.address_district || "";
      const detail = this.customer.address_detail || "";
      const building = this.customer.address_building_name || "";
      return [postcode, `${prefecture}${district}${detail}`, building]
        .filter((val) => val)
        .join(" ");
    },
    customerPhoneNumber() {
      const phone = this.customer.telephone_number || "";
      return latinToFullWidth(phone);
    },
  },
  methods: {
    getCustomerValue(key) {
      return this.func.displayValue(this.customer[key]);
    },
  },
};
</script>

<style scoped>
label {
  cursor: unset;
}
</style>
