<template>
  <ModalLayout>
    <h5 class="fs-18">レコメンド停止理由を次から選択してください。</h5>
    <div class="fs-16 my-5">
      <div class="row align-items-center justify-content-center">
        <div
          v-for="(reasonItem, index) in stopReasons"
          :key="index"
          class="form-check custom-checkbox mr-4">
          <input
            :id="'reason' + index"
            v-model="picked"
            class="form-check-input"
            type="radio"
            name="recommendation"
            :value="reasonItem.value" />
          <label :for="'reason' + index">{{ reasonItem.text }}</label>
        </div>
      </div>
    </div>
    <div class="text-center">
      <Button
        class="btn-blue-light mx-2 w-125 fs-14"
        text="OK"
        @click="submitReason()" />
      <Button
        class="btn-grey mx-2 w-125 fs-14"
        text="キャンセル"
        data-dismiss="modal" />
    </div>
  </ModalLayout>
</template>

<script>
import { rcmStopFlag } from "/src/helpers/select-options.js";

import ModalLayout from "/src/components/layouts/ModalLayout.vue";
import Button from "/src/components/utilitys/buttons/Button.vue";

import $ from "jquery";

export default {
  components: {
    ModalLayout,
    Button,
  },
  props: {
    nextModalId: { type: String, default: null },
    modelValue: { type: Number, default: null },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      stopReasons: [
        rcmStopFlag.EXCLUDE_OTHER_WEB,
        rcmStopFlag.EXCLUDE_COUPLE_WEB,
      ],
    };
  },
  computed: {
    picked: {
      get() {
        return this.modelValue;
      },
      set(mValue) {
        this.$emit("update:modelValue", mValue);
      },
    },
  },
  methods: {
    submitReason() {
      const currentModal = $(`#${this.$attrs.id}`);
      const nextModal = $(`#${this.nextModalId}`);

      // smooth transition between 2 modals
      currentModal.modal("hide");
      setTimeout(() => nextModal.modal("show"), 400);
    },
  },
};
</script>
