import { backToCustomerAction } from "/src/helpers/route-navigate.js";
import { getProperties } from "/src/service/properties.service.js";
import { getSaleFollowHistoryDetail } from "/src/service/sales-follow-histories.service.js";
import {
  API_DELAY,
  ORIGINAL_LOCATION,
  PROPERTY_TYPE,
} from "/src/frontend/src/constants.js";

const fetchProperties = async function (component, key_name, loc_type) {
  const params = { all: true, location_of_original_data: loc_type };
  const response = await getProperties(params);
  response.data.items.forEach((el) => {
    component.availableProperties[key_name].push({
      value: el.property_id,
      label: el.name,
    });
  });
};

export const fetchAvailableProperties = function (component, fetchDelay) {
  // fetch all dynamic properties
  setTimeout(() => {
    fetchProperties(component, "dynamic", ORIGINAL_LOCATION.DYNAMIC);
  }, fetchDelay);
  // fetch all other properties
  setTimeout(() => {
    fetchProperties(component, "other", ORIGINAL_LOCATION.OTHER);
  }, fetchDelay + API_DELAY);
};

export const fetchSalesFollowHistory = async function (component) {
  // Call API fetch id is `component.historyId`
  const response = await getSaleFollowHistoryDetail(component.historyId);
  const data = response.data;

  component.initFormData.user_id = data.user?.user_id;
  component.initFormData.follow_date = data.follow_date?.replaceAll("-", "/");
  component.initFormData.contact_method = data.contact_method;
  component.initFormData.contact_result = data.contact_result;
  component.initFormData.comment = data.comment;

  for (let i = 1; i <= 5; i++) {
    const key = `property_${i}`;
    const propObj = component.initFormData[key];

    propObj.name = data[`property_name_${i}`] ?? null;
    propObj.property_id = data[key]?.property_id ?? null;

    if (propObj.name) {
      propObj.checked = true;
      propObj.location_of_original_data =
        data[key]?.location_of_original_data ?? ORIGINAL_LOCATION.SALESFORCE;
    } else {
      // Reset checkbox and radio button if there no property
      propObj.checked = false;
      propObj.location_of_original_data = ORIGINAL_LOCATION.DYNAMIC;
    }
  }
  component.customerId = data.customer_id;
  component.customerName = data.customer?.full_name_kanji;
};

export const createRequestBody = function (customerId, formData) {
  const requestBody = {
    customer_id: customerId,
    follow_date: formData.follow_date.replaceAll("/", "-"),
    user_id: formData.user_id,
    contact_method: formData.contact_method,
    contact_result: formData.contact_result,
    comment: formData.comment,
  };

  for (let i = 1; i <= 5; i++) {
    const key = `property_${i}`;
    const property = formData[key];

    if (property) {
      requestBody[`property_table_type_${i}`] = calcPropertyType(
        property.location_of_original_data
      );
      requestBody[`property_id_${i}`] = property.property_id;
      // Skip name if property has property_id
      requestBody[`property_name_${i}`] = !property.property_id
        ? property.name
        : null;
    }
  }

  return requestBody;
};

export const navigateBack = function (component, action_type) {
  backToCustomerAction(
    component,
    component.customerId,
    action_type,
    "TabPanelFollow"
  );
};

export const calcPropertyType = function (loc_type) {
  switch (loc_type) {
    case ORIGINAL_LOCATION.SALESFORCE:
      return PROPERTY_TYPE.SALESFORCE_PROPERTY;

    case ORIGINAL_LOCATION.DYNAMIC:
    case ORIGINAL_LOCATION.OTHER:
      return PROPERTY_TYPE.PROPERTY;

    default:
      return null;
  }
};
