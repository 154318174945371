import axios from "axios";
import { CUSTOMER_ACTION_HISTORIES_API } from "./const.service.js";

const getActionHistories = async (params) => {
  return axios
    .get(CUSTOMER_ACTION_HISTORIES_API, { params: params })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const getActionHistoryDetail = async (history_id) => {
  return axios
    .get(CUSTOMER_ACTION_HISTORIES_API + "/" + history_id)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const deleteCustomerActionHistory = async (history_id) => {
  return axios
    .delete(CUSTOMER_ACTION_HISTORIES_API + "/" + history_id)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const createCustomerActionHistory = async (body) => {
  return axios
    .post(CUSTOMER_ACTION_HISTORIES_API, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const updateCustomerActionHistory = async (history_id, body) => {
  return axios
    .put(CUSTOMER_ACTION_HISTORIES_API + "/" + history_id, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

const getSendingCustomers = async (params) => {
  return axios.get(CUSTOMER_ACTION_HISTORIES_API + "/customers", { params });
};

export {
  getActionHistories,
  getActionHistoryDetail,
  deleteCustomerActionHistory,
  createCustomerActionHistory,
  updateCustomerActionHistory,
  getSendingCustomers,
};
