<template>
  <div
    :id="id"
    class="modal fade custom-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="my-4 text-center">
            <div class="fs-16 my-4">{{ message }} <br />よろしいですか？</div>
            <div class="text-center">
              <button
                type="button"
                class="button btn btn-cus btn-blue-light mx-2 w-125 fs-14"
                data-dismiss="modal"
                @click="$emit('yes')">
                はい
              </button>
              <button
                type="button"
                class="button btn btn-cus btn-grey mx-2 w-125 fs-14"
                data-dismiss="modal"
                @click="$emit('no')">
                いいえ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalConfirm",
  props: {
    id: {
      type: String,
      default: "confirmModal",
    },
    message: {
      type: String,
      default: "",
    },
  },
};
</script>
