function checkXO(value) {
  if (value == null) {
    return;
  }
  if (value == true) {
    return "○";
  } else {
    return "×";
  }
}

export { checkXO };
